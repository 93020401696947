<template>
  <div class="company-maximum-users">
    <div class="company-maximum-users-description">
      Number of users (incl. invited):
    </div>

    <div class="company-maximum-users-data">
      <div class="company-maximum-users-count">{{ usersCount }} / {{ allowedUsers }}</div>
      <IcoInfo v-tooltip="$t('15_2_2_tooltip_users_count')" />
    </div>
  </div>
</template>

<script>
import IcoInfo from '@/components/svg/small/IcoInfo.vue'

export default {
  components: {
    IcoInfo

  },
  computed: {

  },
  props: {
    usersCount: {
      type: Number,
      default: 0
    },
    allowedUsers: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {}
  },
  methods: {

  }
}
</script>

<style scoped>
.company-maximum-users {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  line-height: 1;
}

.company-maximum-users-description {
  line-height: 1;
  font-family: Nunito;
  font-size: 13px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
  color: #888F91;
}

.company-maximum-users-data {
  display: flex;
  align-content: center;
  align-items: center;
  gap: 8px;
  line-height: 1;
}

.company-maximum-users-data svg{
  width: 14px;
  height: 14px;
  line-height: 1;
}

.company-maximum-users-count {
  line-height: 1;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 700;
  text-align: right;
  color: #17252A;
}
</style>
