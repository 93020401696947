<template>
    <div
        class="company-avatar"
        :class="{'company-avatar--small': size === 'small'}"
        :style="{backgroundColor: color}"
        >
        <img v-if="imageValid" :src="cachedImageUrl" />
        <span v-if="!imageValid">
            {{ name }}
        </span>
    </div>
</template>

<script>
import { ColorService } from "@/services/ColorService.js";
import {URL, URLFiles} from "@/constants/URLConstant.js"
import api from "@/services/rest-api/restapi"

export default {
    props: {
        company: {
            type: Object
        },
        size: {
            type: String,
            default: 'normal'
        }
    },
    computed: {
        name() {
            if(this.company != null) {

                let name = this.company.name.split(" ");
                if( name.length === 1 ) {
                    return name[0].charAt(0);
                } else {
                    return name[0].charAt(0) + name[1].charAt(0);
                }
            }
        },
        color() {
          if (this.imageValid) {
                return 'transparent';
            }

            if(this.company != null) {
                return ColorService.computedColor(this.company.name);
            }
        },
      cachedImageUrl() {
        return this.imageUrl ? `${this.imageUrl}?${this.cacheBuster}` : ''
      },
      updateCompanyLogo() {
        return this.$store.getters.getCompanyLogo
      },
    },
    created() {
        this.loadImage()
    },
    data() {
      return {
        imageUrl: '',
        imageValid: false,
        cacheBuster: Date.now(),
      }
    },
    methods: {
        async loadImage() {
          this.imageValid = false;

          if (!this.company.id) {
            this.imageUrl = ''
            return
          }

          this.imageUrl = `${URLFiles}/images/company/${this.company.id}.jpg`

          const img = new Image()
          img.src = this.imageUrl
          img.onload = () => {
            this.imageValid = true
          }
        }
    },
    watch: {
      company() {
        this.cacheBuster = Date.now();
        this.loadImage()
      },
      updateCompanyLogo() {
        this.cacheBuster = Date.now();
        this.loadImage();
      }
    }
}
</script>
