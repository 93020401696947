var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu-wrapper"},[_c('div',{staticClass:"menu clearfix"},[_c('div',{staticClass:"menu__controll"},[_c('div',{staticClass:"menu__group"},[_c('Logo'),_c('CompanySelect')],1),_c('div',{staticClass:"menu__items"},[_c('ul',[_c('li',{class:{
              'is-selected':
                (_vm.$route.name === 'Calendar' ||
                  _vm.$route.name === 'CalendarDay' ||
                  _vm.$route.name === 'CalendarWeek' ||
                  _vm.$route.name === 'List') &&
                _vm.selectedCompany &&
                !_vm.selectedCompany.billingOverdue,
              'is-disabled':
                _vm.selectedCompany && _vm.selectedCompany.billingOverdue,
            }},[_c('router-link',{attrs:{"to":"/calendar"}},[_vm._v(" "+_vm._s(_vm.$t('5_1_menu_calendar'))+" ")])],1),_c('li',{class:{
              'is-disabled':
                _vm.selectedCompany && _vm.selectedCompany.billingOverdue,
              'is-selected': _vm.$route.name === 'Dashboard',
            }},[_c('router-link',{attrs:{"to":{ name: 'Dashboard' }}},[_vm._v(" "+_vm._s(_vm.$t('5_1_menu_dashboard'))+" ")])],1),_c('li',{class:{
              'is-disabled':
                _vm.selectedCompany && _vm.selectedCompany.billingOverdue,
              'is-selected': _vm.isRouteActiveCompany,
            }},[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.handleOpenCompany.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('5_1_menu_company'))+" ")])]),_c('li',{class:{
              'is-disabled':
                _vm.selectedCompany && _vm.selectedCompany.billingOverdue,
              'is-selected': _vm.$route.name === 'Ideas',
            }},[_c('router-link',{attrs:{"to":"/ideas"}},[_vm._v(_vm._s(_vm.$t('5_1_menu_ideas')))])],1)])]),(_vm.role !== 'READ_ONLY')?_c('div',{staticClass:"menu__create"},[(_vm.selectedCompany && !_vm.selectedCompany.billingOverdue)?_c('button',{staticClass:"menu__create__btn",on:{"click":_vm.addContent}},[_c('IcoPlus'),_vm._v(" "+_vm._s(_vm.$t('5_1_menu_add_content'))+" ")],1):_vm._e()]):_vm._e()]),_c('div',{staticClass:"menu__icons"},[_c('MenuNotification'),_c('MenuUser'),_c('MenuFeedback')],1)]),(
      _vm.selectedCompany &&
      _vm.selectedCompany.expirationDays &&
      _vm.selectedCompany.showExpirationWarning &&
      !_vm.selectedCompany.billingOverdue
    )?_c('MenuRenewsSubscription',{attrs:{"days":_vm.selectedCompany.expirationDays}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }