<template>
  <button @click="handleOpenLink"><IcoOpenLink /></button>
</template>

<script>
import IcoOpenLink from "@/components/buttonOpenLink/IcoOpenLink.vue";
export default {
  components: {
    IcoOpenLink,
  },
  props: ['link', 'title', 'description'],
  data() {
    return {}
  },
  methods: {
    async handleOpenLink() {
      window.open(this.link, '_blank');
    },
  }
}
</script>

<style scoped>
  button {
    cursor: pointer;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    min-width: 46px;
    max-width: 46px;
    min-height: 46px;
    max-height: 46px;
    background-color: #F6F6F7;
    border-radius: 9px;
    border: none;
    padding: 0;
    margin: 0;
  }

  button:hover {
    color: #F6F6F7;
    background-color: #17252A;
  }
</style>
