import axios from 'axios';

// Create an Axios instance
const api = axios.create({
  baseURL: 'https://your-api-base-url.com',
});

// Function to get access token from local storage or Vuex
const getAccessToken = () => {
  return localStorage.getItem('access_token');
};

// Function to save the new access token to local storage or Vuex
const saveAccessToken = (token) => {
  localStorage.setItem('access_token', token);
};

// Function to get the refresh token from local storage or Vuex
const getRefreshToken = () => {
  return localStorage.getItem('refresh_token');
};

// Attach access token to every request
api.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle token expiration
let isRefreshing = false;
let refreshSubscribers = [];

const onRefreshed = (newToken) => {
  refreshSubscribers.map((cb) => cb(newToken));
};

const addRefreshSubscriber = (cb) => {
  refreshSubscribers.push(cb);
};

api.interceptors.response.use(
  (response) => response, // Return the response if no error
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const refreshToken = getRefreshToken();

          // Call the refresh token endpoint
          const response = await axios.post('https://your-api-base-url.com/refresh-token', {
            refresh_token: refreshToken,
          });

          const newAccessToken = response.data.access_token;
          saveAccessToken(newAccessToken);

          isRefreshing = false;
          onRefreshed(newAccessToken);

          refreshSubscribers = [];

          return api(originalRequest); // Retry the original request with the new token
        } catch (err) {
          isRefreshing = false;
          refreshSubscribers = [];
          return Promise.reject(err);
        }
      }

      // If token is being refreshed, queue the requests
      return new Promise((resolve) => {
        addRefreshSubscriber((newToken) => {
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          resolve(api(originalRequest));
        });
      });
    }

    return Promise.reject(error);
  }
);

export default api;
