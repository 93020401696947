<template>
  <ContentWrapper :disable-top-offset="true">

    <div class="custom-header">
      <h2 class="custom-header-title">{{ $t('15_1_1_title') }}</h2>
      <h2 class="custom-header-meta">{{ $t('15_1_1_title_meta_1') }}<a href="#">{{ $t('15_1_1_title_meta_link') }}</a>{{ $t('15_1_1_title_meta_2') }}</h2>
    </div>

    <StorageIndicator
      :content="contentPercentageTotalSize"
      :company="0"
      :meeting="0"
      :limit="sizes.convertMBToBytes(company.storageLimitMb)"
      :used="company.storageUsed"
    />

    <StorageManagementStatusCard
      color="#2CE0C1"
      type="content"
      :title="$t('15_1_1_subtitle_content_cards')"
      :items="company.storageData.contents"
      :contents-used="company.storageData.contentsUsed"
    />
    <StorageManagementStatusCard
      color="#9795FF"
      :title="$t('15_1_1_subtitle_company_assets')"
      :items="[]"
      :contents-used="0"
    />
    <StorageManagementStatusCard
      color="#40D2F2"
      :title="$t('15_1_1_subtitle_meeting_notes')"
      :items="[]"
      :contents-used="0"
    />
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/sources/ContentWrapper.vue";
import Button from "@/components/button/Button.vue";
import StorageManagementStatusCard from "../components/storageManagement/StorageManagementStatusCard.vue";
import StorageIndicator from "../components/storageIndicator/StorageIndicator.vue";
import COMPANY_STORAGE from "@/graphql/CompanyStorage.gql";
import sizes from "../../../utils/sizes";

export default {
  name: 'StorageManagement',
  components: {
    StorageIndicator,
    StorageManagementStatusCard,
    ContentWrapper,
    Button
  },
  apollo: {
    companies: {
      query: COMPANY_STORAGE,
      variables() {
        return {
          id: this.companyID,
        }
      },
      skip() {
        return !this.companyID
      },
    },
  },
  computed: {
    company() {
      const empty = {
        storageUsed: 0,
        storageLimitMb: 0,
        storageData: {
          contents: [],
          contentsUsed: 0
        }
      }
      if(!this.companies) {
        return empty
      }

      if(!Array.isArray(this.companies)) {
        return empty
      }

      return this.companies[0];
    },
    sizes() {
      return sizes
    },
    contentPercentageTotalSize() {
      if (!this.companies) return 0;
      if (!Array.isArray(this.companies)) return 0;

      const limit = sizes.convertMBToBytes(this.companies[0].storageLimitMb);
      const used = this.companies[0].storageUsed;

      if (limit === 0 || used === 0) {
        return 0;
      }

      return Math.round(((used/limit) * 100) * 10) / 10;
    },
    companyID() {
      if (!this.$route.params.id) {
        if (this.selectedCompany) {
          return this.selectedCompany.id
        }
      } else {
        return this.$route.params.id
      }
    },
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
    role() {
      return this.$store.getters.getMeCompanyRole
    },
  },
  data() {
    return {
      showList: true,
      resources: [],
      resourcesIsOpen: false,
      resourcesIsOpenShowError: false,

      showModalEditChannels: false,
      showModalEditImportances: false,
      showModalDelete: false,
    }
  },
  methods: {

  }
}
</script>

<style scoped>
.custom-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-header-title {
  font-family: Nunito;
  font-size: 28px;
  font-weight: 800;
  line-height: 27px;
  text-align: left;
  color: #17252A;
}

.custom-header-meta {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  text-align: left;
  color: #888F91;
}

.custom-header-meta a{
  text-decoration: none;
  color: #2CE0C1;
}

.custom-header-meta a:hover{
  text-decoration: underline;
}
</style>
