<template>
  <div class="modal-edit-content">
    <div class="modal-edit-content-header">
      <div class="modal-edit-content-title">
        <strong>{{ $t('Edit') }} {{ content.title }}</strong>
      </div>
      <button @click="handleCancel" >
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 6.5L6 18.5M6 6.5L18 18.5" stroke="#888F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>

    <div
      v-if="content.choices.length > 0"
      class="modal-edit-content-tab"
    >
      <ul>
        <li>
          <button
            @click="handleSetTab(0)"
            :class="{'active': activeTab === 0}"
          >
            {{ content.choicesLabel }}
          </button>
        </li>
        <li>
          <button
            @click="handleSetTab(1)"
            :class="{'active': activeTab === 1}"
          >
            {{ $t('Custom') }}
          </button>
        </li>
      </ul>
    </div>

    <div class="modal-edit-content-body">
      <TinymceEditor
        v-if="activeTab === 1"
        tinymceRef="contentTinymceRef"
        :placeholder="$t('Placeholder')"
        :height="300"
        v-model="content.data"
      ></TinymceEditor>

      <div
          v-if="content.choices.length > 0 && activeTab === 0"
          class="modal-edit-content-body-list"
      >
        <div class="column"
             v-for="(choice, index) in choiceList"
             :key="`choice-${index}`"
        >
          <Dropdown
            v-model="choice.value"
            :label="content.choicesLabel"
            :placeholder="content.choicesDescription"
            :items="choicesValues"
          />
          <ButtonAdd v-if="index === choiceList.length - 1" @click="handleAddItem" />
          <ButtonRemove v-else @click="handleRemoveItem(index)" />
        </div>
      </div>

    </div>

    <div class="modal-edit-content-footer">
      <Button @click="handleCancel" type="secondary">{{ $t('Cancel') }}</Button>
      <Button @click="handleUpdate" type="primary">{{ $t('Save') }}</Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/button/Button.vue";
import Dropdown from "@/components/dropdown/Dropdown.vue";
import TextAreaEditor from "@/components/TextAreaEditor.vue";
import textLength from "@/constants/TextLengthConstant";
import WYSIWYGEditor from "@/components/WYSIWYGEditor.vue";
import TinymceEditor from "@/components/TinymceEditor.vue";
import ButtonAdd from "../../buttonCopy/ButtonAdd.vue";
import ButtonRemove from "../../buttonRemove/ButtonRemove.vue";
import {obj_clone} from "../../../services/helpers/clone";
export default {
  components: {
    ButtonRemove,
    ButtonAdd,
    TinymceEditor,
    WYSIWYGEditor,
    TextAreaEditor,
    Button,
    Dropdown
  },
  computed: {
    choicesToSave() {
      return this.choiceList
        .filter((item) => item.value.value)
        .map((item) => item.value.value);
    },
    length() {
      return textLength
    },
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    choicesValues() {
      const selectedChoicesIds = this.choiceList.map(item => item.value.value);

      return this.content.choices
        .filter(item => !selectedChoicesIds.includes(item.id))
        .map(item => ({
          name: item.name,
          value: item.id
        }));
    },
  },
  props: ['content'],
  data() {
    return {
      emptyRecord: {
        name: null,
        value: ''
      },
      choiceList: [{value: obj_clone(this.emptyRecord)}],

      formValidation: {
        content: true,
      },
      contentIsOpenShowError: false,
      contentIsOpen: true,
      activeTab: 1,
    }
  },
  created() {
    if (this.content.selectedChoices.length > 0) {
      this.choiceList = [];
      this.content.selectedChoices.forEach((item) => {
        this.choiceList.push({value: {name: item.name, value: item.id}})
      });

      this.activeTab = 0;
    }
  },
  methods: {
    handleCancel() {
      this.$emit('close');
    },
    handleUpdate() {
      if (this.content.choices.length) {
        this.$emit('update', {
          data: this.content.data,
          type: this.content.type,
          selectedChoicesIds: this.choicesToSave
        });
      } else {
        this.$emit('update', {
          data: this.content.data,
          type: this.content.type,
          selectedChoicesIds: null
        });
      }
    },
    handleAddItem() {
      const empty = obj_clone(this.emptyRecord);
      this.choiceList.push({value: empty});
    },
    handleRemoveItem(index) {
      this.choiceList.splice(index,1);
    },
    handleSetTab(index) {
      this.activeTab = index;
    }
  }
}
</script>

<style scoped>
.modal-edit-content {
  width: 600px;
}

.modal-edit-content-header {
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-edit-content-header button {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.modal-edit-content-title {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
  text-align: left;
  color: #17252A;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.modal-edit-content-title span {
  position: relative;
  font-family: Nunito;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  display: inline-block;
  color: #888F91;
}

.modal-edit-content-title span:after {
  content: '';
  position: absolute;
  left: -10px;
  top: 4px;
  bottom: 4px;
  width: 1px;
  background-color: #888F91;
}

.modal-edit-content-body {
  margin-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
}

.modal-edit-content-body-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex-shrink: 0;
  flex-grow: 0;
  max-width: 100%;
}

.modal-edit-content-body-list > div{
  flex-shrink: 0;
  flex-grow: 0;
  max-width: 100%;
}

.modal-edit-content-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 32px;
  border-top: 1px solid #E7E9E9;
  padding: 32px;
}

.modal-edit-content-tab {
  margin-top: 32px;
  margin-left: 32px;
  margin-right: 32px;
  background-color: #E7E9E9;
  border-radius: 4px;
  padding: 4px;
}

.modal-edit-content-tab ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.modal-edit-content-tab ul li {
  min-width: 50%;
  max-width: 50%;
}

.modal-edit-content-tab ul li button {
  border: none;
  background-color: #E7E9E9;
  border-radius: 4px;
  color: #888F91;
  text-align: center;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
  padding: 16px 12px;
  width: 100%;
  cursor: pointer;
}

.modal-edit-content-tab ul li button.active {
  color: #17252A;
  background-color: #fff;
}
</style>
