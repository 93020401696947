<template>
  <FormGroup :label="$t('Contacts')">
    <div class="contacts">
      <ContactsItem
        v-for="(item, index) in value"
        :key="index"
        :item="item"
        @update="update(index, $event)"
        @delete="deleteItem(index)"
      />

      <div>
        <Button @click="handleAddItem" size="small" type="secondary">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.00065 3.33325V12.6666M3.33398 7.99992H12.6673" stroke="#17252A" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          {{ $t('15_1_contacts_btn_add_contact') }}
        </Button>
      </div>
    </div>
  </FormGroup>
</template>

<script>
import ContactsItem from "./ContactsItem.vue";
import FormGroup from "../../../FormGroup.vue";
import Button from '@/components/button/Button.vue'

export default {
  name: 'Contacts',
  components: { FormGroup, ContactsItem, Button },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  methods: {
    handleAddItem() {
      const newItem = { name: '', description: '', emails: '', phoneNumbers: '', isNew: true };
      const updatedItems = [...this.value, newItem];
      this.$emit('input', updatedItems);
    },
    update(index, updatedItem) {
      const updatedItems = [...this.value];
      updatedItems[index] = updatedItem;
      this.$emit('input', updatedItems);
    },
    deleteItem(index) {
      const updatedItems = [...this.value];
      updatedItems.splice(index, 1);
      this.$emit('input', updatedItems);
    }
  },
}
</script>

<style scoped>
.contacts {
  padding: 16px 16px 8px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
}
</style>
