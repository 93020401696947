<template>
  <FormGroup :label="'Colors'" :required="false">
    <div class="colors-picker">

      <div v-if="colors.length > 0" class="colors-picker-items">
        <ColorPicker v-for="(color, index) in colors" :key="index" :color="color" />
      </div>

      <div class="colors-picker-buttons">
        <div class="colors-picker-buttons-data">
          <IcoColorPickerEmpty v-if="colors.length > 0" />
          <Button @click="addItem" :size="'small'" :type="'secondary'">
            <IcoAdd />
            {{ $t('15_3_4_color_picker_add_color') }}
          </Button>
        </div>

        <div class="colors-picker-buttons-info">{{ $t('15_3_4_color_picker_description') }}</div>
      </div>
    </div>
  </FormGroup>
</template>

<script>
import FormGroup from "@/components/FormGroup.vue";
import Button from "@/components/button/Button.vue";
import IcoAdd from "@/components/svg/IcoAdd.vue";
import ColorPicker from "@/components/colorsPicker/ColorPicker.vue";
import {obj_clone} from "@/services/helpers/clone";
import IcoColorPickerEmpty from "@/components/colorsPicker/IcoColorPickerEmpty.vue";

export default {
  components: {
    IcoColorPickerEmpty,
    ColorPicker,
    IcoAdd,
    Button,
    FormGroup
  },
  computed: {

  },
  data() {
    return {
      colors: [],
      emptyColor: {
        title: 'Black',
        hsl: '#536635',
        rgb: 'rgb(23,37,42)',
        cmyk: 'cmyk(45,12,0,84)'
      }
    }
  },
  methods: {
    addItem() {
      this.colors.push(obj_clone(this.emptyColor));
    }
  }
}
</script>

<style scoped>
  .colors-picker {
    padding: 16px;
  }

  .colors-picker-items {
    margin-bottom: 20px;
    display: flex;
    gap: 8px;
    flex-direction: column;
  }

  .colors-picker-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .colors-picker-buttons-data {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .colors-picker-buttons-info {
    font-family: Nunito;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.02em;
    text-align: left;
    color: #AEB3B5;
  }
</style>
