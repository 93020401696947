<template>
  <div
    class="contacts-item"
    :class="{'has-error': hasError}"
  >
    <div
      v-if="!isEditing"
      class="contacts-item-read"
    >
      <div class="contacts-item-read-content">
        <div class="contacts-item-read-content-name">{{ item.name }}</div>
        <div class="contacts-item-read-content-description">{{ item.description }}</div>

        <div
          v-if="item.emails.length > 0"
          class="contacts-item-read-content-emails"
        >
          <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.16667 4.83398H8.5L10.1667 9.00065L8.08333 10.2507C8.9758 12.0603 10.4404 13.5249 12.25 14.4173L13.5 12.334L17.6667 14.0007V17.334C17.6667 17.776 17.4911 18.1999 17.1785 18.5125C16.866 18.8251 16.442 19.0007 16 19.0007C12.7494 18.8031 9.68346 17.4227 7.38069 15.12C5.07792 12.8172 3.69754 9.75126 3.5 6.50065C3.5 6.05862 3.67559 5.6347 3.98816 5.32214C4.30072 5.00958 4.72464 4.83398 5.16667 4.83398Z" stroke="#9795FF" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

          <div class="contacts-item-read-content-emails-data">
            <a
              v-for="(item, index) in emailsArray"
              :key="`email-${index}`"
              :href="`mailto:${item}`"
              class="contacts-item-read-content-emails-item"
            >
              {{ item }}
            </a>
          </div>

          <button @click="handleCopyEmailsToClipboard">
            <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.3333 8.16732V6.50065C14.3333 6.05862 14.1577 5.6347 13.8451 5.32214C13.5325 5.00958 13.1086 4.83398 12.6666 4.83398H5.99992C5.55789 4.83398 5.13397 5.00958 4.82141 5.32214C4.50885 5.6347 4.33325 6.05862 4.33325 6.50065V13.1673C4.33325 13.6093 4.50885 14.0333 4.82141 14.3458C5.13397 14.6584 5.55789 14.834 5.99992 14.834H7.66659M7.66659 9.83398C7.66659 9.39196 7.84218 8.96803 8.15474 8.65547C8.4673 8.34291 8.89122 8.16732 9.33325 8.16732H15.9999C16.4419 8.16732 16.8659 8.34291 17.1784 8.65547C17.491 8.96803 17.6666 9.39196 17.6666 9.83398V16.5007C17.6666 16.9427 17.491 17.3666 17.1784 17.6792C16.8659 17.9917 16.4419 18.1673 15.9999 18.1673H9.33325C8.89122 18.1673 8.4673 17.9917 8.15474 17.6792C7.84218 17.3666 7.66659 16.9427 7.66659 16.5007V9.83398Z" stroke="#888F91" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>

        <div
          v-if="item.phoneNumbers.length > 0"
          class="contacts-item-read-content-phones"
        >
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.5 6.33268C2.5 5.89065 2.67559 5.46673 2.98816 5.15417C3.30072 4.84161 3.72464 4.66602 4.16667 4.66602H15.8333C16.2754 4.66602 16.6993 4.84161 17.0118 5.15417C17.3244 5.46673 17.5 5.89065 17.5 6.33268M2.5 6.33268V14.666C2.5 15.108 2.67559 15.532 2.98816 15.8445C3.30072 16.1571 3.72464 16.3327 4.16667 16.3327H15.8333C16.2754 16.3327 16.6993 16.1571 17.0118 15.8445C17.3244 15.532 17.5 15.108 17.5 14.666V6.33268M2.5 6.33268L10 11.3327L17.5 6.33268" stroke="#9795FF" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

          <div class="contacts-item-read-content-phones-data">
            <a
              v-for="(item, index) in phoneNumbersArray"
              :key="`tel-${index}`"
              :href="`tel:${item}`"
              class="contacts-item-read-content-phones-item"
            >
              {{ item }}
            </a>
          </div>

          <button @click="handleCopyPhoneNumbersToClipboard">
            <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.3333 8.16732V6.50065C14.3333 6.05862 14.1577 5.6347 13.8451 5.32214C13.5325 5.00958 13.1086 4.83398 12.6666 4.83398H5.99992C5.55789 4.83398 5.13397 5.00958 4.82141 5.32214C4.50885 5.6347 4.33325 6.05862 4.33325 6.50065V13.1673C4.33325 13.6093 4.50885 14.0333 4.82141 14.3458C5.13397 14.6584 5.55789 14.834 5.99992 14.834H7.66659M7.66659 9.83398C7.66659 9.39196 7.84218 8.96803 8.15474 8.65547C8.4673 8.34291 8.89122 8.16732 9.33325 8.16732H15.9999C16.4419 8.16732 16.8659 8.34291 17.1784 8.65547C17.491 8.96803 17.6666 9.39196 17.6666 9.83398V16.5007C17.6666 16.9427 17.491 17.3666 17.1784 17.6792C16.8659 17.9917 16.4419 18.1673 15.9999 18.1673H9.33325C8.89122 18.1673 8.4673 17.9917 8.15474 17.6792C7.84218 17.3666 7.66659 16.9427 7.66659 16.5007V9.83398Z" stroke="#888F91" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
      </div>
      <div class="contacts-item-read-control">
        <button
          @click="handleEdit"
        >
          <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.66675 4.16675H2.00008C1.64646 4.16675 1.30732 4.30722 1.05727 4.55727C0.807224 4.80732 0.666748 5.14646 0.666748 5.50008V11.5001C0.666748 11.8537 0.807224 12.1928 1.05727 12.4429C1.30732 12.6929 1.64646 12.8334 2.00008 12.8334H8.00008C8.3537 12.8334 8.69284 12.6929 8.94289 12.4429C9.19294 12.1928 9.33341 11.8537 9.33341 11.5001V10.8334M8.66675 2.83341L10.6667 4.83341M11.5901 3.89007C11.8526 3.62751 12.0002 3.27139 12.0002 2.90007C12.0002 2.52875 11.8526 2.17264 11.5901 1.91007C11.3275 1.64751 10.9714 1.5 10.6001 1.5C10.2288 1.5 9.87265 1.64751 9.61008 1.91007L4.00008 7.50007V9.50007H6.00008L11.5901 3.89007Z" stroke="#17252A" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>

        <!--
        <button
          v-if="!confirmDeleteReadonly"
          @click="handleDeleteReadonly"
        >
          <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.666748 4.16667H11.3334M4.66675 6.83333V10.8333M7.33341 6.83333V10.8333M1.33341 4.16667L2.00008 12.1667C2.00008 12.5203 2.14056 12.8594 2.39061 13.1095C2.64065 13.3595 2.97979 13.5 3.33341 13.5H8.66675C9.02037 13.5 9.35951 13.3595 9.60956 13.1095C9.8596 12.8594 10.0001 12.5203 10.0001 12.1667L10.6667 4.16667M4.00008 4.16667V2.16667C4.00008 1.98986 4.07032 1.82029 4.19534 1.69526C4.32037 1.57024 4.48994 1.5 4.66675 1.5H7.33341C7.51023 1.5 7.67979 1.57024 7.80482 1.69526C7.92984 1.82029 8.00008 1.98986 8.00008 2.16667V4.16667" stroke="#17252A" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>

        <Button
          v-if="confirmDeleteReadonly"
          v-click-outside="cancelDeleteItemReadonly"
          @click="handleConfirmDeleteReadonly"
          :type="'delete'"
          :size="'slim'"
          class="button-confirm-delete"
        >
          <IcoBin />
        </Button>
        -->
      </div>
    </div>

    <div
      v-if="isEditing"
      class="contacts-item-edit"
    >
      <input
        v-model="item.name"
        type="text"
        class="name"
        :placeholder="$t('15_1_contacts_placeholder_full_name')"
      >
      <textarea
        v-model="item.description"
        :placeholder="$t('15_1_contacts_placeholder_description')"
      ></textarea>

      <div
        class="contacts-item-edit-box"
      >
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.16667 3.83398H7.5L9.16667 8.00065L7.08333 9.25065C7.9758 11.0603 9.44039 12.5249 11.25 13.4173L12.5 11.334L16.6667 13.0007V16.334C16.6667 16.776 16.4911 17.1999 16.1785 17.5125C15.866 17.8251 15.442 18.0007 15 18.0007C11.7494 17.8031 8.68346 16.4227 6.38069 14.12C4.07792 11.8172 2.69754 8.75126 2.5 5.50065C2.5 5.05862 2.67559 4.6347 2.98816 4.32214C3.30072 4.00958 3.72464 3.83398 4.16667 3.83398Z" stroke="#888F91" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <input
          v-model="item.phoneNumbers"
          type="text"
          :placeholder="$t('15_1_contacts_placeholder_phone_number')"
        >
      </div>

      <div
        class="contacts-item-edit-box"
      >
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.5 6.33268C2.5 5.89065 2.67559 5.46673 2.98816 5.15417C3.30072 4.84161 3.72464 4.66602 4.16667 4.66602H15.8333C16.2754 4.66602 16.6993 4.84161 17.0118 5.15417C17.3244 5.46673 17.5 5.89065 17.5 6.33268M2.5 6.33268V14.666C2.5 15.108 2.67559 15.532 2.98816 15.8445C3.30072 16.1571 3.72464 16.3327 4.16667 16.3327H15.8333C16.2754 16.3327 16.6993 16.1571 17.0118 15.8445C17.3244 15.532 17.5 15.108 17.5 14.666V6.33268M2.5 6.33268L10 11.3327L17.5 6.33268" stroke="#888F91" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <input
          v-model="item.emails"
          type="text"
          :placeholder="$t('15_1_contacts_placeholder_email')"
        >
      </div>

      <div
        class="contacts-item-edit-buttons"
      >
        <Button
          @click="handleUpdate"
          :size="'small'"
          :type="'primary'"
        >
          {{ $t('15_1_contacts_btn_save') }}
        </Button>

        <Button
          @click="handleCancel"
          :size="'small'"
          :type="'secondary'"
        >
          {{ $t('15_1_contacts_btn_cancel') }}
        </Button>

        <Button
          v-if="!confirmDelete"
          :size="'slim'"
          @click="handleDelete"
        >
          <IcoBin />
        </Button>

        <Button
          v-if="confirmDelete"
          v-click-outside="cancelDeleteItem"
          @click="handleConfirmDelete"
          :type="'delete'"
          :size="'slim'"
          class="button-confirm-delete"
        >
          <IcoBin />
        </Button>
      </div>
    </div>

  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

import Button from '@/components/button/Button.vue'
import IcoBin from '@/components/svg/small/IcoBin.vue'
import IcoEdit from '@/components/svg/small/IcoEdit.vue'
import IconExternal from '@/components/svg/small/IconExternal.vue'
import IcoDuplicate from '@/components/svg/small/IcoDuplicate.vue'
import {obj_clone} from "../../../../services/helpers/clone";
export default {
  name: 'ContactsItem',
  components: {
    Button,
    IcoBin,
    IcoEdit,
    IconExternal,
    IcoDuplicate,
  },
  directives: {
    ClickOutside,
  },
  computed: {
    phoneNumbersArray() {
      return this.item.phoneNumbers.split(",");
    },
    emailsArray() {
      return this.item.emails.split(",");
    }
  },
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => ({
        name: '',
        description: '',
        emails: '',
        phoneNumbers: ''
      })
    }
  },
  data() {
    return {
      itemOriginal: '',

      confirmDelete: false,
      confirmDeleteReadonly: false,

      title: 'Copied to clipboard',
      description: '',
      hasError: false,
      isEditing: this.edit,
    }
  },
  created() {
    this.itemOriginal = obj_clone(this.item);

    if (this.item.isNew) {
      this.isEditing = true;
    }
  },
  watch: {
    edit(newValue) {
      this.isEditing = newValue; // Sync with parent prop if it changes

      if (this.item.isNew) {
        this.isEditing = true;
      }
    }
  },
  methods: {
    handleDeleteReadonly() {
      this.confirmDeleteReadonly = true;
    },
    cancelDeleteItemReadonly() {
      this.confirmDeleteReadonly = false;
    },
    handleConfirmDeleteReadonly() {
      this.$emit('delete');
    },
    handleConfirmDelete() {
      this.$emit('delete');
    },
    cancelDeleteItem() {
      this.confirmDelete = false;
    },
    handleCancel() {
      this.item.name = this.itemOriginal.name;
      this.item.description = this.itemOriginal.description;
      this.item.emails = this.itemOriginal.emails;
      this.item.phoneNumbers = this.itemOriginal.phoneNumbers;
      this.item.isNew = false;

      this.isEditing = false;
    },
    handleUpdate() {
      this.hasError = false;
      if (this.item.name === '') {
        this.hasError = true;
        return;
      }
      this.isEditing = false;
      this.$emit('update', this.item);
    },
    handleEdit() {
      this.isEditing = true; // Enter edit mode
    },
    handleDelete() {
      window.alert("handle delete");
      this.confirmDelete = true;

      this.$notify({
        title: this.$t('toaster_info_title_confirm_delete'),
        text: this.$t('toaster_info_description_confirm_delete'),
        type: 'info',
        duration: 5000,
      });

      setTimeout(() => {
        this.confirmDelete = false;
      }, 2000);
    },
    async handleCopyEmailsToClipboard() {
      let itemsText = Array.isArray(this.item.emails) ? this.item.emails.join(", ") : this.item.emails;
      await navigator.clipboard.writeText(itemsText);
      this.$notify({
        title: this.$t(this.title),
        text: this.$t(this.description),
        type: 'info',
        duration: 5000,
      });
    },
    async handleCopyPhoneNumbersToClipboard() {
      let itemsText = Array.isArray(this.item.phoneNumbers) ? this.item.phoneNumbers.join(", ") : this.item.phoneNumbers;
      await navigator.clipboard.writeText(itemsText);
      this.$notify({
        title: this.$t(this.title),
        text: this.$t(this.description),
        type: 'info',
        duration: 5000,
      });
    }
  }
}

</script>

<style scoped>
.contacts-item-read {
  display: flex;
  justify-content: space-between;
}

.contacts-item-read-content {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.contacts-item-read-control {
  display: flex;
  gap: 8px;
  min-width: 60px;
  max-width: 60px;
  justify-content: flex-end;
}

.contacts-item-read-control button {
  height: 24px;
  width: 24px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  background: rgba(231, 233, 233, 1);
  border-radius: 4px;
  border: none;
  cursor: pointer;
  }

.contacts-item-read-content-name {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 600;
  line-height: 22.4px;
  text-align: left;
  color: rgba(23, 37, 42, 1);
}

.contacts-item-read-content-description {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: rgba(136, 143, 145, 1);
}

.contacts-item-read-content-emails {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.contacts-item-read-content-emails-item {
  text-decoration: none;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: rgba(23, 37, 42, 1);
}

.contacts-item-read-content-emails-item:hover {
  text-decoration: underline;
}

.contacts-item-read-content-phones {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.contacts-item-read-content-phones-item {
  text-decoration: none;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: rgba(23, 37, 42, 1);
}

.contacts-item-read-content-phones-item:hover {
  text-decoration: underline;
}

.contacts-item-read-content-emails button,
.contacts-item-read-content-phones button{
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  line-height: 1;
}

input {
  box-sizing: border-box;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  padding: 0 10px;
  width: 100%;
  height: 32px;

  line-height: 32px;
}

input:focus {
  outline: none;
  border-color: #2ce0c1;
}

textarea {
  box-sizing: border-box;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  padding: 0 10px;
  width: 100%;
  height: 64px;
  resize: none;
  line-height: 32px;
}

textarea:focus {
  outline: none;
  border-color: #2ce0c1;
}

.contacts-item-edit {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.contacts-item-edit-buttons {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.contacts-item-edit-box {
  border: 1px solid rgba(209, 212, 212, 1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 8px;
}

.contacts-item-edit-box input {
  border: none;
}

.contacts-item.has-error input.name{
  border: 1px solid #f45050;
}
</style>
