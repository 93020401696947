<template>
  <div class="menu-user" v-click-outside="hide">
    <Avatar v-if="user" :name="user.name" :user="user" @click.native="toggle" />

    <div v-if="show" class="menu-user__data">
      <div class="menu-user__wrapper">
        <div class="menu-user__label">{{ user.name }}</div>
        <ul class="menu-user__list">
          <li>
            <router-link to="/profile">{{
              $t('5_2_link_profile')
            }}</router-link>
          </li>
        </ul>
        <div class="menu-user__label menu-user__label--secondary">
          {{ $t('5_2_app_language') }}
        </div>
        <LanguageSelect />
      </div>

      <div class="menu-user__logout">
        <div class="menu-user__wrapper">
          <button @click="logout" class="menu-user__btn">
            <span class="menu-user__btn__ico">
              <IcoLougout />
            </span>
            {{ $t('5_2_link_logout') }}
          </button>
        </div>
      </div>
    </div>

    <ModalLogout v-if="showModalLogout" @closeModal="closeLogoutModal" />
  </div>
</template>

<script>
import ME from '@/graphql/Me.gql'
import ClickOutside from 'vue-click-outside'
import IcoLougout from '@/components/svg/IcoLogout.vue'

import Avatar from '@/components/Avatar.vue'
import LanguageSelect from '@/components/LanguageSelect.vue'
import ModalLogout from '@/components/ModalLogout.vue'

export default {
  components: {
    IcoLougout,
    Avatar,
    LanguageSelect,
    ModalLogout,
  },
  directives: {
    ClickOutside,
  },
  computed: {
    refreshUserAvatar() {
      return this.$store.getters.getRefreshUserAvatar
    },
    user() {
      return this.$store.getters.getMe
    },
  },
  data() {
    return {
      me: '',
      showModalLogout: false,
      show: false,
    }
  },
  methods: {
    logout() {
      this.showModalLogout = true
    },
    closeLogoutModal() {
      this.showModalLogout = false
    },
    toggle() {
      this.show = !this.show
    },
    hide() {
      this.show = false
    },
  },
}
</script>
