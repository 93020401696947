<template>
  <div class="boxed boxed--small">
    <div class="boxed-wrapper">
      <div class="column column--80gap">
        <div class="left">
          <div class="budgets-no-data">
            <h1>{{ $t('empty_cards_tags_title') }}</h1>
            <p>{{ $t('empty_cards_tags_description') }}</p>
            <div class="budgets-no-data-buttons">
              <Button type="primary" @click="handleAdd" size="nav"><IcoPlus /> {{ $t('empty_cards_tags_btn_add') }}</Button>
              <Button @click="handleDelete" size="nav">
                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.33203 4.83333H14.6654M6.33203 8.16667V13.1667M9.66536 8.16667V13.1667M2.16536 4.83333L2.9987 14.8333C2.9987 15.2754 3.17429 15.6993 3.48685 16.0118C3.79941 16.3244 4.22334 16.5 4.66536 16.5H11.332C11.7741 16.5 12.198 16.3244 12.5105 16.0118C12.8231 15.6993 12.9987 15.2754 12.9987 14.8333L13.832 4.83333M5.4987 4.83333V2.33333C5.4987 2.11232 5.5865 1.90036 5.74278 1.74408C5.89906 1.5878 6.11102 1.5 6.33203 1.5H9.66536C9.88638 1.5 10.0983 1.5878 10.2546 1.74408C10.4109 1.90036 10.4987 2.11232 10.4987 2.33333V4.83333" stroke="#888F91" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                {{ $t('empty_cards_tags_btn_delete') }}
              </Button>
            </div>
          </div>
        </div>
        <div class="right">
          <svg width="400" height="217" viewBox="0 0 400 217" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5156_74944)">
              <path d="M179.263 210.687H185.046L187.797 188.381L179.262 188.381L179.263 210.687Z" fill="#FFB8B8"/>
              <path d="M196.203 216.057L178.027 216.057L178.027 209.035L189.18 209.035C190.102 209.035 191.016 209.216 191.868 209.569C192.72 209.922 193.494 210.439 194.146 211.091C194.798 211.743 195.315 212.517 195.668 213.369C196.021 214.221 196.203 215.134 196.203 216.057Z" fill="#2F2E41"/>
              <path d="M125.891 207.369L131.554 208.54L138.766 187.254L130.407 185.525L125.891 207.369Z" fill="#FFB8B8"/>
              <path d="M141.391 216.057L123.591 212.377L125.013 205.5L135.935 207.758C137.759 208.135 139.359 209.221 140.382 210.778C141.405 212.334 141.768 214.233 141.391 216.057Z" fill="#2F2E41"/>
              <path d="M141.577 135.601C141.089 135.108 140.716 134.514 140.482 133.861C140.249 133.208 140.162 132.512 140.226 131.821C140.291 131.131 140.507 130.463 140.858 129.865C141.208 129.267 141.686 128.753 142.257 128.359L137.77 112.111L146.102 114.813L149.086 129.828C149.684 130.831 149.893 132.018 149.673 133.165C149.454 134.312 148.822 135.338 147.896 136.05C146.97 136.761 145.816 137.108 144.651 137.026C143.486 136.943 142.393 136.436 141.577 135.601Z" fill="#FFB8B8"/>
              <path d="M177.573 202.507C177.069 202.508 176.58 202.329 176.196 202.002C175.812 201.675 175.557 201.221 175.477 200.723L164.699 141.337C164.675 141.185 164.603 141.045 164.493 140.938C164.383 140.831 164.242 140.762 164.089 140.742C163.937 140.723 163.783 140.753 163.649 140.829C163.516 140.905 163.411 141.022 163.35 141.163L139.338 196.715C139.124 197.201 138.737 197.591 138.252 197.807C137.767 198.024 137.218 198.051 136.713 197.885L128.279 195.051C128.004 194.959 127.751 194.811 127.535 194.617C127.319 194.423 127.146 194.187 127.025 193.923C126.904 193.659 126.839 193.373 126.832 193.083C126.826 192.793 126.88 192.505 126.99 192.237L138.598 163.801L148.29 120.898L148.358 120.846C148.545 120.704 167.189 106.789 179.929 117.92C191.588 128.105 189.418 186.309 188.865 197.874C188.845 198.331 188.677 198.769 188.386 199.122C188.095 199.475 187.697 199.723 187.252 199.83L178.088 202.445C177.92 202.486 177.747 202.507 177.573 202.507Z" fill="#2F2E41"/>
              <path d="M159.305 70.5752C165.704 70.5752 170.891 65.3882 170.891 58.9898C170.891 52.5913 165.704 47.4043 159.305 47.4043C152.906 47.4043 147.719 52.5913 147.719 58.9898C147.719 65.3882 152.906 70.5752 159.305 70.5752Z" fill="#FFB8B8"/>
              <path d="M181.831 130.411C181.343 129.918 180.969 129.324 180.736 128.671C180.503 128.018 180.415 127.322 180.48 126.632C180.545 125.941 180.761 125.273 181.112 124.675C181.462 124.077 181.94 123.563 182.511 123.17L178.023 106.922L186.356 109.623L189.34 124.638C189.938 125.641 190.147 126.829 189.927 127.975C189.708 129.122 189.075 130.149 188.15 130.86C187.224 131.572 186.07 131.919 184.905 131.836C183.74 131.753 182.647 131.246 181.831 130.411Z" fill="#FFB8B8"/>
              <path d="M146.464 125.78C146.303 125.78 146.144 125.748 145.995 125.687C141.102 123.699 145.003 84.8783 145.172 83.2267L145.19 83.156C145.311 82.8798 145.434 82.6093 145.557 82.3443C146.763 79.6641 148.782 77.4317 151.329 75.9642C153.875 74.4967 156.819 73.8685 159.743 74.169C162.714 74.3973 165.541 75.5422 167.833 77.4456C170.126 79.3491 171.771 81.9172 172.541 84.7954L181.607 118.011C181.704 118.364 181.708 118.736 181.618 119.09C181.529 119.445 181.349 119.771 181.096 120.035C180.844 120.3 180.527 120.495 180.177 120.601C179.827 120.708 179.455 120.721 179.098 120.641C172.044 119.066 154.949 116.269 148.577 124.305C147.794 125.293 147.097 125.78 146.464 125.78Z" fill="#2CE0C1"/>
              <path d="M181.593 122.592C181.36 122.592 181.129 122.553 180.909 122.478C180.638 122.388 180.388 122.242 180.174 122.052C179.961 121.861 179.789 121.629 179.668 121.369L171.778 104.549L164.1 82.4503C163.772 81.5002 163.723 80.4755 163.961 79.4987C164.199 78.5219 164.713 77.6342 165.442 76.9416C166.044 76.3707 166.774 75.9514 167.57 75.7183C168.367 75.4852 169.207 75.445 170.022 75.6012C170.837 75.7573 171.604 76.1053 172.257 76.6163C172.911 77.1272 173.434 77.7865 173.783 78.5396L183.999 100.61L191.97 115.008C192.107 115.256 192.193 115.529 192.223 115.811C192.254 116.092 192.227 116.377 192.145 116.649C192.063 116.92 191.928 117.172 191.747 117.391C191.566 117.609 191.344 117.788 191.092 117.919L182.569 122.351C182.268 122.509 181.933 122.591 181.593 122.592Z" fill="#2CE0C1"/>
              <path d="M147.118 126.726C147.041 126.726 146.965 126.722 146.889 126.714L137.336 125.7C137.052 125.67 136.776 125.583 136.527 125.444C136.277 125.304 136.058 125.116 135.882 124.89C135.707 124.664 135.579 124.405 135.507 124.128C135.434 123.851 135.418 123.563 135.46 123.28L138.174 104.9L143.981 82.2381C144.232 81.2645 144.758 80.3837 145.496 79.7008C146.234 79.0179 147.152 78.5619 148.143 78.3871C148.96 78.2438 149.8 78.2972 150.593 78.5428C151.385 78.7884 152.108 79.2192 152.701 79.7995C153.294 80.3799 153.741 81.0931 154.004 81.8802C154.267 82.6673 154.338 83.5057 154.213 84.326L150.537 108.367L149.229 124.772C149.187 125.303 148.946 125.799 148.555 126.161C148.164 126.523 147.651 126.724 147.118 126.726Z" fill="#2CE0C1"/>
              <path d="M161.137 52.7602L170.765 52.059C171.09 49.8809 170.675 47.6568 169.586 45.7424C168.498 43.8281 166.799 42.3342 164.76 41.4996C160.656 39.9407 155.562 41.6723 153.986 44.1558C151.91 43.9756 149.82 44.2713 147.875 45.0203C145.931 45.7693 144.182 46.952 142.764 48.4783C141.345 50.0047 140.293 51.8345 139.688 53.8285C139.083 55.8225 138.941 57.9283 139.272 59.9856C139.603 62.0429 140.4 63.9975 141.6 65.7008C142.801 67.404 144.374 68.811 146.2 69.8148C148.026 70.8186 150.057 71.3926 152.139 71.4932C154.22 71.5939 156.297 71.2185 158.212 70.3957C154.238 64.8186 156.278 55.9549 161.137 52.7602Z" fill="#2F2E41"/>
              <path d="M143.624 47.442C140.636 44.7124 136.984 42.5304 132.966 42.0484C128.948 41.5663 124.557 43.0385 122.244 46.359C119.931 49.6795 120.334 54.8408 123.588 57.2468C124.924 58.2348 125.937 59.5963 126.5 61.1597C127.063 62.7231 127.151 64.4182 126.751 66.0311C126.352 67.644 125.483 69.1024 124.256 70.2223C123.028 71.3422 121.496 72.0734 119.853 72.3236C113.879 73.2335 109.38 79.3892 109.828 85.4157C110.275 91.4422 115.213 96.6962 121.104 98.0484C126.994 99.4005 133.439 97.0751 137.55 92.6457C141.661 88.2162 143.501 81.9275 143.033 75.9026C142.565 69.8777 139.919 64.138 136.117 59.4412C135.084 58.1658 135.86 56.1434 137.187 55.1777C138.513 54.212 140.203 53.9422 141.756 53.412C143.309 52.8818 144.912 51.883 145.27 50.2816C145.628 48.6802 143.812 46.8101 143.624 47.442Z" fill="#2F2E41"/>
              <path d="M317.982 83.0476H251.469C249.342 83.0452 247.304 82.1996 245.801 80.6963C244.297 79.193 243.452 77.1547 243.449 75.0287V8.51891C243.452 6.3929 244.297 4.35465 245.801 2.85133C247.304 1.34801 249.342 0.502393 251.469 0.5H317.982C320.109 0.502393 322.147 1.34801 323.65 2.85133C325.154 4.35465 325.999 6.3929 326.002 8.51891V75.0287C325.999 77.1547 325.154 79.193 323.65 80.6963C322.147 82.1996 320.109 83.0452 317.982 83.0476ZM251.469 1.4434C249.593 1.44552 247.794 2.19166 246.468 3.51811C245.141 4.84457 244.395 6.64302 244.393 8.51891V75.0287C244.395 76.9046 245.141 78.703 246.468 80.0295C247.794 81.356 249.593 82.1021 251.469 82.1042H317.982C319.858 82.1021 321.657 81.356 322.984 80.0295C324.31 78.703 325.056 76.9046 325.058 75.0287V8.51891C325.056 6.64302 324.31 4.84457 322.984 3.51811C321.657 2.19166 319.858 1.44552 317.982 1.4434H251.469Z" fill="#3F3D56"/>
              <path d="M282.591 54.3436C281.225 54.356 279.871 54.0805 278.618 53.5352C277.365 52.9899 276.24 52.187 275.318 51.1786C273.164 48.8264 272.11 45.415 272.426 41.819C272.69 38.6137 274.032 35.5926 276.235 33.2493C277.286 32.1121 278.559 31.2017 279.974 30.5737C281.39 29.9458 282.919 29.6137 284.467 29.5978C285.902 29.5914 287.322 29.8872 288.635 30.4658C289.947 31.0444 291.124 31.8928 292.087 32.956C293.185 34.1974 294.013 35.653 294.52 37.2307C295.027 38.8085 295.201 40.4742 295.032 42.1226C294.709 45.7771 293.29 48.8809 290.926 51.0981C288.664 53.2035 285.682 54.3645 282.591 54.3436ZM284.467 33.0569C280.095 33.0569 276.32 37.0389 275.872 42.1224C275.641 44.7545 276.368 47.2038 277.869 48.8425C278.466 49.4992 279.195 50.0212 280.01 50.3733C280.824 50.7255 281.704 50.8998 282.591 50.8845C286.67 50.8845 291.033 48.0813 291.586 41.8187C291.713 40.6482 291.597 39.4641 291.244 38.3408C290.891 37.2175 290.309 36.1795 289.536 35.292C288.895 34.5846 288.113 34.02 287.239 33.635C286.366 33.2499 285.422 33.0529 284.467 33.0569Z" fill="#2CE0C1"/>
              <path d="M299.029 54.3895C298.644 54.3904 298.259 54.3574 297.879 54.2909C296.88 54.1186 295.927 53.7449 295.078 53.1926C294.228 52.6403 293.5 51.921 292.937 51.0783C291.708 49.2486 291.189 46.7968 291.436 43.9881L292.565 31.1826C292.606 30.7263 292.826 30.305 293.178 30.0111C293.529 29.7172 293.983 29.5747 294.439 29.6149C294.896 29.6551 295.317 29.8747 295.612 30.2256C295.907 30.5764 296.05 31.0298 296.011 31.4862L294.882 44.2916C294.559 47.9631 295.905 50.4279 298.483 50.885C299.367 51.0417 300.957 50.7985 302.525 49.5968C304.127 48.3696 306.131 45.8331 306.582 40.7192C307.099 34.8523 305.599 29.8156 302.246 26.1536C298.73 22.3145 293.407 20.2853 286.854 20.2853C274.808 20.2853 264.151 30.0812 263.097 42.122C262.89 44.3874 263.053 46.6712 263.579 48.8843C263.687 49.3302 263.613 49.8006 263.373 50.1918C263.134 50.5831 262.749 50.8632 262.303 50.9706C261.857 51.078 261.386 51.0039 260.995 50.7645C260.604 50.5251 260.324 50.14 260.216 49.6941C259.603 47.1174 259.412 44.4582 259.651 41.8203C260.339 35.0468 263.489 28.7613 268.503 24.1547C273.517 19.5481 280.046 16.9406 286.854 16.8262C294.404 16.8262 300.608 19.2437 304.797 23.8175C308.766 28.1513 310.623 34.2616 310.027 41.0227C309.467 47.3899 306.782 50.6929 304.629 52.3426C303.033 53.6118 301.068 54.3299 299.029 54.3895Z" fill="#2CE0C1"/>
              <path d="M282.73 67.1165C277.053 67.1618 271.554 65.1375 267.261 61.4222C267.09 61.2723 266.95 61.0902 266.85 60.8863C266.749 60.6824 266.69 60.4607 266.675 60.2339C266.661 60.0071 266.691 59.7796 266.764 59.5644C266.838 59.3493 266.953 59.1507 267.103 58.98C267.253 58.8093 267.435 58.6699 267.639 58.5697C267.843 58.4695 268.065 58.4105 268.292 58.3961C268.519 58.3816 268.746 58.4121 268.961 58.4856C269.176 58.5591 269.375 58.6743 269.545 58.8246C273.207 61.9844 277.894 63.7024 282.73 63.6574C286.608 63.6574 288.955 63.3984 292.608 61.8633C292.818 61.7753 293.043 61.7293 293.27 61.7281C293.497 61.7269 293.722 61.7704 293.932 61.8562C294.143 61.9421 294.334 62.0685 294.496 62.2283C294.657 62.3881 294.786 62.5782 294.874 62.7876C294.962 62.9971 295.007 63.2218 295.009 63.449C295.01 63.6762 294.966 63.9013 294.88 64.1116C294.794 64.3219 294.668 64.5133 294.508 64.6747C294.348 64.8361 294.158 64.9644 293.948 65.0523C289.771 66.8076 287.002 67.1165 282.73 67.1165Z" fill="#2CE0C1"/>
              <path d="M254.201 216.5H74.4717C74.3466 216.5 74.2266 216.45 74.1382 216.362C74.0497 216.273 74 216.153 74 216.028C74 215.903 74.0497 215.783 74.1382 215.695C74.2266 215.606 74.3466 215.557 74.4717 215.557H254.201C254.326 215.557 254.446 215.606 254.534 215.695C254.623 215.783 254.672 215.903 254.672 216.028C254.672 216.153 254.623 216.273 254.534 216.362C254.446 216.45 254.326 216.5 254.201 216.5Z" fill="#CCCCCC"/>
            </g>
            <defs>
              <clipPath id="clip0_5156_74944">
                <rect width="252" height="216" fill="white" transform="translate(74 0.5)"/>
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/button/Button.vue";
import IcoPlus from '@/components/svg/IcoPlus.vue';

export default {
  components: {
    Button,
    IcoPlus
  },
  computed: {

  },
  data() {
    return {}
  },
  methods: {
    handleAdd() {
      this.$emit('add');
    },
    handleDelete() {
      this.$emit('delete');
    }
  }
}
</script>

<style scoped>
.boxed-wrapper {
  padding: 30px;
}

.right {
  min-width: 402px;
  max-width: 402px;
}

.right svg {
  width: 100% !important;
  height: auto !important;
}

.left {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.budgets-no-data {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
}

.budgets-no-data h1 {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 800;
  line-height: 28.8px;
  text-align: left;
  color: #17252A;
}

.budgets-no-data p {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: #888F91;
}

.budgets-no-data-buttons {
  display: flex;
  align-items: center;
  gap: 16px;
}
</style>
