<template>
  <div class="color-picker">
    <div v-if="type === 'show'" class="color-picker-wrapper">
      <div class="color-picker-name">
        <ColorPickerPreview color="black" />
        <div class="color-picker-title">
          {{ color.title }}
        </div>
      </div>
      <div class="color-picker-data">
        <div class="color-picker-values">
          <ColorPickerCopy :color="color.hsl" />
          <ColorPickerCopy :color="color.rgb" />
          <ColorPickerCopy :color="color.cmyk" />
        </div>
        <div class="color-picker-control">
          <Button v-if="type !== 'edit'"  @click="handleEdit" size="slim"><IcoEdit /></Button>
          <Button v-if="!confirmDelete" @click="handleDelete" size="slim"><IcoBinSmall color="#17252A" /></Button>
          <Button v-if="confirmDelete" @click="handleConfirmDelete" v-click-outside="cancelDeleteItem" type="delete" size="slim"><IcoBinSmall color="#17252A" /></Button>
        </div>
      </div>
    </div>

    <div v-if="type === 'edit'" class="color-picker-edit-wrapper">
      <div class="color-picker-edit-name">
        <ColorPickerPreview color="" />
        <div class="color-picker-edit-title">
          <input type="text" :placeholder="$t('15_3_4_color_picker_name')" />
        </div>
      </div>
      <div class="color-picker-edit-data">
        <div class="color-picker-edit-values">
          <ColorPickerSelect type="HSL" :color="color.hsl" />
          <ColorPickerSelect type="RGB" :color="color.rgb" />
          <ColorPickerSelect type="CMYK" :color="color.cmyk" />
        </div>
        <div class="color-picker-edit-control">
          <Button @click="handleSave" size="small" type="primary">{{ $t('15_3_4_color_picker_save') }}</Button>
          <Button @click="handleCancel" size="small" type="default">{{ $t('15_3_4_color_picker_cancel') }}</Button>
          <Button v-if="!confirmDelete" @click="handleDelete" size="slim"><IcoBinSmall color="#17252A" /></Button>
          <Button v-if="confirmDelete" @click="handleConfirmDelete" v-click-outside="cancelDeleteItem" type="delete" size="slim"><IcoBinSmall color="#17252A" /></Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/button/Button.vue";
import IcoEdit from "@/components/svg/small/IcoEdit.vue";
import IcoBin from "@/components/svg/medium/IcoBin.vue";
import ColorPickerPreview from "@/components/colorsPicker/ColorPickerPreview.vue";
import IcoBinSmall from "@/components/svg/IcoBinSmall.vue";
import {obj_clone} from "@/services/helpers/clone";
import ClickOutside from 'vue-click-outside';
import ColorPickerSelect from "@/components/colorsPicker/ColorPickerSelect.vue";
import ColorPickerCopy from "@/components/colorsPicker/ColorPickerCopy.vue";

export default {
  components: {
    ColorPickerCopy,
    ColorPickerSelect,
    IcoBinSmall,
    ColorPickerPreview,
    IcoBin,
    IcoEdit,
    Button
  },
  directives: {
    ClickOutside,
  },
  computed: {

  },
  created() {
    this.colorEdit = obj_clone(this.color);
  },
  props: ['color'],
  data() {
    return {
      type: 'edit', // edit, create, show
      colorCreate: {
        title: '',
        hsl: '',
        rgb: '',
        cmyk: ''
      },
      colorEdit: {
        title: '',
        hsl: '',
        rgb: '',
        cmyk: ''
      },
      confirmDelete: false,
    }
  },
  methods: {
    handleEdit() {
      this.type = "edit";
    },

    handleSave() {
      this.type = "show";
    },

    handleCancel() {
      this.colorEdit = obj_clone(this.color);
      this.type = "show";
    },

    handleDelete() {
      this.confirmDelete = true;

      // TODO Translations
      this.$notify({
        title: this.$t('toaster_info_title_confirm_delete'),
        text: this.$t('toaster_info_description_confirm_delete'),
        type: 'info',
        duration: 5000,
      })

      setTimeout(() => {
        this.confirmDelete = false
      }, 2000)
    },

    handleConfirmDelete() {
      // TODO delete item
      console.log("should delete item")
    },

    cancelDeleteItem() {
      this.confirmDelete = false
    }
  }
}
</script>

<style scoped>
.color-picker {

}

.color-picker-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.color-picker-name {
  display: flex;
  gap: 8px;
}

.color-picker-data {
  display: flex;
  align-items: center;
  gap: 16px;
}

.color-picker-values {
  display: flex;
  gap: 8px;
  align-items: center;
}

.color-picker-control {
  display: flex;
  gap: 8px;
  align-items: center;
}


.color-picker-edit-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.color-picker-edit-name {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.color-picker-edit-title {
  width: 100%;
}

.color-picker-edit-data {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 30px;
}

.color-picker-edit-values {
  display: flex;
  gap: 8px;
  align-items: center;
}

.color-picker-edit-control {
  display: flex;
  gap: 8px;
  align-items: center;
}

.color-picker input[type="text"] {
  width: 100%;
  padding: 6px;
  border-radius: 3px;
  border: 1px solid #D1D4D4;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.color-picker input[type="text"]::placeholder {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #D1D4D4;
}
</style>
