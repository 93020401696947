<template>
  <button @click="handleClick" v-tooltip.right="$t('15_0_1_1_tooltip_delete_company')">
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.33331 6.33333H16.6666M8.33331 9.66667V14.6667M11.6666 9.66667V14.6667M4.16665 6.33333L4.99998 16.3333C4.99998 16.7754 5.17557 17.1993 5.48813 17.5118C5.8007 17.8244 6.22462 18 6.66665 18H13.3333C13.7753 18 14.1993 17.8244 14.5118 17.5118C14.8244 17.1993 15 16.7754 15 16.3333L15.8333 6.33333M7.49998 6.33333V3.83333C7.49998 3.61232 7.58778 3.40036 7.74406 3.24408C7.90034 3.0878 8.1123 3 8.33331 3H11.6666C11.8877 3 12.0996 3.0878 12.2559 3.24408C12.4122 3.40036 12.5 3.61232 12.5 3.83333V6.33333" stroke="#DB0E0E" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </button>
</template>

<script>
  export default {
    name: 'ButtonDeleteSmall',
    methods: {
      handleClick() {
        this.$emit('click');
      }
    }
  }
</script>

<style scoped>
button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin: 0;
  padding: 0;
}
</style>
