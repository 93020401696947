<template>
  <div
    class="custom-status"
    :style="{
      backgroundColor: color.backgroundColor,
      color: color.color
    }"
  >
    {{ state }}
  </div>
</template>

<script>
export default {
  name: 'Status',
  props: {
    state: {
      type: String,
      default: ''
    }
  },
  computed: {
    color() {
      switch(this.state) {
        case 'PUBLISHED':
          return {
            color: '#23B39A',
            backgroundColor: '#DEFEF8'
          }
        case 'IN_REVIEW':
          return {
            color: '#807300',
            backgroundColor: '#FFE500'
          }
        case 'IN_PROGRESS':
          return {
            color: '#FFFFFF',
            backgroundColor: '#17252A'
          }
        case 'APPROVED':
          return {
            color: '#FFFFFF',
            backgroundColor: '#CE0C1'
          }
        case 'TODO':
          return {
            color: '#FFFFFF',
            backgroundColor: '#888F91'
          }
        default: {
          return {
            color: '#FFFFFF',
            backgroundColor: '#17252A'
          }
        }
      }
    }
  }

}
</script>

<style scoped>
.custom-status {
  display: inline-block;
  border-radius: 4px;
  padding: 2px 6px;
  font-family: Nunito;
  font-size: 12px;
  font-weight: 700;
  line-height: 16.37px;
  text-align: left;
}
</style>

