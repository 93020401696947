<template>
  <div class="modal-edit-content">
    <div class="modal-edit-content-header">
      <div class="modal-edit-content-title">
        <strong>{{ $t('15_4_2_9_modal_edit_title') }}</strong>
      </div>
      <button @click="handleCancel" >
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 6.5L6 18.5M6 6.5L18 18.5" stroke="#888F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>

    <div class="modal-edit-content-body">
      <FormGroup
        :label="$t('15_4_2_9_modal_edit_label_title')"
        :required="true"
        :focused="focusName"
        :validationError="validationError.name"
      >
        <input
          type="text"
          :placeholder="$t('15_4_2_9_modal_edit_placeholder_title')"
          v-model="leanCanvas.name"
          @input="validationError.name = false"
          @focus="focusName = true"
          @blur="focusName = false"
          ref="contentTitle"
        />
      </FormGroup>

      <FormGroup
        :label="$t('15_4_2_9_modal_edit_label_description')"
        :required="false"
      >
        <textarea
          class="form-group__input large"
          :placeholder="$t('15_4_2_9_modal_edit_placeholder_description')"
          v-model="leanCanvas.description"
          @input="validationError.description = false"
          @focus="focusDescription = true"
          @blur="focusDescription = false"
          ref="contentDescription"
        ></textarea>
      </FormGroup>

      <div class="column">
        <Dropdown
          :label="$t('15_4_2_9_modal_edit_label_products')"
          :placeholder="$t('15_4_2_9_modal_edit_placeholder')"
          :items="products"
          v-model="leanCanvas.product"
        />
      </div>
    </div>

    <div class="modal-edit-content-footer">
      <Button @click="handleCancel" type="secondary">{{ $t('15_4_2_9_modal_edit_btn_cancel') }}</Button>
      <Button v-if="type === 'create'" @click="handleCreate" type="primary">{{ $t('15_4_2_9_modal_edit_btn_save') }}</Button>
      <Button v-if="type === 'edit'" @click="handleUpdate" type="primary">{{ $t('15_4_2_9_modal_edit_btn_save') }}</Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/button/Button.vue";
import Dropdown from "@/components/dropdown/Dropdown.vue";
import TextAreaEditor from "@/components/TextAreaEditor.vue";
import textLength from "@/constants/TextLengthConstant";
import WYSIWYGEditor from "@/components/WYSIWYGEditor.vue";
import TinymceEditor from "@/components/TinymceEditor.vue";
import FormGroup from "@/components/FormGroup.vue";
import LEAN_CANVAS_ALLOWED_PRODUCTS from "@/graphql/LeanCanvasAllowedProducts.gql";
export default {
  components: {
    FormGroup,
    TinymceEditor,
    WYSIWYGEditor,
    TextAreaEditor,
    Button,
    Dropdown
  },
  apollo: {
    companies: {
      query: LEAN_CANVAS_ALLOWED_PRODUCTS,
      variables() {
        return {
          id: this.companyID,
        }
      },
      update: function(data) {
        const companies = data.companies.filter((item) => item.id === this.companyID);
        const company = companies[0];

        if (company && company.leanCanvasAllowedProducts && Array.isArray(company.leanCanvasAllowedProducts)) {
          this.products = company.leanCanvasAllowedProducts.map((product) => {
            return {
              value: product.id,
              name: product.name
            }
          })
        }
      }
    }
  },
  computed: {
    companyID() {
      return this.$route.params.id
    },
    length() {
      return textLength
    },
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    type() {
      if(this.editLeanCanvas !== null) {
        return 'edit';
      }
      return 'create';
    },
  },
  props: ['editLeanCanvas'],
  created() {
    console.log("this.editLeanCanvas: ", this.editLeanCanvas);
    if (this.editLeanCanvas) {
      this.leanCanvas.name = this.editLeanCanvas.name;
      this.leanCanvas.description = this.editLeanCanvas.description;
      this.leanCanvas.product = {
        value: this.editLeanCanvas.product.id,
        name: this.editLeanCanvas.product.name
      };
    }
  },
  data() {
    return {
      products: [],
      leanCanvas: {
        name: '',
        description: '',
        product: null
      },
      validationError: {
        name: false,
        description: false
      },
      focusName: false,
      focusDescription: false,
    }
  },
  methods: {
    handleCancel() {
      this.$emit('close');
    },
    handleCreate() {
      if (this.leanCanvas.name === '') {
        this.validationError.name = true;
        return;
      }

      this.$emit('create', {
        name: this.leanCanvas.name,
        description: this.leanCanvas.description,
        productId: this.leanCanvas.product.value
      });
    },
    handleUpdate() {
      if (this.leanCanvas.name === '') {
        this.validationError.name = true;
        return;
      }

      this.$emit('update', {
        name: this.leanCanvas.name,
        description: this.leanCanvas.description,
        product: {
          name: this.leanCanvas.product.name,
          id: this.leanCanvas.product.value
        }
      });
    },
  }
}
</script>

<style scoped>
.modal-edit-content {
  width: 600px;
}

.modal-edit-content-header {
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-edit-content-header button {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.modal-edit-content-title {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
  text-align: left;
  color: #17252A;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.modal-edit-content-title span {
  position: relative;
  font-family: Nunito;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  display: inline-block;
  color: #888F91;
}

.modal-edit-content-title span:after {
  content: '';
  position: absolute;
  left: -10px;
  top: 4px;
  bottom: 4px;
  width: 1px;
  background-color: #888F91;
}

.modal-edit-content-body {
  margin-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  gap: 30px;
  display: flex;
  flex-direction: column;
}

.modal-edit-content-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 32px;
  border-top: 1px solid #E7E9E9;
  padding: 32px;
}
</style>
