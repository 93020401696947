<template>
  <div>
    <button @click="handleClick">
      <IcoTrash />
      {{ $t('sources_sidebar_btn_delete_company') }}
    </button>
  </div>
</template>

<script>
import IcoTrash from "@/components/buttonDelete/IcoTrash.vue";
import textLength from "@/constants/TextLengthConstant";
import { DateService } from "@/services/DateService";
import ModalDeleteCompany from "@/components/ModalDeleteCompany.vue";
import COMPANY from "@/graphql/Company.gql";

export default {
  components: {
    ModalDeleteCompany,
    IcoTrash
  },
  apollo: {
    companies: {
      query: COMPANY,
      variables() {
        return {
          id: this.companyID,
        }
      },
      skip() {
        return !this.companyID
      },
    },
  },
  computed: {
    me() {
      return this.$store.getters.getMe
    },
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    length() {
      return textLength
    },
    timezones() {
      return DateService.getListOfAvailableTimezones()
    },
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
    companyID() {
      if (!this.$route.params.id) {
        if (this.selectedCompany) {
          return this.selectedCompany.id
        }
      } else {
        return this.$route.params.id
      }
    },
  },
  data() {
    return {
      showModalDeleteCompany: false,
    }
  },
  methods: {
    closeModalDeleteCompany() {
      this.showModalDeleteCompany = false
    },
    handleShowModalDeleteCompany () {
      this.showModalDeleteCompany = true
    },
    handleClick() {
      this.$emit('click');
    }
  },
}
</script>

<style scoped>
button {
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 8px;
  align-content: center;
  justify-content: center;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #F45050;
  background: transparent;
  border: none;
}
</style>
