<template>
  <div class="item">
    <div class="item-header">
      <div class="item-main">

        <div class="item-main-label">
          {{ $t('Meeting notes') }}
        </div>

        <div class="item-main-title">
          <TextAreaEditor
            ref="content_name"
            :content="content.name"
            :isValid="formValidation.name"
            :maxLength="length.short"
            :disabled="role === 'READ_ONLY'"
            :showError="titleIsOpenShowError"
            format="title"
            @saveEditName="saveEditName"
            @cancelEditName="cancelEditName"
            @opened="titleIsOpen = true"
            @closed=";(titleIsOpen = false), (titleIsOpenShowError = false)"
            @input.native="formValidation.name = true"
          />
        </div>
      </div>
      <div class="item-sidebar">
        <div class="item-sidebar-control">
          <NoteModalEditSubmenu :content="content" />
          <button @click="close">
            <IcoClose />
          </button>
        </div>
      </div>
    </div>

    <div style="height: 640px">
      <vuescroll ref="vs" :ops="ops">
        <div class="item-body">
          <div class="item-main">
            <WYSIWYGEditor
              id="content_description"
              ref="content_description"
              tinymceRef="update_content_description"
              :content="content.description"
              :isValid="true"
              :title="$t('10_1_title_description')"
              :buttonTitle="$t('10_1_title_add_description')"
              :maxLength="length.long"
              :disabled="role === 'READ_ONLY'"
              :showError="descriptionIsOpenShowError"
              format="description"
              @saveEditName="saveEditDescription"
              @cancelEditName="cancelEditDescription"
              @opened="descriptionIsOpen = true"
              @closed="
                  ;(descriptionIsOpen = false),
                    (descriptionIsOpenShowError = false)
                "
            />

            <h2 class="content-edit__title">
              <IcoResources /> {{ $t('10_1_title_resources') }}
            </h2>
            <div>
              <Resources
                :items="content.resources"
                :disabled="role === 'READ_ONLY'"
                :showError="resourcesIsOpenShowError"
                transCreateNew="10_1_resource_add"
                @updateResources="updateResources"
                @opened="resourcesIsOpen = true"
                @closed="
                    ;(resourcesIsOpen = false),
                      (resourcesIsOpenShowError = false)
                  "
              />
            </div>


            <h2 v-if="role !== 'READ_ONLY'" class="content-edit__title">
              <IcoChecklist /> {{ $t('10_1_subtitle_checklist') }}
              <Button
                style="margin-left: 15px"
                v-if="
                    content &&
                    content.checklist &&
                    content.checklist.length === 0
                  "
                @click="addChecklist()"
                :size="'small'"
              >
                {{ $t('10_1_btn_checklist_add') }}
              </Button>
            </h2>

            <IdeaTask
              v-if="role !== 'READ_ONLY'"
              style="margin-top: 20px"
              :items="content.checklist"
            />

            <Checklist
              v-if="role !== 'READ_ONLY'"
              :contentId="content.id"
              :publishDate="content.date"
              :items="content.checklist"
              :eod="content.eod"
              :addChecklistItem="addChecklistItem"
              :showError="checklistIsOpenShowError"
              @silentDeletedCheckbox="addChecklistItem = false"
              @tasksUpdated="tasksUpdated"
              @opened="checklistIsOpen = true"
              @closed="
                  ;(checklistIsOpen = false),
                    (addChecklistItem = false),
                    (checklistIsOpenShowError = false)
                "
            />

            <h2 class="divider mt-30">
              <span>{{ $t('10_1_title_communication') }}</span>
            </h2>

            <Comments
              v-show="
          content.id &&
          role !== 'READ_ONLY'
        "
              :contentId="content.id"
              :eod="content.eod"
              :comments="content.comments"
              :showError="commentsIsOpenShowError"
              @commentsUpdated="handleUpdateContent"
              @opened="commentsIsOpen = true"
              @closed="
          ;(commentsIsOpen = false), (commentsIsOpenShowError = false)
        "
            />

          </div>
          <div class="item-sidebar">
            <div class="item-date-time">
              <DatePickerEditContent
                v-model="content.date"
                :luxonDate="content.luxonDate"
                :disabled="role === 'READ_ONLY'"
                :state="content.state"
                :eod="content.eod"
                @updatedDate="updatedDate"
                @updatedDateClear="updatedDateClear"
              />

              <TimePickerEditContent
                :content="content"
                :readonly="role === 'READ_ONLY'"
                :slim="true"
                @updatedTime="updatedTime"
                @updatedTimeClear="updatedTimeClear"
              />
            </div>

            <Participants />

          </div>
        </div>
      </vuescroll>
    </div>

  </div>
</template>

<script>
import vuescroll from 'vuescroll'
import Button from "@/components/button/Button.vue";
import TextAreaEditor from "@/components/TextAreaEditor.vue";
import textLength from "@/constants/TextLengthConstant";
import WYSIWYGEditor from "@/components/WYSIWYGEditor.vue";
import Resources from "@/components/design/Resources.vue";
import IcoResources from "@/components/svg/small/IcoResources.vue";
import IcoChecklist from "@/components/svg/small/IcoChecklist.vue";
import IdeaTask from "@/components/IdeaTask.vue";
import Checklist from "@/components/Checklist.vue";
import Comments from "@/components/comments/Comments.vue";
import TimePickerEditContent from "@/components/TimePickerEditContent.vue";
import DatePickerEditContent from "@/components/DatePickerEditContent.vue";
import Participants from "@/components/sources/components/meetingNotes/Participants.vue";
import IcoClose from "@/components/svg/IcoClose.vue";
import NoteModalEditSubmenu from "@/components/sources/components/meetingNotes/NoteModalEditSubmenu.vue";

export default {
  components: {
    NoteModalEditSubmenu,
    IcoClose,
    vuescroll,
    Participants,
    DatePickerEditContent,
    TimePickerEditContent,
    Comments,
    Button,
    Checklist,
    IdeaTask,
    IcoChecklist,
    IcoResources,
    Resources,
    WYSIWYGEditor,
    TextAreaEditor

  },
  props: {

  },
  computed: {
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    length() {
      return textLength
    },
  },
  data() {
    return {
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {},
      },

      content: {
        id: '1',
        contentId: '1',
        name: 'Test title',
        description: '',
        date: '20241001',
        luxonDate: {},
        checklist: [],
        comments: [],
        eod: false,
      },
      formValidation: {
        name: false,
        description: false
      },

      titleIsOpen: false,
      titleIsOpenShowError: false,

      descriptionIsOpen: false,
      descriptionIsOpenShowError: false,

      resourcesIsOpen: false,
      resourcesIsOpenShowError: false,

      addChecklistItem: false,
      checklistIsOpen: false,
      checklistIsOpenShowError: false,

      commentsIsOpenShowError: false,
      commentsIsOpen: false,
    }
  },
  methods: {
    saveEditName() {
      this.editName = false
    },
    cancelEditName() {

    },
    saveEditDescription() {

    },
    cancelEditDescription() {

    },
    updateResources() {

    },
    tasksUpdated() {
      this.$apollo.queries.companies.refetch()
    },
    addChecklist() {
      this.addChecklistItem = true
      this.checklistIsOpen = true
    },
    handleUpdateContent() {
      this.$apollo.queries.companies.refetch()
    },
    updatedDate() {

    },
    updatedDateClear() {

    },
    updatedTime() {

    },
    updatedTimeClear() {

    },
    close() {

    }
  }
}
</script>

<style scoped>
.item {
  position: relative;
  width: 982px;
  padding: 30px;
}

.item:after {
  content: '';
  position: absolute;
  left: 600px;
  top: 30px;
  bottom: 30px;
  width: 1px;
  background-color: #F5F5F5;
}

.item-header {
  display: flex;
  justify-content: space-between;
}

.item-body {
  display: flex;
  justify-content: space-between;
}

.item-main {
  width: 540px;
}

.item-sidebar {
  width: 320px;
}

.item-main-label {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.1px;
  text-align: left;
  color: #888F91;
  text-transform: uppercase;
}

.item-main-title {
  margin-top: 16px;
}

.item-date-time {
  display: flex;
  align-items: center;
  gap: 9px;
}



.divider {
  position: relative;
  width: 100%;
}

.divider:after {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 1;
  margin-top: 2px;
  background-color: #17252a;
  height: 2px;
  content: '';
}

.divider span {
  display: inline-block;
  position: relative;
  z-index: 2;
  background-color: #fff;
  padding-right: 15px;
  color: #17252a;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 14px;
  font-family: Nunito;
  letter-spacing: -0.2px;
  text-transform: uppercase;
}



.item-sidebar-control {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
}

.item-sidebar-control button {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
</style>
