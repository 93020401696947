<template>
  <div @click="handleCopyToClipboard" class="color-picker-copy">
    {{ color }}
    <div v-if="done" class="color-picker-copy-done"><span>{{ $t('15_3_4_copied') }}</span></div>
  </div>
</template>

<script>
export default {
  components: {

  },
  computed: {

  },
  props: {
    color: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      done: false
    }
  },
  methods: {
    async handleCopyToClipboard(color = "black") {
      await navigator.clipboard.writeText(color);

      this.done = true;

      setTimeout(() => {
        this.done = false;
      }, 2000);

      // TODO Translations
      this.$notify({
        title: this.$t('15_3_4_title_color_copied_to_clipboard'),
        text: this.$t('15_3_4_description_color_copied_to_clipboard'),
        type: 'info',
        duration: 5000
      });
    },
  }
}
</script>

<style scoped>
.color-picker-copy {
  position: relative;
  cursor: pointer;
  border: 1px solid #D1D4D4;
  border-radius: 3px;
  padding: 1px 8px;
  font-size: 12px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.color-picker-copy:hover {
  border: 1px solid #17252A;
}

.color-picker-copy-done {
  position: absolute;
  z-index: 2;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  background-color: #17252A;
  border-radius: 3px;
  display: flex;
  align-content: center;
  justify-content: center;
  color: #fff;
}

.color-picker-copy-done span {
  position: relative;
  top: 2px;
}
</style>
