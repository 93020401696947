<template>
  <div
    class="avatar"
    :class="{
      'avatar--small': size === 'small',
      'avatar--extrasmall': size === 'extrasmall',
      'avatar--medium': size === 'medium',
      'avatar--big': size === 'big',
    }"
    :style="{ backgroundColor: color }"
  >
    <img
      v-if="imageValid && !isTeam"
      :src="cachedImageUrl"
      :alt="avatarName"
    />
    <span v-if="!imageValid && !isTeam">
      {{ avatarName }}
    </span>
    <IcoDino v-if="isTeam" />
  </div>
</template>

<script>
import IcoDino from '@/components/svg/IcoDino.vue'
import { ColorService } from '@/services/ColorService.js'
import { URLFiles } from '@/constants/URLConstant.js'

export default {
  components: {
    IcoDino,
  },
  props: {
    name: {
      type: String,
      default: 'No name',
    },
    size: {
      type: String,
      default: 'normal',
    },
    user: {
      type: Object,
      default: () => {},
    },
    isTeam: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    avatarName() {
      const name = this.name.split(' ')
      if (name.length === 1) {
        return name[0].charAt(0)
      } else {
        return name[0].charAt(0) + name[1].charAt(0)
      }
    },
    color() {
      if (this.isTeam) {
        return '#E7E9E9'
      }

      if (this.imageValid) {
        return 'transparent'
      }

      return ColorService.computedColor(this.name)
    },
    cachedImageUrl() {
      return this.imageUrl ? `${this.imageUrl}?${this.cacheBuster}` : ''
    },
    updateUserProfile() {
      return this.$store.getters.getUserProfile
    },
  },
  created() {
    this.loadImage()
  },
  data() {
    return {
      imageUrl: '',
      imageValid: false,
      cacheBuster: Date.now(),
    }
  },
  methods: {
    async loadImage() {
      this.imageValid = false;

      if (!this.user.id) {
        this.imageUrl = ''
        return
      }

      this.imageUrl = `${URLFiles}/images/user/${this.user.id}.jpg`

      const img = new Image()
      img.src = this.imageUrl
      img.onload = () => {
        this.imageValid = true
      }

      img.onerror = () => {
        this.imageValid = false
      }
    },
  },
  watch: {
    user() {
      this.loadImage()
    },
    updateUserProfile() {
      this.cacheBuster = Date.now();
      this.loadImage();
    }
  },
}
</script>
