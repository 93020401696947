<template>
  <div
    id="content-wrapper"
    class="content-wrapper"
    :class="{'sidebar-offset': sourceSidebarState, 'disable-top-offset': disableTopOffset}"
  >
    <div
      @scroll="handleScroll"
    >
      <slot />
    </div>

  </div>
</template>

<script>
export default {
  components: {

  },
  props: {
    disableTopOffset: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    sourceSidebarState() {
      return this.$store.getters.getSourceSidebarState
    },
  },
  created() {

  },
  data() {
    return {}
  },
  methods: {
    handleScroll(event) {
      this.scrollPosition = event.target.scrollTop;
    }
  }
}
</script>

<style scoped>
.content-wrapper {
  position: relative;

  width: 100%;
  padding-top: 122px;
  padding-right: 20px;
  padding-left: 76px;

  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.content-wrapper.sidebar-offset {
  padding-left: 250px;
}

.content-wrapper.disable-top-offset {
  padding-top: 70px;
}
</style>
