<template>
  <VDropdown
    :triggers="[]"
    :shown="showData"
    :handle-resize="true"
    :arrow-padding="distanceNo"
    :popperClass="boxSize"
    :auto-hide="autoHide"
    :eagerMount="autoHide"
    @hide="closeData"
  >
    <div class="fp-item-select">
      <div class="fp-item-select-data">
        <div class="fp-item-select-data-items">

        </div>
        <div class="fp-item-select-data-placeholder">
          {{ placeholder }}
        </div>
      </div>
      <div @click="toggleData" class="fp-item-select-toggle">
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 8L10 13L15 8" stroke="#17252A" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>

    <template #popper>
      <div class="fp-item-select-dropdown">
        <ul>
          <li><button>Item 1</button></li>
          <li><button>Item 2</button></li>
          <li><button>Item 3</button></li>
          <li><button>Item 4</button></li>
          <li><button>Item 5</button></li>
        </ul>
      </div>
    </template>
  </VDropdown>
</template>

<script>

import Label from "@/components/sources/components/label/Label.vue";
import Button from "@/components/button/Button.vue";

export default {
  components: {
    Button,
    Label

  },
  computed: {

  },
  props: {
    placeholder: {
      type: 'String',
      default: 'Select ...'
    }
  },
  data() {
    return {
      type: 'edit',
      showData: false,
      distanceNo: 12,
      autoHide: true,
      toDelete: false,
      boxSize: 'popper-stdc-framework-and-phases-submenu',
    }
  },
  methods: {
    handleOpen() {

    },
    handleEdit() {

    },
    handleDelete() {

    },
    closeData() {

    },
    showData() {
      this.showData = true;
    },
    toggleData() {
      this.showData = !this.showData;
    }
  }
}
</script>

<style>
.popper-stdc-framework-and-phases-submenu {

}

.fp-item-edit-line .v-popper {
  width: 100%;
}
</style>

<style scoped>
  .fp-item-select {
    border: 1px solid #D1D4D4;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .fp-item-select-data {
    width: 100%;
  }

  .fp-item-select-toggle {
    min-width: 30px;
    max-width: 30px;
    min-height: 30px;
    max-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .fp-item-select-data-placeholder {
    font-family: Nunito;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    text-align: left;
    color: #BFC5C5;
    padding-left: 8px;
  }

  .fp-item-select-dropdown {
    min-width: 280px;
    padding: 8px;
    background-color: #fff;
    /* height: 300px; */
  }

  .fp-item-select-dropdown ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .fp-item-select-dropdown ul li {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .fp-item-select-dropdown ul li button {
    border: none;
    margin: 0;
    cursor: pointer;
    background-color: transparent;
    width: 100%;
    text-align: left;
  }
</style>
