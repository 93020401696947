<template>
  <div v-if="color !== ''" class="color-picker-preview" :style="{'background-color': color}"></div>
  <div v-else class="color-picker-preview">
    <IcoColorPickerEmpty />
  </div>
</template>

<script>
import IcoColorPickerEmpty from "@/components/colorsPicker/IcoColorPickerEmpty.vue";

export default {
  components: {
    IcoColorPickerEmpty,
  },
  computed: {

  },
  props: {
    color: {
      type: String,
      default: ""
    }
  },
  data() {
    return {}
  },
  methods: {

  }
}
</script>

<style scoped>
  .color-picker-preview {
    width: 22px;
    height: 22px;
    display: inline-block;
    border-radius: 3px;
  }
</style>
