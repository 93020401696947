<template>
  <Modal @closeModal="closeModal">
    <div class="logout" style="width: 320px">
      <h2 class="logout__title">{{ $t('Unsaved changes') }}</h2>

      <div class="modal-description">
        <p>{{ $t('Do you really want to lost changes?') }}</p>
      </div>

      <div class="modal-options">
        <ul>
          <li>
            <Button
              style="width: 100%"
              @click="closeModal()"
              :type="'secondary'"
            >
              {{ $t('Stay on page') }}
            </Button>
          </li>
          <li>
            <Button
              style="width: 100%"
              @click="confirmDelete()"
              :type="'delete'"
            >
              {{ $t('Leave without saving') }}
            </Button>
          </li>
        </ul>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Button from '@/components/button/Button.vue'

export default {
  components: {
    Modal,
    Button,
  },
  methods: {
    closeModal() {
      this.$emit('close') // Emit an event to the parent component when modal is closed
    },
    confirmDelete() {
      this.$emit('confirm') // Emit an event to the parent component when delete is confirmed
    }
  }
}
</script>
