<template>
  <div @click="handleSelectColor" class="color-picker-select">
    <input type="text" :placeholder="type" v-model="color" />
    <div class="color-picker-select-ico">
      <IcoColorPicker />
    </div>
  </div>
</template>

<script>
import IcoColorPicker from "@/components/colorsPicker/IcoColorPicker.vue";

export default {
  components: {
    IcoColorPicker

  },
  computed: {

  },
  props: {
    type: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  methods: {
    handleSelectColor() {
      window.alert("init select color");
    }
  }
}
</script>

<style>
  .color-picker-select {
    position: relative;
    width: 100%;
  }

  .color-picker-select-ico {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .color-picker-select input[type="text"] {
    width: 100%;
    padding: 6px;
    border-radius: 3px;
    border: 1px solid #D1D4D4;
    font-family: Nunito;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
  }

  .color-picker-select input[type="text"]::placeholder {
    font-family: Nunito;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #D1D4D4;
  }
</style>
