<template>
  <div class="scale">
    <button
      v-for="(choice, index) in choices"
      :key="index"
      :class="{'is-selected': selectedChoiceIndex === index}"
      @click="handleSelectChoice(choice)"
    >
      {{ choice }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    selected: {
      type: String,
      default: ''
    },
    choices: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    selectedChoiceIndex() {
      const index = this.choices.indexOf(this.selected);

      if (index) {
        return index;
      }

      return 0;
    }
  },
  data() {
    return {

    }
  },
  methods: {
    handleSelectChoice(choice) {
      this.$emit('setScale', choice);
    }
  },

}
</script>

<style scoped>
  .scale {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    border-radius: 4px;
    background: #E7E9E9;
  }

  .scale button {
    width: 100%;
    border: none;
    padding: 0;
    margin: 0;
    font-family: Nunito;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    text-align: center;
    color: #888F91;
    background: #E7E9E9;
    height: 43px;
    cursor: pointer;
    border-radius: 4px;
  }

  .scale button.is-selected {
    background-color: #fff;
    color: #17252A;
  }
</style>
