<template>
  <div class="sources-profile-progress-item">
    <div class="sources-profile-progress-item-ico">
      <svg v-if="!data.completed" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.835" y="1.335" width="18.33" height="18.33" rx="9.165" fill="white" stroke="#D1D4D4" stroke-width="1.67"/>
      </svg>

      <svg v-if="data.completed" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.5" width="20" height="20" rx="10" fill="#9795FF"/>
        <path d="M6.5 10.5L9 13L14 8" stroke="white" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <div
      class="sources-profile-progress-item-text"
      :class="{'is-completed': data.completed}"
    >
      {{ data.title }}

      <div class="sources-profile-progress-item-text-ico">
        <svg v-if="!data.completed" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.5 5.5L12.5 10.5L7.5 15.5" stroke="#888F91" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {

  },
  computed: {

  },
  props: ['data'],
  data() {
    return {
      isCollapsed: false,
    }
  },
  methods: {

  },
  watch: {

  },
}
</script>

<style scoped>
.sources-profile-progress-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 8px;
  margin-top: 8px;
  border-top: 1px solid #E7E9E9;
  gap: 8px;
}

.sources-profile-progress-item:first-child {
  border-top: none;
}

.sources-profile-progress-item-ico {
  min-width: 20px;
  max-width: 20px;
}

.sources-profile-progress-item-text {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #17252A;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 16px;
}

.sources-profile-progress-item-text.is-completed {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #888F91;
  text-decoration: line-through;
}

.sources-profile-progress-item-text-ico {
  min-width: 20px;
  max-width: 20px;
}
</style>
