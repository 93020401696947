<template>
  <div
    class="storage-indicator">
    <div class="storage-indicator-progress">
      <div v-if="content" class="storage-indicator-item storage-indicator-item-content" :style="{width: `${content}%`}">{{ content }}%</div>
      <div v-if="company" class="storage-indicator-item storage-indicator-item-company" :style="{width: `${company}%`}">{{ company }}%</div>
      <div v-if="meeting" class="storage-indicator-item storage-indicator-item-meeting" :style="{width: `${meeting}%`}">{{ meeting }}%</div>
    </div>

    <div class="storage-indicator-meta">
      <div class="storage-indicator-legend">
        <div class="storage-indicator-legend-item">
          <div class="storage-indicator-legend-item-ico item-content"></div>
          <div class="storage-indicator-legend-item-name">{{ $t('15_1_1_legend_content_card') }}</div>
        </div>
        <div class="storage-indicator-legend-item">
          <div class="storage-indicator-legend-item-ico item-company"></div>
          <div class="storage-indicator-legend-item-name">{{ $t('15_1_1_legend_company_address') }}</div>
        </div>
        <div class="storage-indicator-legend-item">
          <div class="storage-indicator-legend-item-ico item-meeting"></div>
          <div class="storage-indicator-legend-item-name">{{ $t('15_1_1_legend_meeting_notes') }}</div>
        </div>
      </div>

      <div class="storage-indicator-used">
        {{sizes.readableFileSize(used)}}{{$t('15_1_1_used_size_of')}}{{sizes.readableFileSize(limit)}}{{$t('15_1_1_used_size_used')}}{{sizes.readableFileSize(limit - used)}}{{$t('15_1_1_used_size_left')}}
      </div>
    </div>

  </div>
</template>

<script>
import sizes from "../../../../utils/sizes";

export default {
  name: 'StorageIndicator',
  computed: {
    sizes() {
      return sizes
    }
  },
  props: {
   content: {
     type: Number,
     default: 0
   },
    company: {
      type: Number,
      default: 0
    },
    meeting: {
      type: Number,
      default: 0
    },
    limit: {
      type: Number,
      default: 0
    },
    used: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style scoped>
.storage-indicator {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 24px 0;
}

.storage-indicator-progress {
  width: 100%;
  display: flex;
  gap: 1px;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  background: #D1D4D4;
  height: 32px;
}

.storage-indicator-item {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  text-align: left;
  color: #fff;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 32px;
}

.storage-indicator-item-content {
  background: #2CE0C1;
}

.storage-indicator-item-company {
  background: #9795FF;
}

.storage-indicator-item-meeting {
  background: #40D2F2;
}

.storage-indicator-item:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.storage-indicator-item:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.storage-indicator-legend {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

.storage-indicator-legend-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.storage-indicator-legend-item-name {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  text-align: left;
  color: #17252A;
}

.storage-indicator-legend-item-ico {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  display: inline-block;
}

.storage-indicator-legend-item-ico.item-content {
  background: #2CE0C1;
}

.storage-indicator-legend-item-ico.item-company {
  background: #9795FF;
}

.storage-indicator-legend-item-ico.item-meeting {
  background: #40D2F2;
}

.storage-indicator-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.storage-indicator-used {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  text-align: left;
  color: #888F91;
}
</style>
