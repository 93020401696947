<template>
  <div class="wrapper wrapper--center">
    <div class="container">
      <div class="column column--80gap">
        <div class="left">
          <svg width="403" height="245" viewBox="0 0 403 245" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5153_91321)">
              <path d="M350.828 0.5H5.46129C4.14581 0.501196 2.88456 1.02441 1.95439 1.95478C1.02422 2.88516 0.501154 4.14667 0.5 5.4624V239.538C0.501154 240.853 1.02422 242.115 1.95439 243.045C2.88456 243.976 4.14581 244.499 5.46129 244.5H350.828C351.546 244.5 352.254 244.344 352.906 244.044C353.557 243.743 354.135 243.305 354.601 242.759C354.64 242.716 354.676 242.67 354.709 242.622C355.005 242.26 355.245 241.855 355.42 241.421C355.67 240.825 355.797 240.184 355.795 239.538V5.4624C355.794 4.14575 355.27 2.88352 354.339 1.95304C353.407 1.02256 352.145 0.499931 350.828 0.5ZM354.379 239.538C354.379 240.23 354.175 240.907 353.793 241.484C353.453 241.992 352.991 242.406 352.45 242.69C351.949 242.949 351.392 243.084 350.828 243.083H5.46129C4.52152 243.082 3.62057 242.708 2.95606 242.043C2.29155 241.379 1.91772 240.478 1.91658 239.538V5.4624C1.91772 4.52246 2.29155 3.62134 2.95606 2.9567C3.62057 2.29206 4.52152 1.91816 5.46129 1.91702H350.828C351.769 1.9169 352.671 2.2902 353.337 2.95495C354.003 3.6197 354.377 4.52154 354.379 5.4624V239.538Z" fill="#3F3D56"/>
              <path d="M355.084 20.3887H1.20703V21.8073H355.084V20.3887Z" fill="#3F3D56"/>
              <path d="M13.2628 15.3945C15.6128 15.3945 17.5179 13.4891 17.5179 11.1387C17.5179 8.78822 15.6128 6.88281 13.2628 6.88281C10.9129 6.88281 9.00781 8.78822 9.00781 11.1387C9.00781 13.4891 10.9129 15.3945 13.2628 15.3945Z" fill="#3F3D56"/>
              <path d="M25.4972 15.3945C27.8472 15.3945 29.7523 13.4891 29.7523 11.1387C29.7523 8.78822 27.8472 6.88281 25.4972 6.88281C23.1472 6.88281 21.2422 8.78822 21.2422 11.1387C21.2422 13.4891 23.1472 15.3945 25.4972 15.3945Z" fill="#3F3D56"/>
              <path d="M37.7277 15.3945C40.0777 15.3945 41.9827 13.4891 41.9827 11.1387C41.9827 8.78822 40.0777 6.88281 37.7277 6.88281C35.3777 6.88281 33.4727 8.78822 33.4727 11.1387C33.4727 13.4891 35.3777 15.3945 37.7277 15.3945Z" fill="#3F3D56"/>
              <path d="M43.2028 109.62H30.6854C30.0066 109.619 29.3558 109.349 28.8758 108.869C28.3958 108.389 28.1258 107.738 28.125 107.059V94.5394C28.1258 93.8604 28.3958 93.2095 28.8758 92.7294C29.3558 92.2493 30.0066 91.9793 30.6854 91.9785H43.2028C43.8816 91.9793 44.5324 92.2493 45.0124 92.7294C45.4924 93.2095 45.7624 93.8604 45.7632 94.5394V107.059C45.7624 107.738 45.4924 108.389 45.0124 108.869C44.5324 109.349 43.8816 109.619 43.2028 109.62Z" fill="#2CE0C1"/>
              <path d="M43.2028 142.058H30.6854C30.0066 142.057 29.3558 141.787 28.8758 141.307C28.3958 140.827 28.1258 140.176 28.125 139.497V126.977C28.1258 126.298 28.3958 125.647 28.8758 125.167C29.3558 124.687 30.0066 124.417 30.6854 124.416H43.2028C43.8816 124.417 44.5324 124.687 45.0124 125.167C45.4924 125.647 45.7624 126.298 45.7632 126.977V139.497C45.7624 140.176 45.4924 140.827 45.0124 141.307C44.5324 141.787 43.8816 142.057 43.2028 142.058Z" fill="#3F3D56"/>
              <path d="M43.2028 174.495H30.6854C30.0066 174.494 29.3558 174.224 28.8758 173.744C28.3958 173.264 28.1258 172.613 28.125 171.934V159.414C28.1258 158.735 28.3958 158.084 28.8758 157.604C29.3558 157.124 30.0066 156.854 30.6854 156.854H43.2028C43.8816 156.854 44.5324 157.124 45.0124 157.604C45.4924 158.084 45.7624 158.735 45.7632 159.414V171.934C45.7624 172.613 45.4924 173.264 45.0124 173.744C44.5324 174.224 43.8816 174.494 43.2028 174.495Z" fill="#CCCCCC"/>
              <path d="M125.133 103.36H64.8221C64.143 103.36 63.4918 103.09 63.0116 102.61C62.5315 102.13 62.2617 101.478 62.2617 100.799C62.2617 100.12 62.5315 99.4686 63.0116 98.9883C63.4918 98.5081 64.143 98.2383 64.8221 98.2383H125.133C125.812 98.2383 126.464 98.5081 126.944 98.9883C127.424 99.4686 127.694 100.12 127.694 100.799C127.694 101.478 127.424 102.13 126.944 102.61C126.464 103.09 125.812 103.36 125.133 103.36Z" fill="#CCCCCC"/>
              <path d="M125.133 135.798H64.8221C64.143 135.798 63.4918 135.528 63.0116 135.047C62.5315 134.567 62.2617 133.916 62.2617 133.237C62.2617 132.557 62.5315 131.906 63.0116 131.426C63.4918 130.946 64.143 130.676 64.8221 130.676H125.133C125.812 130.676 126.464 130.946 126.944 131.426C127.424 131.906 127.694 132.557 127.694 133.237C127.694 133.916 127.424 134.567 126.944 135.047C126.464 135.528 125.812 135.798 125.133 135.798Z" fill="#CCCCCC"/>
              <path d="M125.133 168.235H64.8221C64.143 168.235 63.4918 167.965 63.0116 167.485C62.5315 167.005 62.2617 166.353 62.2617 165.674C62.2617 164.995 62.5315 164.344 63.0116 163.863C63.4918 163.383 64.143 163.113 64.8221 163.113H125.133C125.812 163.113 126.464 163.383 126.944 163.863C127.424 164.344 127.694 164.995 127.694 165.674C127.694 166.353 127.424 167.005 126.944 167.485C126.464 167.965 125.812 168.235 125.133 168.235Z" fill="#CCCCCC"/>
              <path d="M332.108 98.2772C332.387 98.4048 332.656 98.5546 332.911 98.7252L358.431 87.8311L359.342 81.1073L369.539 81.0449L368.936 96.466L335.253 105.376C335.182 105.61 335.097 105.839 334.998 106.063C334.511 107.102 333.728 107.975 332.747 108.57C331.766 109.166 330.631 109.458 329.485 109.411C328.338 109.363 327.231 108.978 326.303 108.303C325.374 107.629 324.666 106.695 324.266 105.619C323.867 104.543 323.794 103.373 324.058 102.255C324.321 101.138 324.908 100.124 325.746 99.3393C326.584 98.555 327.635 98.0357 328.767 97.8467C329.899 97.6577 331.061 97.8075 332.108 98.2772Z" fill="#FFB8B8"/>
              <path d="M373.247 238.021L366.271 238.02L362.953 211.109L373.248 211.11L373.247 238.021Z" fill="#FFB8B8"/>
              <path d="M361.291 236.027H374.743V244.499H352.82C352.82 243.387 353.039 242.285 353.465 241.257C353.891 240.229 354.515 239.295 355.301 238.509C356.088 237.722 357.021 237.098 358.049 236.672C359.077 236.246 360.178 236.027 361.291 236.027Z" fill="#2F2E41"/>
              <path d="M400.379 236.051L393.437 236.735L387.496 210.279L397.742 209.27L400.379 236.051Z" fill="#FFB8B8"/>
              <path d="M388.281 235.238L401.669 233.919L402.499 242.35L380.682 244.499C380.573 243.392 380.683 242.274 381.006 241.21C381.329 240.145 381.858 239.155 382.564 238.295C383.269 237.435 384.137 236.722 385.118 236.197C386.099 235.673 387.174 235.347 388.281 235.238Z" fill="#2F2E41"/>
              <path d="M375.189 52.5659C382.907 52.5659 389.164 46.308 389.164 38.5886C389.164 30.8692 382.907 24.6113 375.189 24.6113C367.471 24.6113 361.215 30.8692 361.215 38.5886C361.215 46.308 367.471 52.5659 375.189 52.5659Z" fill="#FFB8B8"/>
              <path d="M364.715 232.579C364.116 232.581 363.535 232.371 363.076 231.986C362.616 231.601 362.307 231.066 362.203 230.475C358.593 210.429 346.791 144.882 346.509 143.067C346.503 143.025 346.5 142.983 346.5 142.94V138.053C346.5 137.875 346.555 137.702 346.659 137.557L348.218 135.373C348.292 135.268 348.389 135.181 348.502 135.119C348.615 135.058 348.74 135.023 348.869 135.017C357.757 134.6 386.867 133.379 388.273 135.136C389.686 136.902 389.187 142.254 389.072 143.308L389.078 143.418L402.156 227.071C402.258 227.738 402.094 228.419 401.698 228.965C401.303 229.512 400.708 229.881 400.042 229.993L391.874 231.339C391.266 231.437 390.642 231.313 390.118 230.99C389.593 230.667 389.202 230.166 389.015 229.579C386.488 221.504 378.017 194.342 375.081 183.832C375.063 183.766 375.021 183.708 374.963 183.67C374.905 183.632 374.836 183.617 374.767 183.626C374.699 183.636 374.636 183.67 374.591 183.723C374.547 183.775 374.522 183.842 374.523 183.911C374.67 193.93 375.024 219.492 375.146 228.321L375.16 229.272C375.165 229.915 374.929 230.538 374.498 231.016C374.067 231.494 373.472 231.792 372.831 231.853L364.954 232.569C364.874 232.576 364.794 232.579 364.715 232.579Z" fill="#2F2E41"/>
              <path d="M366.32 58.06C363.881 59.51 362.422 62.1747 361.585 64.8862C360.034 69.9062 359.101 75.0964 358.806 80.3422L357.92 96.049L346.965 137.689C356.46 145.725 361.938 143.898 374.72 137.325C387.502 130.752 388.962 139.517 388.962 139.517L391.518 104.087L395.17 65.3706C394.351 64.3834 393.423 63.4911 392.404 62.7107C389.029 60.0854 385.101 58.2606 380.918 57.3739C376.735 56.4871 372.406 56.5616 368.256 57.5915L366.32 58.06Z" fill="#B3B3B3"/>
              <path d="M362.763 106.687C363.058 106.798 363.343 106.932 363.617 107.088L388.85 94.4558L389.268 87.6123L399.677 86.8945L400.235 102.495L366.514 113.648C366.239 114.829 365.611 115.899 364.713 116.714C363.815 117.529 362.69 118.052 361.488 118.213C360.286 118.373 359.064 118.164 357.984 117.612C356.904 117.061 356.017 116.194 355.442 115.126C354.866 114.058 354.629 112.841 354.763 111.635C354.896 110.43 355.393 109.293 356.188 108.377C356.983 107.461 358.037 106.809 359.212 106.507C360.386 106.205 361.625 106.268 362.763 106.687Z" fill="#FFB8B8"/>
              <path d="M394.991 65.5486C401.199 67.7403 402.293 91.4838 402.293 91.4838C394.989 87.4659 386.224 94.0408 386.224 94.0408C386.224 94.0408 384.398 87.8313 382.207 79.7954C381.545 77.5351 381.466 75.1441 381.977 72.845C382.488 70.5459 383.572 68.4134 385.128 66.646C385.128 66.646 388.782 63.3556 394.991 65.5486Z" fill="#B3B3B3"/>
              <path d="M387.722 42.2846C385.981 40.8913 383.606 43.4237 383.606 43.4237L382.212 30.8849C382.212 30.8849 373.507 31.9279 367.935 30.5368C362.363 29.1457 361.493 35.5875 361.493 35.5875C361.205 32.9854 361.147 30.363 361.319 27.7507C361.667 24.616 366.194 21.4813 374.203 19.3913C382.212 17.3013 386.391 26.3574 386.391 26.3574C391.963 29.1435 389.463 43.6779 387.722 42.2846Z" fill="#2F2E41"/>
              <path d="M334.944 134.375C335.034 132.868 335.085 131.351 335.085 129.822C335.085 109.899 327.172 90.7925 313.087 76.705C299.003 62.6174 279.899 54.7031 259.98 54.7031V134.375H334.944Z" fill="#2CE0C1"/>
              <path d="M254.291 141.203V61.5313C239.631 61.5307 225.291 65.8216 213.039 73.875C200.788 81.9285 191.161 93.3921 185.345 106.852C179.53 120.313 177.78 135.181 180.312 149.624C182.844 164.067 189.547 177.453 199.595 188.131C209.642 198.81 222.594 206.313 236.854 209.717C251.114 213.121 266.058 212.276 279.844 207.287C293.63 202.297 305.654 193.381 314.434 181.638C323.214 169.895 328.366 155.839 329.255 141.203H254.291Z" fill="#3F3D56"/>
            </g>
            <defs>
              <clipPath id="clip0_5153_91321">
                <rect width="402" height="244" fill="white" transform="translate(0.5 0.5)"/>
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="right">
          <div class="no-personas-content">
            <h1>{{ $t('15_12_1_title') }}</h1>
            <p>{{ $t('15_12_1_description') }}</p>

            <VDropdown
              :triggers="[]"
              :shown="showData"
              :handle-resize="true"
              :arrow-padding="distanceNo"
              :popperClass="boxSize"
              :auto-hide="autoHide"
              :eagerMount="autoHide"
              @hide="closeData"
            >
              <Button @click="openData" type="primary">
                <IcoPlus />
                {{ $t('15_12_1_button') }}
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 8L10 13L15 8" stroke="white" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </Button>
              <template #popper>
                <BudgetsSubmenu @onSelect="goToPage" />
              </template>
            </VDropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/button/Button.vue";
import IcoPlus from '@/components/svg/IcoPlus.vue';
import BudgetsSubmenu from "@/components/sources/components/budgets/BudgetsSubmenu.vue";

export default {
  components: {
    BudgetsSubmenu,
    Button,
    IcoPlus
  },
  computed: {

  },
  data() {
    return {
      showData: false,
      distanceNo: 12,
      autoHide: true,
      toDelete: false,
      boxSize: 'popper-budgets-submenu',
    }
  },
  methods: {
    goToPage(page) {

    },
    handleAdd() {
      this.$emit('add');
    },
    closeData() {
      this.showData = false
    },
    openData() {
      if (this.disabled) {
        this.showData = false
        return
      }

      // this.itemsList = this.items
      this.autoHide = false
      setTimeout(() => (this.autoHide = true), 300)

      this.showData = true
    },
  }
}
</script>

<style scoped>
.sources .no-personas-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: flex-start;
  align-items: flex-start;
}

.sources .no-personas-content,
.sources .no-personas-content h1,
.sources .no-personas-content p {
  text-align: left;
}

.sources .no-personas-content h1 {
  font-family: Nunito;
  font-size: 36px;
  font-weight: 800;
  text-align: left;
}

.sources .no-personas-content p {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 400;
  line-height: 28.8px;
  text-align: left;
}
</style>


<style>
.popper-budgets-submenu {
  width: 200px;
}
</style>
