<template>
  <div class="modal-edit-channels">
    <div class="modal-edit-channels-header">
      <div class="modal-edit-channels-title">
        <strong>{{ $t('Edit Channels') }}</strong>
        <span>{{ type }}</span>
      </div>
      <button @click="handleCancel" >
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 6.5L6 18.5M6 6.5L18 18.5" stroke="#888F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>

    <div class="modal-edit-channels-body">
      <div class="column">
        <Dropdown
          v-model="channel"
          :label="$t('Channels')"
          :placeholder="$t('Select or add a new one...')"
          :items="channelValues"
          :multiple="true"
        />
      </div>
      <!--
      <div class="column"
           v-for="(channel, index) in channelList"
           :key="`channel-${index}`"
      >
        <Dropdown
          v-model="channel.value"
          :label="$t('Channels')"
          :placeholder="$t('Select or add a new one...')"
          :items="channelValues"
        />
        <ButtonAdd v-if="index === channelList.length - 1" @click="handleAddChannelItem" />
        <ButtonRemove v-else @click="handleRemoveChannelItem(index)" />
      </div>
      -->
    </div>

    <div class="modal-edit-channels-footer">
      <Button @click="handleCancel" type="secondary">{{ $t('Cancel') }}</Button>
      <Button @click="handleEdit" type="primary">{{ $t('Save') }}</Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/button/Button.vue";
import Dropdown from "@/components/dropdown/Dropdown.vue";
import {obj_clone} from "../../../../services/helpers/clone";
import ButtonAdd from "../../../buttonCopy/ButtonAdd.vue";
import ButtonRemove from "../../../buttonRemove/ButtonRemove.vue";
export default {
  components: {
    ButtonRemove,
    ButtonAdd,
    Button,
    Dropdown
  },
  computed: {
    channelValues() {
      return this.channels.map((channel) => {
        return {
          name: channel.name,
          value: channel.id
        }
      })
    },
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    channels: {
      type: Array,
      default: () => []
    },
    selectedChannels: {
      type: Array,
      default: () => []
    }
  },
  created() {
    this.channel = this.selectedChannels;
  },
  data() {
    return {
      channel: [],
      emptyRecord: {
        name: null,
        value: ''
      },
      channelList: [{value: obj_clone(this.emptyRecord)}],
    }
  },
  methods: {
    handleRemoveChannelItem(index) {
      this.channelList.splice(index,1);
    },
    handleAddChannelItem() {
      const empty = obj_clone(this.emptyRecord);
      this.channelList.push({value: empty});
    },
    handleCancel() {
      this.$emit('close');
    },
    handleEdit() {
      this.$emit('updateChannels', this.channel);
    }
  }
}
</script>

<style scoped>
.modal-edit-channels {
  width: 536px;
}

.modal-edit-channels-header {
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-edit-channels-header button {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.modal-edit-channels-title {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
  text-align: left;
  color: #17252A;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.modal-edit-channels-title span {
  position: relative;
  font-family: Nunito;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  display: inline-block;
  color: #888F91;
}

.modal-edit-channels-title span:after {
  content: '';
  position: absolute;
  left: -10px;
  top: 4px;
  bottom: 4px;
  width: 1px;
  background-color: #888F91;
}

.modal-edit-channels-body {
  margin-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  gap: 30px;
  display: flex;
  flex-direction: column;
}

.modal-edit-channels-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 32px;
  border-top: 1px solid #E7E9E9;
  padding: 32px;
}
</style>
