<template>
  <div class="progress-container">
    <svg class="progress-ring" :width="size" :height="size">
      <circle
        class="progress-ring-background"
        :cx="center"
        :cy="center"
        :r="radius"
        :stroke="backgroundColor"
        :stroke-width="strokeWidth"
      />
      <circle
        class="progress-ring-circle"
        :cx="center"
        :cy="center"
        :r="radius"
        :style="circleStyle"
      />
    </svg>
  </div>
</template>


<script>
export default {
  name: "CircleProgress",
  props: {
    progress: {
      type: Number,
      required: true,
      validator(value) {
        return value >= 0 && value <= 100;
      },
    },
    size: {
      type: Number,
      default: 18, // Default size 18px
    },
    strokeWidth: {
      type: Number,
      default: 3, // Default stroke width
    },
    progressColor: {
      type: String,
      default: "rgba(128, 128, 255, 1)", // Default color
    },
    backgroundColor: {
      type: String,
      default: "rgba(128, 128, 255, 0.2)", // Background color
    },
  },
  computed: {
    radius() {
      return (this.size - this.strokeWidth) / 2;
    },
    center() {
      return this.size / 2;
    },
    circumference() {
      return 2 * Math.PI * this.radius;
    },
    offset() {
      return this.circumference - (this.progress / 100) * this.circumference;
    },
    circleStyle() {
      return {
        strokeDasharray: this.circumference,
        strokeDashoffset: this.offset,
        stroke: this.progressColor,
        strokeWidth: this.strokeWidth,
        strokeLinecap: "round",
        transition: "stroke-dashoffset 0.5s",
      };
    },
  },
};
</script>

<style scoped>
.progress-container {
  display: inline-block;
}

.progress-ring {
  transform: rotate(-90deg); /* Start from the top */
}

.progress-ring-background {
  fill: none;
}

.progress-ring-circle {
  fill: none;
}
</style>
