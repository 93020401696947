<template>
  <div
    class="sources-sidebar"
    :class="{collapsed: isCollapsed}"
  >
    <div class="sources-sidebar-inner">
      <br>
      <br>
      <br>
      <br>

      <button
        @click="toggleSidebarCollapsed"
        class="sidebar-toggle"
      >
        <span class="sidebar-title">{{ $t('sources_sidebar_title_manage_company') }}</span>
        <IconExpand v-if="isCollapsed" />
        <IconImpact v-if="!isCollapsed" />
      </button>

      <SourcesMenu />

      <div v-if="!isCollapsed" class="bottom">
        <ProgressLabel @open="handleShowProgressBar" />
        <ButtonDelete />
      </div>

      <div v-if="isCollapsed" class="bottom-small">
        <div @click="handleShowProgressBar" class="mini-progress" v-tooltip.right="$t('15_0_1_1_tooltip_filled_profile') + ':\n 3/10 ' + $t('15_0_1_1_tooltip_tasks_complete')">
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3334 5.16602L4.66669 11.8327M11.3334 5.16602H5.33335M11.3334 5.16602V11.166" stroke="#9795FF" stroke-width="1.66" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <CircleProgress :progress="70" />
        </div>

        <ButtonDeleteSmall />
      </div>

    </div>

    <SourcesProfileProgress v-if="showProgressBar" @close="handleCloseProgressBar" />
  </div>
</template>

<script>

import SourcesMenu from "@/components/sources/SourcesMenu.vue";
import IconExpand from "@/components/sources/icons/IconExpand.vue";
import IconImpact from "@/components/sources/icons/IconImpact.vue";
import ProgressLabel from "@/components/progressLabel/ProgressLabel.vue";
import ButtonDelete from "@/components/buttonDelete/ButtonDelete.vue";
import ButtonDeleteSmall from "@/components/buttonDeleteSmall/ButtonDeleteSmall.vue";
import SourcesProfileProgress from "@/components/sources/SourcesProfileProgress.vue";
import CircleProgress from "@/components/circleProgress/CircleProgress.vue";

export default {
  components: {
    CircleProgress,
    SourcesProfileProgress,
    ButtonDelete,
    ButtonDeleteSmall,
    ProgressLabel,
    IconImpact,
    IconExpand,
    SourcesMenu
  },
  computed: {

  },
  data() {
    return {
      isCollapsed: false,
      showProgressBar: false,
    }
  },
  methods: {
    toggleSidebarCollapsed() {
      this.isCollapsed = !this.isCollapsed;
      this.$store.commit('setSourceSidebarStatus', !this.isCollapsed)
    },
    handleCloseProgressBar() {
      this.showProgressBar = false;
    },
    handleShowProgressBar() {
      this.showProgressBar = true;
    }
  },
}
</script>

<style scoped>

.mini-progress {
  cursor: pointer;
  width: 100%;
  background-color: rgba(151, 149, 255, 0.08);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.sources-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 230px;
  background: #fff;
  z-index: 9;
  border-right: 1px solid #E7E9E9;

  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.sources-sidebar-inner {
  width: 230px;
}

.sources-sidebar.collapsed {
  width: 56px;
}

.sources-sidebar.collapsed .sources-sidebar-inner {
  width: 56px;
}

.sidebar-toggle {
  padding: 0 20px;
  margin: 0;
  border: none;
  background: transparent;
  display: flex;
  align-content: center;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
}

.sidebar-toggle svg {
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
}

.sources-sidebar.collapsed .sidebar-toggle {
  position: relative;
  left: -4px;
}

.sources-sidebar.collapsed .sidebar-toggle span {
  display: none;
}

.bottom {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.bottom-small {
  position: absolute;
  bottom: 20px;
  left: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.sidebar-title {
  font-family: Nunito;
  font-size: 12px;
  font-weight: 700;
  line-height: 16.37px;
  text-align: left;
  text-transform: uppercase;
  color: #AEB3B5;
}
</style>
