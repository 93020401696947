<template>
  <div class="files-wrapper">
    <div class="files">
      <div class="files-title">
        <svg v-if="type === 'edit'" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.5013 2.5V5.83333C12.5013 6.05435 12.5891 6.26631 12.7454 6.42259C12.9017 6.57887 13.1136 6.66667 13.3346 6.66667H16.668M12.5013 2.5H9.16797C8.72594 2.5 8.30202 2.67559 7.98946 2.98816C7.6769 3.30072 7.5013 3.72464 7.5013 4.16667V12.5C7.5013 12.942 7.6769 13.366 7.98946 13.6785C8.30202 13.9911 8.72594 14.1667 9.16797 14.1667H15.0013C15.4433 14.1667 15.8673 13.9911 16.1798 13.6785C16.4924 13.366 16.668 12.942 16.668 12.5V6.66667M12.5013 2.5L16.668 6.66667M13.3346 14.1667V15.8333C13.3346 16.2754 13.159 16.6993 12.8465 17.0118C12.5339 17.3244 12.11 17.5 11.668 17.5H5.83464C5.39261 17.5 4.96868 17.3244 4.65612 17.0118C4.34356 16.6993 4.16797 16.2754 4.16797 15.8333V7.5C4.16797 7.05797 4.34356 6.63405 4.65612 6.32149C4.96868 6.00893 5.39261 5.83333 5.83464 5.83333H7.5013" stroke="#17252A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.4993 2.5V5.83333C12.4993 6.05435 12.5871 6.26631 12.7434 6.42259C12.8997 6.57887 13.1117 6.66667 13.3327 6.66667H16.666M12.4993 2.5H9.16602C8.72399 2.5 8.30007 2.67559 7.9875 2.98816C7.67494 3.30072 7.49935 3.72464 7.49935 4.16667V12.5C7.49935 12.942 7.67494 13.366 7.9875 13.6785C8.30007 13.9911 8.72399 14.1667 9.16602 14.1667H14.9993C15.4414 14.1667 15.8653 13.9911 16.1779 13.6785C16.4904 13.366 16.666 12.942 16.666 12.5V6.66667M12.4993 2.5L16.666 6.66667M13.3327 14.1667V15.8333C13.3327 16.2754 13.1571 16.6993 12.8445 17.0118C12.532 17.3244 12.108 17.5 11.666 17.5H5.83268C5.39065 17.5 4.96673 17.3244 4.65417 17.0118C4.34161 16.6993 4.16602 16.2754 4.16602 15.8333V7.5C4.16602 7.05797 4.34161 6.63405 4.65417 6.32149C4.96673 6.00893 5.39065 5.83333 5.83268 5.83333H7.49935" stroke="#9795FF" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ $t('special_list_inputs_title') }}
        ({{uploadingFiles.length + uploadedFiles.length}})
      </div>

      <SourcesWarningMaximumLimit v-if="false" />

      <div
        class="file-uploader"
        @dragover.prevent="handleDragOver"
        @dragleave.prevent="handleDragLeave"
        @drop.prevent="handleDrop"
        :class="{ 'is-dragging': isDragging }"
      >
        <div class="file-uploader-inner">
          <p>{{$t('special_list_inputs_upload_title')}} <button @click="triggerFileInput">{{$t('special_list_inputs_upload_title_link')}}</button></p>
          <p class="description">{{$t('special_list_inputs_upload_description')}}</p>
          <input
            type="file"
            ref="fileInput"
            @change="handleFileUpload"
            multiple
            style="display: none"
          />
        </div>
        <div v-if="isDragging" class="file-uploader-wrapper">
          <span>Drop here to upload</span>
          <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1H7C7.79565 1 8.55871 1.31607 9.12132 1.87868C9.68393 2.44129 10 3.20435 10 4V11M10 11L6 7M10 11L14 7M6 12L10 16L14 12" stroke="#6A68B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>

      <div v-if="uploadingFiles.length || uploadedFiles.length" class="files-list">
        <SourcesFileUploadingItem
          v-for="(file, index) in uploadingFiles"
          :content-id="contentId"
          :company-id="companyId"
          :destination-id="destinationId"
          :file="file"
          :key="`file-uploading-item-${index}`"
          :fileType="fileType"
          @refresh="refresh"
          @cancel-upload="cancelFileUpload"
        />

        <SourcesFileItem
          v-for="(file, index) in uploadedFiles"
          :content-id="contentId"
          :company-id="companyId"
          :destination-id="destinationId"
          :file="file"
          :key="`file-item-${index}`"
          :queryType="queryType"
          :fileType="fileType"
          @refresh="refresh"
          @removeFromList="removeFromList"
          @deleted="deletedItem(index)"
        />
      </div>

    </div>
  </div>
</template>

<script>
import { SourcesFileUploaderService } from "./SourcesFileUploaderService";
import SourcesFileItem from "./SourcesFileItem.vue";
import SourcesWarningMaximumLimit from "./SourcesWarningMaximumLimit.vue";
import SourcesFileUploadingItem from "./SourcesFileUploadingItem.vue";

export default {
  components: {
    SourcesFileItem,
    SourcesWarningMaximumLimit,
    SourcesFileUploadingItem
  },
  props: {
    destinationId: {
      type: String,
      required: true,
    },
    fileType: {
      type: String,
      required: true
    },
    queryType: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'create',
    },
    contentId: {
      type: String,
      default: ''
    },
    attachments: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    companyId() {
      return this.$route.params.id
    },
    accessToken() {
      if (this.currentAccessToken !== '') {
        return this.currentAccessToken;
      }
      return localStorage.getItem('access_token');
    },
    user() {
      return this.$store.getters.getMe
    },
    uploadedFiles() {
      if(this.contentId) {
        return this.attachments;
      }

      const uploaded = this.files.filter((item) => item.uploadProgress === 100);
      return [...this.attachments];
    },
    uploadingFiles() {
      return this.files.filter((item) => item.uploadProgress < 100);
    }
  },
  data() {
    return {
      limitExceeded: false,
      currentAccessToken: '',
      isDragging: false,
      files: [],
    };
  },
  methods: {
    deletedItem(index) {
      this.attachments.splice(index, 1);
    },
    handleDragOver() {
      this.isDragging = true;
    },
    handleDragLeave() {
      this.isDragging = false;
    },
    handleDrop(event) {
      this.isDragging = false;
      const droppedFiles = event.dataTransfer.files;
      this.handleFiles(droppedFiles);
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const selectedFiles = event.target.files;
      this.handleFiles(selectedFiles);
    },
    handleFiles(fileList) {
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        this.generatePresignedURL(file);
      }
    },
    async generatePresignedURL(file) {
      try {
        const response = await SourcesFileUploaderService.generatePresignedURL(file);
        const presignedData = response.data;
        const now = new Date();
        const fileData = {
          file,
          name: file.name,
          presignedURL: presignedData.url,
          formData: presignedData, // Fields like key, policy, signature, etc.
          uploadProgress: 0, // Track progress
          saved: false,
          state: '',
          size: file.size,
          type: file.type,
          author: this.user,
          lastModified: now.toISOString(),
          uploaded: true
        };

        this.files.push(fileData);

        // Start uploading the file
        this.uploadFile(fileData);
      } catch (error) {
        console.error("Error generating presigned URL", error);
      }
    },
    async uploadFile(fileData) {
      const formData = new FormData();
      formData.append('key', fileData.formData.key);
      formData.append('policy', fileData.formData.policy);
      formData.append('x-amz-algorithm', fileData.formData['x-amz-algorithm']);
      formData.append('x-amz-credential', fileData.formData['x-amz-credential']);
      formData.append('x-amz-date', fileData.formData['x-amz-date']);
      formData.append('x-amz-signature', fileData.formData['x-amz-signature']);
      formData.append('x-amz-meta-original-filename', fileData.name);
      formData.append('x-amz-meta-author', this.user.id);
      formData.append('file', fileData.file);

      fileData.abortController = new AbortController();

      try {
        await SourcesFileUploaderService.uploadFile(
          fileData.presignedURL,
          formData,
          (progressEvent) => {
            fileData.uploadProgress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            ); // Update the upload progress
          },
          {
            signal: fileData.abortController.signal,
          }
        );

        // Mark as uploaded
        fileData.uploadProgress = 100;
        this.$emit('setFilesToSave', this.files);
        this.saveFilesToContent();
      } catch (error) {
        console.log("error: ", error);

        if (error.message === 'canceled') {
          fileData.uploadProgress = -1;
        } else {
          console.error("Error uploading file", error);
          fileData.uploadProgress = -1;
        }
      }
    },
    cancelFileUpload(fileData) {
      if (fileData.abortController) {
        fileData.abortController.abort();

        const fileIndex = this.files.indexOf(fileData);
        if (fileIndex !== -1) {
          this.files.splice(fileIndex, 1);
          // this.files = [...this.files];
        }
      }
    },
    removeFromList(file) {
      const fileIndex = this.files.indexOf(file);
      if (fileIndex !== -1) {
        this.files.splice(fileIndex, 1);
        // this.files = [...this.files];
      }
    },
    async saveFilesToContent() {
      if (this.companyId === '' || this.fileType === '') {
        return;
      }

      for (let i = 0; i < this.files.length; i++) {
        const file = this.files[i];

        if (!file.saved && file.state !== 'saving') {
          file.state = 'saving';

          try {
            await SourcesFileUploaderService.saveContentAttachment(file.formData.key, this.destinationId, this.fileType);
            file.saved = true;
            this.$emit('refresh');
            console.log("file saved");

          } catch(e) {
            this.files.splice(i, 1);

            this.$notify({
              title: this.$t("toaster_info_title_error_save_file"),
              text: this.$t("toaster_info_description_error_save_file"),
              type: "info",
              duration: 5000,
            });
          }
        }
      }
    },
    refresh() {
      this.$emit('refresh');
    },
  },
};
</script>

<style scoped>
.files {
  border-top: 1px solid rgba(209, 212, 212, 1);
  padding-top: 16px;
}

.files-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.files-title {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 8px;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  text-align: left;
  text-transform: uppercase;
  color: rgba(23, 37, 42, 1);
}

.file-uploader {
  position: relative;
  z-index: 100;
  margin-top: 24px;
}
.file-uploader.is-dragging {
  border-color: #333;
}

.file-uploader-inner {
  position: relative;
  z-index: 100;
  width: 100%;
  padding: 17px;
  border: 1px dashed rgba(209, 212, 212, 1);
  border-radius: 6px;
  text-align: center;
  transition: border-color 0.3s ease;
}

.file-uploader.is-dragging .file-uploader-inner {
  opacity: 0;
}

.file-uploader-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  border: 1px dashed rgba(121, 119, 204, 1);
  background: rgba(151, 149, 255, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
}

.file-uploader-wrapper span {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 700;
  line-height: 25.6px;
  text-align: center;
  color: rgba(106, 104, 179, 1);
}

.file-uploader p {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: center;
  color: rgba(136, 143, 145, 1);
}

.file-uploader p.description {
  font-family: Nunito;
  font-size: 12px;
  font-weight: 500;
  line-height: 19.2px;
  letter-spacing: 0.01em;
  text-align: center;
  color: rgba(174, 179, 181, 1);
}

.file-uploader button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  color: rgba(35, 179, 154, 1);
  border: none;
  cursor: pointer;
}

.file-uploader button:hover {
  text-decoration: underline;
}

.files-wrapper {
  padding: 0 16px 16px 16px;
}
</style>
