<template>
  <div class="stdc-note">
    <div v-if="content === ''" class="stdc-note-empty">
      {{ title }}
      <button @click="update">
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.83203 6.33343H4.9987C4.55667 6.33343 4.13275 6.50903 3.82019 6.82159C3.50763 7.13415 3.33203 7.55807 3.33203 8.0001V15.5001C3.33203 15.9421 3.50763 16.3661 3.82019 16.6786C4.13275 16.9912 4.55667 17.1668 4.9987 17.1668H12.4987C12.9407 17.1668 13.3646 16.9912 13.6772 16.6786C13.9898 16.3661 14.1654 15.9421 14.1654 15.5001V14.6668M13.332 4.66677L15.832 7.16677M16.9862 5.98759C17.3144 5.65938 17.4988 5.21424 17.4988 4.75009C17.4988 4.28594 17.3144 3.84079 16.9862 3.51259C16.658 3.18438 16.2129 3 15.7487 3C15.2845 3 14.8394 3.18438 14.5112 3.51259L7.4987 10.5001V13.0001H9.9987L16.9862 5.98759Z" stroke="#888F91" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>

    <div v-if="content !== ''" class="stdc-note-full">
      <div class="wysiwyg" v-html="content"></div>
      <button @click="update">
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.83203 6.33343H4.9987C4.55667 6.33343 4.13275 6.50903 3.82019 6.82159C3.50763 7.13415 3.33203 7.55807 3.33203 8.0001V15.5001C3.33203 15.9421 3.50763 16.3661 3.82019 16.6786C4.13275 16.9912 4.55667 17.1668 4.9987 17.1668H12.4987C12.9407 17.1668 13.3646 16.9912 13.6772 16.6786C13.9898 16.3661 14.1654 15.9421 14.1654 15.5001V14.6668M13.332 4.66677L15.832 7.16677M16.9862 5.98759C17.3144 5.65938 17.4988 5.21424 17.4988 4.75009C17.4988 4.28594 17.3144 3.84079 16.9862 3.51259C16.658 3.18438 16.2129 3 15.7487 3C15.2845 3 14.8394 3.18438 14.5112 3.51259L7.4987 10.5001V13.0001H9.9987L16.9862 5.98759Z" stroke="#2CE0C1" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    }
  },
  methods: {
    update() {
      this.$emit('update');
    }
  }
}
</script>

<style>
.stdc-note-full .wysiwyg,
.stdc-note-full .wysiwyg p {
  padding: 0;
  margin: 0;
}
</style>

<style scoped>
.stdc-note-full .wysiwyg p {
  padding-bottom: 0;
  margin-bottom: 0;
}

.stdc-note {
  margin-top: 30px;
}

.stdc-note-empty {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  text-align: left;
  color: #888F91;
  border: 1px dashed #888F91;
  padding: 12px 16px;
}

.stdc-note-full {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 2px 2px 1px #0000000D;
  background: #FFFFFF;
  padding: 12px 16px;
}

.stdc-note button {
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
}
</style>
