<template>
  <button @click="handleRemove">x</button>
</template>

<script>
import IcoCopy from "@/components/svg/IcoCopy.vue";
export default {
  components: {
    IcoCopy
  },
  props: ['items', 'title', 'description'],
  data() {
    return {}
  },
  methods: {
    handleRemove() {
      this.$emit('click');
    }
  }
}
</script>

<style scoped>
  button {
    cursor: pointer;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    min-width: 46px;
    max-width: 46px;
    min-height: 46px;
    max-height: 46px;
    background-color: #F6F6F7;
    border-radius: 9px;
    border: none;
    padding: 0;
    margin: 0;
  }

  button:hover {
    background-color: #d5d5d5;
  }
</style>
