import api from "@/services/rest-api/setupInterceptors";
import { URL, URLFiles } from "@/constants/URLConstant";
import axios from "axios";

const generatePresignedURL = (file) => {
  const accessToken = localStorage.getItem('access_token');

  return api.post(
    `${URL}/api/temp-files/presigned-post?filename=${encodeURIComponent(file.name)}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
}

const saveContentAttachment = (fileKey, companyId, contentId) => {
  const accessToken = localStorage.getItem('access_token');

  return api.post(
    `${URL}/api/temp-files/save-content-attachment`,
    {
      key: fileKey,
      companyId,
      contentId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
}

const deleteContentAttachment = (fileKey, companyId, contentId) => {
  return api.post(
    `${URL}/api/temp-files/delete-content-attachment`,
    {
      key: fileKey,
      companyId,
      contentId,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
}

const uploadFile = (presignedURL, formData, onUploadProgress, options = {}) => {
  return axios.post(presignedURL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    signal: options.signal,
    onUploadProgress: onUploadProgress,
  });
};

const deleteTempContentAttachment = (key) => {
  return api.post(
    `${URL}/api/temp-files/delete?key=${key}`,
    {

    },
    {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const FileUploaderService = {
  generatePresignedURL,
  saveContentAttachment,
  deleteContentAttachment,
  uploadFile,
  deleteTempContentAttachment
}
