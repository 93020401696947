<template>
  <div class="wrapper wrapper--center">
    <div class="container">
      <div class="column column--80gap column--center">
        <div class="left">
          <svg width="402" height="371" viewBox="0 0 402 371" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4930_18333)">
              <path d="M341.768 1.11719H95.9082C84.8625 1.11719 75.9082 10.0715 75.9082 21.1172V124.885C75.9082 135.931 84.8625 144.885 95.9082 144.885H341.768C352.814 144.885 361.768 135.931 361.768 124.885V21.1172C361.768 10.0715 352.814 1.11719 341.768 1.11719Z" fill="#E7E9E9"/>
              <path d="M200.716 15.8828H169.275V90.2947H200.716V15.8828Z" stroke="#BFC5C5" stroke-width="10"/>
              <path d="M247.85 90.2949H169.321V113.679H247.85V90.2949Z" stroke="#BFC5C5" stroke-width="10"/>
              <path d="M326.382 90.3105H247.853V113.695H326.382V90.3105Z" stroke="#BFC5C5" stroke-width="10"/>
              <path d="M263.649 15.8828H232.208V90.2947H263.649V15.8828Z" stroke="#BFC5C5" stroke-width="10"/>
              <path d="M326.428 15.8828H294.987V90.2947H326.428V15.8828Z" stroke="#BFC5C5" stroke-width="10"/>
              <path d="M232.208 15.8828H200.688V53.3885H232.208V15.8828Z" stroke="#BFC5C5" stroke-width="10"/>
              <path d="M295.039 15.8828H263.599V53.6025H295.039V15.8828Z" stroke="#BFC5C5" stroke-width="10"/>
              <path d="M294.973 53.6152H263.532V90.3013H294.973V53.6152Z" stroke="#BFC5C5" stroke-width="10"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M116.992 44.5246H95.6797C94.2253 36.1179 94.2253 27.5239 95.6797 19.1172H116.992C114.591 27.4162 114.591 36.2256 116.992 44.5246Z" fill="#9795FF"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M135.442 55.3469H156.755C158.209 46.9402 158.209 38.3462 156.755 29.9395H135.442C137.843 38.2385 137.843 47.0479 135.442 55.3469Z" fill="#2CE0C1"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M299.333 47.5031H320.645C322.1 39.0964 322.1 30.5024 320.645 22.0957H299.333C301.734 30.3947 301.734 39.2041 299.333 47.5031Z" fill="#2CE0C1"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M110.675 91.9777H131.987C133.442 83.571 133.442 74.977 131.987 66.5703H110.675C113.076 74.8693 113.076 83.6787 110.675 91.9777Z" fill="#2CE0C1"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M236.382 47.5031H257.694C259.149 39.0964 259.149 30.5024 257.694 22.0957H236.382C238.783 30.3947 238.783 39.2041 236.382 47.5031Z" fill="#9795FF"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M370.54 226.809C370.54 226.809 365.896 249.51 373.636 247.446C381.376 245.382 378.28 225.777 378.28 225.777L370.54 226.809Z" fill="#A0616A"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M392.212 132.91H387.052C387.052 132.91 381.376 137.038 381.892 141.681C382.408 146.324 372.604 186.566 372.604 191.725C372.604 196.884 370.54 198.432 370.54 201.528C370.54 204.623 371.572 206.687 370.54 208.235C369.508 209.783 367.444 219.069 367.96 222.681C368.26 225.079 368.26 227.505 367.96 229.903H380.86C380.86 229.903 390.148 198.432 392.212 196.884C394.276 195.337 395.308 192.757 395.308 191.209C395.308 189.662 394.276 189.662 396.856 187.598C399.436 185.534 397.888 176.248 397.888 176.248L401.5 166.961L392.212 132.91Z" fill="#D0CDE1"/>
              <g opacity="0.1">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M392.212 132.91H387.052C387.052 132.91 381.376 137.038 381.892 141.681C382.408 146.324 372.604 186.566 372.604 191.725C372.604 196.884 370.54 198.432 370.54 201.528C370.54 204.623 371.572 206.687 370.54 208.235C369.508 209.783 367.444 219.069 367.96 222.681C368.26 225.079 368.26 227.505 367.96 229.903H380.86C380.86 229.903 390.148 198.432 392.212 196.884C394.276 195.337 395.308 192.757 395.308 191.209C395.308 189.662 394.276 189.662 396.856 187.598C399.436 185.534 397.888 176.248 397.888 176.248L401.5 166.961L392.212 132.91Z" fill="#17252A"/>
              </g>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M319.972 214.169C319.972 214.169 315.329 236.869 323.068 234.805C330.808 232.742 327.712 213.137 327.712 213.137L319.972 214.169Z" fill="#FFB9B9"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M321.521 319.932L318.425 332.83L329.777 340.053L332.873 324.575L321.521 319.932Z" fill="#FFB9B9"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M375.184 335.926L369.508 343.665L376.216 351.403H385.504V335.926H375.184Z" fill="#FFB9B9"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M387.051 344.696H383.955C382.423 344.384 380.844 344.384 379.311 344.696C376.215 345.212 372.014 340.246 372.014 340.246L367.96 343.664C367.96 343.664 359.704 345.728 363.832 355.53L373.119 361.205C373.119 361.205 377.763 371.008 387.051 369.46C396.339 367.912 389.631 357.594 389.631 357.594L387.051 344.696Z" fill="#17252A"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M321.521 326.639L320.736 323.202L313.781 325.091C313.781 325.091 299.333 315.805 299.333 328.187C299.333 340.569 310.685 344.696 310.685 344.696C310.685 344.696 314.813 344.18 314.813 347.792C314.813 351.403 325.649 357.078 329.777 355.015C333.904 352.951 333.904 347.792 333.904 347.792L332.873 333.346L328.229 334.378C328.229 334.378 319.457 333.862 321.005 331.798C322.553 329.735 321.521 326.639 321.521 326.639Z" fill="#17252A"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M336.484 207.977C336.484 207.977 330.292 216.231 330.292 220.875C330.292 225.518 328.228 266.792 328.228 266.792C328.228 266.792 316.877 319.416 319.972 322.511C323.068 325.607 332.356 330.25 333.904 328.702C335.452 327.155 346.288 270.919 346.288 270.919L359.188 236.352L371.056 285.881C371.056 285.881 370.54 328.702 372.604 329.218C374.668 329.734 375.7 330.25 374.668 332.314C373.636 334.377 372.088 338.505 372.088 338.505C372.088 338.505 384.988 341.6 387.567 339.537C390.147 337.473 390.663 287.429 387.567 269.887C384.472 252.346 388.083 212.104 388.083 212.104L336.484 207.977Z" fill="#17252A"/>
              <path d="M355.319 108.147C363.868 108.147 370.798 101.217 370.798 92.6691C370.798 84.121 363.868 77.1914 355.319 77.1914C346.769 77.1914 339.839 84.121 339.839 92.6691C339.839 101.217 346.769 108.147 355.319 108.147Z" fill="#FFB9B9"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M350.674 102.472C350.674 102.472 352.738 113.306 350.674 116.402C348.61 119.497 378.538 117.434 378.538 117.434C378.538 117.434 364.09 100.924 365.638 97.8285C367.186 94.733 350.674 102.472 350.674 102.472Z" fill="#FFB9B9"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M337.517 120.271C337.517 120.271 347.837 106.857 360.737 109.437C364.219 110.133 367.401 110.83 370.19 111.475C380.02 113.751 388.193 120.755 391.551 130.267C392.422 132.734 392.857 135.104 392.47 137.039C390.922 144.778 391.696 205.914 391.696 205.914C391.696 205.914 387.568 207.462 388.084 209.526C388.6 211.589 392.212 214.169 388.6 214.169C384.988 214.169 330.293 212.105 331.325 210.558C332.357 209.01 337.517 120.271 337.517 120.271Z" fill="#D0CDE1"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M342.677 120.27H337.517C337.517 120.27 331.841 124.397 332.357 129.04C332.873 133.683 323.069 173.925 323.069 179.085C323.069 184.244 321.005 185.792 321.005 188.887C321.005 191.983 322.037 194.046 321.005 195.594C319.973 197.142 317.909 206.428 318.425 210.04C318.725 212.438 318.725 214.865 318.425 217.263H331.325C331.325 217.263 340.613 185.792 342.677 184.244C344.741 182.696 345.773 180.116 345.773 178.569C345.773 177.021 344.741 177.021 347.321 174.957C349.901 172.894 348.353 163.607 348.353 163.607L351.965 154.32L342.677 120.27Z" fill="#D0CDE1"/>
              <g opacity="0.1">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M335.153 209.877L350.674 175.732L342.914 212.045L335.153 209.877Z" fill="#17252A"/>
              </g>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M349.918 69.2627L349.926 67.9855C350.992 67.8499 352.074 67.8878 353.128 68.0977C353.14 67.3268 352.98 66.5629 352.66 65.8613C353.722 66.0011 354.756 66.3087 355.722 66.7726L356.078 64.4004L365.32 67.6388C368.637 68.8013 372.143 70.0957 374.296 72.8741C376.697 75.9717 376.871 80.3059 375.95 84.1147C375.028 87.9235 373.154 91.4226 371.755 95.0832C371.245 96.6461 370.587 98.157 369.791 99.5955C366.998 104.098 360.692 105.705 355.75 103.792C353.926 103.086 352.204 101.909 351.28 100.186C350.807 99.1167 350.404 98.0179 350.075 96.8963C349.216 94.6215 347.962 89.8629 345.878 88.6109C344.96 88.06 343.562 90.4252 342.769 89.7059C340.734 87.8572 340.722 84.6265 341.527 81.9975C342.287 79.3631 343.236 76.787 344.367 74.2893C344.852 73.2815 349.919 69.1095 349.918 69.2627Z" fill="#17252A"/>
              <path d="M22.6875 340.319L25.0095 333.096H34.8133L35.0713 341.35L29.3954 342.124L22.6875 340.319Z" fill="#FFB9B9"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M36.5816 207.803L30.5122 265.409C30.5122 265.409 22.9255 316.192 24.4428 324.151C25.9602 332.11 24.0635 333.246 24.0635 333.246H35.4436C35.4436 333.246 35.0643 328.32 37.3403 326.046C39.6163 323.772 38.4783 316.95 38.4783 316.95L67.3079 229.026L83.6194 278.294C83.6194 278.294 86.2748 332.488 86.6541 333.246C87.0335 334.004 88.1715 344.237 88.1715 344.237H102.207C102.207 344.237 100.31 338.173 102.966 336.278C105.621 334.383 107.897 335.899 105.621 332.488C103.345 329.078 102.586 324.909 103.345 323.014C104.104 321.119 104.104 313.539 104.104 313.539C104.104 313.539 102.966 211.214 96.5169 207.803C90.0682 204.392 36.5816 207.803 36.5816 207.803Z" fill="#17252A"/>
              <path d="M87.4443 348.057L88.7343 344.188H101.118V346.767L93.3782 348.831L87.4443 348.057Z" fill="#FFB9B9"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M93.3178 348.618C93.3178 348.618 86.8314 347.646 86.8314 347.97C86.8314 348.294 79.0478 369.355 86.8314 369.031C94.6151 368.707 124.128 364.494 122.182 356.718C120.236 348.942 110.507 351.21 110.507 351.21L101.75 346.35L93.3178 348.618Z" fill="#17252A"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M29.4561 341.907C29.4561 341.907 35.9424 340.935 35.9424 341.259C35.9424 341.583 43.726 362.644 35.9424 362.32C28.1588 361.996 -1.35398 357.783 0.591912 350.007C2.5378 342.231 12.2673 344.499 12.2673 344.499L21.0239 339.639L29.4561 341.907Z" fill="#17252A"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M54.8945 254.831H74.051C75.3583 247.281 75.3583 239.562 74.051 232.012H54.8945C57.0527 239.465 57.0527 247.377 54.8945 254.831Z" fill="#2CE0C1"/>
              <path d="M67.7895 99.5393C76.034 99.5393 82.7176 92.862 82.7176 84.6251C82.7176 76.3882 76.034 69.7109 67.7895 69.7109C59.5449 69.7109 52.8613 76.3882 52.8613 84.6251C52.8613 92.862 59.5449 99.5393 67.7895 99.5393Z" fill="#FFB9B9"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M55.9276 82.7383C55.9276 82.7383 53.2723 109.267 49.8582 111.162C46.4442 113.057 76.0325 114.952 76.0325 114.952C76.0325 114.952 68.8251 90.6969 71.1011 88.423C73.3771 86.1491 55.9276 82.7383 55.9276 82.7383Z" fill="#FFB9B9"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M87.7916 110.404C87.7916 110.404 77.1702 100.171 65.0314 102.066C52.8926 103.961 44.1678 106.235 44.1678 106.235C44.1678 106.235 25.5803 116.088 25.201 124.047C24.8216 132.006 32.0291 161.945 32.0291 161.945C32.0291 161.945 35.4431 210.834 35.8224 210.834C36.2018 210.834 99.9304 210.076 99.9304 210.076L88.171 146.407L87.7916 110.404Z" fill="#575A89"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M85.3108 201.599C82.8527 202.262 78.6156 201.345 74.1924 199.898C66.3023 197.309 57.8241 193.023 57.8241 193.023C57.8241 193.023 30.5118 182.032 22.5457 176.348C14.5796 170.663 19.8904 147.924 19.8904 147.924L32.0291 151.714L31.2705 165.736L61.2381 185.064C65.947 185.505 70.5966 186.439 75.1105 187.85C80.1557 189.506 85.0567 192.087 86.8282 196.051C87.1045 196.656 87.3008 197.294 87.4124 197.95C87.7727 200.042 86.923 201.16 85.3108 201.599Z" fill="#FFB9B9"/>
              <g opacity="0.15">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M86.8281 196.051C86.3009 198.014 85.7887 199.882 85.3108 201.599C82.8527 202.262 78.6155 201.345 74.1924 199.898L75.1104 187.85C80.1556 189.506 85.0566 192.087 86.8281 196.051Z" fill="#17252A"/>
              </g>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M81.3432 140.723L79.0671 160.809L75.2927 210.369C75.2927 210.369 54.4102 235.468 66.1697 236.226C77.9291 236.984 84.3779 211.592 84.3779 211.592C84.3779 211.592 96.1373 171.42 95.758 163.083C95.3786 154.745 95.758 140.723 95.758 140.723H81.3432Z" fill="#FFB9B9"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M84.6486 82.9613C85.0389 82.8053 85.4015 82.5871 85.722 82.3152C86.537 81.5251 86.3996 80.1861 86.0457 79.108C85.6918 78.0299 85.1747 76.9153 85.4281 75.8094C85.5462 75.294 85.8273 74.8226 85.9076 74.3001C86.0986 73.0575 85.1063 71.9249 84.008 71.3114C82.9097 70.6978 81.6464 70.3956 80.6084 69.6849C79.0166 68.5949 78.1377 66.6727 76.5216 65.619C74.9412 64.5885 72.9331 64.5566 71.0459 64.5587L65.4119 64.5649C62.4675 64.5682 59.232 64.6773 57.0407 66.642C56.3742 67.2396 55.8463 67.9796 55.1713 68.5676C53.8119 69.7519 51.9771 70.2246 50.4675 71.2107C48.1775 72.7407 46.8152 75.3221 46.8452 78.0742C46.8985 80.5719 48.1171 82.9982 47.8004 85.4763C47.5881 87.1387 46.6899 88.736 46.9258 90.3952C47.2057 92.3629 49.0123 93.7725 50.8651 94.4962C52.7179 95.2199 54.7377 95.4798 56.5404 96.3203C58.0119 97.0064 59.5491 98.0999 61.1002 97.6199C62.4124 97.2137 63.1324 95.8292 63.6025 94.5396C64.1522 93.0314 64.5561 91.474 64.8086 89.8887C64.9974 88.703 65.1226 87.4462 65.8196 86.4681C66.6518 85.3858 67.9855 84.8111 69.3445 84.9492C72.3867 85.2163 74.0993 90.3927 76.8969 89.1686C78.2841 88.5616 79.35 86.7448 80.4612 85.7542C81.7183 84.6307 83.128 83.6905 84.6486 82.9613Z" fill="#17252A"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M29.7535 120.637C29.7535 120.637 22.1668 123.29 19.1321 133.901C16.0974 144.513 17.2354 151.713 17.2354 151.713L33.1676 154.366L29.7535 120.637Z" fill="#575A89"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M80.2044 109.646C80.2044 109.646 93.8605 106.615 95.7572 119.5C96.9439 128.168 97.5774 136.902 97.6539 145.65C97.6539 145.65 84.7565 139.586 79.0664 145.65L80.2044 109.646Z" fill="#575A89"/>
            </g>
            <defs>
              <clipPath id="clip0_4930_18333">
                <rect width="401" height="370" fill="white" transform="translate(0.5 0.5)"/>
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="right">
          <div class="no-personas-content">
            <h1>{{ $t('15_4_1_1_title') }}</h1>
            <p>{{ $t('15_4_1_1_description') }}</p>
            <Button @click="handleAdd" type="primary"><IcoPlus /> {{ $t('15_4_1_1_button') }}</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/button/Button.vue";
import IcoPlus from '@/components/svg/IcoPlus.vue';

export default {
  components: {
    Button,
    IcoPlus
  },
  computed: {
    sourceSidebarState() {
      return this.$store.getters.getSourceSidebarState
    },
  },
  data() {
    return {}
  },
  methods: {
    handleAdd() {
      this.$emit('add');
    }
  }
}
</script>

<style scoped>
.sources .no-personas-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: flex-start;
  align-items: flex-start;
}

.sources .no-personas-content,
.sources .no-personas-content h1,
.sources .no-personas-content p {
  text-align: left;
}

.sources .no-personas-content h1 {
  font-family: Nunito;
  font-size: 36px;
  font-weight: 800;
  text-align: left;
}

.sources .no-personas-content p {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 400;
  line-height: 28.8px;
  text-align: left;
}
</style>
