<template>
  <div class="color-picker">
    <FormGroup
      :label="label"
      :focused="showData"
      :required="true"
    >
      <div class="color-picker-select" @click="openData">
        <div class="color-picker-select-data" :class="{'is-active': showData}">

          <div v-if="selectedColor" class="color-picker-select-data-color" :style="{'background-color': selectedColor}"></div>
          <svg v-if="!selectedColor" width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="0.5" width="20" height="20" rx="10" stroke="#D1D4D4" stroke-dasharray="3 3"/>
          </svg>

          <span v-if="!selectedColor">
            {{ $t('Select') }}
          </span>

          <div v-if="selectedColor" class="color-picker-select-value">
            {{ selectedColor }}
          </div>
        </div>
        <div class="color-picker-select-ico">
          <svg v-if="showData" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.16536 6.3339L14.1654 11.3339M3.33203 13.8339L13.082 4.08392C13.2378 3.93123 13.4472 3.8457 13.6654 3.8457C13.8835 3.8457 14.0929 3.93123 14.2487 4.08392L16.4154 6.25058C16.5681 6.40636 16.6536 6.61579 16.6536 6.83392C16.6536 7.05204 16.5681 7.26148 16.4154 7.41725L6.66536 17.1673H3.33203V13.8339Z" stroke="#17252A" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <svg v-else width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.16536 6.3339L14.1654 11.3339M3.33203 13.8339L13.082 4.08392C13.2378 3.93123 13.4472 3.8457 13.6654 3.8457C13.8835 3.8457 14.0929 3.93123 14.2487 4.08392L16.4154 6.25058C16.5681 6.40636 16.6536 6.61579 16.6536 6.83392C16.6536 7.05204 16.5681 7.26148 16.4154 7.41725L6.66536 17.1673H3.33203V13.8339Z" stroke="#888F91" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
      <VDropdown
        :triggers="[]"
        :shown="showData"
        :handle-resize="true"
        :arrow-padding="distanceNo"
        :popperClass="boxSize"
        :auto-hide="autoHide"
        :eagerMount="autoHide"
        @hide="closeData"
        them
      >
        <template #popper>
          <div class="color-picker-data">
            <div class="color-picker-data-row" v-for="(item, index) in items" :key="index">
              <ColorPickerItem
                v-for="(color, indexColor) in item"
                :key="indexColor"
                :color="color"
                :active="color === selectedColor"
                @selectColor="selectColor"
              />
            </div>
          </div>
        </template>
      </VDropdown>
    </FormGroup>
  </div>
</template>

<script>
import FormGroup from '@/components/FormGroup.vue';
import IcoDown from '@/components/svg/IcoDown.vue';
import ColorPickerItem from "@/components/colorPicker/ColorPickerItem.vue";

export default {
  components: {
    ColorPickerItem,
    FormGroup,
    IcoDown
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default() {
        return {}
      }
    },
    boxSize: {
      type: String,
      default: 'popper-edit-person-detail',
    },
  },
  computed: {

  },
  created() {
    this.selected = this.value;
  },
  data() {
    return {
      selectedColor: '',
      selected: {},
      items: [
        [
          '#17252A',
          '#4D5253',
          '#F45050',
          '#F47750',
          '#EB984E',
          '#FFE500',
          '#77EB4E',
          '#58D68D',
          '#2CE0C1',
          '#40D2F2',
          '#4E7AEB',
          '#9795FF',
          '#CC4EEB',
          '#F45095',
        ],
        [
          '#2E4A54',
          '#606668',
          '#F12727',
          '#F15627',
          '#E67E22',
          '#E6CE00',
          '#58E724',
          '#2ECC71',
          '#28CAAE',
          '#3ABDDA',
          '#245BE7',
          '#8886E6',
          '#C024E7',
          '#DC4886',
        ],
        [
          '#456E7D',
          '#737B7D',
          '#DB0E0E',
          '#DB3E0E',
          '#CA6F1E',
          '#CCB700',
          '#46C816',
          '#28B463',
          '#23B39A',
          '#33A8C2',
          '#1649C8',
          '#7977CC',
          '#A416C8',
          '#C34077'
        ],
        [
          '#5B91A5',
          '#888F91',
          '#AF0B0B',
          '#AF310B',
          '#AF601A',
          '#B3A000',
          '#38A012',
          '#239B56',
          '#1F9D87',
          '#2D93A9',
          '#123AA0',
          '#6A68B3',
          '#8412A0',
          '#AB3868'
        ],
        [
          '#84ADBB',
          '#AEB3B5',
          '#830808',
          '#832508',
          '#935116',
          '#998900',
          '#2A780D',
          '#1D8348',
          '#1A8674',
          '#267E91',
          '#0D2C78',
          '#5B5999',
          '#630D78',
          '#7A284B',
        ],
        [
          '#ADC8D2',
          '#BFC5C5',
          '#580606',
          '#581906',
          '#784212',
          '#807300',
          '#1C5009',
          '#186A3B',
          '#186A3B',
          '#206979',
          '#206979',
          '#4C4B80',
          '#420950',
          '#62203C',
        ]
      ],
      showData: false,
      distanceNo: 12,
      autoHide: true,
      toDelete: false,
    }
  },
  methods: {
    selectColor(color) {
      this.selectedColor = color;
      this.closeData();
    },

    selectItem(index) {
      this.selected = this.itemList[index];
      this.showData = false;

      this.$emit('updateItem', this.selected);
    },
    hide() {
      this.showData = false;
      this.$emit('blur');
    },
    toggle() {
      if( this.disabled ) {
        this.showData = false
        return
      }
      this.showData = !this.showData;

      if(this.showData) {
        this.$emit('focus');
      } else {
        this.$emit('blur');
      }
    },
    unsetUser() {
      this.selected = {};
      this.showData = false;
      this.$emit('updateUser', this.selected);
    },
    closeData() {
      this.showData = false
    },
    openData() {
      if (this.disabled) {
        this.showData = false
        return
      }

      // this.itemsList = this.items
      this.autoHide = false
      setTimeout(() => (this.autoHide = true), 300)

      this.showData = true
    },
  },
  watch: {
    value() {
      this.selected = this.value;
    }
  }
}
</script>


<style>
.sp.is-open .color-picker__selected span svg path{
  stroke: #2CE0C1 !important;
}
</style>


<style scoped>
.color-picker{
  position: relative;
  width: 100%;
}

.color-picker-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  line-height: 1;
  cursor: pointer;
}

.color-picker-select-value {
  color: #17252A;
}

.color-picker-select-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  gap: 8px;
  line-height: 1;
  color: #BFC5C5;
}

.color-picker-select-data.is-active {
  color: #17252A;
}

.color-picker-select-ico {
  display: inline-flex;
  align-items: center;
  padding-top: 3px;
  line-height: 1;
}

.color-picker-data {
  display: flex;
  gap: 4px;
  flex-direction: column;
  padding: 16px;
}

.color-picker-data-row {
  display: flex;
  gap: 4px;
  flex-direction: row;
}

.color-picker-select-data-color {
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
}
</style>
