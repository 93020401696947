<template>
  <div class="table-input">
    <div v-if="state === 'read'" class="table-input-readonly">
      <span class="empty" v-if="value === ''">{{ $t('15_12_2_tbody_not_defined') }}</span>
      <span v-else>{{ formatCurrency(value, $i18n.locale) }}</span>
      <Button @click="handleEdit" type="ico-only">
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.83203 6.33343H4.9987C4.55667 6.33343 4.13275 6.50903 3.82019 6.82159C3.50763 7.13415 3.33203 7.55807 3.33203 8.0001V15.5001C3.33203 15.9421 3.50763 16.3661 3.82019 16.6786C4.13275 16.9912 4.55667 17.1668 4.9987 17.1668H12.4987C12.9407 17.1668 13.3646 16.9912 13.6772 16.6786C13.9898 16.3661 14.1654 15.9421 14.1654 15.5001V14.6668M13.332 4.66677L15.832 7.16677M16.9862 5.98759C17.3144 5.65938 17.4988 5.21424 17.4988 4.75009C17.4988 4.28594 17.3144 3.84079 16.9862 3.51259C16.658 3.18438 16.2129 3 15.7487 3C15.2845 3 14.8394 3.18438 14.5112 3.51259L7.4987 10.5001V13.0001H9.9987L16.9862 5.98759Z" stroke="#888F91" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </Button>
    </div>
    <div v-if="state === 'edit'" class="table-input-edit">
      <input v-model="value" type="text">
      <button @click="handleSave">
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.5 4.5C0.5 2.29086 2.29086 0.5 4.5 0.5H20.5C22.7091 0.5 24.5 2.29086 24.5 4.5V20.5C24.5 22.7091 22.7091 24.5 20.5 24.5H4.5C2.29086 24.5 0.5 22.7091 0.5 20.5V4.5Z" fill="#2CE0C1"/>
          <path d="M7.83203 12.4993L11.1654 15.8327L17.832 9.16602" stroke="white" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/button/Button.vue";
import {formatCurrency} from "../../../../utils/currencyUtils";
export default {
  components: {
    Button,
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      state: 'read'
    }
  },
  methods: {
    formatCurrency,
    handleEdit() {
      this.state = 'edit';
    },
    handleSave() {
      this.state = 'read';
    }
  }
}
</script>

<style scoped>
  .table-input {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    flex-direction: column;
  }

  .table-input .empty {
    font-family: Nunito;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    text-align: left;
    color: #BFC5C5;
  }

  .table-input-readonly {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    justify-content: space-between;
  }

  .table-input-edit {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
  }

  .table-input input {
    border: 1px solid #2CE0C1;
    height: 24px;
    width: 100%;
    min-width: 0;
    max-width: 100%;
    border-radius: 4px;
    padding: 0 8px;
    text-align: left;
  }

  .table-input button {
    width: 24px;
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

</style>
