<template>
  <div class="wrapper wrapper--center">
    <div class="container">
      <div class="column column--80gap">
        <div class="left">
          <svg width="403" height="204" viewBox="0 0 403 204" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4964_25866)">
              <path d="M363.678 165.93H58.0213C54.1139 165.926 50.3677 164.361 47.6048 161.58C44.8418 158.798 43.2876 155.027 43.2832 151.094V34.34C43.2876 30.4066 44.8418 26.6356 47.6048 23.8542C50.3678 21.0729 54.1139 19.5084 58.0213 19.5039H363.678C367.585 19.5084 371.331 21.0729 374.094 23.8542C376.857 26.6356 378.411 30.4066 378.416 34.34V151.094C378.412 155.027 376.857 158.798 374.094 161.58C371.331 164.361 367.585 165.926 363.678 165.93Z" fill="white"/>
              <path d="M363.678 165.93H58.0213C54.1139 165.926 50.3677 164.361 47.6048 161.58C44.8418 158.798 43.2876 155.027 43.2832 151.094V34.34C43.2876 30.4066 44.8418 26.6356 47.6048 23.8542C50.3678 21.0729 54.1139 19.5084 58.0213 19.5039H363.678C367.585 19.5084 371.331 21.0729 374.094 23.8542C376.857 26.6356 378.411 30.4066 378.416 34.34V151.094C378.412 155.027 376.857 158.798 374.094 161.58C371.331 164.361 367.585 165.926 363.678 165.93ZM58.0213 20.794C54.4524 20.794 51.0297 22.2212 48.5061 24.7615C45.9825 27.3019 44.5648 30.7474 44.5648 34.34V151.094C44.5648 152.873 44.9128 154.634 45.5891 156.278C46.2653 157.921 47.2566 159.414 48.5061 160.672C49.7557 161.93 51.2391 162.928 52.8717 163.609C54.5044 164.289 56.2542 164.64 58.0213 164.64H363.678C365.445 164.64 367.195 164.289 368.827 163.609C370.46 162.928 371.943 161.93 373.193 160.672C374.443 159.414 375.434 157.921 376.11 156.278C376.786 154.634 377.134 152.873 377.134 151.094V107.071C377.134 84.1892 368.104 62.2442 352.031 46.0641C335.958 29.8839 314.158 20.794 291.427 20.794H58.0213Z" fill="#E6E6E6"/>
              <path d="M165.54 90.3406C165.557 102.025 161.269 113.3 153.506 121.987V121.993C152.408 123.222 151.249 124.393 150.033 125.502C148.788 126.637 147.483 127.703 146.124 128.695C145.176 129.392 144.202 130.05 143.202 130.669C142.017 131.411 140.797 132.097 139.543 132.727C138.614 133.198 137.666 133.636 136.698 134.043C130.922 136.486 124.72 137.743 118.455 137.739C114.916 137.743 111.389 137.345 107.939 136.552C106.344 136.187 104.77 135.734 103.223 135.197C102.627 134.997 102.031 134.778 101.442 134.546C97.8672 133.155 94.4773 131.324 91.3494 129.095C84.7379 124.409 79.4366 118.088 75.9561 110.741C72.4757 103.394 70.9335 95.2691 71.478 87.148C72.0225 79.0269 74.6354 71.1838 79.065 64.3743C83.4946 57.5648 89.5916 52.0184 96.7687 48.2694C103.946 44.5203 111.961 42.695 120.042 42.9693C128.123 43.2436 135.998 45.6082 142.908 49.8354C149.818 54.0625 155.53 60.0098 159.494 67.1042C163.458 74.1986 165.54 82.201 165.54 90.3406Z" fill="#F2F2F2"/>
              <path d="M306.463 55.1077H225.083C224.404 55.1067 223.753 54.8344 223.273 54.3506C222.793 53.8668 222.523 53.2111 222.523 52.5275C222.523 51.8438 222.793 51.1881 223.273 50.7043C223.753 50.2206 224.404 49.9483 225.083 49.9473H306.463C307.143 49.9473 307.795 50.2191 308.275 50.703C308.756 51.1869 309.026 51.8431 309.026 52.5275C309.026 53.2118 308.756 53.8681 308.275 54.3519C307.795 54.8358 307.143 55.1077 306.463 55.1077Z" fill="#2CE0C1"/>
              <path d="M306.463 89.9397H225.083C224.404 89.9387 223.753 89.6664 223.273 89.1826C222.793 88.6989 222.523 88.0431 222.523 87.3595C222.523 86.6758 222.793 86.0201 223.273 85.5364C223.753 85.0526 224.404 84.7803 225.083 84.7793H306.463C307.142 84.7803 307.793 85.0526 308.273 85.5364C308.753 86.0201 309.022 86.6758 309.022 87.3595C309.022 88.0431 308.753 88.6989 308.273 89.1826C307.793 89.6664 307.142 89.9387 306.463 89.9397Z" fill="#E6E6E6"/>
              <path d="M306.463 124.773H225.083C224.404 124.772 223.753 124.499 223.273 124.016C222.793 123.532 222.523 122.876 222.523 122.193C222.523 121.509 222.793 120.853 223.273 120.369C223.753 119.886 224.404 119.613 225.083 119.612H306.463C307.143 119.612 307.795 119.884 308.275 120.368C308.756 120.852 309.026 121.508 309.026 122.193C309.026 122.877 308.756 123.533 308.275 124.017C307.795 124.501 307.143 124.773 306.463 124.773Z" fill="#E6E6E6"/>
              <path d="M103.007 201.747C93.8199 189.446 88.5098 175.69 88.3358 160.007C88.0398 159.162 88.0889 158.233 88.4724 157.424C88.8558 156.615 89.5423 155.993 90.3813 155.693L121.696 144.539C122.536 144.241 123.458 144.291 124.262 144.677C125.065 145.063 125.684 145.754 125.982 146.598L140.653 188.338C140.949 189.184 140.9 190.112 140.516 190.921C140.133 191.73 139.446 192.353 138.608 192.652L107.293 203.806C106.453 204.104 105.53 204.055 104.727 203.669C103.923 203.283 103.305 202.592 103.007 201.747Z" fill="#E6E6E6"/>
              <path d="M100.978 188.284C95.4528 182.66 93.0876 172.629 91.5463 161.45C91.2691 160.658 91.3151 159.788 91.6742 159.031C92.0333 158.274 92.6763 157.69 93.4619 157.41L120.375 147.824C121.161 147.545 122.025 147.591 122.777 147.953C123.53 148.314 124.109 148.961 124.388 149.752L137.444 186.895C137.721 187.687 137.675 188.557 137.316 189.314C136.956 190.072 136.314 190.655 135.528 190.935L118.856 196.874C115.354 198.117 111.504 197.91 108.153 196.3C104.801 194.69 102.221 191.807 100.978 188.284Z" fill="white"/>
              <path d="M124.749 169.761L103.785 177.294C103.619 177.353 103.444 177.379 103.269 177.371C103.094 177.362 102.922 177.318 102.764 177.243C102.605 177.167 102.463 177.061 102.345 176.93C102.228 176.799 102.137 176.646 102.078 176.48C102.019 176.313 101.993 176.137 102.002 175.961C102.01 175.784 102.054 175.612 102.129 175.452C102.204 175.292 102.309 175.149 102.439 175.031C102.569 174.912 102.721 174.821 102.887 174.762L123.851 167.229C124.184 167.109 124.552 167.127 124.872 167.28C125.192 167.433 125.438 167.707 125.558 168.043C125.677 168.379 125.658 168.748 125.507 169.071C125.355 169.393 125.082 169.641 124.749 169.761Z" fill="#2CE0C1"/>
              <path d="M105.933 168.693L101.321 170.35C101.156 170.41 100.981 170.436 100.805 170.427C100.63 170.419 100.458 170.375 100.299 170.3C100.141 170.224 99.9985 170.118 99.8807 169.987C99.7629 169.856 99.6719 169.703 99.6129 169.537C99.5539 169.37 99.528 169.194 99.5368 169.017C99.5456 168.841 99.5889 168.668 99.6641 168.508C99.7393 168.349 99.8451 168.206 99.9753 168.087C100.106 167.969 100.258 167.877 100.423 167.818L105.035 166.161C105.2 166.101 105.376 166.075 105.551 166.084C105.726 166.092 105.898 166.136 106.057 166.211C106.215 166.287 106.358 166.393 106.475 166.524C106.593 166.655 106.684 166.808 106.743 166.975C106.802 167.141 106.828 167.317 106.819 167.494C106.811 167.67 106.767 167.843 106.692 168.003C106.617 168.162 106.511 168.306 106.381 168.424C106.251 168.543 106.098 168.634 105.933 168.693Z" fill="#2CE0C1"/>
              <path d="M128.697 180.894L107.733 188.427C107.399 188.547 107.032 188.528 106.712 188.375C106.392 188.222 106.146 187.948 106.027 187.613C105.908 187.277 105.926 186.908 106.078 186.585C106.229 186.263 106.501 186.015 106.835 185.895L127.799 178.362C128.132 178.243 128.499 178.261 128.819 178.414C129.139 178.567 129.385 178.841 129.504 179.177C129.623 179.512 129.605 179.882 129.454 180.204C129.302 180.526 129.03 180.774 128.697 180.894Z" fill="#2CE0C1"/>
              <path d="M126.721 175.328L105.757 182.861C105.424 182.981 105.056 182.962 104.736 182.81C104.416 182.657 104.169 182.382 104.05 182.047C103.931 181.711 103.95 181.341 104.101 181.019C104.253 180.697 104.526 180.448 104.859 180.328L125.823 172.796C125.989 172.736 126.164 172.71 126.339 172.718C126.514 172.727 126.686 172.77 126.845 172.846C127.004 172.922 127.146 173.028 127.264 173.159C127.382 173.29 127.473 173.443 127.532 173.609C127.591 173.776 127.616 173.952 127.608 174.129C127.599 174.305 127.556 174.478 127.48 174.638C127.405 174.797 127.299 174.94 127.169 175.059C127.039 175.177 126.887 175.269 126.721 175.328Z" fill="#2CE0C1"/>
              <path d="M107.685 68.8972C108.368 74.4419 112.184 79.0135 117.735 79.0135C120.4 79.0135 122.956 77.9477 124.841 76.0505C126.726 74.1533 127.784 71.5802 127.784 68.8972C127.784 63.31 123.261 59.2973 117.735 58.7808C111.871 58.2327 106.809 61.7785 107.685 68.8972Z" fill="#2F2E41"/>
              <path d="M126.484 137.049C120.33 138.114 114.028 137.945 107.939 136.552C110.829 134.946 119.595 135.933 126.484 137.049Z" fill="#2F2E41"/>
              <path d="M87.3697 154.843L92.1971 143.1L96.0005 145.227L93.7036 157.173C93.9336 157.93 93.9494 158.736 93.7491 159.502C93.5488 160.267 93.1404 160.961 92.5698 161.505C91.9992 162.05 91.289 162.423 90.5191 162.584C89.7492 162.744 88.95 162.686 88.2115 162.414C87.473 162.142 86.8245 161.669 86.3387 161.046C85.8529 160.424 85.5492 159.678 85.4618 158.891C85.3743 158.104 85.5065 157.309 85.8436 156.594C86.1807 155.879 86.7092 155.273 87.3697 154.843Z" fill="#FFB6B6"/>
              <path d="M137.306 123.064V123.07C137.133 127.16 136.896 131.05 136.697 134.043C130.922 136.486 124.719 137.743 118.454 137.739C114.916 137.743 111.388 137.345 107.939 136.552C106.343 136.187 104.769 135.735 103.222 135.197C103.438 135.057 103.67 134.947 103.915 134.868C106.221 134.081 103.94 133.365 103.959 132.617C104.03 129.882 104.78 126.599 105.747 123.451L101.441 134.546L99.1982 140.313C99.1982 140.313 99.2752 145.292 97.8526 143.77C96.4237 142.248 96.5262 147.176 96.5262 147.176C96.0821 147.494 95.6998 147.892 95.3984 148.35C95.2716 148.556 95.2238 148.801 95.2638 149.04V149.047C95.3282 149.234 95.455 149.394 95.6227 149.498C96.5903 150.156 95.1997 151.898 94.5846 152.588C94.418 152.775 94.309 152.885 94.309 152.885L88.3945 152.459V152.343C88.4202 151.64 88.5804 147.937 89.4967 147.053C90.5028 146.079 90.0093 144.538 90.0093 144.538C90.0093 144.538 92.6366 142.196 90.8744 140.293C90.1695 139.526 90.4835 134.978 91.3486 129.095C93.2389 116.117 97.7693 96.6364 99.7301 96.9137C100.134 94.9378 101.073 93.1127 102.442 91.6398C103.811 90.1669 105.558 89.1034 107.49 88.5668L113.879 86.7994L115.019 82.2646H124.016L125.58 85.2448L132.135 88.2249L132.218 88.2636L132.423 88.3539L132.5 88.599C132.5 88.599 132.494 88.6055 132.5 88.6055L136.146 100.133C137.787 102.029 137.723 113.111 137.306 123.064Z" fill="#575A89"/>
              <path d="M152.627 146.292L146.535 135.16L150.561 133.5L158.392 142.77C159.122 143.062 159.758 143.553 160.226 144.189C160.695 144.825 160.978 145.579 161.044 146.368C161.109 147.157 160.955 147.948 160.599 148.654C160.242 149.359 159.697 149.95 159.025 150.361C158.353 150.772 157.58 150.985 156.794 150.978C156.008 150.971 155.239 150.744 154.574 150.321C153.91 149.898 153.375 149.297 153.031 148.585C152.687 147.874 152.547 147.08 152.627 146.292Z" fill="#FFB6B6"/>
              <path d="M132.137 88.1367C133.22 90.0967 143.131 95.1377 141.413 100.199C141.413 100.199 150.724 129.521 151.934 129.329C153.145 129.138 154.327 132.167 154.327 132.167C154.327 132.167 154.07 134.143 154.825 133.322C155.58 132.5 155.4 134.654 155.4 134.654C155.4 134.654 155.038 136.554 155.996 136.038C156.955 135.521 155.695 139.158 155.695 139.158L149.933 141.889L148.838 140.249C148.838 140.249 146.498 139.8 147.599 139.445C148.7 139.09 146.563 137.894 146.563 137.894C146.563 137.894 144.329 136.365 144.64 133.833C144.951 131.3 138.84 126.322 138.84 126.322C134.639 117.973 132.341 109.4 132.349 100.558L132.137 88.1367Z" fill="#575A89"/>
              <path d="M118.845 79.6144C123.767 79.6144 127.757 75.5981 127.757 70.6436C127.757 65.6892 123.767 61.6729 118.845 61.6729C113.923 61.6729 109.934 65.6892 109.934 70.6436C109.934 75.5981 113.923 79.6144 118.845 79.6144Z" fill="#FFB6B6"/>
              <path d="M109.617 71.6207C109.9 73.57 111.55 76.2898 111.163 76.2898C110.777 76.2898 108.029 68.2538 110.777 67.7298C112.77 67.3497 113.216 67.6831 115.079 66.8699L114.816 67.1189C116.049 68.0216 117.765 67.4652 119.172 66.8777C120.583 66.294 122.295 65.7376 123.532 66.6364C124.309 67.2006 124.638 68.1811 125.121 69.0138C125.604 69.8503 126.497 70.6402 127.417 70.3834C127.775 70.244 128.081 69.9937 128.289 69.6686C128.497 69.3435 128.598 68.9604 128.576 68.5741C128.572 67.8037 127.576 67.0708 127.911 66.3796C128.906 64.3313 128.012 62.9351 125.844 61.9712C125.055 61.8506 124.267 61.7274 123.478 61.6016C123.891 61.0255 124.392 60.5186 124.962 60.0997C124.721 59.478 124.305 58.9409 123.764 58.555C123.096 58.2424 122.351 58.1341 121.623 58.2437C119.992 58.3293 118.362 58.4149 116.733 58.5005C115.52 58.4787 114.317 58.7147 113.2 59.1931C111.855 59.8896 110.974 61.2475 110.332 62.6288C108.895 65.7337 109.123 68.2317 109.617 71.6207Z" fill="#2F2E41"/>
              <path d="M332.542 65.7365H198.523C198.376 65.7365 198.234 65.6775 198.13 65.5724C198.025 65.4674 197.967 65.3249 197.967 65.1763C197.967 65.0278 198.025 64.8853 198.13 64.7803C198.234 64.6752 198.376 64.6162 198.523 64.6162H332.542C332.689 64.6162 332.831 64.6752 332.935 64.7803C333.04 64.8853 333.098 65.0278 333.098 65.1763C333.098 65.3249 333.04 65.4674 332.935 65.5724C332.831 65.6775 332.689 65.7365 332.542 65.7365Z" fill="#E6E6E6"/>
              <path d="M332.542 100.465H198.523C198.376 100.465 198.234 100.406 198.13 100.301C198.025 100.196 197.967 100.053 197.967 99.9049C197.967 99.7563 198.025 99.6138 198.13 99.5088C198.234 99.4037 198.376 99.3447 198.523 99.3447H332.542C332.689 99.3447 332.831 99.4037 332.935 99.5088C333.04 99.6138 333.098 99.7563 333.098 99.9049C333.098 100.053 333.04 100.196 332.935 100.301C332.831 100.406 332.689 100.465 332.542 100.465Z" fill="#E6E6E6"/>
              <path d="M332.542 135.194H198.523C198.376 135.194 198.234 135.135 198.13 135.029C198.025 134.924 197.967 134.782 197.967 134.633C197.967 134.485 198.025 134.342 198.13 134.237C198.234 134.132 198.376 134.073 198.523 134.073H332.542C332.689 134.073 332.831 134.132 332.935 134.237C333.04 134.342 333.098 134.485 333.098 134.633C333.098 134.782 333.04 134.924 332.935 135.029C332.831 135.135 332.689 135.194 332.542 135.194Z" fill="#E6E6E6"/>
              <path d="M352.489 132.973C347.583 132.973 342.838 131.209 339.109 127.999C337.865 126.927 336.54 125.064 335.17 122.461C334.296 120.785 333.806 118.932 333.737 117.04C333.668 115.148 334.021 113.264 334.771 111.527C334.048 112.267 333.249 112.927 332.387 113.496L331.951 113.788L331.926 113.262C331.909 112.92 331.901 112.578 331.901 112.248C331.901 110.301 333.364 108.325 332.689 106.525C329.845 98.9398 320.826 91.4012 333.898 79.6615C335.107 78.5755 333.492 76.4041 333.492 74.7611C333.492 58.6583 356.1 30.8001 362.508 45.5579C370.41 63.7579 384.025 61.8112 390.056 65.5704L390.143 65.8363L389.886 65.9413C388.566 66.4998 387.175 66.872 385.754 67.0475C387.269 67.3585 388.816 67.4889 390.361 67.436L390.601 67.4276L390.659 67.662C391.234 69.984 391.524 72.3681 391.524 74.7611L391.523 75.0197C391.518 76.8373 391.893 78.6357 392.624 80.2975C393.356 81.9593 394.427 83.4473 395.768 84.6645C397.886 86.6086 399.578 88.9756 400.737 91.6146C401.897 94.2537 402.497 97.1069 402.5 99.9923C402.5 103.406 400.195 107.887 398.261 111.045C397.771 111.85 397.107 112.532 396.317 113.041C395.528 113.55 394.634 113.872 393.703 113.984C392.822 114.096 391.926 114.011 391.081 113.736C390.235 113.461 389.46 113.003 388.81 112.393C389.725 114.245 390.918 115.945 392.346 117.432L392.61 117.706L392.282 117.898C389.135 119.746 385.556 120.719 381.912 120.717L381.698 120.717C379.027 120.689 376.376 121.198 373.902 122.215C371.428 123.231 369.181 124.734 367.291 126.636C365.368 128.631 363.067 130.22 360.525 131.308C357.983 132.396 355.251 132.962 352.489 132.973Z" fill="#E6E6E6"/>
              <path d="M345.406 165.794C345.317 165.794 345.231 165.761 345.163 165.703C345.096 165.644 345.052 165.562 345.04 165.474C344.072 156.782 344.014 148.013 344.867 139.309C346.145 125.579 350.249 105.804 362.543 89.5618C362.572 89.5229 362.609 89.4902 362.651 89.4656C362.693 89.441 362.74 89.4249 362.788 89.4184C362.836 89.4118 362.885 89.4149 362.932 89.4274C362.979 89.4399 363.023 89.4616 363.062 89.4913C363.101 89.521 363.133 89.558 363.157 89.6003C363.182 89.6426 363.198 89.6894 363.204 89.7379C363.211 89.7864 363.208 89.8357 363.195 89.883C363.183 89.9303 363.161 89.9747 363.132 90.0137C350.944 106.115 346.873 125.746 345.604 139.379C344.756 148.024 344.812 156.735 345.773 165.369C345.78 165.422 345.776 165.475 345.761 165.527C345.746 165.578 345.721 165.625 345.686 165.666C345.651 165.706 345.608 165.738 345.56 165.76C345.511 165.782 345.459 165.794 345.406 165.794Z" fill="#3F3D56"/>
              <path d="M355.439 112.471C355.361 112.471 355.284 112.446 355.221 112.4C355.158 112.354 355.111 112.289 355.087 112.214C355.062 112.139 355.062 112.058 355.086 111.984C355.111 111.909 355.158 111.843 355.221 111.797C358.724 109.41 362.508 107.471 366.487 106.024C372.702 103.733 382.154 101.657 391.884 104.774C391.93 104.789 391.973 104.813 392.011 104.844C392.048 104.876 392.078 104.915 392.101 104.958C392.123 105.002 392.137 105.049 392.141 105.098C392.145 105.147 392.139 105.196 392.125 105.242C392.11 105.289 392.086 105.332 392.055 105.37C392.023 105.407 391.985 105.438 391.942 105.46C391.899 105.483 391.852 105.496 391.803 105.501C391.755 105.505 391.706 105.499 391.66 105.484C382.129 102.431 372.848 104.473 366.741 106.724C362.827 108.146 359.103 110.053 355.656 112.4C355.593 112.446 355.517 112.471 355.439 112.471Z" fill="#3F3D56"/>
              <path d="M338.493 69.9848C338.487 69.9063 338.507 69.828 338.548 69.7611C338.589 69.6942 338.65 69.6422 338.723 69.6125C338.795 69.5828 338.875 69.577 338.951 69.5958C339.027 69.6146 339.095 69.6572 339.146 69.7174C341.763 73.0627 343.957 76.7226 345.677 80.6129C348.394 86.6885 351.132 96.0287 348.744 106.023C348.733 106.07 348.712 106.115 348.684 106.155C348.655 106.195 348.619 106.229 348.578 106.254C348.536 106.28 348.49 106.297 348.442 106.305C348.394 106.312 348.345 106.31 348.297 106.299C348.25 106.288 348.205 106.267 348.166 106.238C348.127 106.209 348.093 106.173 348.068 106.131C348.043 106.089 348.026 106.042 348.018 105.994C348.011 105.946 348.013 105.896 348.024 105.848C350.363 96.0592 347.671 86.8885 345.002 80.9189C343.311 77.0911 341.153 73.4898 338.579 70.1979C338.529 70.1379 338.499 70.0632 338.493 69.9848Z" fill="#3F3D56"/>
              <path d="M35.7083 125.517C33.8188 123.615 31.5714 122.112 29.0975 121.096C26.6236 120.079 23.9732 119.57 21.3014 119.598L21.0879 119.598C17.444 119.6 13.8652 118.627 10.7173 116.779L10.3898 116.587L10.6536 116.313C12.0819 114.826 13.2745 113.126 14.1897 111.274C13.5397 111.884 12.7645 112.342 11.9189 112.617C11.0733 112.892 10.1779 112.977 9.29625 112.865C8.36543 112.753 7.47182 112.431 6.68245 111.922C5.89307 111.413 5.22843 110.73 4.73839 109.926C2.80483 106.768 0.5 102.287 0.5 98.8732C0.503009 95.9877 1.10318 93.1345 2.26226 90.4955C3.42134 87.8564 5.11391 85.4895 7.23208 83.5454C8.57306 82.3282 9.64416 80.8402 10.3755 79.1784C11.1069 77.5165 11.4822 75.7182 11.4768 73.9006L11.4758 73.6419C11.4754 71.2489 11.7659 68.8649 12.3408 66.5429L12.3991 66.3085L12.6388 66.3168C14.1842 66.3698 15.7305 66.2394 17.2456 65.9284C15.8244 65.7529 14.4341 65.3806 13.1141 64.8222L12.8563 64.7172L12.9438 64.4512C18.9751 60.692 32.5896 62.6388 40.4919 44.4387C46.8995 29.6809 69.5079 57.5392 69.5079 73.6419C69.5079 75.285 67.8925 77.4563 69.1017 78.5423C82.1739 90.2821 73.1543 97.8206 70.3106 105.406C69.6359 107.206 71.0988 109.182 71.0988 111.129C71.0988 111.459 71.0905 111.8 71.0739 112.143L71.0488 112.669L70.6129 112.377C69.7506 111.808 68.9514 111.148 68.2288 110.408C68.9784 112.145 69.3317 114.029 69.2627 115.921C69.1936 117.813 68.704 119.666 67.8298 121.342C66.4598 123.945 65.1345 125.808 63.8906 126.88C59.9059 130.313 54.7692 132.083 49.5307 131.83C44.2922 131.577 39.3477 129.318 35.7083 125.517Z" fill="#E6E6E6"/>
              <path d="M57.5929 164.675C57.6819 164.675 57.768 164.642 57.8352 164.583C57.9025 164.525 57.9464 164.443 57.9589 164.355C58.9271 155.663 58.985 146.894 58.1317 138.19C56.8537 124.46 52.7497 104.684 40.4558 88.4427C40.4263 88.4038 40.3895 88.3711 40.3475 88.3465C40.3055 88.3219 40.259 88.3058 40.2108 88.2993C40.1627 88.2927 40.1136 88.2958 40.0666 88.3083C40.0196 88.3208 39.9755 88.3425 39.9369 88.3722C39.8982 88.4018 39.8657 88.4389 39.8412 88.4812C39.8168 88.5235 39.8009 88.5702 39.7943 88.6187C39.7878 88.6672 39.7909 88.7166 39.8033 88.7639C39.8157 88.8112 39.8373 88.8556 39.8668 88.8946C52.0545 104.996 56.1254 124.627 57.3944 138.26C58.2431 146.905 58.1866 155.616 57.2258 164.25C57.2184 164.302 57.2223 164.356 57.2374 164.407C57.2524 164.459 57.2782 164.506 57.313 164.546C57.3478 164.587 57.3908 164.619 57.4391 164.641C57.4873 164.663 57.5398 164.675 57.5929 164.675Z" fill="#3F3D56"/>
              <path d="M47.5606 111.352C47.6388 111.352 47.715 111.327 47.7784 111.281C47.8417 111.235 47.8888 111.17 47.913 111.095C47.9372 111.02 47.9372 110.939 47.9131 110.864C47.8889 110.789 47.8419 110.724 47.7786 110.678C44.2755 108.291 40.4913 106.352 36.5128 104.905C30.2973 102.614 20.8457 100.538 11.1154 103.655C11.069 103.67 11.0261 103.694 10.9889 103.725C10.9517 103.757 10.9211 103.795 10.8988 103.839C10.8765 103.882 10.8629 103.93 10.8587 103.979C10.8546 104.027 10.8601 104.077 10.8749 104.123C10.8896 104.17 10.9133 104.213 10.9447 104.25C10.976 104.288 11.0144 104.319 11.0576 104.341C11.1008 104.364 11.1479 104.377 11.1964 104.381C11.2448 104.386 11.2936 104.38 11.3399 104.365C20.8708 101.312 30.1511 103.354 36.2582 105.605C40.1727 107.027 43.8963 108.934 47.3432 111.281C47.4064 111.327 47.4825 111.352 47.5606 111.352Z" fill="#3F3D56"/>
              <path d="M64.5074 68.8657C64.5131 68.7872 64.4939 68.7088 64.4527 68.642C64.4114 68.5751 64.3502 68.523 64.2778 68.4933C64.2053 68.4637 64.1254 68.4578 64.0495 68.4767C63.9735 68.4955 63.9055 68.5381 63.855 68.5983C61.2378 71.9436 59.0438 75.6035 57.3238 79.4937C54.6065 85.5694 51.8683 94.9096 54.2563 104.904C54.2676 104.951 54.2881 104.996 54.3166 105.036C54.3452 105.076 54.3812 105.109 54.4227 105.135C54.4642 105.161 54.5104 105.178 54.5585 105.186C54.6066 105.193 54.6557 105.191 54.7031 105.18C54.7504 105.168 54.7951 105.148 54.8345 105.119C54.8738 105.09 54.9071 105.054 54.9325 105.012C54.9579 104.97 54.9748 104.923 54.9823 104.875C54.9898 104.826 54.9877 104.777 54.9762 104.729C52.6373 94.9401 55.3292 85.7694 57.999 79.7998C59.69 75.9719 61.8472 72.3706 64.4212 69.0788C64.4716 69.0187 64.5018 68.9441 64.5074 68.8657Z" fill="#3F3D56"/>
            </g>
            <defs>
              <clipPath id="clip0_4964_25866">
                <rect width="402" height="204" fill="white" transform="translate(0.5)"/>
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="right">
          <div class="no-personas-content">
            <h1>{{ $t('15_6_1_1_title') }}</h1>
            <p>{{ $t('15_6_1_1_description') }}</p>
            <Button type="primary" @click="handleAdd"><IcoPlus /> {{ $t('15_6_1_1_button') }}</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/button/Button.vue";
import IcoPlus from '@/components/svg/IcoPlus.vue';

export default {
  components: {
    Button,
    IcoPlus
  },
  computed: {

  },
  data() {
    return {}
  },
  methods: {
    handleAdd() {
      this.$emit('add');
    }
  }
}
</script>

<style>
.sources .no-personas-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: flex-start;
  align-items: flex-start;
}

.sources .no-personas-content,
.sources .no-personas-content h1,
.sources .no-personas-content p {
  text-align: left;
}

.sources .no-personas-content h1 {
  font-family: Nunito;
  font-size: 36px;
  font-weight: 800;
  text-align: left;
}

.sources .no-personas-content p {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 400;
  line-height: 28.8px;
  text-align: left;
}
</style>
