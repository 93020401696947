<template>
  <div class="product-card">
    <div class="product-card-inner">

      <div class="product-card-avatar"></div>

      <div class="product-card-data">
        <div class="product-card-header">
          <div class="product-card-header-content">
            <div class="product-card-header-main">
              <div class="product-card-header-title">
                {{ item.name }}
              </div>
              <div class="product-card-header-data">
                <div class="product-card-header-performance">

                </div>
                <div class="product-card-header-percentages">
                  {{ item.ratio }}%
                </div>
              </div>
            </div>
            <div class="product-card-header-label">
              <span v-if="item.type">{{ $t('15_7_1_3_label_type') }}: {{ $t(DataService.getProductType(item.type)) }}</span>
              <span v-if="item.averagePrice">{{ $t('15_7_1_3_label_avg_price') }}: {{ item.averagePrice }}</span>
              <span v-if="item.markets">{{ $t('15_7_1_3_label_market_location') }}: {{ item.markets }}</span>
            </div>
          </div>
          <button
            class="product-card-open-detail"
            @click="handleOpenDetail"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 6L15 12L9 18" stroke="#888F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>

        <div class="product-card-description">
          {{ item.description }}
        </div>

        <div
          class="product-card-labels-list"
        >
          <div
            v-if="item.personas && item.personas.length > 0"
            class="product-card-labels"
          >
            <div class="product-card-labels-title">
              {{ $t('15_7_1_3_label_personas') }}:
            </div>
            <div class="product-card-labels-data">
              <Label
                v-for="(item, index) in item.personas"
                :key="`channel-index-${index}`"
                :item="item"
                :title="item.name"
              />
            </div>
          </div>

          <div
            v-if="item.channels && item.channels.length > 0"
            class="product-card-labels"
          >
            <div class="product-card-labels-title">
              {{ $t('15_7_1_3_label_channels') }}:
            </div>
            <div class="product-card-labels-data">
              <Label
                v-for="(item, index) in item.channels"
                :key="`channel-index-${index}`"
                :title="item.name"
              />
            </div>
          </div>

          <!--
          <div class="product-card-labels">
            <div class="product-card-labels-title">
              {{ $t('15_7_1_3_label_competitors') }}:
            </div>
            <div class="product-card-labels-data">
              <LabelStdc title="See" />
              <LabelStdc title="See" />
              <LabelStdc title="See" />
            </div>
          </div>
          -->
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Avatar from "@/components/Avatar.vue";
import Label from "@/components/sources/components/label/Label.vue";
import LabelStdc from "@/components/sources/components/labelStdc/LabelStdc.vue";
import {DateService as DataService} from "../../../../services/DateService";
export default {
  components: {
    LabelStdc,
    Label,
    Avatar,
  },
  props: ['item'],
  computed: {
    DataService() {
      return DataService
    },
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
  },
  data() {
    return {

    }
  },
  methods: {
    handleOpenDetail() {
      this.$router.push({ path: `/company/${this.selectedCompany.id}/products-and-services-detail/${this.item.id}` });
    }
  }
}
</script>

<style scoped>
.product-card {
  padding: 20px;
  box-shadow: 0px 2px 2px 1px #0000000D;
  background-color: #fff;
  border-radius: 12px;
}

.product-card-inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
}

.product-card-avatar {
  min-width: 100px;
  max-width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #E7E9E9;
}

.product-card-data {
  width: 100%;
}

.product-card-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.product-card-open-detail {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.product-card-header-content {
  display: flex;
  gap: 4px;
  flex-direction: column;
}

.product-card-header-main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
}

.product-card-header-title {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: left;
}

.product-card-header-data {
  position: relative;
}

.product-card-header-data::after {
  content: '';
  display: block;
  position: absolute;
  left: -6px;
  top: 3px;
  bottom: 3px;
  width: 1px;
  background-color: #E7E9E9;
}

.product-card-header-performance {

}

.product-card-header-percentages {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  letter-spacing: 0.02em;
  text-align: left;
}

.product-card-header-label {
  display: flex;
  gap: 16px;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
  color: #888F91;
}

.product-card-header-label span {
  position: relative;
}

.product-card-header-label span:after {
  content: '';
  position: absolute;
  right: -8px;
  top: 5px;
  bottom: 5px;
  width: 1px;
  background-color: #888F91;
}

.product-card-header-label span:last-child:after {
  display: none;
}

.product-card-description {
  padding: 12px 0;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #17252A;
}


.product-card-labels {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
}

.product-card-labels-title {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  text-align: left;
  color: #888F91;
}

.product-card-labels-data {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
}

.product-card-labels-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
</style>
