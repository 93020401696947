<template>

    <div class="person">
      <div class="person__inner">
        <div class="person__data">
          <div @mouseout="toCloseData" @mouseover="openData" class="person__avatar">
            <VMenu
              :handle-resize="true"
              :arrow-padding="distanceNo"
              :popperClass="boxSize"
            >
              <Avatar :size="'medium'" :name="person.name" :user="person" />

              <template #popper>
                <div class="person-detail">
                  <div class="person-detail-header">
                    <div class="person-detail-avatar">
                      <Avatar size="big" :name="person.name" :user="person" />
                    </div>
                    <div class="person-detail-role">
                      <PersonRole :readonly="true" :role="person.role" />
                    </div>
                  </div>
                  <div v-if="person.name" class="person-detail-name">{{ person.name }}</div>
                  <div v-if="person.position" class="person-detail-position">{{ person.position }}</div>
                  <div class="person-detail-email">
                    <a href="mailto:v.m.bacova@gmail.com">
                      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.5 6.83366C3.5 6.39163 3.67559 5.96771 3.98816 5.65515C4.30072 5.34259 4.72464 5.16699 5.16667 5.16699H16.8333C17.2754 5.16699 17.6993 5.34259 18.0118 5.65515C18.3244 5.96771 18.5 6.39163 18.5 6.83366M3.5 6.83366V15.167C3.5 15.609 3.67559 16.0329 3.98816 16.3455C4.30072 16.6581 4.72464 16.8337 5.16667 16.8337H16.8333C17.2754 16.8337 17.6993 16.6581 18.0118 16.3455C18.3244 16.0329 18.5 15.609 18.5 15.167V6.83366M3.5 6.83366L11 11.8337L18.5 6.83366" stroke="#9795FF" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      {{ person.email }}
                    </a>
                    <button @click="handleCopyEmail">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.3334 6.66634V4.99967C13.3334 4.55765 13.1578 4.13372 12.8452 3.82116C12.5327 3.5086 12.1087 3.33301 11.6667 3.33301H5.00004C4.55801 3.33301 4.13409 3.5086 3.82153 3.82116C3.50897 4.13372 3.33337 4.55765 3.33337 4.99967V11.6663C3.33337 12.1084 3.50897 12.5323 3.82153 12.8449C4.13409 13.1574 4.55801 13.333 5.00004 13.333H6.66671M6.66671 8.33301C6.66671 7.89098 6.8423 7.46706 7.15486 7.1545C7.46742 6.84194 7.89135 6.66634 8.33337 6.66634H15C15.4421 6.66634 15.866 6.84194 16.1786 7.1545C16.4911 7.46706 16.6667 7.89098 16.6667 8.33301V14.9997C16.6667 15.4417 16.4911 15.8656 16.1786 16.1782C15.866 16.4907 15.4421 16.6663 15 16.6663H8.33337C7.89135 16.6663 7.46742 16.4907 7.15486 16.1782C6.8423 15.8656 6.66671 15.4417 6.66671 14.9997V8.33301Z" stroke="#888F91" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </button>
                  </div>

                  <!--
                  <div class="person-detail-phone">
                    <a href="tel:+420777199016">
                      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.16667 4.33301H8.5L10.1667 8.49967L8.08333 9.74967C8.9758 11.5593 10.4404 13.0239 12.25 13.9163L13.5 11.833L17.6667 13.4997V16.833C17.6667 17.275 17.4911 17.699 17.1785 18.0115C16.866 18.3241 16.442 18.4997 16 18.4997C12.7494 18.3021 9.68346 16.9218 7.38069 14.619C5.07792 12.3162 3.69754 9.25029 3.5 5.99967C3.5 5.55765 3.67559 5.13372 3.98816 4.82116C4.30072 4.5086 4.72464 4.33301 5.16667 4.33301Z" stroke="#9795FF" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      +420 777 199 016
                    </a>
                    <button @click="handleCopyPhone">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.3334 6.66634V4.99967C13.3334 4.55765 13.1578 4.13372 12.8452 3.82116C12.5327 3.5086 12.1087 3.33301 11.6667 3.33301H5.00004C4.55801 3.33301 4.13409 3.5086 3.82153 3.82116C3.50897 4.13372 3.33337 4.55765 3.33337 4.99967V11.6663C3.33337 12.1084 3.50897 12.5323 3.82153 12.8449C4.13409 13.1574 4.55801 13.333 5.00004 13.333H6.66671M6.66671 8.33301C6.66671 7.89098 6.8423 7.46706 7.15486 7.1545C7.46742 6.84194 7.89135 6.66634 8.33337 6.66634H15C15.4421 6.66634 15.866 6.84194 16.1786 7.1545C16.4911 7.46706 16.6667 7.89098 16.6667 8.33301V14.9997C16.6667 15.4417 16.4911 15.8656 16.1786 16.1782C15.866 16.4907 15.4421 16.6663 15 16.6663H8.33337C7.89135 16.6663 7.46742 16.4907 7.15486 16.1782C6.8423 15.8656 6.66671 15.4417 6.66671 14.9997V8.33301Z" stroke="#888F91" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </button>
                  </div>
                  -->
                </div>
              </template>
            </VMenu>
          </div>
          <div class="person__text">
            <div title="" v-tooltip="person.name" class="person__title">{{ person.name }}</div>
            <div title="" v-tooltip="person.position" class="person__position"> {{ person.position }}</div>
          </div>
        </div>

        <div class="person__controll">
          <div class="person__role">
            <PersonRole :role="person.role" @updateRole="updateRole" />
          </div>
          <div>
            <button
              v-if="!toDelete"
              @click="deletePerson()"
              class="person__delete"
            >
              <IcoClose />
            </button>
            <button
              v-if="toDelete"
              @click="confirmDeletePerson()"
              class="person__delete person__delete--confirm"
            >
              <IcoClose />
            </button>
          </div>
        </div>
      </div>
    </div>


</template>

<script>
import IcoClose from "@/components/svg/IcoClose.vue";
import Avatar from "@/components/Avatar.vue";
import PersonRole from "@/components/PersonRole.vue";

export default {
  components: {
    Avatar,
    IcoClose,
    PersonRole,
  },
  props: {
    person: {
      type: Object,
      required: true,
    },
    boxSize: {
      type: String,
      default: 'popper-edit-person-detail',
    },
  },
  data() {
    return {
      showData: false,
      distanceNo: 12,
      autoHide: true,
      toDelete: false,
    };
  },
  methods: {
    async handleCopyEmail() {
      await navigator.clipboard.writeText(this.person.email)

      this.$notify({
        title: this.$t('15_2_6_toaster_title_email_copied_to_clipboard'),
        text: this.$t('15_2_6_toaster_description_email_copied_to_clipboard'),
        type: 'info',
        duration: 5000,
      })
    },
    async handleCopyPhone() {
      await navigator.clipboard.writeText('TODO phone')

      this.$notify({
        title: this.$t('15_2_6_toaster_title_phone_copied_to_clipboard'),
        text: this.$t('15_2_6_toaster_description_phone_copied_to_clipboard'),
        type: 'info',
        duration: 5000,
      })
    },
    toCloseData() {

    },
    closeData() {
      this.showData = false
    },
    openData() {
      if (this.disabled) {
        this.showData = false
        return
      }

      // this.itemsList = this.items
      this.autoHide = false
      setTimeout(() => (this.autoHide = true), 300)

      this.showData = true
    },
    updateRole(role) {
      this.$emit("updateRole", role, this.person);
    },
    deletePerson() {
      this.toDelete = true;

      this.$notify({
        title: this.$t("toaster_info_title_confirm_delete"),
        text: this.$t("toaster_info_description_confirm_delete"),
        type: "info",
        duration: 5000,
      });

      setTimeout(() => {
        this.toDelete = false;
      }, 2000);
    },
    confirmDeletePerson() {
      this.$emit("delete", this.person);
      this.toDelete = false;
    },
  },
};
</script>

<style scoped>
.person {
  border-bottom: 1px solid #F5F5F5;
  box-sizing: border-box;
  border-radius: 0;
  padding: 10px 0;
  margin-bottom: 10px;
}

.person__inner {
  position: relative;
}

.person__data {
  max-width: 207px;
  overflow: hidden;
  display: flex;
  gap: 15px;
  align-items: center;
}

.person__avatar {
  width: 36px;
}

.person__text {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: -2px;
  align-items: flex-start;
}

.person__title {
  max-width: 157px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.2px;
  color: #17252a;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.person__position {
  max-width: 156px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.2px;
  color: #979797;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.person__controll {
  position: absolute;
  right: 0;
  top: 50%;
  display: flex;
  align-items: center;
  gap: 8px;
  transform: translate(0, -50%);
}

.person__delete {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  border-radius: 4px;
  cursor: pointer;
}

.person__delete svg {
  position: relative;
  top: 1px;
}

.person__delete--confirm {
  background: #f45050;
}
</style>

<style>
.person__delete svg path {
  stroke: #AEB3B5;
}

.person__delete--confirm svg path {
  stroke: #fff;
}

.popper-edit-person-detail {
  width: 300px;
  height: 247px;
}

.person-detail {
  padding: 24px;
}

.person-detail-header {
  display: flex;
  justify-content: space-between;
}

.person-detail-name {
  margin-top: 12px;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  line-height: 24.55px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
  color: #17252A;
}

.person-detail-position {
  margin-top: 2px;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.82px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
  color: #888F91;
}

.person-detail-phone {
  margin-top: 12px;
  display: flex;
  justify-content: flex-start;
  justify-items: flex-start;
  align-content: center;
  align-items: center;
  color: #17252A;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  gap: 8px;
}

.person-detail-phone a {
  color: #17252A;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  gap: 8px;
}

.person-detail-phone a:hover {
  color: #23c2a7;
}

.person-detail-phone button {
  cursor: pointer;
  border: none;
  padding: 0;
  background: none;
  position: relative;
  top: 2px;
}


.person-detail-email {
  margin-top: 12px;
  display: flex;
  justify-content: flex-start;
  justify-items: flex-start;
  align-content: center;
  align-items: center;
  color: #17252A;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  gap: 8px;
}

.person-detail-email a {
  color: #17252A;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  gap: 8px;
}

.person-detail-email a:hover {
  color: #23c2a7;
}

.person-detail-email button {
  cursor: pointer;
  border: none;
  padding: 0;
  background: none;
  position: relative;
  top: 3px;
}

</style>
