<template>
  <div class="boxed boxed--small">
    <div class="boxed-wrapper">
      <div class="column column--80gap">
        <div class="left">
          <div class="budgets-no-data">
            <h1>{{ $t('empty_cards_persona_audience_title') }}</h1>
            <p>{{ $t('empty_cards_persona_audience_description') }}</p>
            <div class="budgets-no-data-buttons">
              <Button type="primary" @click="handleAdd" size="nav"><IcoPlus /> {{ $t('empty_cards_persona_audience_btn_add') }}</Button>
              <Button @click="handleDelete" size="nav">
                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.33203 4.83333H14.6654M6.33203 8.16667V13.1667M9.66536 8.16667V13.1667M2.16536 4.83333L2.9987 14.8333C2.9987 15.2754 3.17429 15.6993 3.48685 16.0118C3.79941 16.3244 4.22334 16.5 4.66536 16.5H11.332C11.7741 16.5 12.198 16.3244 12.5105 16.0118C12.8231 15.6993 12.9987 15.2754 12.9987 14.8333L13.832 4.83333M5.4987 4.83333V2.33333C5.4987 2.11232 5.5865 1.90036 5.74278 1.74408C5.89906 1.5878 6.11102 1.5 6.33203 1.5H9.66536C9.88638 1.5 10.0983 1.5878 10.2546 1.74408C10.4109 1.90036 10.4987 2.11232 10.4987 2.33333V4.83333" stroke="#888F91" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                {{ $t('empty_cards_persona_audience_btn_delete') }}
              </Button>
            </div>
          </div>
        </div>
        <div class="right">
          <svg width="401" height="204" viewBox="0 0 401 204" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5153_91060)">
              <path d="M362.273 165.616H57.3773C53.4795 165.612 49.7427 164.055 46.9866 161.288C44.2305 158.52 42.6802 154.767 42.6758 150.853V34.6716C42.6802 30.7575 44.2305 27.0049 46.9866 24.2372C49.7427 21.4695 53.4795 19.9127 57.3773 19.9082H362.273C366.171 19.9127 369.908 21.4695 372.664 24.2372C375.42 27.0049 376.97 30.7575 376.975 34.6716V150.853C376.97 154.767 375.42 158.52 372.664 161.288C369.908 164.055 366.171 165.612 362.273 165.616Z" fill="white"/>
              <path d="M362.273 165.616H57.3773C53.4795 165.612 49.7427 164.055 46.9866 161.288C44.2305 158.52 42.6802 154.767 42.6758 150.853V34.6716C42.6802 30.7575 44.2305 27.0049 46.9866 24.2372C49.7427 21.4695 53.4795 19.9127 57.3773 19.9082H362.273C366.171 19.9127 369.908 21.4695 372.664 24.2372C375.42 27.0049 376.97 30.7575 376.975 34.6716V150.853C376.97 154.767 375.42 158.52 372.664 161.288C369.908 164.055 366.171 165.612 362.273 165.616ZM57.3773 21.192C53.8172 21.192 50.403 22.6121 47.8857 25.1401C45.3684 27.668 43.9542 31.0966 43.9542 34.6716V150.853C43.9542 152.623 44.3014 154.376 44.9759 156.012C45.6505 157.647 46.6392 159.133 47.8857 160.385C49.1321 161.636 50.6119 162.629 52.2405 163.307C53.869 163.984 55.6145 164.333 57.3773 164.333H362.273C364.036 164.333 365.782 163.984 367.41 163.307C369.039 162.629 370.518 161.636 371.765 160.385C373.011 159.133 374 157.647 374.675 156.012C375.349 154.376 375.696 152.623 375.696 150.853V107.046C375.696 84.2764 366.689 62.439 350.656 46.3382C334.622 30.2373 312.877 21.192 290.202 21.192H57.3773Z" fill="#E6E6E6"/>
              <path d="M164.63 90.3983C164.647 102.025 160.37 113.245 152.626 121.889V121.896C151.531 123.118 150.375 124.284 149.161 125.387C147.92 126.517 146.618 127.577 145.262 128.565C144.316 129.258 143.345 129.913 142.348 130.529C141.165 131.267 139.948 131.95 138.698 132.577C137.771 133.045 136.825 133.482 135.86 133.886C130.098 136.318 123.911 137.568 117.662 137.564C114.132 137.568 110.614 137.172 107.173 136.383C105.581 136.02 104.011 135.57 102.468 135.035C101.874 134.836 101.279 134.618 100.691 134.387C97.1255 133.003 93.7441 131.181 90.6239 128.963C84.0289 124.3 78.7407 118.01 75.269 110.699C71.7972 103.388 70.2588 95.3026 70.8019 87.2213C71.3451 79.1401 73.9515 71.3354 78.3701 64.5593C82.7887 57.7832 88.8705 52.2639 96.0297 48.5333C103.189 44.8026 111.184 42.9863 119.245 43.2592C127.306 43.5322 135.162 45.8852 142.054 50.0916C148.947 54.2981 154.645 60.2162 158.599 67.2758C162.553 74.3354 164.63 82.2986 164.63 90.3983Z" fill="#F2F2F2"/>
              <path d="M305.2 55.3382H224.022C223.344 55.3372 222.695 55.0663 222.216 54.5849C221.738 54.1035 221.469 53.451 221.469 52.7707C221.469 52.0904 221.738 51.4379 222.216 50.9565C222.695 50.4751 223.344 50.2041 224.022 50.2031H305.2C305.878 50.2031 306.528 50.4736 307.007 50.9551C307.487 51.4367 307.756 52.0897 307.756 52.7707C307.756 53.4516 307.487 54.1047 307.007 54.5862C306.528 55.0677 305.878 55.3382 305.2 55.3382Z" fill="#2CE0C1"/>
              <path d="M305.2 89.9984H224.022C223.344 89.9974 222.695 89.7264 222.216 89.245C221.738 88.7636 221.469 88.1111 221.469 87.4308C221.469 86.7505 221.738 86.098 222.216 85.6166C222.695 85.1352 223.344 84.8643 224.022 84.8633H305.2C305.877 84.8643 306.526 85.1352 307.005 85.6166C307.484 86.098 307.753 86.7505 307.753 87.4308C307.753 88.1111 307.484 88.7636 307.005 89.245C306.526 89.7264 305.877 89.9974 305.2 89.9984Z" fill="#E6E6E6"/>
              <path d="M305.2 124.66H224.022C223.344 124.659 222.695 124.389 222.216 123.907C221.738 123.426 221.469 122.773 221.469 122.093C221.469 121.413 221.738 120.76 222.216 120.279C222.695 119.797 223.344 119.526 224.022 119.525H305.2C305.878 119.525 306.528 119.796 307.007 120.277C307.487 120.759 307.756 121.412 307.756 122.093C307.756 122.774 307.487 123.427 307.007 123.908C306.528 124.39 305.878 124.66 305.2 124.66Z" fill="#E6E6E6"/>
              <path d="M102.251 201.258C93.087 189.017 87.7901 175.328 87.6166 159.722C87.3213 158.881 87.3703 157.957 87.7528 157.152C88.1353 156.347 88.8201 155.728 89.6569 155.429L120.894 144.33C121.731 144.034 122.652 144.083 123.453 144.467C124.255 144.851 124.872 145.539 125.169 146.379L139.804 187.915C140.099 188.756 140.05 189.68 139.667 190.485C139.285 191.29 138.6 191.909 137.763 192.208L106.526 203.307C105.689 203.603 104.768 203.554 103.967 203.17C103.165 202.786 102.549 202.098 102.251 201.258Z" fill="#E6E6E6"/>
              <path d="M100.228 187.861C94.7161 182.265 92.3567 172.283 90.8193 161.158C90.5428 160.371 90.5887 159.505 90.9469 158.751C91.3051 157.998 91.9464 157.418 92.7302 157.138L119.576 147.599C120.36 147.322 121.222 147.368 121.973 147.727C122.723 148.087 123.301 148.731 123.579 149.518L136.602 186.479C136.879 187.267 136.833 188.133 136.475 188.886C136.116 189.64 135.475 190.22 134.691 190.5L118.061 196.409C114.567 197.646 110.728 197.44 107.385 195.838C104.041 194.236 101.468 191.367 100.228 187.861Z" fill="white"/>
              <path d="M123.938 169.43L103.026 176.926C102.861 176.985 102.687 177.011 102.512 177.002C102.337 176.993 102.166 176.95 102.008 176.875C101.85 176.8 101.708 176.694 101.591 176.564C101.473 176.433 101.382 176.281 101.324 176.116C101.265 175.95 101.239 175.775 101.248 175.599C101.256 175.424 101.3 175.252 101.374 175.093C101.449 174.934 101.555 174.792 101.684 174.674C101.814 174.556 101.966 174.465 102.13 174.406L123.042 166.91C123.375 166.791 123.742 166.809 124.061 166.961C124.38 167.113 124.626 167.386 124.745 167.72C124.864 168.054 124.846 168.422 124.694 168.743C124.543 169.063 124.271 169.311 123.938 169.43Z" fill="#2CE0C1"/>
              <path d="M105.171 168.366L100.57 170.015C100.406 170.074 100.231 170.1 100.056 170.092C99.8811 170.083 99.7096 170.04 99.5514 169.965C99.3932 169.89 99.2512 169.784 99.1337 169.653C99.0162 169.523 98.9254 169.371 98.8666 169.205C98.8077 169.04 98.782 168.864 98.7907 168.688C98.7995 168.513 98.8426 168.341 98.9177 168.182C98.9927 168.023 99.0982 167.881 99.2281 167.763C99.358 167.645 99.5098 167.554 99.6747 167.495L104.275 165.846C104.44 165.787 104.615 165.761 104.79 165.769C104.965 165.778 105.136 165.821 105.294 165.896C105.453 165.971 105.595 166.077 105.712 166.207C105.83 166.338 105.92 166.49 105.979 166.656C106.038 166.821 106.064 166.997 106.055 167.172C106.046 167.348 106.003 167.52 105.928 167.679C105.853 167.838 105.748 167.98 105.618 168.098C105.488 168.216 105.336 168.307 105.171 168.366Z" fill="#2CE0C1"/>
              <path d="M127.878 180.508L106.966 188.004C106.634 188.122 106.268 188.104 105.949 187.952C105.63 187.8 105.384 187.527 105.265 187.193C105.146 186.859 105.165 186.491 105.316 186.171C105.467 185.85 105.738 185.603 106.071 185.484L126.983 177.988C127.315 177.869 127.681 177.887 128 178.039C128.319 178.191 128.565 178.464 128.684 178.798C128.803 179.132 128.784 179.5 128.633 179.82C128.482 180.141 128.211 180.388 127.878 180.508Z" fill="#2CE0C1"/>
              <path d="M125.907 174.969L104.995 182.465C104.662 182.585 104.296 182.566 103.977 182.414C103.657 182.262 103.411 181.989 103.292 181.655C103.174 181.321 103.192 180.953 103.343 180.632C103.495 180.312 103.766 180.065 104.099 179.945L125.011 172.449C125.176 172.39 125.351 172.364 125.526 172.373C125.701 172.381 125.872 172.424 126.03 172.5C126.189 172.575 126.33 172.681 126.448 172.811C126.565 172.941 126.656 173.094 126.715 173.259C126.774 173.425 126.8 173.6 126.791 173.776C126.782 173.952 126.739 174.124 126.664 174.283C126.589 174.441 126.483 174.584 126.354 174.702C126.224 174.819 126.072 174.91 125.907 174.969Z" fill="#2CE0C1"/>
              <path d="M106.919 69.0592C107.6 74.5767 111.407 79.126 116.944 79.126C119.603 79.126 122.152 78.0654 124.032 76.1775C125.912 74.2896 126.968 71.7291 126.968 69.0592C126.968 63.4995 122.457 59.5064 116.944 58.9924C111.095 58.447 106.045 61.9754 106.919 69.0592Z" fill="#2F2E41"/>
              <path d="M125.67 136.878C119.532 137.938 113.245 137.77 107.172 136.384C110.055 134.785 118.799 135.768 125.67 136.878Z" fill="#2F2E41"/>
              <path d="M86.652 154.585L91.4674 142.898L95.2613 145.015L92.9701 156.903C93.1996 157.656 93.2153 158.458 93.0155 159.22C92.8157 159.982 92.4083 160.672 91.8391 161.214C91.2699 161.756 90.5615 162.128 89.7935 162.287C89.0255 162.447 88.2284 162.388 87.4917 162.118C86.7551 161.848 86.1081 161.377 85.6235 160.757C85.139 160.138 84.8361 159.395 84.7488 158.612C84.6616 157.83 84.7935 157.038 85.1297 156.327C85.4659 155.615 85.9931 155.012 86.652 154.585Z" fill="#FFB6B6"/>
              <path d="M136.466 122.961V122.967C136.293 127.037 136.057 130.907 135.858 133.886C130.097 136.317 123.91 137.568 117.66 137.564C114.131 137.567 110.612 137.171 107.171 136.383C105.58 136.019 104.009 135.569 102.467 135.035C102.682 134.895 102.914 134.785 103.157 134.707C105.458 133.924 103.183 133.212 103.202 132.467C103.272 129.745 104.02 126.478 104.985 123.346L100.69 134.386L98.4526 140.125C98.4526 140.125 98.5293 145.08 97.1103 143.565C95.6849 142.05 95.7872 146.954 95.7872 146.954C95.3442 147.271 94.9629 147.667 94.6622 148.123C94.5357 148.328 94.488 148.572 94.528 148.809V148.816C94.5922 149.003 94.7187 149.161 94.8859 149.265C95.8511 149.92 94.464 151.653 93.8504 152.34C93.6842 152.526 93.5756 152.635 93.5756 152.635L87.6758 152.211V152.096C87.7014 151.396 87.8612 147.712 88.7752 146.832C89.7788 145.863 89.2866 144.329 89.2866 144.329C89.2866 144.329 91.9073 141.999 90.1495 140.105C89.4464 139.342 89.7596 134.816 90.6225 128.962C92.5081 116.048 97.0272 96.6626 98.9831 96.9386C99.3862 94.9724 100.322 93.1562 101.688 91.6905C103.054 90.2248 104.797 89.1666 106.724 88.6326L113.097 86.8738L114.234 82.3613H123.209L124.768 85.3268L131.307 88.2924L131.39 88.3309L131.595 88.4207L131.672 88.6647C131.672 88.6647 131.665 88.6711 131.672 88.6711L135.309 100.142C136.945 102.029 136.881 113.056 136.466 122.961Z" fill="#575A89"/>
              <path d="M151.749 146.075L145.672 134.998L149.688 133.346L157.499 142.57C158.228 142.861 158.862 143.35 159.329 143.982C159.796 144.615 160.079 145.366 160.144 146.151C160.21 146.935 160.056 147.723 159.7 148.425C159.345 149.127 158.801 149.715 158.131 150.124C157.46 150.533 156.69 150.745 155.905 150.738C155.121 150.731 154.354 150.505 153.691 150.084C153.028 149.663 152.495 149.065 152.152 148.357C151.809 147.649 151.669 146.859 151.749 146.075Z" fill="#FFB6B6"/>
              <path d="M131.309 88.2051C132.39 90.1555 142.275 95.1717 140.562 100.208C140.562 100.208 149.849 129.386 151.057 129.196C152.265 129.005 153.443 132.019 153.443 132.019C153.443 132.019 153.187 133.986 153.941 133.169C154.694 132.351 154.514 134.494 154.514 134.494C154.514 134.494 154.153 136.385 155.109 135.871C156.065 135.357 154.808 138.976 154.808 138.976L149.06 141.694L147.968 140.062C147.968 140.062 145.634 139.615 146.732 139.262C147.83 138.908 145.699 137.718 145.699 137.718C145.699 137.718 143.47 136.197 143.781 133.677C144.091 131.157 137.996 126.204 137.996 126.204C133.805 117.895 131.513 109.365 131.521 100.565L131.309 88.2051Z" fill="#575A89"/>
              <path d="M118.05 79.7247C122.959 79.7247 126.939 75.728 126.939 70.7979C126.939 65.8678 122.959 61.8711 118.05 61.8711C113.14 61.8711 109.16 65.8678 109.16 70.7979C109.16 75.728 113.14 79.7247 118.05 79.7247Z" fill="#FFB6B6"/>
              <path d="M108.847 71.7688C109.128 73.7086 110.775 76.415 110.389 76.415C110.004 76.415 107.262 68.4184 110.004 67.8969C111.992 67.5187 112.436 67.8505 114.295 67.0413L114.033 67.2891C115.263 68.1873 116.975 67.6337 118.378 67.049C119.785 66.4682 121.493 65.9146 122.727 66.8089C123.502 67.3704 123.83 68.3461 124.312 69.1746C124.794 70.0071 125.684 70.7931 126.602 70.5375C126.96 70.3988 127.264 70.1498 127.472 69.8263C127.68 69.5028 127.78 69.1215 127.759 68.7371C127.755 67.9705 126.761 67.2412 127.095 66.5534C128.088 64.5151 127.195 63.1257 125.033 62.1666C124.246 62.0466 123.46 61.924 122.673 61.7988C123.085 61.2255 123.585 60.7211 124.154 60.3043C123.913 59.6856 123.498 59.1512 122.958 58.7671C122.292 58.456 121.549 58.3483 120.822 58.4574C119.195 58.5426 117.57 58.6277 115.945 58.7129C114.735 58.6912 113.534 58.9261 112.421 59.4021C111.079 60.0952 110.2 61.4464 109.56 62.821C108.126 65.9107 108.353 68.3964 108.847 71.7688Z" fill="#2F2E41"/>
              <path d="M331.217 65.9156H197.532C197.384 65.9156 197.243 65.8568 197.139 65.7523C197.035 65.6478 196.977 65.506 196.977 65.3582C196.977 65.2103 197.035 65.0686 197.139 64.964C197.243 64.8595 197.384 64.8008 197.532 64.8008H331.217C331.364 64.8008 331.505 64.8595 331.609 64.964C331.713 65.0686 331.772 65.2103 331.772 65.3582C331.772 65.506 331.713 65.6478 331.609 65.7523C331.505 65.8568 331.364 65.9156 331.217 65.9156Z" fill="#E6E6E6"/>
              <path d="M331.217 100.472H197.532C197.384 100.472 197.243 100.413 197.139 100.309C197.035 100.204 196.977 100.063 196.977 99.9148C196.977 99.767 197.035 99.6252 197.139 99.5207C197.243 99.4162 197.384 99.3574 197.532 99.3574H331.217C331.364 99.3574 331.505 99.4162 331.609 99.5207C331.713 99.6252 331.772 99.767 331.772 99.9148C331.772 100.063 331.713 100.204 331.609 100.309C331.505 100.413 331.364 100.472 331.217 100.472Z" fill="#E6E6E6"/>
              <path d="M331.217 135.031H197.532C197.384 135.031 197.243 134.972 197.139 134.868C197.035 134.763 196.977 134.621 196.977 134.473C196.977 134.326 197.035 134.184 197.139 134.079C197.243 133.975 197.384 133.916 197.532 133.916H331.217C331.364 133.916 331.505 133.975 331.609 134.079C331.713 134.184 331.772 134.326 331.772 134.473C331.772 134.621 331.713 134.763 331.609 134.868C331.505 134.972 331.364 135.031 331.217 135.031Z" fill="#E6E6E6"/>
              <path d="M351.115 132.822C346.221 132.822 341.488 131.067 337.768 127.873C336.528 126.806 335.206 124.952 333.839 122.362C332.967 120.693 332.479 118.85 332.41 116.967C332.341 115.084 332.693 113.209 333.441 111.481C332.72 112.218 331.923 112.874 331.063 113.44L330.628 113.731L330.603 113.207C330.587 112.867 330.578 112.527 330.578 112.199C330.578 110.261 332.037 108.295 331.364 106.504C328.528 98.9555 319.531 91.4539 332.57 79.7717C333.777 78.691 332.165 76.5303 332.165 74.8953C332.165 58.8715 354.717 31.1498 361.109 45.8352C368.992 63.9461 382.572 62.0089 388.589 65.7497L388.676 66.0143L388.419 66.1188C387.102 66.6745 385.715 67.0449 384.297 67.2195C385.809 67.529 387.351 67.6588 388.893 67.6061L389.132 67.5978L389.19 67.8311C389.764 70.1416 390.053 72.514 390.053 74.8953L390.052 75.1527C390.047 76.9614 390.421 78.7509 391.15 80.4046C391.88 82.0583 392.948 83.539 394.286 84.7502C396.399 86.6847 398.087 89.0401 399.243 91.6662C400.4 94.2923 400.998 97.1315 401.001 100.003C401.001 103.4 398.702 107.859 396.774 111.002C396.285 111.802 395.622 112.481 394.834 112.987C394.047 113.494 393.156 113.815 392.227 113.926C391.348 114.037 390.454 113.953 389.611 113.68C388.767 113.406 387.994 112.949 387.346 112.342C388.259 114.186 389.448 115.877 390.873 117.357L391.136 117.63L390.809 117.821C387.669 119.66 384.099 120.628 380.465 120.626L380.252 120.626C377.587 120.598 374.943 121.105 372.475 122.116C370.007 123.128 367.765 124.624 365.881 126.516C363.962 128.501 361.667 130.082 359.131 131.165C356.596 132.248 353.87 132.811 351.115 132.822Z" fill="#E6E6E6"/>
              <path d="M344.05 165.481C343.961 165.481 343.875 165.449 343.808 165.39C343.741 165.332 343.697 165.251 343.684 165.162C342.719 156.514 342.661 147.787 343.512 139.126C344.787 125.464 348.881 105.785 361.144 89.6226C361.173 89.5839 361.21 89.5514 361.252 89.5269C361.294 89.5024 361.34 89.4864 361.388 89.4799C361.436 89.4734 361.485 89.4764 361.532 89.4889C361.579 89.5013 361.623 89.5229 361.662 89.5524C361.7 89.582 361.733 89.6188 361.757 89.6609C361.781 89.703 361.797 89.7496 361.804 89.7978C361.81 89.8461 361.807 89.8952 361.795 89.9423C361.782 89.9893 361.761 90.0335 361.732 90.0723C349.574 106.095 345.513 125.629 344.247 139.196C343.401 147.798 343.457 156.467 344.416 165.058C344.423 165.11 344.419 165.164 344.404 165.215C344.389 165.266 344.363 165.313 344.329 165.353C344.294 165.393 344.251 165.426 344.203 165.448C344.155 165.47 344.102 165.481 344.05 165.481Z" fill="#3F3D56"/>
              <path d="M354.057 112.421C353.979 112.421 353.903 112.396 353.84 112.35C353.777 112.304 353.73 112.239 353.706 112.165C353.681 112.09 353.681 112.01 353.706 111.936C353.73 111.861 353.777 111.796 353.84 111.75C357.334 109.375 361.109 107.445 365.077 106.005C371.278 103.726 380.706 101.659 390.412 104.762C390.458 104.776 390.501 104.8 390.538 104.831C390.575 104.863 390.605 104.901 390.628 104.945C390.65 104.988 390.664 105.035 390.668 105.084C390.672 105.132 390.666 105.181 390.652 105.227C390.637 105.274 390.613 105.317 390.582 105.354C390.551 105.391 390.512 105.422 390.469 105.444C390.426 105.467 390.379 105.48 390.331 105.484C390.283 105.489 390.234 105.483 390.188 105.468C380.68 102.43 371.423 104.462 365.331 106.702C361.427 108.117 357.712 110.014 354.274 112.35C354.211 112.396 354.135 112.421 354.057 112.421Z" fill="#3F3D56"/>
              <path d="M337.153 70.142C337.148 70.0639 337.167 69.986 337.208 69.9194C337.249 69.8528 337.31 69.8011 337.382 69.7715C337.455 69.742 337.534 69.7362 337.61 69.7549C337.686 69.7737 337.754 69.816 337.804 69.8759C340.415 73.2048 342.603 76.8468 344.319 80.718C347.03 86.7638 349.761 96.0583 347.379 106.003C347.368 106.051 347.347 106.096 347.319 106.135C347.29 106.175 347.254 106.208 347.213 106.234C347.171 106.259 347.125 106.276 347.077 106.284C347.03 106.292 346.98 106.29 346.933 106.278C346.886 106.267 346.841 106.246 346.802 106.217C346.763 106.189 346.73 106.152 346.704 106.111C346.679 106.069 346.662 106.023 346.655 105.975C346.647 105.926 346.649 105.877 346.661 105.83C348.994 96.0886 346.309 86.9629 343.645 81.0225C341.959 77.2135 339.807 73.6298 337.239 70.3541C337.189 70.2944 337.159 70.2201 337.153 70.142Z" fill="#3F3D56"/>
              <path d="M35.1207 125.401C33.236 123.508 30.9941 122.013 28.5264 121.001C26.0587 119.99 23.4148 119.483 20.7496 119.511L20.5367 119.511C16.9019 119.513 13.3319 118.544 10.1919 116.706L9.86517 116.514L10.1283 116.242C11.5531 114.762 12.7428 113.071 13.6557 111.227C13.0073 111.834 12.234 112.291 11.3905 112.564C10.547 112.838 9.65384 112.922 8.77437 112.811C7.84586 112.7 6.95448 112.379 6.16707 111.872C5.37966 111.366 4.71667 110.687 4.22784 109.886C2.29909 106.744 0 102.285 0 98.8876C0.00300162 96.0163 0.601678 93.1771 1.75788 90.551C2.91408 87.9249 4.60244 85.5695 6.71533 83.635C8.05297 82.4238 9.12141 80.943 9.85098 79.2894C10.5805 77.6357 10.9548 75.8462 10.9495 74.0375L10.9484 73.7801C10.9481 71.3988 11.2379 69.0264 11.8113 66.7158L11.8695 66.4825L12.1086 66.4909C13.6502 66.5436 15.1926 66.4138 16.7039 66.1043C15.2863 65.9297 13.8994 65.5593 12.5827 65.0035L12.3255 64.8991L12.4128 64.6344C18.4291 60.8936 32.0098 62.8308 39.8924 44.72C46.2841 30.0346 68.8362 57.7563 68.8362 73.78C68.8362 75.4151 67.2249 77.5757 68.4311 78.6564C81.4707 90.3386 72.4735 97.8402 69.6369 105.389C68.9639 107.18 70.4232 109.146 70.4232 111.083C70.4232 111.412 70.4149 111.751 70.3984 112.092L70.3733 112.616L69.9385 112.325C69.0784 111.759 68.2811 111.102 67.5603 110.366C68.3081 112.094 68.6605 113.969 68.5916 115.852C68.5227 117.735 68.0343 119.578 67.1623 121.246C65.7958 123.836 64.4737 125.691 63.2329 126.757C59.2581 130.173 54.1342 131.935 48.9087 131.683C43.6832 131.431 38.7511 129.184 35.1207 125.401Z" fill="#E6E6E6"/>
              <path d="M56.9529 164.368C57.0417 164.368 57.1276 164.335 57.1946 164.277C57.2617 164.218 57.3055 164.137 57.3181 164.049C58.2838 155.4 58.3416 146.674 57.4903 138.013C56.2156 124.35 52.1218 104.671 39.8584 88.5094C39.829 88.4706 39.7923 88.4381 39.7504 88.4136C39.7085 88.3891 39.6622 88.3731 39.6141 88.3666C39.566 88.3601 39.5171 88.3632 39.4703 88.3756C39.4234 88.388 39.3794 88.4097 39.3408 88.4392C39.3022 88.4687 39.2698 88.5056 39.2454 88.5477C39.221 88.5898 39.2051 88.6363 39.1986 88.6845C39.1921 88.7328 39.1952 88.7819 39.2076 88.829C39.22 88.8761 39.2415 88.9203 39.2709 88.959C51.4283 104.981 55.4891 124.516 56.7549 138.082C57.6015 146.685 57.5452 155.354 56.5867 163.945C56.5793 163.997 56.5833 164.051 56.5983 164.102C56.6133 164.153 56.639 164.2 56.6737 164.24C56.7084 164.28 56.7513 164.312 56.7995 164.334C56.8476 164.356 56.8999 164.368 56.9529 164.368Z" fill="#3F3D56"/>
              <path d="M46.9439 111.306C47.0219 111.306 47.098 111.281 47.1611 111.235C47.2243 111.189 47.2713 111.124 47.2954 111.05C47.3196 110.975 47.3196 110.895 47.2955 110.82C47.2714 110.746 47.2245 110.681 47.1614 110.635C43.667 108.26 39.8921 106.33 35.9236 104.89C29.7235 102.611 20.2954 100.544 10.5893 103.646C10.5431 103.661 10.5003 103.685 10.4632 103.716C10.4261 103.748 10.3956 103.786 10.3733 103.829C10.351 103.873 10.3374 103.92 10.3334 103.968C10.3293 104.017 10.3347 104.066 10.3494 104.112C10.3641 104.159 10.3878 104.202 10.4191 104.239C10.4503 104.276 10.4886 104.307 10.5317 104.329C10.5748 104.351 10.6218 104.365 10.6701 104.369C10.7185 104.373 10.7671 104.368 10.8133 104.353C20.3205 101.315 29.5778 103.347 35.6696 105.587C39.5744 107.002 43.2887 108.899 46.7271 111.235C46.7901 111.281 46.866 111.306 46.9439 111.306Z" fill="#3F3D56"/>
              <path d="M63.8487 69.0288C63.8544 68.9506 63.8353 68.8727 63.7942 68.8061C63.753 68.7396 63.6919 68.6878 63.6197 68.6582C63.5474 68.6287 63.4677 68.6229 63.392 68.6416C63.3162 68.6604 63.2483 68.7028 63.198 68.7627C60.5873 72.0916 58.3988 75.7335 56.683 79.6047C53.9725 85.6506 51.2411 94.945 53.6232 104.89C53.6344 104.938 53.6549 104.982 53.6833 105.022C53.7118 105.061 53.7478 105.095 53.7892 105.12C53.8306 105.146 53.8766 105.163 53.9246 105.171C53.9726 105.178 54.0216 105.176 54.0688 105.165C54.1161 105.154 54.1606 105.133 54.1999 105.104C54.2392 105.075 54.2724 105.039 54.2977 104.998C54.323 104.956 54.3399 104.91 54.3474 104.861C54.3548 104.813 54.3528 104.764 54.3413 104.717C52.0082 94.9753 54.6934 85.8496 57.3566 79.9093C59.0433 76.1002 61.1952 72.5165 63.7627 69.2408C63.813 69.1811 63.8432 69.1068 63.8487 69.0288Z" fill="#3F3D56"/>
            </g>
            <defs>
              <clipPath id="clip0_5153_91060">
                <rect width="401" height="203" fill="white" transform="translate(0 0.5)"/>
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/button/Button.vue";
import IcoPlus from '@/components/svg/IcoPlus.vue';

export default {
  components: {
    Button,
    IcoPlus
  },
  computed: {

  },
  data() {
    return {}
  },
  methods: {
    handleAdd() {
      this.$emit('add');
    },
    handleDelete() {
      this.$emit('delete');
    }
  }
}
</script>

<style scoped>
.boxed-wrapper {
  padding: 30px;
}

.right {
  min-width: 402px;
  max-width: 402px;
}

.right svg {
  width: 100% !important;
  height: auto !important;
}

.left {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.budgets-no-data {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
}

.budgets-no-data h1 {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 800;
  line-height: 28.8px;
  text-align: left;
  color: #17252A;
}

.budgets-no-data p {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: #888F91;
}

.budgets-no-data-buttons {
  display: flex;
  align-items: center;
  gap: 16px;
}
</style>
