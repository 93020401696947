<template>
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.375 12.25H3.8125C3.29473 12.25 2.875 11.8303 2.875 11.3125V2.875H11.3125V11.3125C11.3125 11.8303 10.8928 12.25 10.375 12.25Z" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.6875 9.4375V5.6875" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.5 9.4375V5.6875" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1 2.875H13.1875" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 1H5.6875C5.16973 1 4.75 1.41973 4.75 1.9375V2.875H9.4375V1.9375C9.4375 1.41973 9.01777 1 8.5 1Z" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#888F91'
    },
  },
  data() {
    return {}
  },
  methods: {}
}
</script>
