<template>
  <div class="subitems">
    <div class="subitems-title">{{ $t('15_12_1_title_select_budget_type') }}</div>
    <ul>
      <li><button @click="handleSelect('personas_and_audiences')">{{ $t('15_12_1_button_add_personas_and_audiences') }}</button></li>
      <li><button @click="handleSelect('')">{{ $t('15_12_1_button_add_products_and_services') }}</button></li>
      <li><button @click="handleSelect('')">{{ $t('15_12_1_button_add_stdc_phases') }}</button></li>
      <li><button @click="handleSelect('')">{{ $t('15_12_1_button_add_channels') }}</button></li>
      <li><button @click="handleSelect('')">{{ $t('15_12_1_button_add_tags') }}</button></li>
    </ul>
  </div>
</template>

<script>
export default {
  components: {

  },
  computed: {

  },
  data() {
    return {}
  },
  methods: {
    handleSelect(item) {
      this.$emit('onSelect', item)
    }
  }
}
</script>

<style scoped>
.subitems {

}

.subitems-title {
  font-family: Nunito;
  font-size: 11px;
  font-weight: 700;
  line-height: 13.2px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #888F91;
  padding: 10px;
  text-transform: uppercase;
}

.subitems ul{
  margin: 0;
  padding: 0 12px;
  list-style-type: none;
}

.subitems ul li{
  padding: 5px 0;
}

.subitems ul li button {
  cursor: pointer;
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: left;
  color: #17252A;
  text-decoration: none;
}

.subitems ul li button:hover{
  color: #2CE0C1;
}
</style>
