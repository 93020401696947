<template>
  <div @click="handleOpen" class="progress-label">
    <div class="progress-label-content">
      <div class="progress-label-title">Filled Profile <IcoArrow /></div>
      <div class="progress-label-description">3 / 10 tasks completed</div>
    </div>
    <div class="progress-label-line">
      <div class="progress-label-line-current" :style="{'width': progress}"></div>
    </div>
  </div>
</template>

<script>
import IcoArrow from "@/components/progressLabel/IcoArrow.vue";

export default {
  components: {
    IcoArrow

  },
  computed: {

  },
  data() {
    return {
      progress: '33px'
    }
  },
  methods: {
    handleOpen() {
      this.$emit('open');
    }
  }
}
</script>

<style scoped>
  .progress-label {
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: #9795FF14;
    padding: 12px;
    border-radius: 8px;
    cursor: pointer;
  }

  .progress-label-content {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .progress-label-title {
    display: flex;
    justify-content: space-between;
    font-family: Nunito;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.8px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #17252A;
  }

  .progress-label-description {
    font-family: Nunito;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.4px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #888F91;
  }

  .progress-label-line {
    display: block;
    width: 100%;
    height: 5px;
    border-radius: 4px;
    position: relative;
    background: #9795FF33;
  }

  .progress-label-line-current {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: 4px;
    background: #9795FF;
  }
</style>
