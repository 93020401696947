<template>
  <button
    :class="[
      type,
      size,
      disabled ? 'disabled' : '',
      active ? 'active' : '',
      red ? 'red' : '',
    ]"
    :disabled="disabled"
    @click="cklicked"
  >
    <span>
      <slot></slot>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'secondary',
    },
    size: {
      type: String,
      default: 'normal',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    red: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    cklicked() {
      if (this.disabled) {
        return
      }

      this.$emit('click')
    },
  },
}
</script>

<style scoped>
button {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  cursor: pointer;
  border: none;
  border-radius: 9px;
  background: #e7e9e9;
  padding: 12px 25px;
  height: 46px;
  color: #17252a;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 46px;
  font-family: Nunito;
}

button.secondary {
  background: #e7e9e9;
  color: #17252a;
}

button.secondary:hover {
  background: #d1d4d4;
  color: #17252a;
}

button.secondary:active {
  background: #c2c2c2;
}

button.secondary.active {
  background: rgba(44, 224, 193, 0.2);
  color: #2ce0c1;
}

button.nav {
  height: 36px;
  padding: 8px 16px;
}

button.nav span {
  gap: 8px;
}

button.small {
  border-radius: 4px;
  padding: 0px 12px;
  height: 22px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  font-family: Nunito;
  letter-spacing: -0.2px;
  gap: 8px;
}

button.medium {
  border-radius: 4px;
  padding: 0px 12px;
  height: 22px;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15.6px;
  font-family: Nunito;
  letter-spacing: 0.02em;
  color: #17252A;
}

button.slim {
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 0px;
  padding-right: 6px;
  padding-left: 6px;
  height: 22px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  font-family: Nunito;
  letter-spacing: -0.2px;
}

button.slim span {
  display: flex;
  align-items: center;
  gap: 3px;
}

button.slim-duplicate {
  display: inline-block;
  align-items: center;
  border-radius: 4px;
  padding: 0px;
  padding-right: 6px;
  padding-left: 6px;
  height: 22px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  font-family: Nunito;
  letter-spacing: -0.2px;
}

button.slim-duplicate span {
  display: flex;
  align-items: center;
  gap: 3px;
}

button.slim-duplicate span svg {
  position: relative;
  top: -1px !important;
}

button.ico {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  border-radius: 4px;
  background: #e7e9e9;
  padding: 0;
  width: 30px;
  height: 30px;
}

button.ico-only {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  border-radius: 4px;
  background: transparent;
  padding: 0;
  width: 30px;
  height: 30px;
}

button.ico span {
  line-height: 0;
}

button.ico-only span {
  line-height: 0;
}

button span {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 15px;
  line-height: 1;
}

button.small span {
  gap: 8px;
}

button.ico span svg {
  width: 12px !important;
  height: auto !important;
}

button.ico-only span svg {
  width: 20px !important;
  height: 21px !important;
}

button.primary {
  background: #2ce0c1;
  color: #fff;
}

button.primary:hover {
  background: #23c2a7;
  color: #fff;
}

button.primary:active {
  background: #1b927d;
  color: #fff;
}

button.red {
  border: 1px solid #f45050;
  color: red;
}

button.active.red {
  border: none !important;
}

button.delete {
  background: #f45050;
  color: #fff;
}

button.delete:hover {
  background: #d23838;
  color: #fff;
}

button.delete.active {
  background: #d23838;
  color: #fff;
}

button.delete:active {
  background: #b02828;
  color: #fff;
}

button.disabled {
  cursor: not-allowed;
  background: #f6f6f7;
  color: #aeb3b5;
}

button.disabled:hover {
  cursor: not-allowed;
  background: #f6f6f7;
  color: #aeb3b5;
}

button.max {
  height: 46px;
  line-height: 46px;
}
</style>

<style>
button.delete svg path {
  stroke: #fff;
}

button.disabled svg path {
  fill: #aeb3b5;
}

button.red svg path {
  fill: #d23838;
}

button.active svg path {
  fill: #2ce0c1;
}

button.active.red svg path {
  fill: #2ce0c1;
}

button.settings {
  background-color: transparent;
  padding: 0;
  margin: 0;
}

button.settings span{
  gap: 8px;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #888F91;
}
</style>
