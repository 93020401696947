<template>
  <div>
    <div style="position: relative">
      <file-upload
        extensions="gif,jpg,jpeg,png,webp"
        accept="image/png,image/gif,image/jpeg,image/webp"
        name="file"
        class=""
        :post-action="`${mainUrl}/api/profile-images/user/${user.id}`"
        :headers="{ Authorization: `Bearer ${accessToken}` }"
        :drop="!edit"
        v-model="files"
        @input-filter="inputFilter"
        @input-file="inputFile"
        ref="upload"
      >
        <div class="profile-picture">
          <div class="profile-picture__image">
            <img v-if="imageValid" :src="cachedImageUrl" alt="" />
            <div v-if="!imageValid" class="profile-picture__bg">
              <IcoNoImage />
            </div>
          </div>
          <div
            class="profile-picture__data"
            @click="$refs.upload.active = true"
          >
            <div class="profile-picture__title">
              {{ title }}
            </div>
            <div v-if="cachedImageUrl === ''" class="profile-picture__name">
              {{ $t('4_2_upload_profile_no_image') }}
            </div>
            <div v-else class="profile-picture__name">
              {{ $t('4_2_upload_profile_image') }}
            </div>
          </div>
        </div>
      </file-upload>
      <button
        v-if="imageValid"
        class="profile-picture__delete"
        @click.prevent="deleteImage()"
      >
        <IcoCross />
      </button>
    </div>

    <div class="avatar-edit" v-show="files.length && edit">
      <div class="avatar-edit-image" v-if="files.length">
        <img ref="editImage" :src="files[0].url" />
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          margin-top: 15px;
          margin-bottom: 200px;
        "
      >
        <Button @click.native="$refs.upload.clear">
          {{ $t('7_1_button_cancel_avatar') }}
        </Button>
        <Button type="primary" @click.native="editSave">
          {{ $t('7_1_button_save_avatar') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import IcoCross from '@/components/svg/small/IcoCross.vue'
import IcoNoImage from '@/components/svg/IcoNoImage.vue'
import 'cropperjs/dist/cropper.css'
import Cropper from 'cropperjs'
import FileUpload from 'vue-upload-component'
import {URL, URLFiles} from '@/constants/URLConstant.js'
import api from '@/services/rest-api/restapi'

import Button from '@/components/button/Button.vue'

export default {
  components: {
    IcoCross,
    IcoNoImage,
    FileUpload,
    Button,
  },
  props: {
    image: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    mainUrl() {
      return URL
    },
    user() {
      return this.$store.getters.getMe
    },
    accessToken() {
      if (this.currentAccessToken !== '') {
        return this.currentAccessToken
      }

      return localStorage.getItem('access_token')
    },
    cachedImageUrl() {
      return this.imageUrl ? `${this.imageUrl}?${this.cacheBuster}` : ''
    },
  },
  created() {
    this.loadImage()
  },
  data() {
    return {
      files: [],
      edit: false,
      cropper: false,
      imageUrl: '',
      imageValid: false,
      currentAccessToken: '',
      cacheBuster: Date.now(),
    }
  },
  methods: {
    async deleteImage() {

      const refresh_token = localStorage.getItem('refresh_token')
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          token: refresh_token,
        }),
      }

      fetch(URL + '/api/login/refresh', requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.currentAccessToken = data.access_token

          localStorage.setItem('access_token', data.access_token)
          localStorage.setItem('refresh_token', data.refresh_token)

          try {
            const response = fetch(
              `${this.mainUrl}/api/profile-images/user/${this.user.id}/delete`,
              {
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${this.accessToken}`,
                },
              }
            ).then(() => {
              this.imageUrl = ''

              this.$notify({
                title: this.$t('7_1_toaster_success_title_deleted_avatar'),
                text: this.$t('7_1_toaster_success_description_deleted_avatar'),
                type: 'success',
                duration: 5000,
              })

              this.loadImage();
              this.$store.commit('setUserProfile')
            })

          } catch (e) {
            this.imageUrl = ''
            this.$store.commit('setRefreshUserAvatar')
          }
        })
        .catch((error) => {
          localStorage.removeItem('access_token')
          localStorage.removeItem('refresh_token')
        })
    },

    async loadImage() {
      this.imageValid = false;

      if (!this.user.id) {
        this.imageUrl = ''
        return
      }

      this.imageUrl = `${URLFiles}/images/user/${this.user.id}.jpg`

      const img = new Image()
      img.src = this.imageUrl
      img.onload = () => {
        this.imageValid = true
        this.cacheBuster = Date.now()
      }
      img.onerror = () => {
        this.imageValid = false
        this.imageUrl = ''
      }
    },

    inputFile(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        this.$nextTick(function () {
          this.edit = true
        })
      }

      if (!newFile && oldFile) {
        this.edit = false
      }
    },

    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.alert('Your choice is not a picture')
          return prevent()
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        let URL = window.URL || window.webkitURL

        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
        }
      }
    },

    editSave() {
      this.edit = false
      let oldFile = this.files[0]
      let binStr = atob(
        this.cropper.getCroppedCanvas().toDataURL(oldFile.type).split(',')[1]
      )
      let arr = new Uint8Array(binStr.length)

      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i)
      }

      let file = new File([arr], oldFile.name, { type: oldFile.type })

      const refresh_token = localStorage.getItem('refresh_token')
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          token: refresh_token,
        }),
      }

      fetch(URL + '/api/login/refresh', requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.currentAccessToken = data.access_token

          localStorage.setItem('access_token', data.access_token)
          localStorage.setItem('refresh_token', data.refresh_token)

          this.$refs.upload.update(oldFile.id, {
            file,
            type: file.type,
            size: file.size,
            active: true,
          })

        })
        .catch((error) => {
          localStorage.removeItem('access_token')
          localStorage.removeItem('refresh_token')
        })
    },
  },
  watch: {
    edit(value) {
      if (value) {
        this.$nextTick(function () {
          if (!this.$refs.editImage) {
            return
          }

          let cropper = new Cropper(this.$refs.editImage, {
            aspectRatio: 1 / 1,
            viewMode: 1,
            autoCropArea: 0.65,
          })

          this.cropper = cropper
        })
      } else {
        if (this.cropper) {
          this.cropper.destroy()
          this.cropper = false
        }
      }
    },
    files: {
      deep: true,
      handler() {
        if (this.files[0].success === true) {
          this.$notify({
            title: this.$t('7_1_toaster_success_title_upload_avatar'),
            text: this.$t('7_1_toaster_success_description_upload_avatar'),
            type: 'success',
            duration: 5000,
          })

          this.imageUrl = this.files[0].url
          this.cacheBuster = Date.now()
          this.loadImage();
          this.$store.commit('setUserProfile')
        }

        if (this.files[0].error !== '') {
          this.$notify({
            title: this.$t('7_1_toaster_error_title_upload_avatar'),
            text: this.$t('7_1_toaster_error_description_upload_avatar'),
            type: 'error',
            duration: 5000,
          })
        }
      },
    },
  },
}
</script>

<style scoped>
label {
  position: relative;
  top: 5px;
  left: 2px;
  cursor: pointer;
}
.profile-picture {
  display: flex;
  gap: 20px;
}

.profile-picture__image {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  mix-blend-mode: normal;
  border: 1px dashed #c5c5c5;
  border-radius: 6px;
  width: 54px;
  min-width: 54px;
  height: 54px;
}

.profile-picture__bg {
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  width: 44px;
  height: 44px;
}

.profile-picture__delete {
  display: inline-block;
  position: absolute;
  top: -6px;
  left: 42px;
  z-index: 9;
  cursor: pointer;
  margin: 0;
  border: none;
  border-radius: 4px;
  background: transparent;
  background: #17252a;
  padding: 0;
  padding: 6px;
  line-height: 1px;
}

.profile-picture__data {
  cursor: pointer;
  text-align: left;
}
.profile-picture__title {
  color: #17252a;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  font-family: Nunito;
  letter-spacing: -0.2px;
}

.profile-picture__name {
  margin-top: 1px;
  color: #888f91;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  font-family: Nunito;
  letter-spacing: -0.2px;
}
</style>

<style>
.profile-picture__delete svg path {
  stroke: #fff;
}

.avatar-edit-image {
  margin-top: 30px;
  max-width: 100%;
}
</style>

<style>
.file-uploads {
  overflow: visible !important;
}
</style>
