<template>
  <div class="file-item">
    <div class="file-item-ico">
      <CircleProgress :size="46" :stroke-width="6" :progress="file.uploadProgress" />
      <span>{{file.uploadProgress}}%</span>
    </div>
    <div class="file-item-data">
      <div class="file-item-context">
        <div class="file-item-context-title" ref="filenameContainer">
          {{ file.name }}
        </div>
        <div class="file-item-context-meta">
          <span>{{ $t('special_list_inputs_uploading') }}</span>
        </div>
      </div>
      <div class="file-item-control">
        <button v-if="!confirmDelete" @click="handleDelete">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.66675 4.66667H13.3334M6.66675 7.33333V11.3333M9.33341 7.33333V11.3333M3.33341 4.66667L4.00008 12.6667C4.00008 13.0203 4.14056 13.3594 4.39061 13.6095C4.64065 13.8595 4.97979 14 5.33341 14H10.6667C11.0204 14 11.3595 13.8595 11.6096 13.6095C11.8596 13.3594 12.0001 13.0203 12.0001 12.6667L12.6667 4.66667M6.00008 4.66667V2.66667C6.00008 2.48986 6.07032 2.32029 6.19534 2.19526C6.32037 2.07024 6.48994 2 6.66675 2H9.33341C9.51023 2 9.67979 2.07024 9.80482 2.19526C9.92984 2.32029 10.0001 2.48986 10.0001 2.66667V4.66667" stroke="#17252A" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
        <button v-if="confirmDelete" class="delete" @click="handleConfirmDelete">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.66675 4.66667H13.3334M6.66675 7.33333V11.3333M9.33341 7.33333V11.3333M3.33341 4.66667L4.00008 12.6667C4.00008 13.0203 4.14056 13.3594 4.39061 13.6095C4.64065 13.8595 4.97979 14 5.33341 14H10.6667C11.0204 14 11.3595 13.8595 11.6096 13.6095C11.8596 13.3594 12.0001 13.0203 12.0001 12.6667L12.6667 4.66667M6.00008 4.66667V2.66667C6.00008 2.48986 6.07032 2.32029 6.19534 2.19526C6.32037 2.07024 6.48994 2 6.66675 2H9.33341C9.51023 2 9.67979 2.07024 9.80482 2.19526C9.92984 2.32029 10.0001 2.48986 10.0001 2.66667V4.66667" stroke="#17252A" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CircleProgress from "../../../circleProgress/CircleProgress.vue";

export default {
  name: 'FileUploadingItem',
  components: { CircleProgress },
  computed: {
    translatedFileType() {
      const fileTypeMappings = {
        'application/pdf': 'PDF',
        'application/msword': 'Word Document',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Word Document (DOCX)',
        'application/vnd.ms-excel': 'Excel Spreadsheet',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Excel Spreadsheet (XLSX)',
        'image/jpeg': 'JPEG Image',
        'image/png': 'PNG Image',
        'text/plain': 'Text File',
        'application/zip': 'ZIP Archive',
        'application/x-rar-compressed': 'RAR Archive',
        // Add more file type mappings as needed
      };

      return fileTypeMappings[this.file.type] || this.file.type; // Return the translated type or the original if no match is found
    },
    readableFileSize() {
      const bytes = this.file.size;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return '0 Byte';
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      const size = bytes / Math.pow(1024, i);
      return size.toFixed(2) + ' ' + sizes[i];
    },
  },
  props: [
    'file',
    'contentId',
    'companyId'
  ],
  data() {
    return {
      confirmDelete: false
    }
  },
  methods: {
    cancelUpload() {
      this.$emit('cancel-upload', this.file); // Emit event to cancel upload
    },
    handleDelete() {
      this.confirmDelete = true;

      this.$notify({
        title: this.$t("toaster_info_title_confirm_delete"),
        text: this.$t("toaster_info_description_confirm_delete"),
        type: "info",
        duration: 5000,
      });

      setTimeout(() => {
        this.confirmDelete = false;
      }, 2000);
    },
    async handleConfirmDelete() {
      this.confirmDelete = false;
      this.cancelUpload();
    }
  }
}
</script>

<style scoped>
.file-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 12px;
}

.file-item-ico {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  min-width: 48px;
  max-width: 48px;
  height: 48px;
  position: relative;
}

.file-item-ico span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -75%);
  font-family: Nunito;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-align: center;
  color: rgba(75, 85, 99, 1);
}

.file-item-ico-uploaded {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: rgba(151, 149, 255, 0.1);
}

.file-item-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.file-item-context {
  width: 100%;
}

.file-item-control {
  display: flex;
  min-width: 56px;
  max-width: 56px;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.file-item-control button{
  border: none;
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}

.file-item-control button:hover {
  background: #d1d4d4;
  color: #17252a;
}

.file-item-control button.delete {
  background: #f45050;
  color: #fff;
}

.file-item-control button.delete:hover {
  background: #d23838;
  color: #fff;
}

.file-item-control button.delete.active {
  background: #d23838;
  color: #fff;
}

.file-item-control button.delete:active {
  background: #b02828;
  color: #fff;
}

.file-item-context-title {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-item-context-meta {
  display: flex;
  gap: 4px;
  font-family: Nunito;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  color: rgba(136, 143, 145, 1);
}

.file-item-context-meta span:after {
  content: '•';
  display: inline-block;
  padding-left: 4px;
}

.file-item-context-meta span:last-child:after {
  content: '';
}
</style>
