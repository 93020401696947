const formatCurrency = (value, locale) => {

  // en-US
  // cs-CZ

  // $i18n.locale === 'en'
  let currencyLocale = 'cs-CZ';
  if (locale === 'en') {
    currencyLocale = 'en-US';
  }

  const currency = 'CZK';



  const formattedCurrency = new Intl.NumberFormat(currencyLocale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(value);

  return formattedCurrency;
}

module.exports = {
  formatCurrency,
}
