<template>
  <ul class="scale-menu">
    <li>
      <button @click="handleEditScale">
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.95898 4.95842H4.25065C3.87493 4.95842 3.51459 5.10768 3.24892 5.37335C2.98324 5.63903 2.83398 5.99936 2.83398 6.37509V12.7501C2.83398 13.1258 2.98324 13.4861 3.24892 13.7518C3.51459 14.0175 3.87493 14.1668 4.25065 14.1668H10.6257C11.0014 14.1668 11.3617 14.0175 11.6274 13.7518C11.8931 13.4861 12.0423 13.1258 12.0423 12.7501V12.0418M11.334 3.54175L13.459 5.66675M14.44 4.66445C14.719 4.38548 14.8757 4.00711 14.8757 3.61258C14.8757 3.21805 14.719 2.83968 14.44 2.5607C14.1611 2.28173 13.7827 2.125 13.3882 2.125C12.9936 2.125 12.6153 2.28173 12.3363 2.5607L6.37565 8.50008V10.6251H8.50065L14.44 4.66445Z" stroke="#17252A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ $t('15_5_2_scale_submenu_edit') }}
      </button>
    </li>
    <li v-if="!confirmDelete">
      <button @click="handleDeleteScale">
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8145 14.0001H4.37696C3.79015 14.0001 3.31445 13.5244 3.31445 12.9376V3.375H12.877V12.9376C12.877 13.5244 12.4013 14.0001 11.8145 14.0001Z" stroke="#17252A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M6.50195 10.8125V6.5625" stroke="#17252A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M9.6875 10.8125V6.5625" stroke="#17252A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M1.18945 3.375H15.0021" stroke="#17252A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.69144 1.25H6.50392C5.91711 1.25 5.44141 1.7257 5.44141 2.31251V3.37502H10.754V2.31251C10.754 1.7257 10.2783 1.25 9.69144 1.25Z" stroke="#17252A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ $t('15_5_2_scale_submenu_delete') }}
      </button>
    </li>
    <li v-if="confirmDelete">
      <button class="confirm-delete" @click="handleConfirmDeleteScale">
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8145 14.0001H4.37696C3.79015 14.0001 3.31445 13.5244 3.31445 12.9376V3.375H12.877V12.9376C12.877 13.5244 12.4013 14.0001 11.8145 14.0001Z" stroke="#17252A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M6.50195 10.8125V6.5625" stroke="#17252A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M9.6875 10.8125V6.5625" stroke="#17252A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M1.18945 3.375H15.0021" stroke="#17252A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.69144 1.25H6.50392C5.91711 1.25 5.44141 1.7257 5.44141 2.31251V3.37502H10.754V2.31251C10.754 1.7257 10.2783 1.25 9.69144 1.25Z" stroke="#17252A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ $t('15_5_2_scale_submenu_delete') }}
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ScaleMenu',
  data() {
    return {
      confirmDelete: false
    }
  },
  methods: {
    handleEditScale() {
      this.$emit('editScale')
    },
    handleDeleteScale() {
      this.confirmDelete = true;

      setTimeout(() => {
        this.confirmDelete = false;
      }, 2000);
    },
    handleConfirmDeleteScale() {
      this.confirmDelete = false;
      this.$emit('deleteScale');
    }
  }
}
</script>

<style scoped>
.scale-menu {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.scale-menu li {
  padding: 0;
}

button {
  width: 100%;
  border: none;
  background: transparent;
  padding: 12px 16px 12px 16px;
  margin: 0;
  display: flex;
  line-height: 1;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  cursor: pointer;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 700;
  text-align: left;
}

button:hover {
  background-color: #F6F6F7;
}

button.confirm-delete {
  color: rgb(219, 14, 14);
}

button.confirm-delete svg path{
  stroke: rgb(219, 14, 14);
}
</style>
