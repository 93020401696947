<template>
  <div class="smsc">
    <div class="smsc-header">
      <div class="smsc-header-title">
        <div class="smsc-header-title-ico" :style="{backgroundColor: color}"></div>
        {{ title }}
        <svg v-tooltip="$t('15_13_subtitle_total_tooltip')" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 2C13.1423 2 16.5 5.35775 16.5 9.5C16.5016 11.4691 15.7287 13.3598 14.3482 14.764C12.9677 16.1681 11.0904 16.973 9.12152 17.0049C7.15267 17.0369 5.25025 16.2932 3.825 14.9345C2.39974 13.5758 1.56599 11.7111 1.50375 9.743L1.5 9.5L1.503 9.29C1.614 5.24525 4.9275 2 9 2ZM9 8.75H8.25L8.16225 8.75525C7.97996 8.77693 7.81196 8.86472 7.69006 9.00197C7.56816 9.13923 7.50084 9.31643 7.50084 9.5C7.50084 9.68357 7.56816 9.86077 7.69006 9.99803C7.81196 10.1353 7.97996 10.2231 8.16225 10.2448L8.25 10.25V12.5L8.25525 12.5878C8.27499 12.7552 8.35056 12.911 8.46976 13.0302C8.58896 13.1494 8.74484 13.225 8.91225 13.2448L9 13.25H9.75L9.83775 13.2448C10.0052 13.225 10.161 13.1494 10.2802 13.0302C10.3994 12.911 10.475 12.7552 10.4948 12.5878L10.5 12.5L10.4948 12.4123C10.4768 12.2592 10.4122 12.1155 10.3097 12.0005C10.2072 11.8855 10.0717 11.8048 9.92175 11.7695L9.83775 11.7545L9.75 11.75V9.5L9.74475 9.41225C9.72501 9.24484 9.64944 9.08896 9.53024 8.96976C9.41104 8.85056 9.25516 8.77499 9.08775 8.75525L9 8.75ZM9.0075 6.5L8.91225 6.50525C8.72996 6.52693 8.56196 6.61472 8.44006 6.75197C8.31816 6.88923 8.25084 7.06643 8.25084 7.25C8.25084 7.43357 8.31816 7.61077 8.44006 7.74803C8.56196 7.88528 8.72996 7.97307 8.91225 7.99475L9 8L9.09525 7.99475C9.27754 7.97307 9.44554 7.88528 9.56744 7.74803C9.68934 7.61077 9.75666 7.43357 9.75666 7.25C9.75666 7.06643 9.68934 6.88923 9.56744 6.75197C9.44554 6.61472 9.27754 6.52693 9.09525 6.50525L9.0075 6.5Z" fill="#AEB3B5"/>
        </svg>
      </div>
      <div class="smsc-header-meta">
        <div class="smsc-header-meta-description">
          {{ $t('15_13_subtitle_total_attachment_size') }}
        </div>
        <div class="smsc-header-meta-data">
          {{ sizes.readableFileSize(contentsUsed) }}
        </div>
      </div>
    </div>
    <div v-if="items.length > 0" class="smsc-items">
      <StorageManagementCard
        v-for="(item, index) in items"
        :key="index"
        :content="item"
        :type="type"
      />
    </div>
  </div>
</template>

<script>
import StorageManagementCard from "./StorageManagementCard.vue";
import sizes from "../../../../utils/sizes";

export default {
  name: 'StorageManagementStatusCard',
  components: {StorageManagementCard},
  props: ['items', 'title', 'color', 'contentsUsed', 'type'],
  computed: {
    sizes() {
      return sizes
    },
  },
  data() {
    return {

    }
  },
}
</script>

<style scoped>
  .smsc {
    background: rgba(231, 233, 233, 1);
    border-radius: 8px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
  }

  .smsc:last-child {
    margin-bottom: 0;
  }

  .smsc-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .smsc-header-title {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
    font-family: Nunito;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    text-align: left;
    color: rgba(23, 37, 42, 1);
  }

  .smsc-header-title-ico {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background-color: #D1D4D4;
  }

  .smsc-header-meta {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
  }

  .smsc-header-meta-description {
    font-family: Nunito;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    text-align: left;
    color: rgba(136, 143, 145, 1);
  }

  .smsc-header-meta-data {
    font-family: Nunito;
    font-size: 18px;
    font-weight: 700;
    line-height: 24.55px;
    text-align: left;
    color: rgba(23, 37, 42, 1);
  }

  .smsc-items {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
</style>
