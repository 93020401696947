<template>
  <span class="card-budget-body-data-ico" :style="{'background-color': color}"></span>
</template>

<script>
export default {
  components: {

  },
  computed: {

  },
  props: {
    color: {
      type: String,
      default: '#888F91'
    }
  },
  data() {
    return {}
  },
  methods: {

  }
}
</script>

<style scoped>
.card-budget-body-data-ico {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}
</style>
