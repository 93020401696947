<template>
    <div>
        <label v-for="(item, index) in items" :key="item.value" @click.prevent="$emit('input', item.value)">
            <input :checked="item.value === value" type="radio" value="item.value">
            <IcoRadio v-if="item.value !== value" />
            <IcoRadioSelected v-if="item.value === value" />
            <span>{{ item.name }}</span>
        </label>
    </div>
</template>

<script>
import IcoRadio from "@/components/svg/IcoRadio.vue";
import IcoRadioSelected from "@/components/svg/IcoRadioSelected.vue";

export default {
    components: {
        IcoRadio,
        IcoRadioSelected
    },
    props: ['items', 'value'],
}
</script>

<style scoped>
label {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin-right: 15px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.2px;
  color: #17252A;
  text-transform: lowercase;
}

label:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  bottom: 0;
  background-color: #E7E9E9;
}

label input {
    display: none;
}

label span:first-letter {
  text-transform: uppercase;
}
</style>
