<template>
  <div class="percentage">
    <input
      type="text"
      :placeholder="$t('Enter')"
      v-model="modelValue"
    />
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 15.5L15 5.5M13.3333 14.6667C13.3333 14.8877 13.4211 15.0996 13.5774 15.2559C13.7337 15.4122 13.9457 15.5 14.1667 15.5C14.3877 15.5 14.5996 15.4122 14.7559 15.2559C14.9122 15.0996 15 14.8877 15 14.6667C15 14.4457 14.9122 14.2337 14.7559 14.0774C14.5996 13.9211 14.3877 13.8333 14.1667 13.8333C13.9457 13.8333 13.7337 13.9211 13.5774 14.0774C13.4211 14.2337 13.3333 14.4457 13.3333 14.6667ZM5 6.33333C5 6.55435 5.0878 6.76631 5.24408 6.92259C5.40036 7.07887 5.61232 7.16667 5.83333 7.16667C6.05435 7.16667 6.26631 7.07887 6.42259 6.92259C6.57887 6.76631 6.66667 6.55435 6.66667 6.33333C6.66667 6.11232 6.57887 5.90036 6.42259 5.74408C6.26631 5.5878 6.05435 5.5 5.83333 5.5C5.61232 5.5 5.40036 5.5878 5.24408 5.74408C5.0878 5.90036 5 6.11232 5 6.33333Z" stroke="#D1D4D4" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
</template>

<script>
export default {
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  },
  props: ['value']
}
</script>

<style scoped>
.percentage {
  border: 1px solid#D1D4D4;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 12px;
  width: 100px;
}

.percentage input {
  border: none;
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
}
</style>
