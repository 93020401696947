<template>
  <div class="modal-scale">
    <div class="modal-scale-header">
      <div class="modal-scale-title">
        <strong>{{ $t('15_5_6_1_title') }}</strong>
      </div>
      <button @click="handleCancel" >
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 6.5L6 18.5M6 6.5L18 18.5" stroke="#888F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>

    <div class="modal-scale-body">
      <FormGroup
        :label="$t('15_5_6_1_label_title')"
        :reqired="true"
        :focused="focusTitle"
        :validationError="validationError.title"
      >
        <input
          type="text"
          :placeholder="$t('15_5_6_1_placeholder_title')"
          v-model="title"
          @input="validationError.title = false"
          @focus="focusTitle = true"
          @blur="focusTitle = false"
          ref="contentTitle"
        />
      </FormGroup>

      <FormGroup
        :label="$t('15_5_6_1_label_description')"
        :reqired="false"
        :focused="focusDescription"
      >
        <textarea
          class="form-group__input large"
          :placeholder="$t('15_5_6_1_placeholder_description')"
          v-model="description"
          @focus="focusDescription = true"
          @blur="focusDescription = false"
          ref="contentName"
        ></textarea>
      </FormGroup>
    </div>

    <div class="modal-scale-footer">
      <Button @click="handleCancel" type="secondary">{{ $t('15_5_6_1_btn_cancel') }}</Button>

      <Button v-if="isEdit" @click="handleUpdate" type="primary">{{ $t('15_5_6_1_btn_update') }}</Button>
      <Button v-else @click="handleSave" type="primary">{{ $t('15_5_6_1_btn_save') }}</Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/button/Button.vue";
import Dropdown from "@/components/dropdown/Dropdown.vue";
import TextAreaEditor from "@/components/TextAreaEditor.vue";
import textLength from "@/constants/TextLengthConstant";
import WYSIWYGEditor from "@/components/WYSIWYGEditor.vue";
import TinymceEditor from "@/components/TinymceEditor.vue";
import FormGroup from "@/components/FormGroup.vue";
import {obj_clone} from "../../../../services/helpers/clone";
export default {
  components: {
    FormGroup,
    TinymceEditor,
    WYSIWYGEditor,
    TextAreaEditor,
    Button,
    Dropdown
  },
  computed: {
    isEdit(){
      return this.ruleToEdit !== null;
    },
    length() {
      return textLength
    },
    role() {
      return this.$store.getters.getMeCompanyRole
    },
  },
  props: ['ruleToEdit'],
  data() {
    return {
      focusTitle: false,
      focusDescription: false,
      rule: {
        description: '',
        Do: '',
        Dont: '',
        name: ''
      },
      title: '',
      description: '',
      validationError: {
        title: false,
        description: false
      },
    }
  },
  methods: {
    handleUpdate() {
      const rule = obj_clone(this.ruleToEdit);
      rule.name = this.title;
      rule.description = this.description;

      this.$emit('updateRule', rule);
    },
    handleCancel() {
      this.$emit('close');
    },
    handleClose() {
      this.$emit('close');
    },
    handleSave() {
      this.validationError.title = false;

      if(this.title === '') {
        this.validationError.title = true;
        console.log("title error");
        return;
      }

      const rule = obj_clone(this.rule);
      rule.name = this.title;
      rule.description = this.description;

      this.$emit('addRule', rule);
      this.$emit('close');
    }
  },
  watch: {
    ruleToEdit: {
      handler(newVal) {
        if (newVal) {
          this.title = newVal.name || '';
          this.description = newVal.description || '';
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style scoped>
.modal-scale {
  width: 600px;
}

.modal-scale-header {
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-scale-header button {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.modal-scale-title {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
  text-align: left;
  color: #17252A;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.modal-scale-title span {
  position: relative;
  font-family: Nunito;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  display: inline-block;
  color: #888F91;
}

.modal-scale-title span:after {
  content: '';
  position: absolute;
  left: -10px;
  top: 4px;
  bottom: 4px;
  width: 1px;
  background-color: #888F91;
}

.modal-scale-body {
  margin-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  gap: 30px;
  display: flex;
  flex-direction: column;
}

.modal-scale-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 32px;
  border-top: 1px solid #E7E9E9;
  padding: 32px;
}

.modal-scale-vis {
  border-radius: 6px;
  width: 100%;
  background: rgba(246, 246, 247, 1);
  display: flex;
  gap: 4px;
  padding: 4px;
  justify-content: space-between;
  align-items: center;
}

.modal-scale-vis-item {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
  color: rgba(136, 143, 145, 1);
  width: 100%;
  border-radius: 6px;
  border: 1px dashed rgba(136, 143, 145, 1);
  padding: 12px;
}

.modal-scale-wrapper {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
}

.modal-scale-add {
  display: flex;
  justify-content: flex-start;
}

.modal-scale-add-btn {
  border: none;
  background: none;
  display: flex;
  cursor: pointer;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: rgba(44, 224, 193, 1);
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
}

.modal-scale-remove {
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 6px;
  background: rgba(231, 233, 233, 1);
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.modal-scale-remove:hover {
  background: #d1d4d4;
  color: #17252a;
}

.modal-scale-description {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 15px;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: rgba(136, 143, 145, 1);
}
</style>
