var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"item-header"},[_c('div',{staticClass:"item-main"},[_c('div',{staticClass:"item-main-label"},[_vm._v(" "+_vm._s(_vm.$t('Meeting notes'))+" ")]),_c('div',{staticClass:"item-main-title"},[_c('TextAreaEditor',{ref:"content_name",attrs:{"content":_vm.content.name,"isValid":_vm.formValidation.name,"maxLength":_vm.length.short,"disabled":_vm.role === 'READ_ONLY',"showError":_vm.titleIsOpenShowError,"format":"title"},on:{"saveEditName":_vm.saveEditName,"cancelEditName":_vm.cancelEditName,"opened":function($event){_vm.titleIsOpen = true},"closed":function($event){;(_vm.titleIsOpen = false), (_vm.titleIsOpenShowError = false)}},nativeOn:{"input":function($event){_vm.formValidation.name = true}}})],1)]),_c('div',{staticClass:"item-sidebar"},[_c('div',{staticClass:"item-sidebar-control"},[_c('NoteModalEditSubmenu',{attrs:{"content":_vm.content}}),_c('button',{on:{"click":_vm.close}},[_c('IcoClose')],1)],1)])]),_c('div',{staticStyle:{"height":"640px"}},[_c('vuescroll',{ref:"vs",attrs:{"ops":_vm.ops}},[_c('div',{staticClass:"item-body"},[_c('div',{staticClass:"item-main"},[_c('WYSIWYGEditor',{ref:"content_description",attrs:{"id":"content_description","tinymceRef":"update_content_description","content":_vm.content.description,"isValid":true,"title":_vm.$t('10_1_title_description'),"buttonTitle":_vm.$t('10_1_title_add_description'),"maxLength":_vm.length.long,"disabled":_vm.role === 'READ_ONLY',"showError":_vm.descriptionIsOpenShowError,"format":"description"},on:{"saveEditName":_vm.saveEditDescription,"cancelEditName":_vm.cancelEditDescription,"opened":function($event){_vm.descriptionIsOpen = true},"closed":function($event){;(_vm.descriptionIsOpen = false),
                  (_vm.descriptionIsOpenShowError = false)}}}),_c('h2',{staticClass:"content-edit__title"},[_c('IcoResources'),_vm._v(" "+_vm._s(_vm.$t('10_1_title_resources'))+" ")],1),_c('div',[_c('Resources',{attrs:{"items":_vm.content.resources,"disabled":_vm.role === 'READ_ONLY',"showError":_vm.resourcesIsOpenShowError,"transCreateNew":"10_1_resource_add"},on:{"updateResources":_vm.updateResources,"opened":function($event){_vm.resourcesIsOpen = true},"closed":function($event){;(_vm.resourcesIsOpen = false),
                    (_vm.resourcesIsOpenShowError = false)}}})],1),(_vm.role !== 'READ_ONLY')?_c('h2',{staticClass:"content-edit__title"},[_c('IcoChecklist'),_vm._v(" "+_vm._s(_vm.$t('10_1_subtitle_checklist'))+" "),(
                  _vm.content &&
                  _vm.content.checklist &&
                  _vm.content.checklist.length === 0
                )?_c('Button',{staticStyle:{"margin-left":"15px"},attrs:{"size":'small'},on:{"click":function($event){return _vm.addChecklist()}}},[_vm._v(" "+_vm._s(_vm.$t('10_1_btn_checklist_add'))+" ")]):_vm._e()],1):_vm._e(),(_vm.role !== 'READ_ONLY')?_c('IdeaTask',{staticStyle:{"margin-top":"20px"},attrs:{"items":_vm.content.checklist}}):_vm._e(),(_vm.role !== 'READ_ONLY')?_c('Checklist',{attrs:{"contentId":_vm.content.id,"publishDate":_vm.content.date,"items":_vm.content.checklist,"eod":_vm.content.eod,"addChecklistItem":_vm.addChecklistItem,"showError":_vm.checklistIsOpenShowError},on:{"silentDeletedCheckbox":function($event){_vm.addChecklistItem = false},"tasksUpdated":_vm.tasksUpdated,"opened":function($event){_vm.checklistIsOpen = true},"closed":function($event){;(_vm.checklistIsOpen = false),
                  (_vm.addChecklistItem = false),
                  (_vm.checklistIsOpenShowError = false)}}}):_vm._e(),_c('h2',{staticClass:"divider mt-30"},[_c('span',[_vm._v(_vm._s(_vm.$t('10_1_title_communication')))])]),_c('Comments',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.content.id &&
        _vm.role !== 'READ_ONLY'
      ),expression:"\n        content.id &&\n        role !== 'READ_ONLY'\n      "}],attrs:{"contentId":_vm.content.id,"eod":_vm.content.eod,"comments":_vm.content.comments,"showError":_vm.commentsIsOpenShowError},on:{"commentsUpdated":_vm.handleUpdateContent,"opened":function($event){_vm.commentsIsOpen = true},"closed":function($event){;(_vm.commentsIsOpen = false), (_vm.commentsIsOpenShowError = false)}}})],1),_c('div',{staticClass:"item-sidebar"},[_c('div',{staticClass:"item-date-time"},[_c('DatePickerEditContent',{attrs:{"luxonDate":_vm.content.luxonDate,"disabled":_vm.role === 'READ_ONLY',"state":_vm.content.state,"eod":_vm.content.eod},on:{"updatedDate":_vm.updatedDate,"updatedDateClear":_vm.updatedDateClear},model:{value:(_vm.content.date),callback:function ($$v) {_vm.$set(_vm.content, "date", $$v)},expression:"content.date"}}),_c('TimePickerEditContent',{attrs:{"content":_vm.content,"readonly":_vm.role === 'READ_ONLY',"slim":true},on:{"updatedTime":_vm.updatedTime,"updatedTimeClear":_vm.updatedTimeClear}})],1),_c('Participants')],1)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }