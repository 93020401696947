<template>
  <div class="slick-menu" :class="{'sidebar-offset': !sourceSidebarState}">
    <div class="slick-menu-inner">
      <div class="slick-menu-title">
        <button v-if="!disableBack" @click="handleStepBack()">
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.16602 10.5H15.8327M4.16602 10.5L9.16602 15.5M4.16602 10.5L9.16602 5.5" stroke="#888F91" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>

        {{ title }}
      </div>
      <div class="slick-menu-control">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  computed: {
    sourceSidebarState() {
      return this.$store.getters.getSourceSidebarState
    },
  },
  created() {
    // this.getScrollPosition();
    window.addEventListener('scroll', this.getScrollPosition);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.getScrollPosition);
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    disableBack: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      scrollPosition: 0
    }
  },
  methods: {
    getScrollPosition() {
      this.scrollPosition = window.scrollY;
      // this.scrollPosition = document.getElementById('content-wrapper').scrollY;
      // console.log("this.scrollPosition: ", this.scrollPosition);
    },
    handleStepBack() {
      window.history.back();
    }
  }
}
</script>

<style scoped>
  .slick-menu {
    padding: 20px;
    position: fixed;
    z-index: 100;
    top: 67px;
    left: 231px;
    right: 0;
    background-color: #F6F6F7;
    transition: all 200ms ease;
  }

  .slick-menu.sidebar-offset {
    left: 56px;
    right: 12px;
  }

  .slick-menu-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .slick-menu-title {
    font-family: Nunito;
    font-size: 28px;
    font-weight: 800;
    line-height: 33.6px;
    text-align: left;
    color: #17252A;
  }

  .slick-menu-title button {
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .slick-menu-control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }
</style>
