<template>
  <button @click="handleSelectColor" class="color-picker-item" :class="{'is-active': active}">
    <span :style="{'background-color': color}"></span>
  </button>
</template>

<script>
export default {
  components: {

  },
  props: {
    color: {
      type: String,
      default: "#17252A"
    },
    active: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {}
  },
  methods: {
    handleSelectColor() {
      this.$emit('selectColor', this.color);
    }
  }
}
</script>

<style scoped>
.color-picker-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  border: 1px solid #fff;
}

.color-picker-item:hover {
  border: 1px solid #17252A;
}

.color-picker-item.is-active {
  border: 1px solid #17252A;
}

.color-picker-item span {
  min-width: 24px;
  max-width: 24px;
  max-height: 24px;
  min-height: 24px;
  border-radius: 50%;
  transition: all 200ms ease;
}

.color-picker-item:hover span {
  min-width: 20px;
  max-width: 20px;
  max-height: 20px;
  min-height: 20px;
}

.color-picker-item.is-active span {
  min-width: 20px;
  max-width: 20px;
  max-height: 20px;
  min-height: 20px;
}
</style>
