<template>
  <ContentWrapper>
    <NoBudgets
      v-if="!showList"
      @add="showList = true"
    />

    <div v-if="showList">
      <SlickMenu :disable-back="true" :title="$t('15_12_2_title')" >
        <Button size="nav" type="secondary">
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.1667 14.6667H15.8333C16.2754 14.6667 16.6993 14.4911 17.0118 14.1785C17.3244 13.866 17.5 13.442 17.5 13V9.66667C17.5 9.22464 17.3244 8.80072 17.0118 8.48816C16.6993 8.17559 16.2754 8 15.8333 8H4.16667C3.72464 8 3.30072 8.17559 2.98816 8.48816C2.67559 8.80072 2.5 9.22464 2.5 9.66667V13C2.5 13.442 2.67559 13.866 2.98816 14.1785C3.30072 14.4911 3.72464 14.6667 4.16667 14.6667H5.83333M14.1667 8V4.66667C14.1667 4.22464 13.9911 3.80072 13.6785 3.48816C13.366 3.17559 12.942 3 12.5 3H7.5C7.05797 3 6.63405 3.17559 6.32149 3.48816C6.00893 3.80072 5.83333 4.22464 5.83333 4.66667V8M5.83333 13C5.83333 12.558 6.00893 12.134 6.32149 11.8215C6.63405 11.5089 7.05797 11.3333 7.5 11.3333H12.5C12.942 11.3333 13.366 11.5089 13.6785 11.8215C13.9911 12.134 14.1667 12.558 14.1667 13V16.3333C14.1667 16.7754 13.9911 17.1993 13.6785 17.5118C13.366 17.8244 12.942 18 12.5 18H7.5C7.05797 18 6.63405 17.8244 6.32149 17.5118C6.00893 17.1993 5.83333 16.7754 5.83333 16.3333V13Z" stroke="#17252A" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          {{ $t('15_12_2_btn_print') }}
        </Button>

        <VDropdown
          :triggers="[]"
          :shown="showData"
          :handle-resize="true"
          :arrow-padding="distanceNo"
          :popperClass="boxSize"
          :auto-hide="autoHide"
          :eagerMount="autoHide"
          @hide="closeData"
        >
          <Button size="nav"ss @click="openData" type="primary">
            <IcoPlus />
            {{ $t('15_12_1_button') }}
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 8L10 13L15 8" stroke="white" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </Button>
          <template #popper>
            <BudgetsSubmenu @onSelect="goToPage" />
          </template>
        </VDropdown>
      </SlickMenu>

      <BudgetsAddPersonaAudience />
      <BudgetsAddProductService />
      <BudgetsAddChannel />
      <BudgetsAddTags />

      <div class="list">
        <div class="boxed boxed--small">
          <div class="box">

            <div class="card-budget">
              <div class="card-budget-header">
                <div class="card-budget-header-title">
                  <h2>Personas & Audiences (3)</h2>
                  <div class="card-budget-header-control">
                    <div>
                      <Button type="settings">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.60417 3.5975C8.95917 2.13417 11.0408 2.13417 11.3958 3.5975C11.4491 3.81733 11.5535 4.02148 11.7006 4.19333C11.8477 4.36518 12.0332 4.49988 12.2422 4.58645C12.4512 4.67303 12.6776 4.70904 12.9032 4.69156C13.1287 4.67407 13.3469 4.60359 13.54 4.48583C14.8258 3.7025 16.2983 5.17417 15.515 6.46083C15.3974 6.65388 15.327 6.87195 15.3096 7.09731C15.2922 7.32267 15.3281 7.54897 15.4146 7.75782C15.5011 7.96666 15.6356 8.15215 15.8073 8.29921C15.9789 8.44627 16.1829 8.55075 16.4025 8.60417C17.8658 8.95917 17.8658 11.0408 16.4025 11.3958C16.1827 11.4491 15.9785 11.5535 15.8067 11.7006C15.6348 11.8477 15.5001 12.0332 15.4135 12.2422C15.327 12.4512 15.291 12.6776 15.3084 12.9032C15.3259 13.1287 15.3964 13.3469 15.5142 13.54C16.2975 14.8258 14.8258 16.2983 13.5392 15.515C13.3461 15.3974 13.1281 15.327 12.9027 15.3096C12.6773 15.2922 12.451 15.3281 12.2422 15.4146C12.0333 15.5011 11.8479 15.6356 11.7008 15.8073C11.5537 15.9789 11.4492 16.1829 11.3958 16.4025C11.0408 17.8658 8.95917 17.8658 8.60417 16.4025C8.5509 16.1827 8.44648 15.9785 8.29941 15.8067C8.15233 15.6348 7.96676 15.5001 7.75779 15.4135C7.54882 15.327 7.32236 15.291 7.09685 15.3084C6.87133 15.3259 6.65313 15.3964 6.46 15.5142C5.17417 16.2975 3.70167 14.8258 4.485 13.5392C4.60258 13.3461 4.67296 13.1281 4.6904 12.9027C4.70785 12.6773 4.67187 12.451 4.58539 12.2422C4.49892 12.0333 4.36438 11.8479 4.19273 11.7008C4.02107 11.5537 3.81714 11.4492 3.5975 11.3958C2.13417 11.0408 2.13417 8.95917 3.5975 8.60417C3.81733 8.5509 4.02148 8.44648 4.19333 8.29941C4.36518 8.15233 4.49988 7.96676 4.58645 7.75779C4.67303 7.54882 4.70904 7.32236 4.69156 7.09685C4.67407 6.87133 4.60359 6.65313 4.48583 6.46C3.7025 5.17417 5.17417 3.70167 6.46083 4.485C7.29417 4.99167 8.37417 4.54333 8.60417 3.5975Z" stroke="#888F91" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M7.5 10C7.5 10.663 7.76339 11.2989 8.23223 11.7678C8.70107 12.2366 9.33696 12.5 10 12.5C10.663 12.5 11.2989 12.2366 11.7678 11.7678C12.2366 11.2989 12.5 10.663 12.5 10C12.5 9.33696 12.2366 8.70107 11.7678 8.23223C11.2989 7.76339 10.663 7.5 10 7.5C9.33696 7.5 8.70107 7.76339 8.23223 8.23223C7.76339 8.70107 7.5 9.33696 7.5 10Z" stroke="#888F91" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        {{ $t('15_12_2_btn_manage') }}
                      </Button>
                    </div>
                    <div>
                      <Button type="settings">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3.33203 5.83333H16.6654M8.33203 9.16667V14.1667M11.6654 9.16667V14.1667M4.16536 5.83333L4.9987 15.8333C4.9987 16.2754 5.17429 16.6993 5.48685 17.0118C5.79941 17.3244 6.22334 17.5 6.66536 17.5H13.332C13.7741 17.5 14.198 17.3244 14.5105 17.0118C14.8231 16.6993 14.9987 16.2754 14.9987 15.8333L15.832 5.83333M7.4987 5.83333V3.33333C7.4987 3.11232 7.5865 2.90036 7.74278 2.74408C7.89906 2.5878 8.11102 2.5 8.33203 2.5H11.6654C11.8864 2.5 12.0983 2.5878 12.2546 2.74408C12.4109 2.90036 12.4987 3.11232 12.4987 3.33333V5.83333" stroke="#888F91" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        {{ $t('15_12_2_btn_delete_budget') }}
                      </Button>
                    </div>
                  </div>
                </div>
                <div class="card-budget-header-total">
                  <span>{{ $t('15_12_2_label_total_budget') }}:</span>&nbsp; 1,000 Kč
                </div>
              </div>

              <div class="card-budget-body">
                <div class="card-budget-body-data">
                  <table>
                    <thead>
                      <tr>
                        <th>{{ $t('15_12_2_thead_col') }}</th>
                        <th>{{ $t('15_12_2_thead_channel') }}</th>
                        <th>{{ $t('15_12_2_thead_share') }}</th>
                        <th>{{ $t('15_12_2_thead_budget') }}</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td class="ico"><CardBudgetDataIco /></td>
                        <td>Jane Foster</td>
                        <td class="share"><span>49%</span></td>
                        <td class="budget">
                          <TableInput value="123" />
                        </td>
                      </tr>
                      <tr>
                        <td class="ico"><CardBudgetDataIco /></td>
                        <td>Jane Foster</td>
                        <td class="share"><span>49%</span></td>
                        <td class="budget">
                          <TableInput value="456" />
                        </td>
                      </tr>
                      <tr>
                        <td class="ico"><CardBudgetDataIco /></td>
                        <td>Jane Foster</td>
                        <td class="share"><span>49%</span></td>
                        <td class="budget">
                          <TableInput value="789" />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <Accordion :title="$t('Undefined')" :no-offset="true">
                    <table>
                      <tbody>
                      <tr>
                        <td class="ico"><CardBudgetDataIco /></td>
                        <td>Jane Foster</td>
                        <td class="share"><span>49%</span></td>
                        <td class="budget">
                          <TableInput value="" />
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </Accordion>

                  <table>
                    <tfoot>
                      <tr>
                        <td colspan="3">
                          <div class="card-budget-table-footer">{{ $t('15_12_2_tfoot_total_defined') }}: 3/3 personas & audiences</div>
                        </td>
                        <td colspan="2">
                          <div class="card-budget-table-footer">1,000 Kč</div>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>

                <div class="card-budget-body-charts">
                  <div class="card-budget-body-chart">
                    <DoughnutChart
                      :width="267"
                      :height="267"
                      ref="state_chart"
                      :chart-data="chartData"
                      :options="options"
                    >
                    </DoughnutChart>

                    <div v-if="chartData" class="card-budget-body-chart-center">
                      <strong>{{ chartSum }}</strong>
                      {{ $t('personas & audiences') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

    </div>
  </ContentWrapper>
</template>

<script>
import NoPersonas from "@/components/sources/components/personas/NoPersonas.vue";
import PersonaCard from "@/components/sources/components/personas/PersonaCard.vue";
import Button from "@/components/button/Button.vue";
import IcoPlus from '@/components/svg/IcoPlus.vue';
import IcoChartPie from "@/components/svg/IcoChartPie.vue";
import ContentWrapper from "@/components/sources/ContentWrapper.vue";
import NoProducts from "@/components/sources/components/products/NoProducts.vue";
import NoStdc from "@/components/sources/components/stdc/NoStdc.vue";
import NoChannels from "@/components/sources/components/channels/NoChannels.vue";
import NoBudgets from "@/components/sources/components/budgets/NoBudgets.vue";
import SlickMenu from "@/components/sources/components/SlickMenu.vue";
import BudgetsSubmenu from "@/components/sources/components/budgets/BudgetsSubmenu.vue";
import DoughnutChart from "@/components/dashboard/DoughnutChart.vue";
import CardBudgetDataIco from "@/components/sources/components/budgets/CardBudgetDataIco.vue";
import TableInput from "@/components/sources/components/budgets/TableInput.vue";
import Accordion from "@/components/accordion/Accordion.vue";
import BudgetsAddChannel from "@/components/sources/components/budgets/BudgetsAddChannel.vue";
import BudgetsAddTags from "@/components/sources/components/budgets/BudgetsAddTags.vue";
import BudgetsAddProductService from "@/components/sources/components/budgets/BudgetsAddProductService.vue";
import BudgetsAddPersonaAudience from "@/components/sources/components/budgets/BudgetsAddPersonaAudience.vue";

const options = {
  with: '267px',
  height: '267px',
  cutoutPercentage: 70,
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    animateRotate: true,
  },
  legend: {
    display: false,
  },
  tooltips: {
    enabled: false,
    custom: function (tooltipModel) {
      // console.log('tooltipModel', tooltipModel)
      let tooltipEl = document.getElementById('chartjs-tooltip')

      // Create element on first render
      if (!tooltipEl) {
        tooltipEl = document.createElement('div')
        tooltipEl.id = 'chartjs-tooltip'
        document.body.appendChild(tooltipEl)
      }

      // Hide if no tooltip
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0
        return
      }

      // Set caret Position
      tooltipEl.classList.remove('above', 'below', 'no-transform')
      if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign)
      } else {
        tooltipEl.classList.add('no-transform')
      }

      function getBody(bodyItem) {
        return bodyItem.lines
      }

      // Set Text
      if (tooltipModel.body) {
        var bodyLines = tooltipModel.body.map(getBody)
        tooltipEl.innerHTML = `<div style="display: flex; flex-direction: row; align-items: center; background-color: #fff; border-radius: 8px; box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.05); padding: 4px; color: #888F91 !important">${bodyLines[0]}</div>`
      }

      var position = this._chart.canvas.getBoundingClientRect()

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1
      tooltipEl.style.zIndex = 99999
      tooltipEl.style.position = 'absolute'
      tooltipEl.style.left = position.left + tooltipModel.caretX / 2 + 'px'
      tooltipEl.style.top =
        position.top + window.pageYOffset + tooltipModel.caretY / 2 + 'px'
      tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily
      tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px'
      tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle
      tooltipEl.style.padding =
        tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px'
      tooltipEl.style.pointerEvents = 'none'
    },
  },
}
const colors = [
  '#2CE0C1',
  '#40D2F2',
  '#FFE500',
  '#9795FF',
  '#F45050',

  '#28CAAE',
  '#3ABDDA',
  '#E6CE00',
  '#8886E6',
  '#FF6A88',

  '#23B39A',
  '#33A8C2',
  '#CCB700',
  '#7977CC',

  '#1F9D87',
  '#2D93A9',
  '#B3A000',
  '#6A68B3',

  '#1A8674',
  '#267E91',
  '#998900',
  '#5B5999',

  '#167061',
  '#206979',
  '#807300',
  '#4C4B80',
]

export default {
  components: {
    BudgetsAddPersonaAudience,
    BudgetsAddProductService,
    BudgetsAddTags,
    BudgetsAddChannel,
    Accordion,
    TableInput,
    CardBudgetDataIco,
    DoughnutChart,
    BudgetsSubmenu,
    SlickMenu,
    NoBudgets,
    NoChannels,
    NoStdc,
    NoProducts,
    ContentWrapper,
    IcoChartPie,
    PersonaCard,
    NoPersonas,
    Button,
    IcoPlus
  },
  computed: {},
  data() {
    return {
      showList: true,
      showData: false,
      distanceNo: 12,
      autoHide: true,
      toDelete: false,
      boxSize: 'popper-budgets-submenu',

      chartData: {
        "labels": [
          "<span class=\"label-percentage\" style=\"background-color: #2CE0C1;\">66.7%</span><span class=\"label-name\">Rumcajs</span>",
          "<span class=\"label-percentage\" style=\"background-color: #40D2F2;\">16.7%</span><span class=\"label-name\">Nepřiřazeno</span>",
          "<span class=\"label-percentage\" style=\"background-color: #FFE500;\">12.5%</span><span class=\"label-name\">Manka</span>",
          "<span class=\"label-percentage\" style=\"background-color: #9795FF;\">4.2%</span><span class=\"label-name\">Cipísek</span>"
        ],
        "legend": [
          {
            "label": "Rumcajs",
            "percentage": "66.7%",
            "count": 16
          },
          {
            "label": "Nepřiřazeno",
            "percentage": "16.7%",
            "count": 4
          },
          {
            "label": "Manka",
            "percentage": "12.5%",
            "count": 3
          },
          {
            "label": "Cipísek",
            "percentage": "4.2%",
            "count": 1
          }
        ],
        "datasets": [
          {
            "backgroundColor": [
              "#2CE0C1",
              "#40D2F2",
              "#FFE500",
              "#9795FF",
              "#F45050",
              "#28CAAE",
              "#3ABDDA",
              "#E6CE00",
              "#8886E6",
              "#FF6A88",
              "#23B39A",
              "#33A8C2",
              "#CCB700",
              "#7977CC",
              "#1F9D87",
              "#2D93A9",
              "#B3A000",
              "#6A68B3",
              "#1A8674",
              "#267E91",
              "#998900",
              "#5B5999",
              "#167061",
              "#206979",
              "#807300",
              "#4C4B80"
            ],
            "borderWidth": 2,
            "data": [
              16,
              4,
              3,
              1
            ]
          }
        ]
      },
      chartSum: '3',
      options,
    }
  },
  methods: {
    closeData() {
      this.showData = false
    },
    openData() {
      if (this.disabled) {
        this.showData = false
        return
      }

      // this.itemsList = this.items
      this.autoHide = false
      setTimeout(() => (this.autoHide = true), 300)

      this.showData = true
    },
    goToPage(page) {

    },
  }
}
</script>

<style scoped>
.card-budget-body-data {

}

.card-budget {
  display: flex;
  flex-direction: column;
}

.card-budget-header {
  display: flex;
  justify-content: space-between;
}

.card-budget-header-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.card-budget-header-control {
  display: flex;
  align-items: center;
}

.card-budget-header-control > div {
  position: relative;
  padding-left: 10px;
  margin-left: 10px;
}

.card-budget-header-control > div::after {
  content: '';
  top: 10px;
  bottom: 10px;
  left: 0;
  position: absolute;
  width: 1px;
  background-color: #D1D4D4;
}

.card-budget-header-total {
  font-family: Nunito;
  font-size: 21px;
  font-weight: 800;
  line-height: 25.2px;
  text-align: right;
  color: #17252A;
  display: flex;
  align-items: center;
}

.card-budget-header-total span {
  font-family: Nunito;
  font-size: 21px;
  font-weight: 500;
  line-height: 25.2px;
  text-align: right;
  color: #888F91;
}

.card-budget-body {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  gap: 40px;
}

.card-budget-body-data {
  width: 100%;
}

.card-budget-body-data table{
  border-collapse: collapse;
  width: 100%;
}

.card-budget-body-charts {
  min-width: 267px;
  max-width: 267px;
}

.card-budget-body-chart {
  position: relative;
  min-width: 267px;
  max-width: 267px;
}

.card-budget-body-chart-center{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #888888;
}

.card-budget-body-chart-center strong {
  display: block;
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 28px;
  text-align: center;
  color: #17252A;
}

.card-budget-body-data table th,
.card-budget-body-data table td{
  text-align: left;
  padding: 0;
  margin: 0;
}

.card-budget-body-data table th {
  font-family: Nunito;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #888F91;
  text-transform: uppercase;
}

.card-budget-body-data table td{
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  text-align: left;
  color: #17252A;
  padding-top: 20px;
  padding-bottom: 20px;
}

.card-budget-body-data table tbody tr td {
  border-bottom: 1px solid #E7E9E9;
}

.card-budget-body-data table tbody tr td:last-child {
  text-align: right;
}

.card-budget-body-data table tbody tr td.ico {
  width: 45px;
}

.card-budget-body-data table tbody tr td.edit {
  width: 20px;
}

.card-budget-body-data table tbody tr td.share {
  width: 80px;
}

.card-budget-body-data table tbody tr td.budget {
  width: 142px;
}

.card-budget-body-data table tbody tr:last-child td{
  border-bottom: none;
}

.card-budget-table-footer {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 700;
  line-height: 25.2px;
  text-align: left;
  color: #17252A;
}

.card-budget-body-chart {
  position: relative;
  width: 100%;
}

.card-budget-body-data table {

}
</style>
