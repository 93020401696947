<template>
  <div class="accordion" :class="{'is-expanded': isExpanded}">
    <div class="accordion-title" @click="handleToggleState()">
      {{ title }}
      <span class="accordion-icon">
        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11 6L6 1L1 6" stroke="#2CE0C1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </span>
    </div>
    <div v-if="description !== '' && isExpanded" class="accordion-description">
      {{ description }}
    </div>
    <div v-show="isExpanded" class="accordion-body" :class="{'no-offset': noOffset}">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import IcoCarretUp from "@/components/svg/IcoCarretUp.vue";

export default {
  components: {
    IcoCarretUp

  },
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    noOffset: {
      type: Boolean,
      default: false
    }
  },
  computed: {

  },
  data() {
    return {
      isExpanded: false
    }
  },
  methods: {
    handleToggleState() {
      this.isExpanded = !this.isExpanded;
    }
  }
}
</script>

<style scoped>
  .accordion {
    display: inline-flex;
    gap: 12px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    width: 100%;
  }

  .accordion:after {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    display: block;
    border-bottom: 1px solid #E7E9E9;
  }

  .accordion.is-expanded:after {

  }

  .accordion-title {
    position: relative;
    z-index: 2;
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    justify-content: flex-start;
    gap: 13px;
    font-family: Nunito;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #888F91;
    background-color: #fff;
    padding-right: 13px;
  }

  .accordion.is-expanded .accordion-title {
    color: #23B39A;
  }

  .accordion-icon svg {
    transform: rotate(180deg);
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
  }

  .accordion-icon svg path {
    stroke: #888F91;
  }

  .accordion.is-expanded .accordion-icon svg {
    transform: rotate(0deg);
  }

  .accordion.is-expanded .accordion-icon svg path {
    stroke: #23B39A;
  }

  .accordion-description {
    color: #888F91;
    font-family: Nunito;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
  }

  .accordion-body {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
  }

  .accordion-body.no-offset {
    margin-top: 0 !important;
  }
</style>
