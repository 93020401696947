<template>
  <div class="wrapper wrapper--center">
    <div class="container">
      <div class="column column--80gap">
        <div class="left">
          <svg width="403" height="263" viewBox="0 0 403 263" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5009_82527)">
              <path d="M55.0587 262.789C85.1906 262.789 109.617 259.945 109.617 256.438C109.617 252.931 85.1906 250.088 55.0587 250.088C24.9267 250.088 0.5 252.931 0.5 256.438C0.5 259.945 24.9267 262.789 55.0587 262.789Z" fill="#E6E6E6"/>
              <path d="M347.941 262.999C378.073 262.999 402.5 260.156 402.5 256.649C402.5 253.142 378.073 250.299 347.941 250.299C317.81 250.299 293.383 253.142 293.383 256.649C293.383 260.156 317.81 262.999 347.941 262.999Z" fill="#E6E6E6"/>
              <path d="M336.262 141.058H59.8899C59.1144 141.057 58.3709 140.748 57.8226 140.199C57.2742 139.65 56.9658 138.906 56.9648 138.13V2.92792C56.9657 2.15167 57.2742 1.40747 57.8226 0.85857C58.3709 0.309674 59.1144 0.000906436 59.8899 0H336.262C337.037 0.000913157 337.781 0.309688 338.329 0.858583C338.877 1.40748 339.186 2.15167 339.187 2.92792V138.13C339.186 138.906 338.877 139.65 338.329 140.199C337.781 140.748 337.037 141.057 336.262 141.058ZM59.8899 1.17118C59.4246 1.17169 58.9785 1.35694 58.6494 1.68628C58.3204 2.01562 58.1354 2.46216 58.1349 2.92792V138.13C58.1354 138.596 58.3204 139.042 58.6494 139.372C58.9785 139.701 59.4246 139.886 59.8899 139.887H336.262C336.727 139.886 337.173 139.701 337.502 139.372C337.831 139.042 338.016 138.596 338.017 138.13V2.92792C338.016 2.46216 337.831 2.01562 337.502 1.68628C337.173 1.35694 336.727 1.17169 336.262 1.17118H59.8899Z" fill="#E6E6E6"/>
              <path d="M188.353 58.7457C190.858 58.7457 192.889 56.7127 192.889 54.2049C192.889 51.6971 190.858 49.6641 188.353 49.6641C185.847 49.6641 183.816 51.6971 183.816 54.2049C183.816 56.7127 185.847 58.7457 188.353 58.7457Z" fill="#3F3D56"/>
              <path d="M188.354 57.1253C189.964 57.1253 191.27 55.8184 191.27 54.2062C191.27 52.594 189.964 51.2871 188.354 51.2871C186.743 51.2871 185.438 52.594 185.438 54.2062C185.438 55.8184 186.743 57.1253 188.354 57.1253Z" fill="#2CE0C1"/>
              <path d="M207.794 58.7457C210.299 58.7457 212.33 56.7127 212.33 54.2049C212.33 51.6971 210.299 49.6641 207.794 49.6641C205.289 49.6641 203.258 51.6971 203.258 54.2049C203.258 56.7127 205.289 58.7457 207.794 58.7457Z" fill="#3F3D56"/>
              <path d="M207.795 57.1253C209.406 57.1253 210.711 55.8184 210.711 54.2062C210.711 52.594 209.406 51.2871 207.795 51.2871C206.185 51.2871 204.879 52.594 204.879 54.2062C204.879 55.8184 206.185 57.1253 207.795 57.1253Z" fill="#2CE0C1"/>
              <path d="M187.844 54.5293C185.667 54.5222 183.565 55.3302 181.953 56.7946C180.34 58.2591 179.332 60.2741 179.128 62.4441L178.221 71.7498C178.114 72.8544 178.238 73.9692 178.586 75.0227C178.935 76.0762 179.5 77.045 180.245 77.867C180.99 78.6889 181.898 79.3457 182.912 79.7952C183.925 80.2447 185.022 80.4769 186.13 80.4769H210.02C211.128 80.4769 212.225 80.2447 213.238 79.7952C214.252 79.3457 215.16 78.6889 215.905 77.8669C216.65 77.045 217.215 76.0762 217.564 75.0227C217.912 73.9692 218.036 72.8543 217.929 71.7498L217.022 62.4441C216.817 60.2741 215.81 58.2591 214.197 56.7946C212.585 55.3302 210.483 54.5222 208.306 54.5293H187.844Z" fill="#3F3D56"/>
              <path d="M200.496 67.6864C201.166 66.787 202.164 66.1898 203.272 66.0258C204.38 65.8618 205.509 66.1443 206.409 66.8115C206.91 66.1389 207.199 65.3329 207.242 64.4953C207.284 63.6578 207.078 62.8264 206.648 62.1066C206.218 61.3867 205.585 60.8107 204.828 60.4514C204.071 60.0922 203.224 59.9659 202.395 60.0885C201.567 60.2112 200.793 60.5772 200.172 61.1403C199.551 61.7035 199.111 62.4383 198.908 63.2519C198.704 64.0654 198.747 64.9211 199.03 65.7105C199.312 66.4999 199.823 67.1875 200.496 67.6864Z" fill="white"/>
              <path d="M190.617 68.5047C191.287 67.6053 192.285 67.0082 193.393 66.8442C194.502 66.6802 195.63 66.9627 196.53 67.6298C197.031 66.9573 197.321 66.1512 197.363 65.3137C197.406 64.4761 197.199 63.6448 196.769 62.9249C196.339 62.2051 195.706 61.629 194.949 61.2698C194.192 60.9105 193.345 60.7842 192.516 60.9069C191.688 61.0295 190.914 61.3956 190.293 61.9587C189.672 62.5218 189.232 63.2567 189.029 64.0702C188.826 64.8838 188.868 65.7394 189.151 66.5288C189.434 67.3182 189.944 68.0059 190.617 68.5047Z" fill="white"/>
              <path d="M193.419 65.833C194.28 65.833 194.978 65.1341 194.978 64.272C194.978 63.4098 194.28 62.7109 193.419 62.7109C192.558 62.7109 191.859 63.4098 191.859 64.272C191.859 65.1341 192.558 65.833 193.419 65.833Z" fill="#3F3D56"/>
              <path d="M203.52 64.6436C204.382 64.6436 205.08 63.9447 205.08 63.0825C205.08 62.2204 204.382 61.5215 203.52 61.5215C202.659 61.5215 201.961 62.2204 201.961 63.0825C201.961 63.9447 202.659 64.6436 203.52 64.6436Z" fill="#3F3D56"/>
              <path d="M198.175 71.3958C199.07 71.3958 199.795 70.6697 199.795 69.7741C199.795 68.8784 199.07 68.1523 198.175 68.1523C197.28 68.1523 196.555 68.8784 196.555 69.7741C196.555 70.6697 197.28 71.3958 198.175 71.3958Z" fill="#2CE0C1"/>
              <path d="M191.711 72.3691C191.793 73.2552 192.202 74.0787 192.859 74.6786C193.515 75.2784 194.372 75.6115 195.261 75.6126H201.741C202.63 75.6115 203.487 75.2784 204.143 74.6785C204.8 74.0786 205.209 73.2551 205.291 72.3691H191.711Z" fill="white"/>
              <path d="M97.7289 58.9093C97.7295 59.506 97.6126 60.0969 97.3848 60.6484C97.1571 61.1998 96.8231 61.7009 96.4018 62.123C95.9805 62.5451 95.4802 62.88 94.9296 63.1085C94.3789 63.3369 93.7887 63.4545 93.1926 63.4545C92.5965 63.4545 92.0062 63.3369 91.4556 63.1085C90.9049 62.88 90.4047 62.5451 89.9834 62.123C89.5621 61.7009 89.228 61.1998 89.0003 60.6484C88.7726 60.0969 88.6557 59.506 88.6563 58.9093C88.6563 56.4015 90.6872 45.9355 93.1926 45.9355C95.6979 45.9355 97.7289 56.4015 97.7289 58.9093Z" fill="#E6E6E6"/>
              <path d="M96.1098 58.9087C96.1098 59.6829 95.8025 60.4254 95.2556 60.9728C94.7087 61.5203 93.967 61.8278 93.1936 61.8278C92.4201 61.8278 91.6784 61.5203 91.1315 60.9728C90.5846 60.4254 90.2773 59.6829 90.2773 58.9087C90.2773 57.2965 91.583 47.5566 93.1936 47.5566C94.8041 47.5566 96.1098 57.2965 96.1098 58.9087Z" fill="white"/>
              <path d="M108.098 58.9097C108.098 60.114 108.576 61.269 109.426 62.1206C110.277 62.9721 111.431 63.4505 112.634 63.4505C113.837 63.4505 114.991 62.9721 115.842 62.1206C116.692 61.269 117.17 60.114 117.17 58.9097C117.17 56.4019 115.139 44.9629 112.634 44.9629C110.129 44.9629 108.098 56.4019 108.098 58.9097Z" fill="#E6E6E6"/>
              <path d="M109.719 58.9091C109.719 59.6833 110.026 60.4258 110.573 60.9732C111.12 61.5206 111.862 61.8282 112.635 61.8282C113.408 61.8282 114.15 61.5206 114.697 60.9732C115.244 60.4258 115.551 59.6833 115.551 58.9091C115.551 57.2969 114.246 46.584 112.635 46.584C111.024 46.584 109.719 57.2969 109.719 58.9091Z" fill="white"/>
              <path d="M92.6839 59.2325C90.5065 59.2253 88.4051 60.0333 86.7924 61.4978C85.1797 62.9622 84.1723 64.9773 83.968 67.1472L83.061 76.453C82.9533 77.5575 83.0778 78.6723 83.4263 79.7258C83.7748 80.7793 84.3397 81.7482 85.0846 82.5701C85.8295 83.392 86.738 84.0488 87.7516 84.4983C88.7652 84.9478 89.8616 85.18 90.9703 85.18H114.859C115.968 85.18 117.064 84.9478 118.078 84.4983C119.092 84.0488 120 83.392 120.745 82.5701C121.49 81.7482 122.055 80.7793 122.403 79.7258C122.752 78.6723 122.876 77.5575 122.769 76.453L121.862 67.1472C121.657 64.9773 120.65 62.9622 119.037 61.4978C117.425 60.0333 115.323 59.2253 113.146 59.2325H92.6839Z" fill="#E6E6E6"/>
              <path d="M105.336 72.3895C106.005 71.4901 107.004 70.8929 108.112 70.7289C109.22 70.5649 110.348 70.8475 111.249 71.5146C111.749 70.8421 112.039 70.036 112.082 69.1984C112.124 68.3609 111.918 67.5296 111.488 66.8097C111.058 66.0898 110.425 65.5138 109.667 65.1545C108.91 64.7953 108.064 64.669 107.235 64.7916C106.406 64.9143 105.633 65.2803 105.012 65.8434C104.391 66.4066 103.951 67.1414 103.748 67.955C103.544 68.7685 103.587 69.6242 103.87 70.4136C104.152 71.203 104.663 71.8907 105.336 72.3895Z" fill="white"/>
              <path d="M95.4573 73.2079C96.1265 72.3084 97.1247 71.7113 98.233 71.5473C99.3414 71.3833 100.469 71.6658 101.37 72.333C101.871 71.6604 102.16 70.8543 102.203 70.0168C102.245 69.1793 102.039 68.3479 101.609 67.628C101.179 66.9082 100.546 66.3321 99.7886 65.9729C99.0315 65.6137 98.185 65.4874 97.3563 65.61C96.5275 65.7326 95.7537 66.0987 95.1328 66.6618C94.512 67.2249 94.0721 67.9598 93.8688 68.7733C93.6654 69.5869 93.7078 70.4425 93.9906 71.232C94.2734 72.0214 94.7838 72.709 95.4573 73.2079Z" fill="white"/>
              <path d="M98.2587 73.1299C99.12 73.1299 99.8182 72.431 99.8182 71.5689C99.8182 70.7067 99.12 70.0078 98.2587 70.0078C97.3974 70.0078 96.6992 70.7067 96.6992 71.5689C96.6992 72.431 97.3974 73.1299 98.2587 73.1299Z" fill="#E6E6E6"/>
              <path d="M108.685 72.2647C109.546 72.2647 110.244 71.5658 110.244 70.7036C110.244 69.8415 109.546 69.1426 108.685 69.1426C107.823 69.1426 107.125 69.8415 107.125 70.7036C107.125 71.5658 107.823 72.2647 108.685 72.2647Z" fill="#E6E6E6"/>
              <path d="M103.015 76.0989C103.909 76.0989 104.635 75.3728 104.635 74.4772C104.635 73.5815 103.909 72.8555 103.015 72.8555C102.12 72.8555 101.395 73.5815 101.395 74.4772C101.395 75.3728 102.12 76.0989 103.015 76.0989Z" fill="white"/>
              <path d="M106.403 75.6599C106.957 75.3712 107.588 75.5642 108.131 75.787C108.724 76.0308 109.3 76.3072 109.911 76.506C111.053 76.9056 112.276 77.0122 113.47 76.8161C115.628 76.4122 117.439 74.4813 116.954 72.1914C116.824 71.5792 115.887 71.8383 116.016 72.4501C116.097 72.9088 116.059 73.3804 115.906 73.8204C115.754 74.2604 115.492 74.6542 115.145 74.9646C114.353 75.6439 113.332 75.9965 112.289 75.9511C111.138 75.896 110.01 75.6066 108.973 75.1008C107.983 74.662 106.945 74.2816 105.912 74.8197C105.357 75.1089 105.848 75.9489 106.403 75.6599Z" fill="white"/>
              <path d="M100.238 74.8199C99.2316 74.2954 98.2131 74.6437 97.2434 75.0712C96.1871 75.591 95.0362 75.8904 93.8607 75.9512C92.8393 75.9947 91.8381 75.6579 91.0502 75.0057C90.6894 74.6957 90.4153 74.2972 90.2546 73.8492C90.094 73.4012 90.0523 72.9192 90.1338 72.4503C90.2632 71.8394 89.3263 71.5784 89.1965 72.1916C88.7116 74.4814 90.5224 76.4123 92.6805 76.8162C93.8738 77.0129 95.0974 76.9063 96.2388 76.5061C96.8502 76.3064 97.4259 76.0312 98.0195 75.7871C98.5611 75.5644 99.1937 75.3715 99.7476 75.66C100.302 75.9488 100.794 75.1094 100.238 74.8199Z" fill="white"/>
              <path d="M103.401 80.1534C104.832 80.1534 105.993 79.5725 105.993 78.856C105.993 78.1394 104.832 77.5586 103.401 77.5586C101.969 77.5586 100.809 78.1394 100.809 78.856C100.809 79.5725 101.969 80.1534 103.401 80.1534Z" fill="white"/>
              <path d="M276.874 67.2967C281.928 67.2967 286.025 63.1956 286.025 58.1366C286.025 53.0777 281.928 48.9766 276.874 48.9766C271.82 48.9766 267.723 53.0777 267.723 58.1366C267.723 63.1956 271.82 67.2967 276.874 67.2967Z" fill="#3F3D56"/>
              <path d="M276.871 64.0253C280.12 64.0253 282.754 61.3889 282.754 58.1367C282.754 54.8845 280.12 52.248 276.871 52.248C273.622 52.248 270.988 54.8845 270.988 58.1367C270.988 61.3889 273.622 64.0253 276.871 64.0253Z" fill="#2CE0C1"/>
              <path d="M309.6 67.2967C314.654 67.2967 318.751 63.1956 318.751 58.1366C318.751 53.0777 314.654 48.9766 309.6 48.9766C304.546 48.9766 300.449 53.0777 300.449 58.1366C300.449 63.1956 304.546 67.2967 309.6 67.2967Z" fill="#3F3D56"/>
              <path d="M309.598 64.0253C312.847 64.0253 315.48 61.3889 315.48 58.1367C315.48 54.8845 312.847 52.248 309.598 52.248C306.349 52.248 303.715 54.8845 303.715 58.1367C303.715 61.3889 306.349 64.0253 309.598 64.0253Z" fill="#2CE0C1"/>
              <path d="M283.166 55.2188C283.024 55.2188 282.884 55.222 282.744 55.2286C277.471 55.4764 273.826 60.6429 275.111 65.7679L275.297 66.5103C275.958 69.1477 276.408 71.8293 276.854 74.5116C277.161 76.3707 278.117 78.0602 279.553 79.2789C280.989 80.4977 282.81 81.1665 284.693 81.1663H302.101C304.055 81.1665 305.94 80.4462 307.396 79.1431C308.853 77.84 309.779 76.0454 309.997 74.1021C310.304 71.6768 310.771 69.2746 311.396 66.9114L311.683 65.7679C312.968 60.6429 309.323 55.4764 304.05 55.2286C303.91 55.2221 303.769 55.2188 303.628 55.2188H283.166Z" fill="#3F3D56"/>
              <path d="M295.817 68.3758C296.486 67.4764 297.484 66.8792 298.592 66.7152C299.701 66.5512 300.829 66.8338 301.729 67.5009C302.23 66.8284 302.52 66.0223 302.562 65.1848C302.605 64.3472 302.398 63.5159 301.968 62.796C301.539 62.0761 300.905 61.5001 300.148 61.1409C299.391 60.7816 298.544 60.6553 297.716 60.778C296.887 60.9006 296.113 61.2667 295.492 61.8298C294.871 62.3929 294.431 63.1278 294.228 63.9413C294.025 64.7549 294.067 65.6105 294.35 66.3999C294.633 67.1893 295.143 67.877 295.817 68.3758Z" fill="white"/>
              <path d="M285.942 69.1922C286.611 68.2928 287.609 67.6957 288.717 67.5317C289.826 67.3677 290.954 67.6502 291.854 68.3173C292.355 67.6448 292.645 66.8387 292.687 66.0012C292.73 65.1636 292.523 64.3323 292.093 63.6124C291.664 62.8926 291.03 62.3165 290.273 61.9573C289.516 61.598 288.669 61.4717 287.841 61.5944C287.012 61.717 286.238 62.0831 285.617 62.6462C284.996 63.2093 284.556 63.9442 284.353 64.7577C284.15 65.5713 284.192 66.4269 284.475 67.2163C284.758 68.0057 285.268 68.6934 285.942 69.1922Z" fill="white"/>
              <path d="M287.122 66.5205C287.983 66.5205 288.681 65.8216 288.681 64.9595C288.681 64.0973 287.983 63.3984 287.122 63.3984C286.261 63.3984 285.562 64.0973 285.562 64.9595C285.562 65.8216 286.261 66.5205 287.122 66.5205Z" fill="#3F3D56"/>
              <path d="M297.22 65.9795C298.081 65.9795 298.779 65.2806 298.779 64.4185C298.779 63.5563 298.081 62.8574 297.22 62.8574C296.358 62.8574 295.66 63.5563 295.66 64.4185C295.66 65.2806 296.358 65.9795 297.22 65.9795Z" fill="#3F3D56"/>
              <path d="M295.119 70.463C295.119 70.724 295.056 70.9812 294.936 71.2127C294.815 71.4442 294.64 71.6431 294.426 71.7926C294.213 71.9421 293.966 72.0376 293.707 72.0712C293.449 72.1047 293.186 72.0753 292.941 71.9854C292.696 71.8954 292.476 71.7476 292.301 71.5546C292.125 71.3615 291.999 71.1289 291.932 70.8764C291.866 70.624 291.861 70.3592 291.919 70.1046C291.977 69.85 292.095 69.6131 292.263 69.414C292.561 69.0636 292.903 70.1386 293.398 70.1386C293.911 70.1386 294.469 69.0797 294.766 69.4518C294.995 69.7388 295.12 70.0955 295.119 70.463Z" fill="#2CE0C1"/>
              <path d="M289.934 72.7324H297.71C297.71 73.7647 297.301 74.7547 296.571 75.4846C295.842 76.2145 294.853 76.6246 293.822 76.6246C292.791 76.6246 291.802 76.2145 291.072 75.4846C290.343 74.7547 289.934 73.7647 289.934 72.7324Z" fill="white"/>
              <path d="M292.262 57.1649C293.426 57.1649 294.369 55.5675 294.369 53.5971C294.369 51.6267 293.426 50.0293 292.262 50.0293C291.099 50.0293 290.156 51.6267 290.156 53.5971C290.156 55.5675 291.099 57.1649 292.262 57.1649Z" fill="#2CE0C1"/>
              <path d="M297.229 55.4547C298.358 53.8406 298.501 51.9908 297.548 51.3229C296.595 50.6551 294.908 51.4223 293.779 53.0364C292.65 54.6505 292.507 56.5004 293.46 57.1682C294.413 57.836 296.1 57.0689 297.229 55.4547Z" fill="#2CE0C1"/>
              <path d="M130.292 97.4985H75.5383C74.7628 97.4976 74.0194 97.1889 73.471 96.64C72.9226 96.0911 72.6142 95.3469 72.6133 94.5706V35.5724C72.6142 34.7962 72.9226 34.052 73.471 33.5031C74.0194 32.9542 74.7628 32.6454 75.5383 32.6445H130.292C131.068 32.6454 131.811 32.9542 132.359 33.5031C132.908 34.052 133.216 34.7962 133.217 35.5724V94.5706C133.216 95.3469 132.908 96.0911 132.359 96.64C131.811 97.1889 131.068 97.4976 130.292 97.4985ZM75.5383 33.8157C75.073 33.8162 74.6269 34.0014 74.2979 34.3308C73.9689 34.6601 73.7838 35.1067 73.7833 35.5724V94.5706C73.7838 95.0364 73.9689 95.4829 74.2979 95.8123C74.6269 96.1416 75.073 96.3269 75.5383 96.3274H130.292C130.757 96.3269 131.204 96.1416 131.533 95.8123C131.862 95.4829 132.047 95.0364 132.047 94.5706V35.5724C132.047 35.1067 131.862 34.6601 131.533 34.3308C131.204 34.0014 130.757 33.8162 130.292 33.8157H75.5383Z" fill="#E6E6E6"/>
              <path d="M225.452 97.4985H170.698C169.923 97.4976 169.18 97.1889 168.631 96.64C168.083 96.0911 167.774 95.3469 167.773 94.5706V35.5724C167.774 34.7962 168.083 34.052 168.631 33.5031C169.18 32.9542 169.923 32.6454 170.698 32.6445H225.452C226.228 32.6454 226.971 32.9542 227.52 33.5031C228.068 34.052 228.376 34.7962 228.377 35.5724V94.5706C228.376 95.3469 228.068 96.0911 227.52 96.64C226.971 97.1889 226.228 97.4976 225.452 97.4985ZM170.698 33.8157C170.233 33.8162 169.787 34.0014 169.458 34.3308C169.129 34.6601 168.944 35.1067 168.943 35.5724V94.5706C168.944 95.0364 169.129 95.4829 169.458 95.8123C169.787 96.1416 170.233 96.3269 170.698 96.3274H225.452C225.918 96.3269 226.364 96.1416 226.693 95.8123C227.022 95.4829 227.207 95.0364 227.207 94.5706V35.5724C227.207 35.1067 227.022 34.6601 226.693 34.3308C226.364 34.0014 225.918 33.8162 225.452 33.8157H170.698Z" fill="#E6E6E6"/>
              <path d="M320.612 97.4985H265.859C265.083 97.4976 264.34 97.1889 263.791 96.64C263.243 96.0911 262.935 95.3469 262.934 94.5706V35.5724C262.935 34.7962 263.243 34.052 263.791 33.5031C264.34 32.9542 265.083 32.6454 265.859 32.6445H320.612C321.388 32.6454 322.131 32.9542 322.68 33.5031C323.228 34.052 323.537 34.7962 323.537 35.5724V94.5706C323.537 95.3469 323.228 96.0911 322.68 96.64C322.131 97.1889 321.388 97.4976 320.612 97.4985ZM265.859 33.8157C265.393 33.8162 264.947 34.0014 264.618 34.3308C264.289 34.6601 264.104 35.1067 264.104 35.5724V94.5706C264.104 95.0364 264.289 95.4829 264.618 95.8123C264.947 96.1416 265.393 96.3269 265.859 96.3274H320.612C321.078 96.3269 321.524 96.1416 321.853 95.8123C322.182 95.4829 322.367 95.0364 322.367 94.5706V35.5724C322.367 35.1067 322.182 34.6601 321.853 34.3308C321.524 34.0014 321.078 33.8162 320.612 33.8157H265.859Z" fill="#E6E6E6"/>
              <path d="M279.7 104.18C279.588 104.18 279.481 104.224 279.401 104.304C279.322 104.383 279.277 104.491 279.277 104.603V107.99C279.277 108.102 279.322 108.21 279.401 108.289C279.481 108.369 279.588 108.413 279.7 108.413H306.768C306.88 108.413 306.988 108.369 307.067 108.289C307.146 108.21 307.191 108.102 307.191 107.99V104.603C307.191 104.491 307.146 104.383 307.067 104.304C306.988 104.224 306.88 104.18 306.768 104.18H279.7Z" fill="#3F3D56"/>
              <path d="M184.54 104.18C184.428 104.18 184.32 104.224 184.241 104.304C184.162 104.383 184.117 104.491 184.117 104.603V107.99C184.117 108.102 184.162 108.21 184.241 108.289C184.32 108.369 184.428 108.413 184.54 108.413H211.608C211.72 108.413 211.828 108.369 211.907 108.289C211.986 108.21 212.031 108.102 212.031 107.99V104.603C212.031 104.491 211.986 104.383 211.907 104.304C211.828 104.224 211.72 104.18 211.608 104.18H184.54Z" fill="#2CE0C1"/>
              <path d="M89.38 104.18C89.2678 104.18 89.1603 104.224 89.081 104.304C89.0017 104.383 88.9571 104.491 88.957 104.603V107.99C88.9571 108.102 89.0017 108.21 89.081 108.289C89.1603 108.369 89.2678 108.413 89.38 108.413H116.448C116.56 108.413 116.668 108.369 116.747 108.289C116.826 108.21 116.871 108.102 116.871 107.99V104.603C116.871 104.491 116.826 104.383 116.747 104.304C116.668 104.224 116.56 104.18 116.448 104.18H89.38Z" fill="#E6E6E6"/>
              <path d="M75.3609 159.913L74.0921 165.417C74.0921 165.417 74.5151 178.117 71.1316 176.847C67.7481 175.577 68.594 164.57 68.594 164.57L71.5545 159.066L75.3609 159.913Z" fill="#FFB8B8"/>
              <path d="M56.5407 99.5846C61.2124 99.5846 64.9994 95.7937 64.9994 91.1175C64.9994 86.4412 61.2124 82.6504 56.5407 82.6504C51.8691 82.6504 48.082 86.4412 48.082 91.1175C48.082 95.7937 51.8691 99.5846 56.5407 99.5846Z" fill="#FFB8B8"/>
              <path d="M49.9826 93.4473L49.1367 105.725L59.7101 106.995C59.7101 106.995 59.7101 96.8341 60.556 95.564C61.4018 94.294 49.9826 93.4473 49.9826 93.4473Z" fill="#FFB8B8"/>
              <path d="M73.4336 109.434L59.7327 103.641C59.7327 103.641 53.1859 98.5933 49.3514 102.333L47.6305 104.8C47.6305 104.8 32.41 110.74 32.4141 115.397L38.3555 138.677C38.3555 138.677 34.9835 151.804 40.0595 152.646L67.5525 155.162C67.5525 155.162 71.3548 150.502 67.9661 144.578L69.2227 130.606L73.4336 109.434Z" fill="#E6E6E6"/>
              <path d="M64.1289 109.867L73.4331 109.436C73.4331 109.436 76.3944 110.28 78.5161 118.322C80.6378 126.363 81.0722 139.487 81.0722 139.487L76.0177 163.199L69.6729 162.358L72.1906 139.495L67.9479 124.258L64.1289 109.867Z" fill="#E6E6E6"/>
              <path d="M37.4866 112.43L32.9005 113.864C32.9005 113.864 29.0341 119.634 29.0367 122.598C29.0393 125.561 27.3672 148 27.3672 148L38.3794 166.195L41.7551 157.302L34.9782 145.877L40.4641 131.901L37.4866 112.43Z" fill="#E6E6E6"/>
              <path d="M68.8039 154.198L38.3962 151.52C38.3962 151.52 35.392 170.708 35.815 178.329C36.2379 185.949 36.6608 197.803 36.6608 197.803L33.2773 249.452L46.3883 247.759L48.0801 205L53.5782 181.716L59.9223 211.351L64.1516 249.452L75.5709 248.182L71.7645 202.037C71.7645 202.037 75.1479 160.548 68.8039 154.198Z" fill="#2F2E41"/>
              <path d="M73.0329 246.066L76.8393 247.336C76.8393 247.336 84.8751 245.22 82.7604 250.723C80.6458 256.227 74.7247 255.38 74.7247 255.38C74.7247 255.38 67.5348 260.884 64.5742 256.65L66.266 247.76L73.0329 246.066Z" fill="#2F2E41"/>
              <path d="M42.1579 246.066L45.9643 247.336C45.9643 247.336 54.0001 245.22 51.8854 250.723C49.7708 256.227 43.8497 255.38 43.8497 255.38C43.8497 255.38 36.6598 260.884 33.6992 256.65L35.391 247.76L42.1579 246.066Z" fill="#2F2E41"/>
              <path d="M49.9838 98.5264C49.9838 98.5264 41.1021 92.176 45.7544 84.5557C48.9819 79.2691 55.6697 79.6875 59.4634 80.4397C60.4855 80.6285 61.4463 81.0633 62.2632 81.7066C63.08 82.3499 63.7282 83.1824 64.1521 84.1323C64.7865 85.614 64.7865 87.0958 62.2489 87.0958C57.1737 87.0958 59.7113 90.906 59.7113 90.906C59.7113 90.906 56.3278 91.7527 57.1737 94.7162C58.0195 97.6797 49.9838 98.5264 49.9838 98.5264Z" fill="#2F2E41"/>
              <path d="M353.998 247.219C353.998 247.219 344.619 247.941 345.581 251.311C346.543 254.681 353.998 254.2 353.998 254.2C353.998 254.2 359.77 253.719 362.897 254.681C366.023 255.644 369.63 254.681 369.871 252.996C370.111 251.311 368.187 250.108 368.187 250.108L366.985 247.7L353.998 247.219Z" fill="#2F2E41"/>
              <path d="M329.348 171.99L328.386 175.842C328.386 175.842 324.058 184.749 329.348 184.749C334.639 184.749 332.715 176.564 332.715 176.564L333.196 172.712L329.348 171.99Z" fill="#FFB8B8"/>
              <path d="M375.762 168.861L376.724 172.713C376.724 172.713 381.053 181.62 375.762 181.62C370.471 181.62 372.395 173.435 372.395 173.435L371.914 169.584L375.762 168.861Z" fill="#FFB8B8"/>
              <path d="M350.751 103.383C356.463 103.383 361.093 98.7482 361.093 93.0312C361.093 87.3142 356.463 82.6797 350.751 82.6797C345.04 82.6797 340.41 87.3142 340.41 93.0312C340.41 98.7482 345.04 103.383 350.751 103.383Z" fill="#FFB8B8"/>
              <path d="M347.625 100.974C347.625 100.974 348.827 106.511 348.106 109.159C347.385 111.807 363.017 108.678 363.017 108.678C363.017 108.678 357.485 99.5298 357.726 99.2891L347.625 100.974Z" fill="#FFB8B8"/>
              <path d="M350.002 107.103C350.002 107.103 345.672 105.904 344.23 107.349C342.789 108.795 334.375 112.895 334.375 112.895L340.178 148.277L365.184 142.719L369.484 109.253C369.484 109.253 362.661 105.256 361.502 106.055C360.343 106.854 350.002 107.103 350.002 107.103Z" fill="#E6E6E6"/>
              <path d="M336.302 113.134L335.147 112.516C335.147 112.516 330.771 113.38 331.014 116.75C331.258 120.12 332.236 138.896 332.236 138.896L328.418 172.843L335.393 174.04L342.343 145.628L336.302 113.134Z" fill="#E6E6E6"/>
              <path d="M368.765 109.976L369.919 109.355C369.919 109.355 374.297 110.212 374.059 113.583C373.822 116.953 372.876 135.731 372.876 135.731L376.754 169.671L369.78 170.881L362.781 142.48L368.765 109.976Z" fill="#E6E6E6"/>
              <path d="M340.409 144.788L339.447 159.473L336.32 188.842L340.89 249.747L356.762 250.229L351.952 192.212L356.762 172.232L372.484 171.649C372.484 171.649 373.597 148.64 364.699 141.418L340.409 144.788Z" fill="#2F2E41"/>
              <path d="M357.726 168.862L355.201 221.943L354.359 248.785L368.548 250.229L372.129 171.291L366.624 165.732L357.726 168.862Z" fill="#2F2E41"/>
              <path d="M355.321 79.3091C355.321 79.3091 345.942 78.1055 343.296 82.6794C343.296 82.6794 342.575 86.0497 340.17 87.2533C337.765 88.457 340.651 92.5494 341.372 93.7531C342.094 94.9567 344.018 101.938 341.853 103.623C339.689 105.308 345.701 110.123 345.701 110.123C345.701 110.123 348.587 113.975 346.182 116.863C343.777 119.752 349.309 120.715 349.309 120.715C349.309 120.715 348.106 128.9 350.271 126.733C350.271 126.733 351.233 145.27 360.372 133.715C369.51 122.159 373.358 116.623 369.51 112.049C365.662 107.475 368.548 103.382 368.548 103.382C368.548 103.382 369.751 100.975 367.105 97.3641C364.46 93.7531 364.46 79.3091 355.321 79.3091Z" fill="#2F2E41"/>
              <path d="M341.733 248.664C341.733 248.664 332.353 249.386 333.315 252.757C334.277 256.127 341.733 255.645 341.733 255.645C341.733 255.645 347.504 255.164 350.631 256.127C353.757 257.09 357.365 256.127 357.605 254.442C357.846 252.757 355.922 251.553 355.922 251.553L354.719 249.146L341.733 248.664Z" fill="#2F2E41"/>
              <path d="M235.32 50.671L221.59 42.3906L225.6 57.9269L228.017 52.2666L232.048 57.678L233.244 56.7855L229.212 51.3742L235.32 50.671Z" fill="#2CE0C1"/>
              <path d="M253.883 50.671L267.613 42.3906L263.604 57.9269L261.187 52.2666L257.155 57.678L255.959 56.7855L259.991 51.3742L253.883 50.671Z" fill="#3F3D56"/>
            </g>
            <defs>
              <clipPath id="clip0_5009_82527">
                <rect width="402" height="263" fill="white" transform="translate(0.5)"/>
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="right">
          <div class="no-personas-content">
            <h1>{{ $t('15_10_1_1_title') }}</h1>
            <p>{{ $t('15_10_1_1_description') }}</p>
            <Button @click="handleAdd" type="primary"><IcoPlus /> {{ $t('15_10_1_1_button') }}</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/button/Button.vue";
import IcoPlus from '@/components/svg/IcoPlus.vue';

export default {
  components: {
    Button,
    IcoPlus
  },
  computed: {

  },
  data() {
    return {}
  },
  methods: {
    handleAdd() {
      this.$emit('add');
    }
  }
}
</script>

<style>
.sources .no-personas-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: flex-start;
  align-items: flex-start;
}

.sources .no-personas-content,
.sources .no-personas-content h1,
.sources .no-personas-content p {
  text-align: left;
}

.sources .no-personas-content h1 {
  font-family: Nunito;
  font-size: 36px;
  font-weight: 800;
  text-align: left;
}

.sources .no-personas-content p {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 400;
  line-height: 28.8px;
  text-align: left;
}
</style>
