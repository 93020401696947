<template>
  <div class="modal-edit-importances">
    <div class="modal-edit-importances-header">
      <div class="modal-edit-importances-title">
        <strong>{{ $t('modal_edit_importances_title') }}</strong>
      </div>
      <button @click="handleCancel" >
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 6.5L6 18.5M6 6.5L18 18.5" stroke="#888F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>

    <div class="modal-edit-importances-description">
      {{ $t('modal_edit_importances_description') }}
    </div>

    <div class="modal-edit-importances-body">
      <Splitpanes
        class="default-theme"
        vertical
        @resize="updatePaneSizes"
      >
        <pane
          v-for="(importance, index) in itemsComputed"
          :key="index"
          :size="importance.ratio"
        >
          <div class="item" :style="{'background-color': getColor(index)}">
            <div class="item-title">{{ importance.name }}</div>
            <div class="item-value">{{ importance.ratio }}</div>
          </div>
        </pane>
      </Splitpanes>

      <table>
        <thead>
        <tr>
          <th>{{ $t('modal_edit_importances_row_col') }}</th>
          <th>{{ $t('modal_edit_importances_row_product') }}</th>
          <th>{{ $t('modal_edit_importances_row_share') }}</th>
          <!-- <th>{{ $t('modal_edit_importances_row_imp') }}</th> -->
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="(importance, index) in itemsComputed"
          :key="index"
        >
          <td>
            <div class="ico" :style="{'background-color': getColor(index)}"></div>
          </td>
          <td>
            <Dropdown
              v-if="isEditable"
              :placeholder="$t('modal_edit_importances_select_placeholder')"
              :items="[{name: 'jedna', value: 'jedna'}, {name: 'dva', value: 'dva'}]"
            />
            <span v-if="!isEditable">{{ importance.name }}</span>
          </td>
          <td>
            <InputPercentage v-model="importance.ratio" />
          </td>
          <!-- <td></td> -->
        </tr>
        </tbody>
      </table>

      <div v-if="totalRatio > 100" class="warning">
        <div class="warning-ico">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.94 2C12.4212 2.00033 12.8951 2.11677 13.3216 2.33944C13.7482 2.5621 14.1147 2.88441 14.39 3.279L14.498 3.443L22.929 17.517C23.1816 17.955 23.319 18.4499 23.3283 18.9555C23.3376 19.461 23.2184 19.9607 22.9821 20.4077C22.7457 20.8547 22.3998 21.2344 21.9767 21.5113C21.5536 21.7882 21.0672 21.9532 20.563 21.991L20.363 22H3.507C3.00219 21.994 2.50713 21.8602 2.06796 21.6112C1.6288 21.3622 1.25983 21.006 0.995465 20.5759C0.731098 20.1458 0.579938 19.6558 0.556076 19.1515C0.532215 18.6473 0.636429 18.1451 0.859004 17.692L0.960004 17.503L9.385 3.438C9.65148 2.99901 10.0266 2.63609 10.4741 2.38421C10.9216 2.13234 11.4265 2.00002 11.94 2ZM12.01 16L11.883 16.007C11.64 16.0359 11.4159 16.153 11.2534 16.336C11.0909 16.519 11.0011 16.7552 11.0011 17C11.0011 17.2448 11.0909 17.481 11.2534 17.664C11.4159 17.847 11.64 17.9641 11.883 17.993L12 18L12.127 17.993C12.3701 17.9641 12.5941 17.847 12.7566 17.664C12.9191 17.481 13.0089 17.2448 13.0089 17C13.0089 16.7552 12.9191 16.519 12.7566 16.336C12.5941 16.153 12.3701 16.0359 12.127 16.007L12.01 16ZM12 8C11.7551 8.00003 11.5187 8.08996 11.3356 8.25272C11.1526 8.41547 11.0357 8.63975 11.007 8.883L11 9V13L11.007 13.117C11.0359 13.36 11.153 13.5841 11.336 13.7466C11.519 13.9091 11.7552 13.9989 12 13.9989C12.2448 13.9989 12.481 13.9091 12.664 13.7466C12.847 13.5841 12.9641 13.36 12.993 13.117L13 13V9L12.993 8.883C12.9643 8.63975 12.8474 8.41547 12.6644 8.25272C12.4813 8.08996 12.2449 8.00003 12 8Z" fill="#F12727"/>
          </svg>
        </div>
        <div class="warning-text">
          <div class="warning-text-title">{{ $t('modal_edit_importances_warning_title') }}</div>
          <div class="warning-text-description">{{ $t('modal_edit_importances_warning_description') }}</div>
        </div>
      </div>
    </div>

    <div class="modal-edit-importances-footer">
      <Button @click="handleCancel" type="secondary">{{ $t('modal_edit_importances_btn_cancel') }}</Button>
      <Button :disabled="totalRatio > 100 || updateRatio === false" @click="updateRatio" type="primary">{{ $t('modal_edit_importances_btn_save') }}</Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/button/Button.vue";
import Dropdown from "@/components/dropdown/Dropdown.vue";
import { Splitpanes, Pane } from "splitpanes";
import InputPercentage from "@/components/sources/components/inputs/InputPercentage.vue";
import { arrayClone } from "../../../services/helpers/clone";
import PRODUCT_UPDATE_RATIO from '@/graphql/ProductUpdateRatio.gql';

export default {
  components: {
    Button,
    Dropdown,
    Splitpanes,
    Pane,
    InputPercentage
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    definedColors: Array,
    editable: Boolean,
  },
  computed: {
    isEditable() {
      return this.editable;
    },
    colors() {
      return Array.isArray(this.definedColors) ? this.definedColors : this.defaultColors;
    },
    itemsComputed: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    },
    totalRatio() {
      if(Array.isArray(this.itemsComputed)) {
        return this.itemsComputed.reduce((sum, item) => sum + item.ratio ?? 0, 0);
      }
    }
  },
  data() {
    return {
      originalValues: arrayClone(this.value),
      defaultColors: ['#2CE0C1', '#9795FF', '#40D2F2'],
      saving: false,
    };
  },
  created() {
    if (this.totalRatio === 0) {
      const totalItems = this.itemsComputed.length;
      const initRatio = 100 / totalItems;
      this.itemsComputed.forEach(item => {
        item.ratio = initRatio;
      });
    }

    this.updatePaneSizes();
  },
  methods: {
    getColor(index) {
      return this.colors[index] || '#2CE0C1';
    },
    handleCancel() {
      this.itemsComputed = arrayClone(this.originalValues);
      this.$emit('update:items',  this.itemsComputed);
      this.$emit('close');
    },
    updatePaneSizes(event) {
      const updatedItems = [...this.itemsComputed];

      if(event) {
        event.forEach((item, index) => {
          updatedItems[index].ratio = Math.round(item.size);
        });
      }

      this.itemsComputed = updatedItems;
    },
    updateRatio() {
      this.saving = true;

      const products = this.itemsComputed.map((item) => {
        return {
          productId: item.id,
          ratio: item.ratio
        }
      })

      try {
        this.$apollo.mutate({
          mutation: PRODUCT_UPDATE_RATIO,
          variables: {
            productRatios: products
          },
          update: (store, data) => {
            this.originalValues = arrayClone(this.itemsComputed);
          }
        }).then(() => {
          this.$notify({
            title: this.$t('15_8_toaster_success_title_saved'),
            text: this.$t('15_8_toaster_success_description_saved'),
            type: 'success',
            duration: 5000,
          });

          this.$emit('update:items',  this.itemsComputed);
          this.$emit('close');
          this.saving = false;
        });
      } catch(e) {
        this.$notify({
          title: this.$t('15_8_toaster_error_title_saved'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        });

        this.saving = false;
      }
    },
  }
}
</script>


<style scoped>

.warning {
  margin-top: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 8px;
  gap: 16px;
  background: #FCD4D4;
  padding: 12px;
}

.warning-text {
  display: flex;
  flex-direction: column;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
}

.warning-text-title {
  font-family: Nunito;
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  text-align: left;
}

.ico {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-top: 5px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 32px;
}

table th {
  font-family: Nunito;
  font-size: 11px;
  font-weight: 700;
  line-height: 17.6px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #888F91;
  text-transform: uppercase;
  border-bottom: 1px solid #E7E9E9;
}

table td,
table th{
  padding: 4px;
  text-align: left;
  vertical-align: middle;
}

table th {
  padding-bottom: 14px;
}

table td {
  padding: 8px 4px;
}

table tr:first-child td {
  padding-top: 14px;
}

table tr td:last-child {
  text-align: right;
  float: right;
}

.default-theme {
  border-radius: 4px;
  overflow: hidden;
}

.item {
  height: 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.item-title {
  color: #fff;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.05em;
  text-align: center;
}

.item-value {
  color: #fff;
  font-family: Nunito;
  font-size: 10px;
  font-weight: 600;
  line-height: 10px;
  letter-spacing: 0.1em;
  text-align: center;

}


.modal-edit-importances {
  width: 536px;
}

.modal-edit-importances-header {
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-edit-importances-header button {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.modal-edit-importances-title {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
  text-align: left;
  color: #17252A;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.modal-edit-importances-title span {
  position: relative;
  font-family: Nunito;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  display: inline-block;
  color: #888F91;
}

.modal-edit-importances-title span:after {
  content: '';
  position: absolute;
  left: -10px;
  top: 4px;
  bottom: 4px;
  width: 1px;
  background-color: #888F91;
}

.modal-edit-importances-description {
  margin-top: 16px;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: #888F91;
  padding-left: 32px;
  padding-right: 32px;
}

.modal-edit-importances-body {
  margin-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
}

.modal-edit-importances-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 32px;
  border-top: 1px solid #E7E9E9;
  padding: 32px;
}
</style>

<style>
.default-theme.splitpanes--vertical>.splitpanes__splitter,
.default-theme .splitpanes--vertical>.splitpanes__splitter {

}

.default-theme.splitpanes--vertical>.splitpanes__splitter:before,
.default-theme .splitpanes--vertical>.splitpanes__splitter:before {

}
</style>
