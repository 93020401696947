<template>
  <div class="modal-add">
    <div class="modal-add-header">
      <div class="modal-add-header-title">{{ $t('Add Meeting Note') }}</div>
      <div class="modal-add-header-control">
        <button class="modal-add-header-control-close" @click="handleClose">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 6L6 18M6 6L18 18" stroke="#888F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
    </div>

    <div class="modal-add-body">

      <div class="column">
        <FormGroup :label="$t('Meeting Title')" :required="true">
          <input
            type="text"
            :placeholder="$t('Enter meeting title')"
            v-model="title"
            @input="validationError.title = false"
            ref="contentName"
          />
        </FormGroup>
      </div>

      <div class="column">
        <Dropdown
          :label="$t('Participants')"
          :placeholder="$t('Select...')"
          :items="[{name: 'jedna', value: 'jedna'}, {name: 'dva', value: 'dva'}]"
        />
      </div>

      <div class="column">
        <FormGroup :label="$t('Links')" :required="false">
          <Resources
            :items="resources"
            :disabled="role === 'READ_ONLY'"
            :showError="resourcesIsOpenShowError"
            transCreateNew="Add a Link"
            @updateResources="updateResources"
            @opened="resourcesIsOpen = true"
            @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
          />
        </FormGroup>
      </div>

      <div class="column">
        <FormGroup :label="$t('Checklist')" :required="false">
          <Resources
            :items="resources"
            :disabled="role === 'READ_ONLY'"
            :showError="resourcesIsOpenShowError"
            transCreateNew="Add Item"
            @updateResources="updateResources"
            @opened="resourcesIsOpen = true"
            @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
          />
        </FormGroup>
      </div>

      <div class="column">
        <FormGroup
          :label="$t('Description')"
          :focused="focusContentDescription"
          :required="false"
          :border="false"
        >
          <WYSIWYGEditor
            :content="description"
            :isValid="true"
            title=""
            tinymceRef=""
            :buttonTitle="$t('10_1_title_add_description')"
            :maxLength="length.long"
            :externalEditState="true"
            :showControl="false"
            :placeholder="$t('9_1_placeholder_description')"
            :showError="false"
            format="description"
            @updateContent="updateDescription"
          />
        </FormGroup>
      </div>

    </div>

    <div class="modal-add-footer">
      <Button type="secondary">{{ $t('Cancel') }}</Button>
      <Button type="primary">{{ $t('Add Meeting Note') }}</Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/button/Button.vue";
import Dropdown from "@/components/dropdown/Dropdown.vue";
import WYSIWYGEditor from "@/components/WYSIWYGEditor.vue";
import textLength from "@/constants/TextLengthConstant";
import FormGroup from "@/components/FormGroup.vue";
import Resources from "@/components/design/Resources.vue";

export default {
  components: {
    Resources,
    FormGroup,
    WYSIWYGEditor,
    Dropdown,
    Button
  },
  computed: {
    length() {
      return textLength
    },
    role() {
      return this.$store.getters.getMeCompanyRole
    },
  },
  data() {
    return {
      resources: [],
      resourcesIsOpen: false,
      resourcesIsOpenShowError: false,

      title: '',
      description: '',

      validationError: {
        title: ''
      },
      focusContentDescription: false
    }
  },
  methods: {
    updateDescription() {

    },
    updateResources() {

    },
    handleClose() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
.modal-add {
  width: 600px;
}

.modal-add-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
}

.modal-add-header-title {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
  text-align: left;
  color: #17252A;
}

.modal-add-header-control-close {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.modal-add-body {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.modal-add-footer {
  padding: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  border-top: 1px solid #F5F5F5;
}
</style>
