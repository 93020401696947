<template>
  <div class="role-label" :style="{'background-color': getColor}">
    {{ name }}
  </div>
</template>

<script>
export default {
  components: {

  },
  props: {
    color: {
      type: String,
      default: '#e7e9e9',
    },
    name: {
      type: String,
      default: ''
    }
  },
  computed: {
    getColor() {
      if(this.name === 'OWNER') {
        return this.colorOwner;
      }

      if(this.name === 'ADMINN') {
        return this.colorAdmin;
      }

      if(this.name === 'APPROVER') {
        return this.colorApprover;
      }

      return '#e7e9e9';
    }
  },
  data() {
    return {
      colorOwner: '#2CE0C1',
      colorAdmin: '#9795FF',
      colorApprover: '#17252A',
    }
  }
}
</script>

<style scoped>
.role-label {
  display: inline-block;
  border-radius: 4px;
  padding: 2px;
  font-family: Nunito;
  font-size: 9px;
  font-weight: 700;
  line-height: 12.28px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #fff;
}
</style>
