<template>
  <button @click="handleSelectItem" class="dropdown-item">
    <span>
      <template v-if="type === 'geography'">{{ getFlagEmoji(item.value) }}</template> <template v-if="type === 'normal' || type === 'geography'">{{ item.name }}</template>
      <template v-if="type === 'currency'">{{ item.value }}</template>
    </span>

    <span v-if="isSelected"><IcoCross /></span>
  </button>
</template>

<script>
import Avatar from '@/components/Avatar.vue';
import { getFlagEmoji } from "../../utils/utils";
import IcoCross from "../svg/small/IcoCross.vue";

export default {
  components: {
    IcoCross,
    Avatar,
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: 'normal'
    },
    selected: {
      required: false
    }
  },
  computed: {
    isSelected() {
      if (typeof this.selected === 'string' && this.selected !== "") {
        if (this.selected === this.item.value) {
          return true;
        }
      }

      if(this.selected && Array.isArray(this.selected)) {
        const match = this.selected.filter((item) => item.value === this.item.value);
        if (match.length > 0) {
          return true;
        }
      }

      return false;
    }
  },
  methods: {
    getFlagEmoji,
    handleSelectItem() {
      this.$emit('selectItem');
    }
  }
}
</script>

<style scoped>
.dropdown-item{
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;

  cursor: pointer;

  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #17252A;

  word-wrap: break-word;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;

  padding: 7px 15px;
  margin: 0;
  border: none;
  background: transparent;
}

.dropdown-item:hover{
  background-color: #F6F6F7;
}

.dropdown-item.is-active:hover{
  background-color: #fff;
}
</style>
