<template>
  <div class="fp">
    <FormGroup
      :label="$t('stdc_frameworks_and_phases_input_label')"
    >
      <div class="fp-inner">
        <ol v-if="list.length" class="fp-list">
          <li v-for="(item, index) in list" :key="index">
            <STDCFrameworksAndPhasesInput />
          </li>
        </ol>

        <div class="fp-buttons">
          <Button
            size="small"
            type="secondary"
            @click="handleAdd"
          >
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.9987 3.83398V13.1673M3.33203 8.50065H12.6654" stroke="#17252A" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            {{ $t('stdc_frameworks_and_phases_input_btn_add') }}
          </Button>
        </div>
      </div>
    </FormGroup>
  </div>
</template>

<script>

import FormGroup from "@/components/FormGroup.vue";
import Button from "@/components/button/Button.vue";
import STDCFrameworksAndPhasesInput from "@/components/sources/components/stdcFrameworksAndPhases/STDCFrameworksAndPhasesInput.vue";

export default {
  components: {
    STDCFrameworksAndPhasesInput,
    Button,
    FormGroup
  },
  computed: {

  },
  data() {
    return {
      list: []
    }
  },
  methods: {
    handleAdd() {
      this.list.push(1);
    }
  }
}
</script>

<style scoped>
.fp {

}

.fp-inner {
  padding: 8px 16px;
}

.fp-list {
  padding: 0 0 0 16px;
}

.fp-list li {
  margin-top: 20px;
}

.fp-buttons {
  padding-top: 8px;
  padding-left: 32px;
  padding-bottom: 0;
}
</style>
