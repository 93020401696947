export default {
  "1_1_title": "Let's Log You In",
  "1_1_description": "Welcome to the content world of dinos!",
  "1_1_label_email": "Email",
  "1_1_label_password": "Password",
  "1_1_checkbox_remember_me": "Remember me",
  "1_1_link_forgot_password": "Forgot password?",
  "1_1_btn_login": "Log in",
  "1_1_or": "or",
  "1_1_btn_google_login": "Continue with Google",
  "1_1_no_account": "Don't have an account?",
  "1_1_sign_up": "Sign up",
  "1_2_invalid_login_title": "Invalid login attempt",
  "1_2_invalid_login_text": "Please check your email and password.",
  "1_2_ok_google_complete_title": "Complete your profile setup",
  "1_2_ok_google_complete_text": "You are currently logged in with your Google account. Complete the profile setup by creating a password.",
  "2_1_title": "Getting Started",
  "2_1_description": "Create an account to join our awesome content dinos!",
  "2_1_label_name": "Full name",
  "2_1_label_email": "Email",
  "2_1_label_password": "Password",
  "2_1_label_confirm_password": "Confirm password",
  "2_1_btn_register": "Register",
  "2_1_or": "or",
  "2_1_btn_google_login": "Continue with Google",
  "2_1_have_account": "Already have an account?",
  "2_1_btn_sign_in": "Log in",
  "2_1_checkbox_terms_line_1": "By creating an account, you agree to our",
  "2_1_link_terms_condition": "Terms and Conditions",
  "2_1_and": "and",
  "2_1_link_privacy_policy": "Privacy Policy.",
  "2_2_invalid_fields_title": "Invalid data",
  "2_2_invalid_fields_description": "Please check your data.",
  "3_1_go_back": "Back to Log in",
  "3_1_title": "Forgot your password?",
  "3_1_description": "Enter your email to recover it.",
  "3_1_label_email": "Email",
  "3_1_input_email_placeholder": "Start typing...",
  "3_1_btn_recover": "Recover",
  "3_1_trouble_recover": "Having trouble recovering your account?",
  "3_1_link_contact_us": "Contact us",
  "3_2_go_back": "Back to Log in",
  "3_2_title": "Check your email",
  "3_2_description": "We have sent a password recover instructions to your email. To reset your password, please check your inbox and click the received link.",
  "3_2_trouble_recover": "Having trouble recovering your account?",
  "3_2_link_contact_us": "Contact us",
  "3_3_title": "Create new password",
  "3_3_description": "Please, come up with a brand new password. Dinos know recycling doesn't work well with security.",
  "3_3_label_new_password": "New password",
  "3_3_label_confirm_password": "Confirm password",
  "3_3_btn_reset_password": "Save new password",
  "3_3_link_contact_us": "Contact us",
  "3_3_trouble_recover": "Having trouble recovering your account?",
  "3_3_1_modal_title": "Contact us",
  "3_3_1_modal_description": "Meteor in the sky? T-Rex snoring at night? Let us know if you bump into any trouble. You can also tell us you like dinos – they enjoy hearing it from time to time.",
  "3_3_1_label_name": "Full name",
  "3_3_1_label_email": "Email",
  "3_3_1_label_message": "Your message",
  "3_3_1_placeholder_email": "superaddress@email.com",
  "3_3_1_placeholder_name": "Jason Bourne",
  "3_3_1_placeholder_message": "Please describe your issue or request.",
  "3_3_1_button_send": "Send",
  "4_1_title": "Create new company...",
  "4_1_description": "Company, project, environment,... Call it as you like, it is an unified space for your content. Do you want to create a new one, or join an existing one?",
  "4_1_btn_create": "Create company",
  "4_1_or": "or",
  "4_1_btn_join": "Join existing company",
  "4_2_title": "Create your first company",
  "4_2_description": "Exciting, you are setting up a new project! Want to plan content for yourself, your business, or a new client?",
  "4_2_label_name": "Name",
  "4_2_placeholder_name": "Amazing company name",
  "4_2_label_description": "Description",
  "4_2_placeholder_description": "Epic company that will conquer the world",
  "4_2_btn_create": "Create company",
  "4_2_already_have_company": "Already have a company?",
  "4_2_link_join_company": "Join selected company",
  "4_2_toaster_success_title_company_create": "Created",
  "4_2_toaster_success_description_company_create": "You created a new company.",
  "4_2_toaster_error_title_company_create": "Error",
  "4_2_toaster_error_description_company_create": "You can't create this company.",
  "4_3_title": "Join your first company",
  "4_3_description": "Awesome, there is a project waiting for you! Don't let your team ponder and join them now.",
  "4_3_label_company_id": "Company ID",
  "4_3_link_get_company_id": "Where to get company ID?",
  "4_3_link_join_company": "Join existing company",
  "4_3_no_company": "Don't have a company?",
  "4_3_link_create_company": "Create company",
  "5_1_menu_calendar": "Calendar",
  "5_1_menu_company": "Company",
  "5_1_menu_ideas": "Ideas",
  "5_1_menu_add_content": "Add content",
  "5_2_new_company": "Add or join new company",
  "5_2_select_company": "Select company",
  "5_2_companies_count": "Companies",
  "5_2_placeholder_search": "Search",
  "5_2_no_result_p1": "We can't find this company. Maybe it became extinct?",
  "5_2_no_result_p2": "Try another name or create a new company.",
  "5_2_btn_show_all": "Show all companies",
  "5_2_btn_new_company": "Add/Join new company",
  "5_2_link_profile": "My profile",
  "5_2_link_billing": "Billing",
  "5_2_app_language": "App language",
  "5_2_link_logout": "Log out",
  "5_3_1_modal_title": "Are you really done for today?",
  "5_3_1_logout": "Log out",
  "5_3_1_back": "Back to work",
  "5_3_2_lang_english": "English",
  "5_3_2_lang_czech": "Česky",
  "6_1_title": "Add/Join new company",
  "6_1_create_subtitle": "Create new company...",
  "6_1_create_description": "Exciting, you are setting up a brand new project! Is it a new client, market, or business opportunity?",
  "6_1_label_name": "Name",
  "6_1_placeholder_name": "Amazing company name",
  "6_1_label_description": "Description",
  "6_1_placeholder_description": "Super company, that will conquer the world...",
  "6_1_btn_create_company": "Create company",
  "6_1_subtitle_join": "...or join one?",
  "6_1_description_join": "Awesome, there is a project waiting for you! Don't let your team ponder and join them now.",
  "6_1_label_company_id": "Company ID",
  "6_1_link_get_company_id": "Awesome, there is a project waiting for you! Don't let your team wait and join them now.",
  "6_1_btn_join": "Join existing company",
  "6_2_title": "All companies",
  "6_2_company_no": "Number of companies",
  "6_3_subtitle_info": "Company information",
  "6_3_label_name": "Name",
  "6_3_placeholder_name": "Amazing company name",
  "6_3_label_description": "Description",
  "6_3_placeholder_description": "Super company, that will conquer the world...",
  "6_3_btn_save": "Save changes",
  "6_3_subtitle_team": "People",
  "6_3_invite_title": "Invite new team members",
  "6_3_invite_description": "Grow your team by increasing numbers! Invite new dinos to your company by email.",
  "6_3_company_id": "Company ID",
  "6_3_btn_delete": "Delete company",
  "6_3_label_email": "Email",
  "6_3_toaster_title_copied_to_clipboard": "Copied",
  "6_3_toaster_description_copied_to_clipboard": "You copied the company ID.",
  "6_3_toaster_error_title_max_users": "Error",
  "6_3_toaster_error_description_max_users": "You can input only 10 email addresses.",
  "6_3_1_tooltip_email": "People you add automatically receive an invite. Enter up to 10 email addresses separated by commas.",
  "6_3_1_placeholder_email": "Put a comma after each email",
  "6_3_1_btn_send_email_join_request": "Send email invites",
  "6_3_1_toaster_success_title_user_deleted": "Removed",
  "6_3_1_toaster_success_description_user_deleted": "You removed the user.",
  "6_3_2_toaster_error_title_user_delete": "Error",
  "6_3_2_toaster_error_description_user_delete": "You can't remove this user.",
  "6_3_1_toaster_success_title_company_updated": "Updated",
  "6_3_1_toaster_success_description_company_updated": "You updated company details.",
  "6_3_2_title": "Are you sure you want to delete",
  "6_3_2_description": "All the content will be deleted and all the users will be removed.",
  "6_3_2_content_no": "NUMBER OF PLANNED CONTENT",
  "6_3_2_users_no": "NUMBER OF USERS",
  "6_3_2_btn_delete": "Delete",
  "6_3_2_btn_cancel": "Go back",
  "6_3_2_toaster_success_title_company_deleted": "Deleted",
  "6_3_2_toaster_success_description_company_deleted": "You deleted the company.",
  "6_3_2_toaster_error_title_company_delete": "Error",
  "6_3_2_toaster_error_description_company_delete": "You can't delete this company.",
  "6_3_2_toaster_success_title_company_create": "Created",
  "6_3_2_toaster_success_description_company_create": "You created a new company.",
  "6_3_2_toaster_error_title_company_create": "Error",
  "6_3_2_toaster_error_description_company_create": "You can't create the company.",
  "6_3_2_toaster_success_title_company_join": "Joined",
  "6_3_2_toaster_success_description_company_join": "Congratz! You joined this company.",
  "6_3_2_toaster_error_title_company_join": "Error",
  "7_1_title": "My profile",
  "7_1_subtitle_personal": "Personal information",
  "7_1_label_name": "Full name",
  "7_1_placeholder_name": "Enter your name",
  "7_1_label_job": "Job title",
  "7_1_description_job": "Enter your job title",
  "7_1_current_email_title": "Current email",
  "7_1_current_email_info": "Your account is registered under this email address",
  "7_1_label_new_email": "New email address",
  "7_1_linked_accounts": "Linked accounts",
  "7_1_disconnect_linked_account": "Disconnect",
  "7_1_connect_linked_account": "Connect",
  "7_1_btn_save_personal": "Save changes",
  "7_1_subtitle_security": "Security",
  "7_1_label_current_password": "Current password",
  "7_1_link_forgot_password": "Forgot password",
  "7_1_label_new_password": "New password",
  "7_1_label_confirm_password": "Confirm new password",
  "7_1_password_strength_weak": "C'mon, make your password stronger!",
  "7_1_password_strength_average": "Very average password…",
  "7_1_password_strength_strong": "That’s an excellent password!",
  "7_1_btn_save_security": "Save changes",
  "7_1_btn_delete_account": "Delete account",
  "7_1_1_title": "Are you sure you want to delete your account?",
  "7_1_1_description": "This will permanently delete your account. You will lose access to all companies and contents you are working on. Moreover, the dinos will wave you goodbye.",
  "7_1_1_company_no": "Number of companies you are a member of",
  "7_1_1_cards_no": "Number of cards you are working on",
  "7_1_1_label_password": "Password",
  "7_1_1_link_forgot_password": "Forgot password",
  "7_1_1_btn_delete": "Delete",
  "7_1_1_btn_back": "Go back",
  "7_1_2_title": "Forgot your password?",
  "7_1_2_description": "Enter your email to recover it.",
  "7_1_2_btn_recover_email": "Recover",
  "7_1_2_info_trouble": "Having trouble recovering your account?",
  "7_1_2_link_contact_us": "Contact us",
  "7_1_3_title": "Check your email",
  "7_1_3_description": "We have sent a password recover instructions to your email. Please check your inbox and click the received link to reset your password.",
  "7_1_3_info_trouble": "Having trouble recovering your account?",
  "7_1_3_link_contact_us": "Contact us",
  "8_1_drop_zone": "DROP HERE",
  "8_1_drop_contents": "Contents",
  "8_1_idea_btn_share": "Share",
  "8_1_idea_btn_duplicate": "Duplicate",
  "8_1_idea_btn_delete": "Delete",
  "8_1_1_all_ideas": "All Ideas",
  "8_2_drop_area": "DROP HERE",
  "8_2_contents": "Contents",
  "8_2_content_btn_share": "Share",
  "8_2_content_btn_duplicate": "Duplicate",
  "8_2_content_btn_delete": "Delete",
  "8_2_add_content": "Add content",
  "8_3_drop_area": "DROP HERE",
  "8_3_contents": "Contents",
  "8_3_content_btn_share": "Share",
  "8_3_content_btn_duplicate": "Duplicate",
  "8_3_content_btn_delete": "Delete",
  "8_3_add_content": "Add content",
  "8_3_label_product": "Product",
  "8_3_label_persona": "Persona",
  "8_3_label_stdc": "STDC",
  "8_4_control_expand": "Expand all",
  "8_4_control_collapse": "Collapse all",
  "8_4_drop_area": "DROP HERE",
  "8_4_add_content": "Add content",
  "8_4_contents": "Contents",
  "8_4_content_btn_share": "Share",
  "8_4_content_btn_duplicate": "Duplicate",
  "8_4_content_btn_delete": "Delete",
  "8_5_note_month": "Note: In <strong>Month view</strong> the calendar will apply one month range from the selected day.",
  "8_5_note_week": "Note: In <strong>Weekly view</strong> the calendar will apply one week range from the selected day.",
  "8_5_note_day": "Note: In <strong>Daily view</strong> the date range selection is disabled.",
  "8_5_btn_today": "Today",
  "8_5_btn_this_week": "This week",
  "8_5_btn_last_week": "Last week",
  "8_5_btn_this_month": "This month",
  "8_5_btn_last_month": "Last month",
  "8_5_btn_this_year": "This year",
  "8_5_btn_last_year": "Last year",
  "8_5_btn_apply": "Apply",
  "8_5_btn_me": "Me",
  "8_5_tooltip_me": "Show content I own or where I have an assigned task.",
  "8_5_btn_all": "All",
  "8_5_tooltip_all": "Show all cards.",
  "8_5_tooltip_ideas_show": "Show cards with ideas.",
  "8_5_tooltip_ideas_hide": "Hide cards with ideas.",
  "8_5_btn_ideas": "Ideas",
  "8_5_btn_month": "Month",
  "8_5_btn_week": "Week",
  "8_5_btn_day": "Day",
  "8_5_btn_list": "List",
  "8_5_name_filer": "Filter",
  "8_5_active_filters": "ACTIVE FILTERS",
  "8_5_clear_filters": "Clear all filters",
  "8_5_add_filter": "Add filter",
  "8_5_placeholder_search": "Search",
  "8_5_filter_item_name": "Name",
  "8_5_filter_item_state": "Status",
  "8_5_filter_item_channels": "Channel",
  "8_5_filter_item_users": "Owner",
  "8_5_filter_item_tags": "Tag",
  "8_5_filter_item_products": "Product",
  "8_5_filter_item_personas": "Persona",
  "8_5_filter_item_stdcs": "STDC",
  "8_5_group_by_item_state": "Status",
  "8_5_group_by_item_owner": "Owner",
  "8_5_group_by_item_channel": "Channel",
  "8_5_group_by_item_date": "Publish date",
  "8_5_group_by_item_tags": "Tag",
  "8_5_group_by_item_products": "Product",
  "8_5_group_by_item_personas": "Persona",
  "8_5_group_by_item_stdc": "STDC",
  "8_5_group_by_title": "Group by",
  "8_5_group_by_tooltip": "Group ideas by",
  "8_5_group_by_default": "default",
  "9_1_title": "Create content",
  "9_1_label_name": "Name",
  "9_1_placeholder_name": "Name this content",
  "9_1_label_channel": "Channel",
  "9_1_placeholder_channel": "Choose the channel",
  "9_1_label_description": "Description",
  "9_1_placeholder_description": "Enter a brief for the content creator (copywriter, graphic designer).",
  "9_1_resources": "Resource links",
  "9_1_details": "Details",
  "9_1_label_keywords": "Keywords",
  "9_1_placeholder_keywords": "Enter the keywords",
  "9_1_label_tags": "Tag (max 5)",
  "9_1_name_tag": "Tag",
  "9_1_placeholder_tags": "Choose the tags",
  "9_1_label_product": "Product (max 5)",
  "9_1_name_product": "Product",
  "9_1_placeholder_product": "Choose the products",
  "9_1_label_persona": "Persona (max 5)",
  "9_1_name_persona": "Persona",
  "9_1_placeholder_persona": "Choose the personas",
  "9_1_label_stdc": "STDC",
  "9_1_name_stdc": "STDC",
  "9_1_placeholder_stdc": "Choose the phase",
  "9_1_content": "Content",
  "9_1_label_final_text": "Final text",
  "9_1_placeholder_final_text": "Enter the final text (e.g. social media post).",
  "9_1_content_links": "Content links",
  "9_1_content_review": "Content review",
  "9_1_label_review_text": "Written review",
  "9_1_placeholder_review_text": "Take a moment to evaluate this content. How was its performance?",
  "9_1_check_duplicate": "Create a duplicate after saving.",
  "9_1_btn_save": "Save content",
  "9_1_btn_cancel": "Cancel",
  "9_1_toaster_error_title_create": "Error",
  "9_2_select_company": "Select company",
  "9_2_select_user": "Select user",
  "9_2_back": "Back",
  "9_2_edit": "Edit",
  "9_2_search": "Search",
  "9_2_link_edit_mode": "Edit mode",
  "9_2_search_no_result_else": "Try another tag or create a new one.",
  "9_2_placeholder_new_tag": "New tag",
  "10_1_btn_name_save": "Save",
  "10_1_btn_name_cancel": "Cancel",
  "10_1_title_resources": "Resources",
  "10_1_title_content": "Content",
  "10_1_title_final_text": "Final text",
  "10_1_btn_add_text": "Add text",
  "10_1_btn_final_text_save": "Save",
  "10_1_btn_final_text_cancel": "Cancel",
  "10_1_title_content_links": "Content links",
  "10_1_title_checklist": "Checklist",
  "10_1_title_comments": "Comments",
  "10_1_name_channel": "Channel",
  "10_1_placeholder_channel": "Select channel",
  "10_1_title_details": "Details",
  "10_1_label_keywords": "Keywords",
  "10_1_placeholder_keyword": "Enter keywords",
  "10_1_label_product": "Product",
  "10_1_name_product": "Product",
  "10_1_placeholder_product": "Enter product",
  "10_1_label_persona": "Persona",
  "10_1_name_persona": "Persona",
  "10_1_label_stdc": "STDC",
  "10_1_name_stdc": "STDC",
  "10_1_placeholder_stdc": "Enter STDC",
  "10_1_label_tag": "Tag",
  "10_1_name_tag": "Tag",
  "10_1_placeholder_tag": "Enter tag",
  "10_3_btn_share": "Share",
  "10_3_btn_duplicate": "Duplicate",
  "10_3_btn_delete": "Delete",
  "10_1_resource_save": "Save",
  "10_1_resource_cancel": "Cancel",
  "10_1_resource_add": "Add resource",
  "10_1_toaster_success_title_resources_updated": "Updated",
  "10_1_toaster_success_description_resources_updated": "You updated the list of resources.",
  "10_1_btn_create_comment": "Write a comment...",
  "10_1_btn_comment_save": "Save",
  "10_1_btn_comment_cancel": "Cancel",
  "10_1_btn_checklist_add": "Add an item",
  "10_1_toaster_success_title_comment_deleted": "Deleted",
  "10_1_toaster_success_description_comment_deleted": "You deleted the comment.",
  "10_1_toaster_success_title_comment_updated": "Updated",
  "10_1_toaster_success_description_comment_updated": "You updated the comment.",
  "10_1_toaster_success_title_comment_create": "Created",
  "10_1_toaster_success_description_comment_create": "You created the comment.",
  "9_2_toaster_success_title_tag_deleted": "Deleted",
  "9_2_toaster_success_description_tag_deleted": "You deleted the tag.",
  "9_2_toaster_error_title_tag_delete": "Error",
  "9_2_toaster_error_description_tag_delete": "You can't delete this tag.",
  "9_2_toaster_success_title_tag_created": "Created",
  "9_2_toaster_success_description_tag_created": "You created the tag.",
  "9_2_toaster_error_title_tag_create": "Error",
  "9_2_toaster_error_description_tag_create": "You can't create this tag.",
  "9_2_toaster_success_title_tag_updated": "Updated",
  "9_2_toaster_success_description_tag_updated": "You updated the tag.",
  "9_2_toaster_error_title_tag_updated": "Error",
  "9_2_toaster_error_description_tag_updated": "You can't update this tag.",
  "9_2_toaster_success_title_product_updated": "Updated",
  "9_2_toaster_success_description_product_updated": "You updated the product.",
  "9_2_toaster_error_title_product_updated": "Error",
  "9_2_toaster_error_description_product_updated": "You can't update this product.",
  "9_2_toaster_success_title_product_deleted": "Deleted",
  "9_2_toaster_success_description_product_deleted": "You deleted the product.",
  "9_2_toaster_error_title_product_delete": "Error",
  "9_2_toaster_error_description_product_delete": "You can't delete this product.",
  "9_2_toaster_success_title_product_created": "Created",
  "9_2_toaster_success_description_product_created": "You created the product.",
  "9_2_toaster_error_title_product_create": "Error",
  "9_2_toaster_error_description_product_create": "You can't create this product.",
  "9_2_toaster_success_title_persona_created": "Created",
  "9_2_toaster_success_description_persona_created": "You created the persona.",
  "9_2_toaster_error_title_persona_create": "Error",
  "9_2_toaster_error_description_persona_create": "You can't create this persona.",
  "9_2_toaster_success_title_persona_deleted": "Deleted",
  "9_2_toaster_success_description_persona_deleted": "You deleted the persona.",
  "9_2_toaster_error_title_persona_delete": "Error",
  "9_2_toaster_error_description_persona_delete": "You can't delete this persona.",
  "9_2_toaster_success_title_persona_updated": "Updated",
  "9_2_toaster_success_description_persona_updated": "You updated the persona.",
  "9_2_toaster_error_title_persona_updated": "Error",
  "9_2_toaster_error_description_persona_updated": "You can't update this persona.",
  "9_2_toaster_success_title_channel_created": "Created",
  "9_2_toaster_success_description_channel_created": "You created the channel.",
  "9_2_toaster_error_title_channel_create": "Error",
  "9_2_toaster_error_description_channel_create": "You can't create this channel.",
  "9_2_toaster_success_title_channel_deleted": "Deleted",
  "9_2_toaster_success_description_channel_deleted": "You deleted the channel.",
  "9_2_toaster_error_title_channel_delete": "Error",
  "9_2_toaster_error_description_channel_delete": "You can't delete this channel.",
  "9_2_toaster_success_title_channel_updated": "Updated",
  "9_2_toaster_success_description_channel_updated": "You updated the channel.",
  "9_2_toaster_error_title_channel_updated": "Error",
  "9_2_toaster_error_description_channel_updated": "You can't update this channel.",
  "9_1_publish_date": "Publish date",
  "9_1_publish_time": "Publish time",
  "9_1_no_persons": "No users",
  "9_1_label_owner": "Owner",
  "10_1_checklist_item_btn_save": "Save",
  "10_1_checklist_item_btn_cancel": "Cancel",
  "10_1_checklist_item_btn_select_date": "Select date",
  "10_1_title_description": "Description",
  "10_1_btn_checklist_assign": "Assign",
  "10_1_btn_edit": "Edit",
  "10_1_btn_delete": "Delete",
  "10_1_btn_save": "Save",
  "10_1_view_more_comments": "View more comments",
  "6_3_placeholder_search": "Search",
  "7_1_image_default_name": "Image",
  "1_1_placeholder_email": "superaddress@email.com",
  "2_1_placeholder_name": "Jason Bourne",
  "2_1_placeholder_email": "superaddress@email.com",
  "3_1_info_description": "Making a mind map helps you visually organize information. You’ll better see the whole context and identify the basic needs and requirements of the core idea. First, write your idea in the middle of a blank page. Then, add any associations, words, pictures, and thoughts related to that idea.",
  "3_4_title": "Password successfully changed!",
  "3_4_description": "You changed your password. Use it to log in to your account.",
  "3_4_btn_sign_in": "Log in",
  "10_1_title_content_review": "Content review",
  "TODO": "To Do",
  "IN_PROGRESS": "In Progress",
  "IN_REVIEW": "In Review",
  "APPROVED": "Approved",
  "PUBLISHED": "Published",
  "IDEA": "Idea",
  "6_1_image_default_name": "Company logo",
  "6_3_image_default_name": "Company logo",
  "4_2_image_default_name": "Company logo",
  "10_1_resource_name_placeholder": "Resource name",
  "6_1_btn_add_company": "Add/Join new company",
  "10_1_btn_cancel": "Cancel",
  "10_1_toaster_title_copied_to_clipboard": "Copied",
  "10_1_toaster_description_copied_to_clipboard": "You copied the link.",
  "8_1_4_modal_delete_title": "Do you really want to delete",
  "8_1_4_modal_delete_description": "This content and all its information will be permanently deleted.  Once deleted, it cannot be restored.",
  "8_1_4_modal_btn_delete": "Delete",
  "8_1_4_modal_btn_go_back": "Go back",
  "8_1_4_toaster_error_title_delete_content": "Error",
  "8_1_4_toaster_success_title_deleted_content": "Deleted",
  "9_2_clear": "Clear",
  "10_1_toaster_warning_title_finish_resources_edit": "Warning",
  "10_1_toaster_warning_description_finish_resources_edit": "Make sure to save the changes you made.",
  "10_1_toaster_success_title_description_updated": "Updated",
  "10_1_toaster_success_description_description_updated": "You updated the description.",
  "10_1_toaster_success_title_title_updated": "Updated",
  "10_1_toaster_success_description_title_updated": "You updated the title.",
  "10_1_toaster_success_title_final_updated": "Updated",
  "10_1_toaster_success_description_final_updated": "You updated the final text.",
  "10_1_toaster_success_title_content_review_updated": "Updated",
  "10_1_toaster_success_description_content_review_updated": "You updated the content review.",
  "10_1_toaster_success_title_stdc_updated": "Updated",
  "10_1_toaster_success_description_stdc_updated": "You updated the STDC phase.",
  "10_1_toaster_success_title_tags_updated": "Updated",
  "10_1_toaster_success_description_tags_updated": "You updated the tags.",
  "10_1_toaster_success_title_state_updated": "Updated",
  "10_1_toaster_success_description_state_updated": "You updated the status.",
  "10_1_toaster_success_title_personas_updated": "Updated",
  "10_1_toaster_success_description_personas_updated": "You updated the personas.",
  "10_1_toaster_success_title_user_updated": "Updated",
  "10_1_toaster_success_description_user_updated": "You updated the user.",
  "10_1_toaster_success_title_products_updated": "Updated",
  "10_1_toaster_success_description_products_updated": "You updated the products.",
  "10_1_toaster_success_title_keywords_updated": "Updated",
  "10_1_toaster_success_title_channel_updated": "Updated",
  "10_1_toaster_success_description_channel_updated": "You updated the channels.",
  "10_1_toaster_success_title_checklist_deleted": "Deleted",
  "10_1_toaster_success_description_checklist_deleted": "You deleted the checklist item.",
  "10_1_toaster_success_title_content_links_updated": "Updated",
  "10_1_toaster_success_description_content_links_updated": "You updated the links.",
  "10_1_toaster_success_title_content_date_updated": "Updated",
  "10_1_toaster_success_description_content_date_updated": "You updated the date.",
  "10_1_toaster_success_title_content_time_updated": "Updated",
  "10_1_toaster_success_description_content_time_updated": "You updated the time.",
  "9_2_placeholder_new_product": "New product",
  "9_2_placeholder_new_persona": "New persona",
  "10_1_date_picker_not_set": "Set the date",
  "10_1_time_picker_not_set": "Set the time",
  "share_content_title_copied_to_clipboard": "Copied",
  "share_content_description_copied_to_clipboard": "You copied the link to this content.",
  "8_1_3_notification_title": "Notifications",
  "8_1_3_notification_btn_show_this_company": "This company",
  "8_1_3_notification_btn_show_all_companies": "All companies",
  "8_1_3_notification_btn_show_only_unread": "Show unread only",
  "8_1_3_notification_subtitle_today": "Today",
  "8_1_3_notification_btn_mark_all_as_read": "Mark all as read",
  "8_1_3_notification_subtitle_older": "Older",
  "8_1_3_mark_as_read": "Mark as read",
  "8_1_3_mark_as_unread": "Mark as unread",
  "9_1_toaster_success_title_content_created_and_duplicated": "You created and duplicated the content.",
  "9_1_toaster_success_title_content_created": "You created the content.",
  "10_1_toaster_success_title_final_text_copied": "You copied the final text.",
  "10_1_toaster_error_title_final_text_copied": "You can't copy the final text.",
  "10_1_checklist_placeholder_new_task": "New task",
  "10_1_title_add_description": "Add a description",
  "8_5_all_ideas": "All ideas",
  "8_5_select_no_result": "No matching options",
  "10_1_now": "now",
  "10_1_days": "days",
  "10_1_day": "day",
  "10_1_toaster_error_title_content_date_updated": "Error",
  "10_1_toaster_error_title_content_time_updated": "Error",
  "10_1_toaster_error_title_content_links_updated": "Error",
  "10_1_toaster_error_title_resources_updated": "Error",
  "10_1_toaster_error_title_checklist_deleted": "Error",
  "10_1_toaster_error_title_channel_updated": "Error",
  "10_1_toaster_error_title_keywords_updated": "Error",
  "10_1_toaster_error_title_products_updated": "Error",
  "10_1_toaster_error_title_user_updated": "Error",
  "10_1_toaster_error_title_personas_updated": "Error",
  "10_1_toaster_error_title_state_updated": "Error",
  "10_1_toaster_error_title_tags_updated": "Error",
  "10_1_toaster_error_title_stdc_updated": "Error",
  "10_1_toaster_error_title_content_review_updated": "Error",
  "10_1_toaster_error_title_description_updated": "Error",
  "10_1_toaster_error_title_final_updated": "Error",
  "9_1_toaster_error_title_content_created": "Error",
  "8_1_toaster_error_content_state_update": "Error",
  "8_1_toaster_error_content_update": "Error",
  "8_2_toaster_error_content_state_update": "You can't update the status.",
  "8_2_toaster_error_content_update": "You can't update the content.",
  "8_3_toaster_error_content_state_update": "You can't update the status.",
  "8_3_toaster_error_content_update": "You can't update the content.",
  "8_4_toaster_error_content_delete": "Error",
  "8_4_toaster_error_content_state_update": "You can't update the status.",
  "8_4_toaster_error_content_update": "You can't update the content.",
  "8_2_error_title_load_contents": "Error",
  "8_2_error_description_load_contents": "Check if you have a company selected.",
  "8_3_error_title_load_contents": "Error",
  "8_3_error_description_load_contents": "Check if you have a company selected.",
  "8_4_error_title_load_contents": "Error",
  "8_4_error_description_load_contents": "Check if you have a company selected.",
  "8_3_idea_task_count": "Tasks",
  "8_3_idea_task_no_checklist": "No checklist",
  "9_2_none": "none",
  "10_1_subtitle_content_review": "Written review",
  "10_1_subtitle_checklist": "Tasks",
  "10_1_toaster_error_checklist_item_delete": "Error",
  "10_1_toaster_success_checklist_item_delete": "Deleted",
  "10_1_toaster_error_checklist_item_update": "You can't update this checklist.",
  "10_1_toaster_success_checklist_item_update": "Updated",
  "10_1_toaster_error_title_comment_create": "You can't create the comment.",
  "10_1_toaster_error_title_comment_delete": "You can't delete the comment.",
  "10_1_toaster_error_title_comment_update": "You can't update the comment.",
  "10_1_toaster_success_description_keywords_updated": "You updated the keywords.",
  "9_2_placeholder_new_channel": "New channel",
  "9_2_toaster_error_title_resources_missing_name": "Error",
  "9_2_toaster_error_description_resources_missing_name": "You must name the resource.",
  "9_2_toaster_error_title_resources_missing_link": "Error",
  "9_2_toaster_error_description_resources_missing_link": "You must add the link.",
  "10_3_toaster_error_title_checklist_name_empty": "Error",
  "10_3_toaster_error_description_checklist_name_empty": "You must name the task.",
  "10_1_toaster_error_title_comment_name_empty": "Error",
  "9_2_product_none": "none",
  "9_2_persona_none": "none",
  "time_before": "",
  "time_after": " ago",
  "5_1_menu_dashboard": "Statistics",
  "7_1_toaster_success_title_profile_updated": "Updated",
  "7_1_toaster_success_description_profile_updated": "You updated the profile.",
  "7_1_toaster_error_title_name_is_short": "Error",
  "7_1_toaster_error_description_name_is_short": "Choose a longer name. The minimum is 3 characters.",
  "7_1_toaster_error_title_name_is_long": "Error",
  "7_1_toaster_error_description_name_is_long": "Choose a shorter name. The maximum is 50 characters.",
  "7_1_toaster_error_title_profile_updated": "Error",
  "7_1_toaster_error_title_current_password_required": "Error",
  "7_1_toaster_error_description_current_password_required": "Write your current password.",
  "7_1_toaster_error_title_new_password_required": "Error",
  "7_1_toaster_error_description_new_password_required": "Write a new password.",
  "7_1_toaster_error_title_password_mismatch": "Error",
  "7_1_toaster_error_description_password_mismatch": "New and confirmed passwords don't match.",
  "7_1_toaster_success_title_password_updated": "Updated",
  "7_1_toaster_success_description_password_updated": "You updated the password.",
  "7_1_toaster_error_title_password_updated": "Error",
  "13_title_channels": "Channels",
  "13_description_channels": "What channels you use the most. Are these the right places to approach your customers, or could you use a bit more diversification?",
  "13_title_products": "Products",
  "13_description_products": "What products you talk about the most in your content. Are you happy about this distribution, or do you keep forgetting some of them?",
  "13_title_personas": "Personas",
  "13_description_personas": "What personas you talk to most often. Is it possible that someone else would also like to hear your message regularly?",
  "13_title_stdc": "STDC",
  "13_description_stdc": "What business phase you give the most attention to. Should you focus more on customer acquisition or your customer care?",
  "13_title_tags": "Tags",
  "13_description_tags": "What tags you assign to your content the most. Is the distribution equal or does a single tag stand out and if so, why?",
  "13_title_tasks": "Tasks",
  "13_description_tasks": "How busy are your team members. Make sure you distribute the work equally so the whole team can perform as a unit.",
  "13_title_tips": "Did you know?",
  "13_description_tips": "A proper content sorting will help you to identify areas that could use a little more love.",
  "7_1_toaster_success_title_user_deleted": "Deleted",
  "7_1_toaster_success_description_user_deleted": "You deleted the profile.",
  "7_1_toaster_error_title_user_deleted": "Error",
  "6_3_toaster_error_description_invalid_name": "Name the company (min length is 3, max length is 50 characters).",
  "6_3_1_toaster_success_title_user_invited": "Sent",
  "6_3_1_toaster_success_description_user_invited": "You sent the email invitation.",
  "6_3_2_toaster_error_title_user_invited": "Error",
  "4_3_1_title": "Where to find company ID?",
  "4_3_1_description_step_1": "Select Company in the main menu.",
  "4_3_1_description_step_2": "Scroll to the Invite more team members section at the bottom.",
  "4_3_1_description_step_3": "Copy the company ID.",
  "4_3_1_btn_confirm": "Got it",
  "6_3_label_timezone": "Time zone",
  "6_3_placeholder_timezone": "Select company's time zone",
  "6_3_toaster_error_title_invalid_timezone": "Error",
  "6_3_toaster_error_description_invalid_timezone": "Select your time zone.",
  "10_1_toaster_success_description_checklist_item_update": "You have updated the checklist.",
  "10_1_toaster_success_description_checklist_item_delete": "You have deleted a task from the checklist.",
  "role_admin": "ADMIN",
  "role_approver": "APPROVER",
  "6_3_tooltip_team": "Admins can invite users, create content, add changes, do everything. Approvers can only change content status, and they can't see comments nor checklists.",
  "8_1_1_drop_here": "DROP HERE",
  "7_1_2_email": "Email",
  "10_1_toaster_error_description_comment_name_empty": "You must write a comment.",
  "10_1_toaster_error_title_title_updated": "Updated",
  "6_3_toaster_error_description_invalid_description": "You must write a description.",
  "6_3_toaster_error_title_invalid_description": "Error",
  "6_3_toaster_error_title_invalid_name": "Error",
  "9_2_placeholder_new_stdc": "STDC",
  "January": "January",
  "February": "February",
  "March": "March",
  "April": "April",
  "May": "May",
  "June": "June",
  "July": "July",
  "August": "August",
  "September": "September",
  "October": "October",
  "November": "November",
  "December": "December",
  "9_1_toaster_success_description_content_created_and_duplicated": "You created and duplicated the content.",
  "9_1_toaster_success_description_content_created": "You created the content.",
  "8_1_4_toaster_success_description_deleted_content": "You deleted the content.",
  "10_1_toaster_success_description_final_text_copied": "You copied the final text.",
  "8_1_add_content": "Add content",
  "9_1_modal_close_content_title": "Close content",
  "9_1_modal_close_content_description": "Do you really want to close this content? All edits in those fileds will be lost:",
  "9_1_modal_close_content_btn_close": "Close content",
  "9_1_modal_close_content_btn_cancel": "Don't close",
  "9_1_toaster_error_description_create": "Error",
  "8_1_4_toaster_error_description_delete_content": "You can't delete this content.",
  "9_2_toaster_error_title_maximum_items_count": "Error",
  "9_2_toaster_error_description_maximum_items_count": "Maximum items count:",
  "8_1_toaster_error_title_delete_content": "Error",
  "8_1_toaster_error_description_delete_content": "You can't delete this content.",
  "8_1_toaster_success_title_deleted_content": "Deleted",
  "8_1_toaster_success_description_deleted_content": "You deleted the content.",
  "8_3_toaster_error_title_content_delete": "Error",
  "8_3_toaster_error_description_content_delete": "You can't delete this content.",
  "8_3_toaster_success_title_deleted_content": "Deleted",
  "8_3_toaster_success_description_deleted_content": "You deleted the content.",
  "8_2_toaster_error_title_content_delete": "Error",
  "8_2_toaster_error_description_content_delete": "You can't delete this content.",
  "8_2_toaster_success_title_deleted_content": "Deleted",
  "8_2_toaster_success_description_deleted_content": "You deleted the content.",
  "8_4_toaster_success_title_content_updated": "Updated",
  "8_4_toaster_success_description_content_updated": "You updated the content.",
  "8_4_toaster_success_title_deleted_content": "Deleted",
  "8_4_toaster_success_description_deleted_content": "You deleted the content.",
  "10_1_toaster_error_title_title_is_empty": "Error",
  "10_1_toaster_error_description_title_is_empty": "Title can't be empty.",
  "8_5_filter_item_rating": "Review",
  "13_title_review": "Reviews",
  "13_description_review": "How did your content do with your audience? Check the evaluation of your published content pieces.",
  "7_1_1_label_company": "Your company name",
  "7_1_1_placeholder_company": "Type in your company name to confirm",
  "9_2_item_search_no_result_title": "We didn't find anything",
  "9_2_item_search_no_result_desc": "Try another name or create a new item in",
  "9_2_item_search_no_result_else": "Try another name or write a new item and create it with the plus button.",
  "3_1_info_title": "while you're checking your email... Did you know?",
  "9_2_toaster_error_title_resources_invalid_link": "Error",
  "9_2_toaster_error_description_resources_invalid_link": "Please, make sure the link is in the right format (e.g., www.link.com).",
  "6_3_2_toaster_error_title_user_cannot_update_role": "Error",
  "6_3_2_toaster_error_description_user_cannot_update_role": "Cannot update role",
  "6_3_2_toaster_error_title_user_cannot_delete": "Error",
  "6_3_2_toaster_error_description_user_cannot_delete": "Invite new team members to be able to delete yourself from this company",
  "8_1_3_notification_content_comment_mentioned": "has mentoined you in comment",
  "8_1_3_toaster_success_title_notification_all_read": "Updated",
  "8_1_3_toaster_success_description_notification_all_read": "All notifications are read.",
  "8_1_3_toaster_error_title_notification_all_read": "Error",
  "8_1_3_toaster_success_title_notification_company_read": "Updated",
  "8_1_3_toaster_success_description_notification_company_read": "All notifications for this company are read.",
  "8_1_3_toaster_error_title_notification_company_read": "Error",
  "8_1_3_toaster_success_title_notification_read": "Updated",
  "8_1_3_toaster_success_description_notification_read": "Notification marked as read.",
  "8_1_3_toaster_success_title_notification_unread": "Updated",
  "8_1_3_toaster_success_description_notification_unread": "Notification marked as unread.",
  "8_1_3_toaster_error_title_notification_read": "Error",
  "8_1_3_toaster_error_title_notification_unread": "Error",
  "9_2_stdc_search_no_result_title": "We can't find this STDC phase.",
  "3_3_1_agree_submit_form": "By sending this form, you agree to our",
  "3_3_1_link_terms_condition": "Terms and Conditions",
  "3_3_1_and": "and",
  "3_3_1_link_privacy_policy": "Privacy Policy.",
  "10_1_link_add": "Add link",
  "9_1_link_add": "Add link",
  "1_1_4_back_to_sign_in": "Back to Sign In",
  "1_1_4_title": "Verify your email",
  "1_1_4_description": "We have sent a verification link to your email %email%. Please check your inbox and click the received link to verify your email.",
  "1_1_4_btn_resend": "Resend verification link",
  "1_1_4_trouble_verify": "Having trouble verifying your email?",
  "1_1_4_link_contact_us": "Contact us",
  "1_1_4_toaster_success_title_resend_verification_email": "Updated",
  "1_1_4_toaster_success_description_resend_verification_email": "Verification link was resend to your email address.",
  "1_1_4_toaster_error_title_resend_verification_email": "Error",
  "1_2_toaster_error_title_not_confirmed": "Error",
  "1_2_toaster_error_description_not_confirmed": "This email address is not verified.",
  "1_2_toaster_error_title_account_locked": "Error",
  "1_2_toaster_error_description_account_locked": "This account is locked.",
  "1_2_toaster_error_title_unauthorized": "Error",
  "1_2_toaster_error_description_unauthorized": "Unable to log in.",
  "1_2_title_verify_email": "Verifying email address",
  "1_2_description_verify_email": "We are verifying your email address.",
  "1_2_toaster_error_title_invalid_token": "Error",
  "1_2_toaster_error_description_invalid_token": "The time for this action has run out.",
  "1_2_toaster_success_title_account_verified": "Verified",
  "1_2_toaster_success_description_account_verified": "Account has been verified.",
  "1_2_toaster_error_title_account_verify": "Error",
  "2_2_toaster_error_title_register_conflict": "Error",
  "2_2_toaster_error_description_register_conflict": "Please use different name or email address.",
  "toaster_info_title_confirm_delete": "Confirm deletion",
  "toaster_info_description_confirm_delete": "Click the delete button to confirm.",
  "3_1_toaster_error_title_invalid_reset_password": "Error",
  "3_1_toaster_error_description_invalid_reset_password": "The password reset can't be submitted.",
  "1_2_toaster_error_title_account_verified": "Error",
  "1_2_toaster_error_description_account_verified": "The time for this action has run out.",
  "3_3_toaster_error_title_password_mismatch": "Error",
  "3_3_toaster_error_description_password_mismatch": "Passwords don't match.",
  "3_3_toaster_error_title_change_password": "Error",
  "3_3_toaster_error_description_change_password": "New password can't be set.",
  "3_3_toaster_success_title_change_password": "Updated",
  "3_3_toaster_success_description_change_password": "New password is set.",
  "4_1_link_select_all": "Select all",
  "4_1_btn_join_selected_companies": "Join the selected Company",
  "4_1_link_how_users_work_with_companies": "How do our users work with Companies?",
  "4_1_invited_by": "Invited by",
  "4_1_2_title": "How do our users work with Companies?",
  "4_1_2_examples": "Here are few examples:",
  "4_1_2_marketing_agency_title": "Marketing Agency",
  "4_1_2_marketing_agency_description": "each client has its own space: 3 clients = 3 Companies",
  "4_1_2_international_company_title": "International company",
  "4_1_2_international_company_description": "each market has its own space: 3 markets / languages / countries = 3 Companies",
  "4_1_2_enterpreneur_title": "Enterpreneur",
  "4_1_2_enterpreneur_description": "each project has its space: 3 projects e.g. e-shop with books, consultation business, and house renting = 3 companies",
  "4_1_2_influencer_title": "Influencer",
  "4_1_2_influencer_description": "their own brand: 1 Company",
  "4_1_2_btn_got_it": "Got It",
  "4_1_title_join": "...or join one?",
  "4_1_description_join": "Awesome, there is a project waiting for you! Don't let your team ponder and join them now.",
  "6_3_2_toaster_success_title_invite_deleted": "Deleted",
  "6_3_2_toaster_success_description_invite_deleted": "Invitation is deleted.",
  "6_3_2_toaster_error_title_invite_delete": "Error",
  "6_3_2_toaster_success_title_invite_resend": "Resend",
  "6_3_2_toaster_success_description_invite_resend": "Invitation has been resent.",
  "6_3_2_toaster_error_title_invite_resend": "Error",
  "6_1_invited_by": "Invited by",
  "6_1_invited": "Invited",
  "6_1_tooltip_resend_invitation": "Resend Invitation",
  "4_1_link_deselect_all": "Deselect all",
  "6_1_toaster_success_title_user_accept": "Accepted",
  "6_1_toaster_success_description_user_accept": "You have successfully joined a company.",
  "6_1_toaster_error_title_user_accept": "Error",
  "1_2_title_invite_company": "Joining company",
  "1_2_description_invite_company": "It will takes few moments.",
  "1_2_toaster_success_title_company_joined": "Company joined",
  "1_2_toaster_success_description_company_joined": "You have successfully joined company",
  "1_2_toaster_error_title_company_join": "Error",
  "6_1_subtitle_join_no_results": "...or join one?",
  "6_1_description_join_no_results_paragraph_1": "There is no company waiting for you to join right now.",
  "6_1_description_join_no_results_paragraph_2": "If you expect to participate in a project, ask your team to invite you; they can do so easily from the Company page. The dinos will make the invite ready for you here!",
  "8_5_btn_open_in_list_view": "Open in List View",
  "1_2_toaster_error_description_company_join": "This user is not the recipient of the invitation.",
  "1_2_invalid_google_login_title": "Error",
  "1_2_invalid_google_login_text": "Google login failure.",
  "3_1_support_mail_subject": "Reset password",
  "7_1_2_support_mail_subject": "Forgotten password",
  "3_3_support_mail_subject": "Create new password",
  "3_2_support_mail_subject": "Password reset problems",
  "11_1_2_support_mail_subject": "Network issue",
  "11_1_1_support_mail_subject": "Page not found",
  "1_1_4_support_mail_subject": "Verify your e-mail",
  "9_1_is_copy": "Copy",
  "6_3_2_toaster_success_title_role_updated": "Updated",
  "6_3_2_toaster_success_description_role_updated": "Role is updated.",
  "6_3_2_toaster_error_title_role_updated": "Error",
  "8_1_3_notification_content_state_changed": "changed content state",
  "8_1_3_notification_content_added_as_owner": "added you as owner",
  "8_1_3_notification_content_removed_as_owner": "removed you as owner",
  "10_1_tooltip_review_remove": "You can remove your review by clicking selected star",
  "10_1_review_value_none": "None",
  "global_toaster_error_description": "Action went wrong",
  "8_4_empty_title_none": "None",
  "13_title_state": "Contents",
  "13_description_state": "How much content have you created? Take a look at the states.",
  "13_none": "None",
  "8_5_not_rated": "Not rated",
  "8_1_3_notification_btn_show_only_read": "Show all",
  "10_3_toaster_error_title_checklist_already_editing": "Error",
  "10_3_toaster_error_description_checklist_already_editing": "You must complete editing previous task.",
  "10_1_after_publish_date": "After publish date",
  "9_1_toaster_error_title_owner_undefined": "Error",
  "9_1_toaster_error_description_owner_undefined": "Set an active content Owner.",
  "10_1_title_content_not_found": "Content not found",
  "10_1_description_content_not_found": "",
  "10_1_btn_close": "Close",
  "7_1_toaster_success_title_upload_avatar": "Uploaded",
  "7_1_toaster_success_description_upload_avatar": "Profile picture uploaded.",
  "7_1_toaster_error_title_upload_avatar": "Error",
  "7_1_toaster_error_description_upload_avatar": "Try it again or contact us.",
  "7_1_toaster_success_description_deleted_avatar": "Profile picture is deleted.",
  "7_1_toaster_success_title_deleted_avatar": "Deleted",
  "7_1_button_cancel_avatar": "Cancel",
  "7_1_button_save_avatar": "Save",
  "7_1_toaster_success_title_upload_company_logo": "Uploaded",
  "7_1_toaster_success_description_upload_company_logo": "You uploaded the company logo.",
  "7_1_toaster_error_title_upload_company_logo": "Error",
  "7_1_toaster_error_description_upload_company_logo": "Try it again or contact us.",
  "7_1_toaster_success_title_deleted_company_logo": "Deleted",
  "7_1_toaster_success_description_deleted_company_logo": "Logo is deleted.",
  "7_1_button_cancel_company_logo": "Cancel",
  "7_1_button_save_company_logo": "Save",
  "4_2_upload_logo_image": "Delete the current logo or upload a new one (maximum size 1MB, format jpg or png).",
  "4_2_upload_logo_no_image": "Select a company logo (maximum size 1MB, format jpg or png).",
  "4_2_upload_profile_no_image": "Select a profile picture (maximum size 1MB, format jpg or png).",
  "4_2_upload_profile_image": "Delete the current profile picture or upload a new one (maximum size 1MB, format jpg or png).",
  "10_1_title_communication": "Communication",
  "10_1_btn_name_comments": "Comments",
  "10_1_btn_name_feedback": "Feedback",
  "10_1_btn_create_feedback": "Write a feedback...",
  "10_1_btn_feedback_save": "Save",
  "10_1_btn_feedback_cancel": "Cancel",
  "10_1_view_more_feedbacks": "View more feedbacks",
  "10_1_toaster_error_title_feedback_name_empty": "Error",
  "10_1_toaster_error_description_feedback_name_empty": "You must write a feedback.",
  "10_1_toaster_success_title_feedback_create": "Created",
  "10_1_toaster_success_description_feedback_create": "You created the feedback.",
  "10_1_toaster_error_title_feedback_create": "You can't create the feedback.",
  "10_1_toaster_error_title_feedback_update": "You can't update the feedback.",
  "10_1_toaster_success_title_feedback_deleted": "Deleted",
  "10_1_toaster_success_description_feedback_deleted": "You deleted the feedback.",
  "10_1_toaster_error_title_feedback_delete": "You can't delete the feedback.",
  "Team": "Team",
  "8_5_group_by_item_ideas_date": "Creation date",
  "8_4_1_1_title": "Create new ideas!",
  "8_4_1_1_description": "You have no ideas for new content stored here yet. Create some for the times of need! Just add content and leave it in the Idea state.",
  "8_4_1_1_btn_add_content": "Add content",
  "5_3_3_title": "Give us a feedback",
  "5_3_3_report_bug": "I want to report a bug",
  "5_3_3_report_suggestion": "I have suggestion",
  "5_3_3_report_like": "I like something in the app",
  "5_3_3_report_dislike": "I don't like something",
  "5_3_3_btn_back": "Go Back",
  "5_3_3_btn_submit": "Submit",
  "5_3_3_label_message": "Your message",
  "5_3_3_textarea_placeholder": "Start typing...",
  "2_1_label_first_name": "First Name",
  "2_1_placeholder_first_name": "T-Rex",
  "2_1_label_last_name": "Last Name",
  "2_1_placeholder_last_name": "Creatorylus",
  "2_1_lang_czech": "Czech",
  "2_1_lang_english": "English",
  "2_1_language": "Email language",
  "2_1_toaster_error_title_first_name_is_short": "Error",
  "2_1_toaster_error_description_first_name_is_short": "Choose a longer first name. The minimum is 3 characters.",
  "2_1_toaster_error_title_first_name_is_long": "Error",
  "2_1_toaster_error_description_first_name_is_long": "Choose a shorter first name. The maximum is 50 characters.",
  "2_1_toaster_error_title_last_name_is_short": "Error",
  "2_1_toaster_error_description_last_name_is_short": "Choose a longer last name. The minimum is 3 characters.",
  "2_1_toaster_error_title_last_name_is_long": "Error",
  "2_1_toaster_error_description_last_name_is_long": "Choose a shorter last name. The maximum is 50 characters.",
  "7_1_label_first_name": "First Name",
  "7_1_placeholder_first_name": "T-Rex",
  "7_1_label_last_name": "Last Name",
  "7_1_placeholder_last_name": "Creatorylus",
  "7_1_toaster_error_title_first_name_is_short": "Error",
  "7_1_toaster_error_description_first_name_is_short": "Choose a longer first name. The minimum is 3 characters.",
  "7_1_toaster_error_title_first_name_is_long": "Error",
  "7_1_toaster_error_description_first_name_is_long": "Choose a shorter first name. The maximum is 50 characters.",
  "7_1_toaster_error_title_last_name_is_short": "Error",
  "7_1_toaster_error_description_last_name_is_short": "Choose a longer last name. The minimum is 3 characters.",
  "7_1_toaster_error_title_last_name_is_long": "Error",
  "7_1_toaster_error_description_last_name_is_long": "Choose a shorter last name. The maximum is 50 characters.",
  "6_3_company_active": "This company is active",
  "6_3_company_active_until": "until",
  "6_3_contact_us": "Contact us",
  "6_3_company_billing_info": "Request for extending",
  "6_5_btn_open_billing": "Request a subscription",
  "6_5_title": "The company is not active",
  "6_5_description": "To continue working with this company, pay a subscription for the next period.",
  "6_5_information": "This company's data will disappear within <strong>90</strong> days from the subscription expiration date.",
  "5_3_3_toaster_success_title_feedback_send": "Sent",
  "5_3_3_toaster_success_description_feedback_send": "Thank you for your feedback.",
  "5_3_3_toaster_error_title_feedback_send": "Error",
  "5_3_3_toaster_error_description_feedback_send": "Unfortunatelly, your feedback could not be sent. Please, try again later",
  "6_5_email_subject_request_subscription": "Request a subscription",
  "6_5_email_body_request_subscription": "Company: {companyName}, {companyId}\nSend me an invoice for subscription: YEARLY / MONTHLY \n\nPromo code:\nBilling info: \n- Company name \n- Address (street, city, postal code, country) \n- ID \n- TID (VAT no.)",
  "6_2_select_subscription": "Select subscription",
  "6_3_invite_tooltip": "Admins can invite users, create content, add changes, do everything. Approvers can only change content status, and they can't see comments nor checklists.",
  "6_3_no_members": "No members found",
  "6_3_no_invitations": "No invitations found",
  "6_3_roles_and_permissions": "Roles and permission",
  "6_3_tab_title_members": "Members",
  "6_3_tab_title_invited": "Invited",
  "8_5_btn_print_page": "Print",
  "8_1_3_notification_content_todo_unassigned": "uanssigned you from task",
  "8_1_3_notification_content_todo_assigned": "assigned you a task",
  "8_1_3_notification_content_feedback_mentoined": "mentioned you in feedback",
  "9_1_modal_close_content_is_open_title": "Title",
  "9_1_modal_close_content_is_open_description": "Description",
  "9_1_modal_close_content_is_open_final_text": "Final text",
  "9_1_modal_close_content_is_open_content_review": "Content review",
  "9_1_modal_close_content_is_open_resources": "Resources",
  "9_1_modal_close_content_is_open_content_link": "Content links",
  "9_1_modal_close_content_is_open_checklist": "Checklist",
  "9_1_modal_close_content_is_open_feedback": "Feedback",
  "9_1_modal_close_content_is_open_comments": "Comments",
  "8_3_2_suggestion_btn_name": "Add Content",
  "6_3_company_billing_info_body": "Company: {companyName}, {companyId} \n\nSend me an invoice for subscription: Solo Dino / Triassic Team / Jurassic Crew / Content T-Rex \nI am interested in this period: YEARLY / MONTHLY \n\nPromo code: \nBilling info: \n- Company name \n- Address (street, city, postal code, country) \n- ID \n- TID (VAT no.)",
  "10_1_btn_generate_ai_text": "Generate text with AI",
  "10_1_tooltip_generate_ai_text": "Text is generated based on the content name.",
  "10_1_toaster_title_start_generating_final_text_ai": "Generating text",
  "10_1_toaster_description_start_generating_final_text_ai": "Your text is hatching from the egg of artificial intelligence.",
  "10_1_toaster_title_finish_generating_final_text_ai": "Generating completed",
  "10_1_toaster_description_finish_generating_final_text_ai": "Your text has hatched into a draft dino.",
  "10_1_toaster_title_error_generating_final_text_ai": "Error",
  "10_1_toaster_description_error_generating_final_text_ai": "Something went wrong while generating the text. Try again later.",
  "resolution_warning_description": "Contesaur doesn't work well on a tiny screen. Try using a bigger browser window.",
  "resolution_warning_continue": "Continue anyway",
  "5_1_company_expire_days": "days",
  "5_1_company_expire_day_1": "day",
  "5_1_company_expire_day_2": "days",
  "5_1_company_expire_day_3": "days",
  "5_1_company_expire_day_4": "days",
  "5_1_company_subscription_will_expire_in": "Company subscription will expire in",
  "5_1_renew_subscription": "Renew Subscription",
  "15_0_1_1_menu_company_info": "Company Info",
  "15_0_1_1_menu_people_and_team": "People & Team",
  "15_0_1_1_menu_brand_and_identity": "Brand & Identity",
  "15_0_1_1_menu_lean_canvas": "Lean Canvas",
  "15_0_1_1_menu_tone_of_voice": "Tone of Voice",
  "15_0_1_1_menu_personas_and_audiences": "Marketing personas",
  "15_0_1_1_menu_products_and_services": "Products & Services",
  "15_0_1_1_menu_stdc": "STDC",
  "15_0_1_1_menu_channels": "Channels",
  "15_0_1_1_menu_competitors": "Competitors",
  "15_0_1_1_menu_meeting_notes": "Meeting Notes",
  "15_0_1_1_menu_budgets": "Budgets",
  "15_0_1_1_tooltip_filled_profile": "Company setup",
  "15_0_1_1_tooltip_tasks_complete": "tasks completed",
  "15_0_1_1_tooltip_expand": "Expand",
  "15_0_1_1_tooltip_delete_company": "Delete company",
  "15_0_4_2_title": "Company setup",
  "15_0_4_2_completed": "tasks completed",
  "15_0_4_2_slogan": "Keep up the good work!",
  "15_0_4_2_item_title_fill_up_company_info": "Add company information",
  "15_0_4_2_item_title_add_at_least_one_team_member": "Add one team member",
  "15_0_4_2_item_title_fill_up_brand_and_identity_info": "Add brand and identity resources",
  "15_0_4_2_item_title_add_at_least_one_lean_canvas": "Add a lean canvas",
  "15_0_4_2_item_title_set_up_one_scale_and_do_dont_rule_in_the_tone_of_voice": "Add tone of voice",
  "15_0_4_2_item_title_add_at_least_one_persona": "Add a target persona",
  "15_0_4_2_item_title_add_at_least_one_product": "Add a product",
  "15_0_4_2_item_title_set_up_least_one_stdc_framework": "Set up STDC framework",
  "15_0_4_2_item_title_add_at_least_one_channel": "Add a channel",
  "15_0_4_2_item_title_add_at_least_one_competitor": "Add a competitor",
  "15_3_4_title_color_copied_to_clipboard": "Color code copied",
  "15_3_4_description_color_copied_to_clipboard": "Color code copied to clipboard.",
  "15_3_4_copied": "Copied",
  "15_3_4_color_picker_save": "Save",
  "15_3_4_color_picker_cancel": "Cancel",
  "15_3_4_color_picker_name": "Color name",
  "15_3_4_color_picker_add_color": "Add color",
  "15_3_4_color_picker_description": "Click on the color code to copy it to your clipboard.",
  "15_3_2_main_title": "Brand & Identity",
  "15_3_2_title_brand_manual": "Brand manual",
  "15_3_2_label_communication_manual": "Communication manual",
  "15_3_2_accordion_title_brand_manual": "Additional information",
  "15_3_2_accordion_description_brand_manual": "In this section, you can specify additional information about the brand identity.",
  "15_3_2_label_claim": "Claim",
  "15_3_2_placeholder_claim": "Write the claim of the brand.",
  "15_3_2_label_vision": "Vision",
  "15_3_2_placeholder_vision": "Write the vision of the brand.",
  "15_3_2_label_mission": "Mission",
  "15_3_2_placeholder_mission": "Write the mission of the brand.",
  "15_3_2_label_values": "Values",
  "15_3_2_placeholder_values": "Write the main values of the brand.",
  "15_3_2_label_used_keywords": "Keywords",
  "15_3_2_placeholder_used_keywords": "Write the keywords you associate with the brand, separated by a comma.",
  "15_3_2_label_used_hashtags": "Hashtags",
  "15_3_2_placeholder_used_hashtags": "Write the hashtags you associate with the brand, separated by a comma.",
  "15_3_2_label_other_resources": "Other resources",
  "15_3_2_other_resources_add": "Add resource",
  "15_3_2_title_visual_identity": "Visual identity",
  "15_3_2_label_identity_manual": "Identity manual",
  "15_3_2_label_identity_manual_add": "Add link",
  "15_3_2_accordion_title_visual_identity": "Additional information",
  "15_3_2_accordion_description_visual_identity": "In this section, you can specify additional information about the visual identity.",
  "15_3_2_label_logo": "Logo",
  "15_3_2_placeholder_logo": "Write a link leading to the logo.",
  "15_3_2_label_font": "Font",
  "15_3_2_placeholder_font": "Write a link leading to the font.",
  "15_3_2_label_visual_identity_other_resources": "Other resources",
  "15_3_2_label_visual_identity_other_resources_add": "Add resource",
  "15_3_2_btn_save_changes": "Save changes",
  "15_3_2_label_communication_manual_add": "Add link",
  "15_3_2_title_keywords_copied_to_clipboard": "Keyword copied",
  "15_3_2_description_keywords_copied_to_clipboard": "Keywords copied to clipboard.",
  "15_3_2_title_hashtags_copied_to_clipboard": "Hashtag copied",
  "15_3_2_description_hashtags_copied_to_clipboard": "Hashtags copied to clipboard.",
  "15_3_2_title_logo_copied_to_clipboard": "Link copied",
  "15_3_2_description_logo_copied_to_clipboard": "Link to the logo copied to clipboard.",
  "15_3_2_title_font_copied_to_clipboard": "Link copied",
  "15_3_2_description_font_copied_to_clipboard": "Link to the font copied to clipboard.",
  "sources_sidebar_btn_delete_company": "Delete company",
  "sources_sidebar_title_manage_company": "Manage company",
  "dropdown_select_no_options": "No options",
  "15_1_label_name": "Name",
  "15_1_label_industry": "Industry",
  "15_1_placeholder_industry": "Select industry",
  "15_1_label_annual_revenue": "Annual revenue",
  "15_1_placeholder_annual_revenue": "Select annual revenue",
  "15_1_label_business_type": "Business type",
  "15_1_placeholder_business_type": "Select business type",
  "15_1_label_main_company_website": "Main website",
  "15_1_main_company_website_add": "Add link",
  "15_1_label_number_of_employees": "Number of employees",
  "15_1_placeholder_number_of_employees": "Select number of employees",
  "15_1_label_description": "Description",
  "15_1_label_geography": "Markets",
  "15_1_accordion_title_additional_info": "Additional information",
  "15_1_accordion_description_additional_info": "In this section, you can specify additional information about the company.",
  "15_1_btn_save_changes": "Save changes",
  "15_1_placeholder_description": "Epic company that will conquer the world.",
  "15_1_label_timezone": "Timezone",
  "15_1_placeholder_timezone": "Select timezone",
  "15_1_label_currency": "Currency",
  "15_1_placeholder_currency": "Select currency",
  "15_1_label_project_type": "Project type",
  "15_1_placeholder_project_type": "Select project type",
  "15_1_tools_add": "Add tool",
  "15_1_label_tools": "Tools",
  "15_2_title_people_and_team": "People & Team",
  "15_2_subtitle_people": "People",
  "15_2_roles_and_permissions": "Roles and permissions",
  "15_2_tooltip_team": "Admins can invite users, create content, add changes, do everything. Approvers can only change content status, and they can\\'t see comments nor checklists.",
  "15_2_tab_members": "Members",
  "15_2_tab_invited": "Invited",
  "15_2_no_members": "No members found.",
  "15_2_no_invitations": "No invitations found.",
  "15_2_label_email": "Email",
  "15_2_tooltip_email": "People you add automatically receive an invite. Enter up to 10 email addresses separated by commas.",
  "15_2_placeholder_email": "Write emails of colleagues you want to invite, separated by commas.",
  "15_2_btn_send_email_join_request": "Send email invites",
  "15_2_5_1_invite_title": "Invite new team members",
  "15_2_5_1_invite_description": "Invite new dinos to the company and assign them the appropriate role.",
  "15_2_5_1_description_maximum_quota": "This company has reached the maximum number of members in its subscription plan.",
  "15_2_5_2_title_reached_maximum_quota": "This company has reached the maximum number of members in its subscription plan.",
  "15_2_5_2_description_reached_maximum_quota": "To get more members in, please upgrade your subscription plan or remove some existing members.",
  "15_2_6_toaster_title_email_copied_to_clipboard": "Email copied",
  "15_2_6_toaster_description_email_copied_to_clipboard": "Email copied to clipboard.",
  "15_2_6_toaster_title_phone_copied_to_clipboard": "Phone copied",
  "15_2_6_toaster_description_phone_copied_to_clipboard": "Phone copied to clipboard.",
  "15_2_2_tooltip_users_count": "Current and maximum number of members in the subscription plan.",
  "15_4_1_1_title": "Create your fist Lean Canvas",
  "15_4_1_1_description": "Lean Canvas is a 1-page business model that helps you to outline and validate business ideas.",
  "15_4_1_1_button": "Add Lean Canvas",
  "15_4_1_2_title": "Lean Canvas",
  "15_4_1_2_btn_add_canvas": "Add Lean Canvas",
  "15_4_1_2_label_items_count": "Number of Lean Canvases",
  "15_4_2_1_btn_edit": "Edit",
  "15_4_2_1_btn_print": "Print",
  "15_4_2_1_btn_delete": "Delete",
  "15_4_2_1_title_problems": "Problems",
  "15_4_2_1_description_problems": "What are the main problems your customers are trying to solve?",
  "15_4_2_1_title_solutions": "Solutions",
  "15_4_2_1_description_solutions": "How does your business solve these problems?",
  "15_4_2_1_title_usp": "Unique selling proposition",
  "15_4_2_1_description_usp": "What is unique about your solution?",
  "15_4_2_1_title_unfair_advantage": "Unfair advantage",
  "15_4_2_1_description_unfair_advantage": "What do you offer that can't be easily copied or purchased elsewhere?",
  "15_4_2_1_title_customer_segments": "Customer segments",
  "15_4_2_1_description_customer_segments": "Who are your target customers or users?",
  "15_4_2_1_title_early_adopters": "Early adopters",
  "15_4_2_1_description_early_adopters": "Which customer segment will be the first to buy your solution?",
  "15_4_2_1_title_high_level_concept": "High-level concept",
  "15_4_2_1_description_high_level_concept": "How can you simply and shortly describe your solution?",
  "15_4_2_1_title_existing_alternatives": "Existing alternatives",
  "15_4_2_1_description_existing_alternatives": "How do your clients solve their problems right now? Who are your competitors?",
  "15_4_2_1_title_key_metricts": "Key metrics",
  "15_4_2_1_description_key_metricts": "How do you define success? What are the main KPIs?",
  "15_4_2_1_title_channels": "Channels",
  "15_4_2_1_description_channels": "How and where do you approach potential customers?",
  "15_4_2_1_title_cost_structure": "Cost structure",
  "15_4_2_1_description_cost_structure": "What and how much are your fixed and varaible expenses?",
  "15_4_2_1_title_revenue_streams": "Revenue streams",
  "15_4_2_1_description_revenue_streams": "What is the price structure of your solution?",
  "15_4_2_9_modal_edit_title": "Edit Lean Canvas",
  "15_4_2_9_modal_edit_label_title": "Name",
  "15_4_2_9_modal_edit_placeholder_title": "Name this Lean Canvas",
  "15_4_2_9_modal_edit_label_description": "Description",
  "15_4_2_9_modal_edit_placeholder_description": "Briefly describe this Lean Canvas (what product or service it relates to)",
  "15_4_2_9_modal_edit_btn_cancel": "Cancel",
  "15_4_2_9_modal_edit_btn_save": "Save",
  "15_4_2_10_modal_delete_title": "Delete Lean Canvas",
  "15_4_2_10_modal_delete_btn_back": "Go Back",
  "15_4_2_10_modal_delete_btn_delete": "Delete",
  "15_4_2_10_modal_delete_text_line1": "This Lean Canvas and all its information will be permanently deleted. ",
  "15_4_2_10_modal_delete_text_line2": "Once deleted, it cannot be restored.",
  "15_5_1_btn_print": "Print",
  "15_5_1_btn_save": "Save changes",
  "15_5_1_title_scales": "Dimensions",
  "15_5_1_add_custom_scale": "Add custom dimensions",
  "15_5_1_accordion_title": "Additional information",
  "15_5_1_accordion_description": "In this section, you can specify additional information about the tone of voice dimensions.",
  "15_5_1_title_do_dont_rules": "General rules",
  "15_5_1_btn_add_custom_scale": "Add custom dimensions",
  "15_5_1_btn_add_custom_rule": "Add custom rule",
  "15_5_1_accordion_dodont_title": "Additional information",
  "15_5_1_accordion_dodont_placeholder": "In this section, you can specify additional information about communication rules.",
  "15_5_2_label_do": "DO",
  "15_5_2_placeholder_do": "Rules to follow",
  "15_5_2_label_dont": "DON\\'T",
  "15_5_2_placeholder_dont": "What to avoid",
  "15_5_2_label_characteristics": "Charecteristics",
  "15_5_2_placeholder_characteristics": "Start typing",
  "15_5_2_label_examples": "Examples",
  "15_5_2_placeholder_examples": "Write a text example for this dimension",
  "15_5_3_rule_general_title": "General rules",
  "15_5_3_rule_general_description": "Note down any general DOs and DON'Ts to follow when communicating as the brand.",
  "15_5_3_rule_brand_title_title": "Brand name",
  "15_5_3_rule_brand_title_description": "How do you write the brand name? All caps, only first capital letter, non-with or without the structure abbreviation, such as l.t.d., etc.",
  "15_5_3_rule_communication_with_customer_title": "Communication with customers",
  "15_5_3_rule_communication_with_customer_description": "How do you talk to your clients? Do you use singular or plural person? Do you use individual signature or do you sign as team etc.",
  "15_5_3_rule_reaching_out_to_customers_title": "Reaching out to customers",
  "15_5_3_rule_reaching_out_to_customers_description": "Lorem ipsum dolor sit amet, id est homero eligendi. Cu sea dolor tation intellegat, et vix latine comprehensam, mei odio reque definiebas at.",
  "15_5_3_rule_brand_glossary_title": "Brand glossary",
  "15_5_3_rule_brand_glossary_description": "Do you use any specific brand-related terms?",
  "15_5_3_rule_frequently_used_phrases_and_expressions_title": "Frequently used phrases",
  "15_5_3_rule_frequently_used_phrases_and_expressions_description": "How do you open and end emails or social posts? Do you have a catch phrase?",
  "15_5_3_rule_brand_personality_title": "Brand personality",
  "15_5_3_rule_brand_personality_description": "Lorem ipsum dolor sit amet, id est homero eligendi. Cu sea dolor tation intellegat, et vix latine comprehensam, mei odio reque definiebas at.",
  "15_5_3_rule_themes_areas_title": "Themes and areas",
  "15_5_3_rule_themes_areas_description": "Lorem ipsum dolor sit amet, id est homero eligendi. Cu sea dolor tation intellegat, et vix latine comprehensam, mei odio reque definiebas at.",
  "15_5_3_scale_formal_formal": "Formal",
  "15_5_3_scale_formal_neutral": "Neutral",
  "15_5_3_scale_formal_text": "Text",
  "15_5_3_scale_formal_casual": "Casual",
  "15_5_3_scale_serious_serious": "Serious",
  "15_5_3_scale_serious_text": "Text",
  "15_5_3_scale_serious_neutral": "Neutral",
  "15_5_3_scale_serious_playful": "Playful",
  "15_5_3_scale_respectful_respectful": "Respectful",
  "15_5_3_scale_respectful_text": "Text",
  "15_5_3_scale_respectful_neutral": "Neutral",
  "15_5_3_scale_respectful_irreverent": "Irreverent",
  "15_5_3_scale_matter_of_fact_matter_of_fact": "Matter-of-fact",
  "15_5_3_scale_matter_of_fact_text": "Text",
  "15_5_3_scale_matter_of_fact_neutral": "Neutral",
  "15_5_3_scale_matter_of_fact_irreverent": "Enthusiastic",
  "15_5_3_toaster_success_title_saved": "Saved",
  "15_5_3_toaster_success_description_saved": "Changes saved",
  "15_5_3_toaster_error_title_saved": "Error",
  "15_5_5_1_title": "Add custom dimensions",
  "15_5_5_1_description": "Specify at least 2 words for this ToV dimension, such as Serious–Funny.",
  "15_5_5_1_option": "Option",
  "15_5_5_1_label_option": "Option",
  "15_5_5_1_value_option": "Write a specific word",
  "15_5_5_1_placeholder_option": "Enter option name",
  "15_5_5_1_btn_add_option": "Add option",
  "15_5_5_1_btn_cancel": "Cancel",
  "15_5_5_1_btn_add_custom_scale": "Add custom dimensions",
  "15_5_6_1_title": "Add custom rule",
  "15_5_6_1_label_title": "Name",
  "15_5_6_1_placeholder_title": "Name this rule",
  "15_5_6_1_label_description": "Description",
  "15_5_6_1_placeholder_description": "Describe what is this rule about and what it relates to.",
  "15_5_6_1_btn_cancel": "Cancel",
  "15_5_6_1_btn_save": "Save",
  "15_6_1_1_title": "Create your first persona",
  "15_6_1_1_description": "Marketing personas are fictional characters representing a specific demographic of your customer base. They help you better target products, services, communication, and strategies to a given target audience.",
  "15_6_1_1_button": "Add persona",
  "15_6_1_2_modal_edit_title": "Add persona",
  "15_6_1_2_modal_edit_btn_cancel": "Cancel",
  "15_6_1_2_modal_edit_btn_save": "Save",
  "15_6_1_2_label_persona_audience_name": "Name",
  "15_6_1_2_placeholder_persona_audience_name": "Name this persona",
  "15_6_1_2_avatar_image": "Avatar",
  "15_6_1_2_label_key_insight": "Key insight",
  "15_6_1_2_placeholder_key_insight": "What should everyone know about this persona",
  "15_6_1_2_label_gender": "Gender",
  "15_6_1_2_placeholder_gender": "Select their gender",
  "15_6_1_2_label_age": "Age",
  "15_6_1_2_placeholder_age": "Select their age",
  "15_6_1_2_label_demographics": "Demographics",
  "15_6_1_2_placeholder_demographics": "Any important notes about this demographics",
  "15_6_1_2_label_education": "Education",
  "15_6_1_2_placeholder_education": "Select their education",
  "15_6_1_2_label_occupation": "Occupation",
  "15_6_1_2_placeholder_occupation": "Select their occupation",
  "15_6_1_3_title": "Personas",
  "15_6_1_3_btn_add_persona": "Add persona",
  "15_6_1_3_btn_edit_importances": "Adjust weight",
  "15_6_1_3_label_items_count": "Number of personas",
  "15_6_1_3_label_products_and_services": "Products & Services",
  "15_6_1_3_label_channels": "Channels",
  "15_6_1_3_label_stdc_framework_and_phase": "STDC",
  "15_6_2_1_btn_delete": "Delete",
  "15_6_2_1_btn_print": "Print",
  "15_6_2_1_btn_save": "Save changes",
  "15_6_2_1_title_main_info": "General information",
  "15_6_2_1_label_persona_audience_name": "Name",
  "15_6_2_1_placeholder_persona_audience_name": "Name this persona",
  "15_6_2_1_avatar_image": "Avatar",
  "15_6_2_1_label_key_insight": "Key insights",
  "15_6_2_1_placeholder_key_insight": "What should everyone know about this persona",
  "15_6_2_1_label_gender": "Gender",
  "15_6_2_1_placeholder_gender": "Select their gender",
  "15_6_2_1_label_age": "Age",
  "15_6_2_1_placeholder_age": "Select their age",
  "15_6_2_1_label_demographics": "Demographics",
  "15_6_2_1_placeholder_demographics": "Any important notes about this demographics",
  "15_6_2_1_label_education": "Education",
  "15_6_2_1_placeholder_education": "Select their education",
  "15_6_2_1_label_occupation": "Occupation",
  "15_6_2_1_label_personas": "Personas",
  "15_6_2_1_placeholder_personas": "Select or add a new one...",
  "15_6_2_1_label_product_and_services": "Products and services",
  "15_6_2_1_placeholder_product_and_services": "Select related products and services",
  "15_6_2_1_placeholder_occupation": "Select their occupation",
  "15_6_2_1_title_other_info": "Specific information",
  "15_6_2_1_label_needs": "Needs",
  "15_6_2_1_add_needs": "Add resource",
  "15_6_2_1_label_desires": "Desires",
  "15_6_2_1_add_desires": "Add resource",
  "15_6_2_1_label_problems": "Problems",
  "15_6_2_1_add_problems": "Add resource",
  "15_6_2_1_label_solutions": "Solutions",
  "15_6_2_1_add_solutions": "Add resource",
  "15_6_2_1_label_concerns": "Concerns",
  "15_6_2_1_accordion_title": "Additional information",
  "15_6_2_1_accordion_description": "In this section, you can specify additional information about this persona.",
  "15_6_2_1_label_content_specifics": "Relevant content",
  "15_6_2_1_add_content_specifics": "Add resource",
  "15_6_2_1_label_note": "Note",
  "15_6_2_1_placeholder_note": "Additional notes",
  "15_6_2_1_label_other_resource": "Other resources",
  "15_6_2_1_add_other_resource": "Add resource",
  "15_6_2_1_add_concerns": "Add resource",
  "15_6_2_2_title": "Delete this persona?",
  "15_6_2_2_description_line1": "This persona and all its information will be permanently deleted. ",
  "15_6_2_2_description_line2": "Once deleted, it cannot be restored.",
  "15_6_2_2_modal_delete_btn_back": "Go back",
  "15_6_2_2_modal_delete_btn_delete": "Delete",
  "15_7_1_1_title": "Create your first product",
  "15_7_1_1_description": "Products are goods and services that you offer to your customers.",
  "15_7_1_1_button": "Add product",
  "15_7_1_2_label_product_service_title": "Name",
  "15_7_1_2_modal_edit_title": "Add product",
  "15_7_1_2_modal_edit_btn_cancel": "Cancel",
  "15_7_1_2_modal_edit_btn_save": "Add product",
  "15_7_1_2_placeholder_product_service_title": "Name this product",
  "15_7_1_2_avatar_image": "Logo",
  "15_7_1_2_label_key_insight": "Key insight",
  "15_7_1_2_placeholder_key_insight": "What should everyone know about this product",
  "15_7_1_2_label_average_price": "Average price",
  "15_7_1_2_placeholder_average_price": "Select average price",
  "15_7_1_3_title_main_info": "General information",
  "15_7_1_3_title": "Products & Services",
  "15_7_1_3_label_items_count": "Number of products",
  "15_7_1_3_btn_edit_importances": "Adjust weight",
  "15_7_1_3_btn_add_product_service": "Add product",
  "15_7_1_3_label_personas": "Personas",
  "15_7_1_3_label_channels": "Channels",
  "15_7_1_3_label_competitors": "STDC",
  "15_7_2_1_title_other_info": "Specific information",
  "15_7_2_1_btn_delete": "Delete",
  "15_7_2_1_btn_print": "Print",
  "15_7_2_1_btn_save": "Save changes",
  "15_7_2_1_title_main_info": "General information",
  "15_7_2_1_label_product_service_name": "Name",
  "15_7_2_1_placeholder_product_service_name": "Name this product",
  "15_7_2_1_label_product_service_image": "Logo",
  "15_7_2_1_label_key_insight": "Key insight",
  "15_7_2_1_placeholder_key_insight": "What should everyone know about this product",
  "15_7_2_1_hint_key_insight": "What should everyone know about this product",
  "15_7_2_1_label_average_price": "Average price",
  "15_7_2_1_placeholder_average_price": "Select average price",
  "15_7_2_1_label_personas": "Personas",
  "15_7_2_1_placeholder_personas": "Select target personas",
  "15_7_2_1_label_channels": "Channels",
  "15_7_2_1_placeholder_channels": "Select relevant channels",
  "15_7_2_1_label_competitors": "Competitors",
  "15_7_2_1_placeholder_competitors": "Select relevant competitors",
  "15_7_2_1_accordion_title": "Additional information",
  "15_7_2_1_accordion_description": "In this section, you can specify additional information about this product.",
  "15_7_2_1_label_type": "Type",
  "15_7_2_1_placeholder_type": "Select",
  "15_7_2_1_label_marker_location": "Market Location",
  "15_7_2_1_placeholder_marker_location": "Start typing....",
  "15_7_2_1_label_total_addressable_marker": "Total addressable market (TAM)",
  "15_7_2_1_placeholder_total_addressable_marker": "What is the the maximum size of the opportunity for this product?",
  "15_7_2_1_other_info": "Additional information",
  "15_7_2_1_label_description": "Description",
  "15_7_2_1_placeholder_description": "Describe this product",
  "15_7_2_1_label_argumantation_beneftis": "Arguments & benefits",
  "15_7_2_1_add_argumantation_beneftis": "Add resource",
  "15_7_2_1_label_unique_selling_proposition": "Unique selling proposition",
  "15_7_2_1_placeholder_unique_selling_proposition": "What is unique about this product?",
  "15_7_2_1_accordion_other_title": "Additional information",
  "15_7_2_1_accordion_other_description": "In this section, you can specify additional information about this product.",
  "15_7_2_1_label_note": "Note",
  "15_7_2_1_placeholder_note": "Write down anything relevant",
  "15_7_2_1_label_other_resources": "Other resources",
  "15_7_2_1_add_other_resources": "Add resource",
  "15_7_2_3_title": "Delete this product?",
  "15_7_2_3_description_line1": "This product and all its information will be permanently deleted. ",
  "15_7_2_3_description_line2": "Once deleted, it cannot be restored.",
  "15_7_2_3_modal_delete_btn_back": "Go back",
  "15_7_2_3_modal_delete_btn_delete": "Delete",
  "15_8_1_1_title": "Create your first STDC framework",
  "15_8_1_1_description": "This framework breaks the customer journey into four stages: See, Think, Do, and Care. Each stage reflects a different point in the buying process, where customers have unique needs and reasons for their actions.",
  "15_8_1_1_button": "Add STDC framework",
  "15_8_1_2_title": "STDC frameworks",
  "15_8_1_2_label_items_count": "Number of frameworks",
  "15_8_1_2_btn_add_std": "Add STDC framework",
  "15_8_1_3_modal_edit_title": "Add STDC framework",
  "15_8_1_3_modal_edit_btn_cancel": "Cancel",
  "15_8_1_3_label_title": "Name",
  "15_8_1_3_placeholder_title": "Name this STDC framework",
  "15_8_1_3_modal_edit_btn_save": "Add STDC framework",
  "15_8_1_3_label_description": "Description",
  "15_8_1_3_placeholder_description": "Describe this STDC",
  "15_8_1_3_label_competitors": "Personas",
  "15_8_1_3_placeholder_competitors": "Add relevant personas",
  "15_8_1_3_label_product_and_services": "Products & Services",
  "15_8_1_3_placeholder_product_and_services": "Add relevant products",
  "15_8_2_1_label_personas": "Personas",
  "15_8_2_1_label_products_and_services": "Products & Services",
  "15_8_2_1_btn_edit": "Edit",
  "15_8_2_1_btn_edit_importances": "Adjust weight",
  "15_8_2_1_btn_print": "Print",
  "15_8_2_1_btn_delete": "Delete",
  "15_8_2_5_title": "Delete this STDC?",
  "15_8_2_5_description_line1": "This framework and all its information will be permanently deleted. ",
  "15_8_2_5_description_line2": "Once deleted, it cannot be restored.",
  "15_8_2_5_modal_delete_btn_back": "Go back",
  "15_8_2_5_modal_delete_btn_delete": "Delete",
  "15_9_1_1_title": "Create your first channel",
  "15_9_1_1_description": "Channels are places where you interact with target customers, such as webpages (e.g. landing pages or blog posts), various social platforms (e.g. LinkedIn, Facebook, Reddit), or any other channels, be it print, OOH or podcasts.",
  "15_9_1_1_button": "Add channel",
  "15_9_1_2_title": "Add channel",
  "15_9_1_2_label_channel": "Name",
  "15_9_1_2_placeholder_channel": "Name this channel",
  "15_9_1_2_label_key_insight": "Key insight",
  "15_9_1_2_placeholder_key_insight": "What should everyone know about this channel",
  "15_9_1_2_label_channel_color": "Color",
  "15_9_1_2_label_channel_image": "Select a color for this channel",
  "15_9_1_2_hint_key_insight": "What should everyone know about this channel",
  "15_9_1_2_modal_edit_btn_cancel": "Cancel",
  "15_9_1_2_modal_edit_btn_add": "Add channel",
  "15_9_1_3_title": "Channels",
  "15_9_1_3_label_items_count": "Number of channels",
  "15_9_1_3_btn_edit_importances": "Adjust weight",
  "15_9_1_3_btn_add_channel": "Add channel",
  "15_9_1_3_label_products_and_services_to_compete_with": "Products & services to compete with",
  "15_9_1_3_label_competitors": "Competitors",
  "15_9_1_3_label_personas": "Personas",
  "15_9_1_3_label_stdc_framework_and_phase": "STDC framework",
  "15_9_1_3_label_stdc_product_and_services": "Products & Services",
  "15_9_2_1_title_main_info": "General information",
  "15_9_2_1_title_other_info": "Specific information",
  "15_9_2_1_btn_delete": "Delete",
  "15_9_2_1_btn_print": "Print",
  "15_9_2_1_btn_save": "Save changes",
  "15_9_2_1_label_channel_title": "Name",
  "15_9_2_1_placeholder_enter_title": "Name this channel",
  "15_9_2_1_label_channel_color": "Color",
  "15_9_2_1_label_channel_image": "Select a color for this channel",
  "15_9_2_1_label_key_insight": "Key insight",
  "15_9_2_1_placeholder_enter_key_insight": "What should everyone know about this channel",
  "15_9_2_1_hint_key_insight": "What should everyone know about this channel",
  "15_9_2_1_label_personas": "Personas",
  "15_9_2_1_placeholder_add_personas": "Select target personas",
  "15_9_2_1_products_and_services": "Products & Services",
  "15_9_2_1_placeholder_select_products_and_services": "Select relevant products",
  "15_9_2_1_competitors": "Competitors",
  "15_9_2_1_placeholder_select_competitors": "Select relevant competitors",
  "15_9_2_1_label_channel_link": "Links to digital channels and profiles",
  "15_9_2_1_channel_link_add": "Add link",
  "15_9_2_1_label_publication_frequency": "Publication frequency",
  "15_9_2_1_placeholder_select_publication_frequency": "Select how often to publish content on this channel",
  "15_9_2_1_label_formats": "Formats",
  "15_9_2_1_placeholder_formats": "Specify what formats to use on this channel (posts, infographics etc.)",
  "15_9_2_1_label_main_messages": "Main message",
  "15_9_2_1_main_messages_add": "Add resource",
  "15_9_2_1_accordion_title_additional": "Additional information",
  "15_9_2_1_accordion_description_additional": "In this section, you can specify additional information about this channel.",
  "15_9_2_1_label_topics": "Topics",
  "15_9_2_1_placeholder_topics": "Select main topics for this channel",
  "15_9_2_1_label_goals": "Goals",
  "15_9_2_1_add_label_goals": "Add resource",
  "15_9_2_1_label_lessons_learnt": "Lessons learned",
  "15_9_2_1_add_lessons_learnt": "Add resource",
  "15_9_2_1_label_note": "Notes",
  "15_9_2_1_placeholder_note": "Anything else worth mentioning?",
  "15_9_2_1_label_other_resources": "Other resources",
  "15_9_2_1_add_other_resources": "Add resource",
  "15_9_2_3_title": "Delete this channel?",
  "15_9_2_3_description_line1": "This channel and all its information will be permanently deleted. ",
  "15_9_2_3_description_line2": "Once deleted, it cannot be restored.",
  "15_9_2_3__modal_delete_btn_back": "Go back",
  "15_9_2_3__modal_delete_btn_delete": "Delete",
  "15_10_1_1_title": "Add your first competitor",
  "15_10_1_1_description": "Keeping track of your competition is crucial to stay ahead. Analyze their strategies, see what works and what doesn't for them, and learn from their successes and failures.",
  "15_10_1_1_button": "Add competitor",
  "15_10_1_2_title": "Add competitor",
  "15_10_1_2_label_title": "Name",
  "15_10_1_2_placeholder_title": "Name this competitor",
  "15_10_1_2_label_competitor_image": "Logo",
  "15_10_1_2_label_key_insight": "Key insight",
  "15_10_1_2_placeholder_key_insight": "What should everyone know about this competitor",
  "15_10_1_2_hint_key_insight": "What should everyone know about this competitor",
  "15_10_1_2_label_corporate_website": "Website",
  "15_10_1_2_add_corporate_website": "Add link",
  "15_10_1_3_title": "Competitors",
  "15_10_1_3_label_items_count": "Number of competitors",
  "15_10_1_3_btn_edit_importances": "Adjust weight",
  "15_10_1_3_btn_add_competitor": "Add competitor",
  "15_10_1_3_label_products_and_services_to_compete_with": "Similar products you offer",
  "15_10_1_3_btn_cancel": "Cancel",
  "15_10_1_3_btn_add": "Add competitor",
  "15_10_2_1_btn_delete": "Delete",
  "15_10_2_1_btn_print": "Print",
  "15_10_2_1_btn_save": "Save changes",
  "15_10_2_1_title_main_info": "General information",
  "15_10_2_1_label_competitor_title": "Name",
  "15_10_2_1_placeholder_competitor_title": "Name this competitor",
  "15_10_2_1_label_competitor_image": "Logo",
  "15_10_2_1_label_key_insight": "Key insight",
  "15_10_2_1_placeholder_key_insight": "What should everyone know about this competitor",
  "15_10_2_1_hint_key_insight": "What should everyone know about this competitor",
  "15_10_2_1_label_products_and_services": "Similar products you offer",
  "15_10_2_1_placeholder_products_and_services": "Add product",
  "15_10_2_1_accordion_title": "Additional information",
  "15_10_2_1_accordion_placeholder": "In this section, you can specify additional information about this competitor.",
  "15_10_2_1_label_corporate_website": "Website",
  "15_10_2_1_add_corporate_website": "Add link",
  "15_10_2_1_label_other_relevant_link": "Other relevant links",
  "15_10_2_1_add_other_relevant_link": "Add link",
  "15_10_2_1_title_other_info": "Specific information",
  "15_10_2_1_label_unique": "Differences",
  "15_10_2_1_add_unique": "Add resource",
  "15_10_2_1_label_similar": "Simillarities",
  "15_10_2_1_add_similar": "Add resource",
  "15_10_2_1_accordion_other_info_title": "Additional information",
  "15_10_2_1_other_info_description": "In this section, you can specify additional information about this competitor.",
  "15_10_2_1_label_better": "What they do better",
  "15_10_2_1_add_better": "Add resource",
  "15_10_2_1_label_worse": "What they do worst",
  "15_10_2_1_add_worse": "Add resource",
  "15_10_2_1_label_how_we_win": "How do we stand out",
  "15_10_2_1_add_how_we_win": "Add resource",
  "15_10_2_1_label_note": "Notes",
  "15_10_2_1_placeholder_note": "Anything else worth mentioning?",
  "15_10_2_1_label_other_resources": "Other resources",
  "15_10_2_1_add_other_resources": "Add resource",
  "15_11_1_1_title": "Meeting notes",
  "15_11_1_1_tab_me": "Me",
  "15_11_1_1_tab_all": "All",
  "15_11_1_1_btn_filter": "Filter",
  "15_11_1_1_btn_add_meeting_note": "Add meeting note",
  "15_11_1_1_label_no_checklist": "No checklists",
  "15_11_1_1_label_no_participants": "No participants",
  "15_12_1_title": "Create your first budget",
  "15_12_1_description": "A marketing budget is the amount of money you plan to spend on promoting your products and services. It includes costs for things like advertising, social media, events, and other ways to reach customers.",
  "15_12_1_button": "Add budget",
  "15_12_1_title_select_budget_type": "Select budget type",
  "15_12_1_button_add_personas_and_audiences": "Personas & Audiences",
  "15_12_1_button_add_products_and_services": "Products & Services",
  "15_12_1_button_add_stdc_phases": "STDC Phases",
  "15_12_1_button_add_channels": "Channels",
  "15_12_1_button_add_tags": "Tags",
  "15_8_2_1_add_item": "Add item",
  "15_12_2_description": "To manage your budget, please add at least one channel. This will help you track your spending and use your money wisely.",
  "15_12_2_button": "Add channel",
  "15_12_2_button_delete_budget": "Delete budget",
  "15_12_2_title": "Budget",
  "15_12_2_btn_print": "Print",
  "15_12_2_btn_manage": "Manage",
  "15_12_2_btn_delete_budget": "Delete budget",
  "15_12_2_label_total_budget": "Total budget",
  "15_12_2_label_budget_not_defined": "No defined budget",
  "15_12_2_thead_col": "Col",
  "15_12_2_thead_channel": "Channel",
  "15_12_2_thead_share": "Share",
  "15_12_2_thead_budget": "Budget",
  "15_12_2_tfoot_total_defined": "Total defined",
  "15_12_2_tbody_not_defined": "Not defined",
  "empty_cards_channel_title": "Add at least one channel",
  "empty_cards_channel_description": "To manage your budget, please add at least one channel. This will help you track your spending and use your money wisely.",
  "empty_cards_channel_btn_add": "Add chhannel",
  "empty_cards_channel_btn_delete": "Delete budget",
  "empty_cards_tags_title": "No tags added",
  "empty_cards_tags_description": "To manage your budget, please add at least one tag. This will help you track your spending and use your money wisely.",
  "empty_cards_tags_btn_add": "Add tag",
  "empty_cards_tags_btn_delete": "Delete budget",
  "empty_cards_product_service_title": "Add at least one product",
  "empty_cards_product_service_description": "To manage your budget, please add at least one product. This will help you track your spending and use your money wisely.",
  "empty_cards_product_service_btn_add": "Add product",
  "empty_cards_product_service_btn_delete": "Delete budget",
  "empty_cards_persona_audience_title": "Add at least one persona",
  "empty_cards_persona_audience_description": "To manage your budget, please add at least one persona. This will help you track your spending and use your money wisely.",
  "empty_cards_persona_audience_btn_add": "Add persona",
  "empty_cards_persona_audience_btn_delete": "Delete budget",
  "color_picker": "Select",
  "stdc_frameworks_and_phases_input_label": "STDC framework",
  "stdc_frameworks_and_phases_input_btn_add": "Connect STDC",
  "stdc_frameworks_and_phases_btn_save": "Save",
  "stdc_frameworks_and_phases_btn_cancel": "Cancel",
  "stdc_frameworks_and_phases_title_confirm_delete": "Confirm deletion",
  "stdc_frameworks_and_phases_confirm_delete": "Click the delete button to confirm",
  "15_5_5_1_value_option_placeholder": "Enter text...",
  "special_list_inputs_title": "Files",
  "special_list_inputs_warning_title": "You have reached maximum files limit!",
  "special_list_inputs_warning_description_part_1": "Upgrade the plan on the",
  "special_list_inputs_warning_description_link": "Billing Page",
  "special_list_inputs_warning_description_part_2": "or remove files to be able to uppload a new one",
  "special_list_inputs_uploading": "Uploading...",
  "toaster_info_title_error_save_file": "Cannot save file",
  "toaster_info_description_error_save_file": "The file could not be saved",
  "special_list_inputs_upload_title": "Drag & Drop files here or",
  "special_list_inputs_upload_title_link": "click to upload manually",
  "special_list_inputs_upload_description": "SVG, PNF, JPG or GIF (max. 3MB)",
  "special_list_inputs_upload_minutes_before": "",
  "special_list_inputs_upload_minutes_after": "min ago",
  "special_list_inputs_upload_hours_before": "",
  "special_list_inputs_upload_hours_after": "h ago",
  "15_13_subtitle_total_attachment_size": "Total attachment size:",
  "15_13_subtitle_total_tooltip": "Tooltip",
  "15_13_subtitle_content_cards": "Content Cards",
  "15_13_menu_storage_management": "Storage management",
  "15_1_1_legend_content_card": "Content Cards",
  "15_1_1_legend_company_address": "Company Assets",
  "15_1_1_legend_meeting_notes": "Meeting Notes",
  "15_1_1_title_meta_1": "To free up storage, click on the issue card and remove attachments, or ",
  "15_1_1_title_meta_link": "upgrade",
  "15_1_1_title_meta_2": " for more storage",
  "15_1_1_used_size_of": " of ",
  "15_1_1_used_size_used": " used (",
  "15_1_1_used_size_left": " left)",
  "15_1_1_title": "Storage Management",
  "15_1_1_subtitle_content_cards": "Content Cards",
  "15_1_1_subtitle_company_assets": "Company Assets",
  "15_1_1_subtitle_meeting_notes": "Meeting Notes",
  "9_1_title_links_attachments": "Links & Attachments",
  "9_1_title_links": "Links",
  "10_1_title_links_attachments": "Links & Attachments",
  "10_1_title_links": "Links",
  "15_0_4_2_title_main": "Tone of voice",
  "15_5_5_1_btn_update_custom_scale": "Update custom scale",
  "15_5_6_1_btn_update": "Update",
  "15_5_2_scale_submenu_edit": "Edit Scale",
  "15_5_2_scale_submenu_delete": "Delete",
  "15_8_1_3_modal_edit_btn_edit": "Save",
  "15_8_toaster_success_title_saved": "Saved",
  "15_8_toaster_success_description_saved": "STDC saved",
  "15_8_toaster_error_title_saved": "Error",
  "15_8_toaster_error_title_delete": "Delete error",
  "15_8_toaster_success_title_deleted": "Deleted",
  "15_8_toaster_success_description_deleted": "STDC Deleted",
  "15_8_2_1_see_trigger_title": "Trigger",
  "15_8_2_1_think_trigger_title": "I know what type of product/service I want",
  "15_8_2_1_do_trigger_title": "I chose a dealer and made a purchase",
  "15_8_2_1_label_channels": "Channels",
  "15_8_2_1_label_what_is_the_situation": "What is the situation",
  "15_8_2_1_label_how_it_has_been_solved": "How it has been solved",
  "15_8_2_1_label_how_can_we_help": "How can we help",
  "15_8_2_4_title_part_edit": "Edit",
  "15_8_2_4_btn_cancel": "Cancel",
  "15_8_2_4_editor_placeholder": "Placeholder",
  "15_8_2_4_btn_save": "Save",
  "15_4_1_2_toaster_error_title_create": "Cannot create lean canvas",
  "15_8_toaster_error_title_create": "Cannot create stdc",
  "15_4_1_2_toaster_success_title_deleted": "Deleted",
  "15_4_1_2_toaster_success_description_deleted": "Lean canvas Deleted",
  "15_4_1_2_toaster_error_title_delete": "Delete error",
  "15_4_2_1_toaster_error_title_update": "Update error",
  "15_4_2_1_toaster_success_title_saved": "Updated",
  "15_4_2_1_toaster_success_description_saved": "Lean canvas updated",
  "15_4_2_1_toaster_error_title_delete": "Delete error",
  "list_BusinessType_B2B": "B2B",
  "list_BusinessType_B2C": "B2C",
  "list_BusinessType_B2B2C": "B2B2C",
  "list_BusinessType_D2C": "D2C",
  "list_BusinessType_C2C": "C2C",
  "list_BusinessType_C2B": "C2B",
  "list_BusinessType_B2G": "B2G",
  "list_BusinessType_GOVERNMENT_INSTITUTION": "Government Institution",
  "list_BusinessType_NON_PROFIT_NGO": "Non Profit Ngo",
  "list_BusinessType_FRANCHISE": "Franchise",
  "list_BusinessType_E_COMMERCE": "E-commerce",
  "list_BusinessType_OTHER": "Other",
  "list_IndustryType_MARKETING_SERVICES": "Marketing Services",
  "list_IndustryType_TECHNOLOGY": "Technology",
  "list_IndustryType_RETAIL": "Retail",
  "list_IndustryType_MEDIA": "Media",
  "list_IndustryType_ENTERTAINMENT": "Entertainment",
  "list_IndustryType_HEALTHCARE": "Healthcare",
  "list_IndustryType_FINANCE": "Finance",
  "list_IndustryType_HOSPITALITY": "Hospitality",
  "list_IndustryType_REAL_ESTATE": "Real Estate",
  "list_IndustryType_BEAUTY": "Beauty",
  "list_IndustryType_LOGISTICS": "Logistics",
  "list_IndustryType_CONSULTING": "Consulting",
  "list_IndustryType_LEGAL_SERVICES": "Legal Services",
  "list_IndustryType_TELECOMMUNICATIONS": "Telecommunications",
  "list_IndustryType_MANUFACTURING": "Manufacturing",
  "list_IndustryType_FOOD_BEVERAGE": "Food Beverage",
  "list_IndustryType_EDUCATION": "Education",
  "list_IndustryType_EVENT_PLANNING": "Event Planning",
  "list_IndustryType_SOCIAL_SERVICES": "Social Services",
  "list_IndustryType_UTILITIES": "Utilities",
  "list_IndustryType_OTHER": "Other",
  "list_ProjectType_LEAD_GENERATION": "Lead Generation",
  "list_ProjectType_BRAND_AWARENESS": "Brand Awareness",
  "list_ProjectType_PRODUCT_SERVICE_LAUNCH": "Product Service Launch",
  "list_ProjectType_CUSTOMER_ENGAGEMENT": "Customer Engagement",
  "list_ProjectType_CONTENT_STRATEGY_PLANNING": "Content Strategy Planning",
  "list_ProjectType_SEO_TRAFFIC_GROWTH": "Seo Traffic Growth",
  "list_ProjectType_EVENT_PROMOTION": "Event Promotion",
  "list_ProjectType_E_COMMERCE_MARKETING": "E Commerce Marketing",
  "list_ProjectType_PUBLIC_RELATIONS": "Public Relations",
  "list_ProjectType_INTERNAL_COMMUNICATION": "Internal Communication",
  "list_ProjectType_SALES_ENABLEMENT": "Sales Enablement",
  "list_ProjectType_RECRUITING": "Recruiting",
  "list_ProjectType_MARKET_EDUCATION": "Market Education",
  "15_1_label_company_geography": "Geography",
  "15_1_placeholder_company_geography": "Select countries",
  "15_3_2_toaster_error_title_update": "Update error",
  "15_8_toaster_success_title_updated": "Updated",
  "15_8_toaster_success_description_updated": "Company updated",
  "15_4_2_9_modal_edit_placeholder": "Select products",
  "15_4_2_9_modal_edit_label_products": "Products",
  "15_1_contacts_btn_save": "Save",
  "15_1_contacts_btn_cancel": "Cancel",
  "15_1_contacts_placeholder_phone_number": "Phone Number",
  "15_1_contacts_placeholder_email": "E-mail",
  "15_1_contacts_placeholder_full_name": "Full Name",
  "15_1_contacts_placeholder_description": "Description",
  "15_1_contacts_btn_add_contact": "Add Contact",
  "15_4_2_1_label_product": "Product",
  "15_7_2_1_toaster_success_title_saved": "Updated",
  "15_7_2_1_toaster_success_description_saved": "Product updated",
  "15_7_2_1_toaster_error_title_saved": "Error product update",
  "list_ProductType_PHYSICAL_PRODUCT": "Physical Product",
  "list_ProductType_DIGITAL_PRODUCT": "Digital Product",
  "list_ProductType_SERVICE": "Service",
  "list_ProductType_SUPPORT_MAINTENANCE": "Support & Maintenance",
  "list_ProductType_IMPLEMENTATION_INTEGRATION": "Implementation & Integration",
  "list_ProductType_PARTS_CONSUMABLES": "Parts & Consumables",
  "list_ProductType_EDUCATION_TRAINING": "Education & Training",
  "list_ProductType_SUBSCRIPTION_MEMBERSHIP": "Subscription & Membership",
  "list_ProductType_FINANCES_TRANSACTIONS": "Finances & Transactions",
  "list_ProductType_LOGISTICS_OPERATIONS": "Logistics & Operations",
  "list_ProductType_MARKETPLACE": "Marketplace",
  "list_ProductType_DATA_ANALYTICS": "Data & Analytics",
  "list_ProductType_COMMUNITY_EVENTS": "Community & Events",
  "list_ProductType_OTHER": "Other",
  "modal_edit_importances_title": "Edit Importances",
  "modal_edit_importances_description": "Set the importance either by adjusting the slider or manually entering values. When entering values manually, please make sure, that the total percentage must equal 100%.",
  "modal_edit_importances_row_col": "Col",
  "modal_edit_importances_row_product": "Product",
  "modal_edit_importances_row_share": "Share",
  "modal_edit_importances_row_imp": "IMP",
  "modal_edit_importances_select_placeholder": "Select or add a new one...",
  "modal_edit_importances_warning_title": "Total share percentages exceeds the maximum by 10%!",
  "modal_edit_importances_warning_description": "Make sure that total of share is 100%",
  "modal_edit_importances_btn_save": "Save",
  "modal_edit_importances_btn_cancel": "Cancel",
  "15_7_1_3_label_type": "Type",
  "15_7_1_3_label_avg_price": "Avg Price",
  "15_7_1_3_label_market_location": "Market Location",
  "15_7_2_1_toaster_success_title_deleted": "Deleted",
  "15_7_2_1_toaster_success_description_deleted": "Product deleted",
  "15_7_1_3_toaster_success_title_create": "Created",
  "15_7_1_3_toaster_error_title_create": "Error create product",
  "15_7_1_3_toaster_success_description_create": "Product create",
  "15_8_2_1_title_other_resources": "Other Resources"
}
