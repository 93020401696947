<template>
  <div class="modal-scale">
    <div class="modal-scale-header">
      <div class="modal-scale-title">
        <strong>{{ $t('15_5_5_1_title') }}</strong>
      </div>
      <button @click="handleCancel" >
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 6.5L6 18.5M6 6.5L18 18.5" stroke="#888F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>

    <div class="modal-scale-description">
      {{ $t('15_5_5_1_description') }}
    </div>

    <div class="modal-scale-wrapper">
      <div class="modal-scale-vis">
        <div
          class="modal-scale-vis-item"
          v-for="(option, index) in options"
          :key="index"
        >
          <span v-if="option.value === ''">{{ option.name }}</span>
          <span v-else>{{ option.value }}</span>
        </div>
      </div>
    </div>

    <div class="modal-scale-body">
      <FormGroup
        :label="firstOption.name"
      >
        <input
          type="text"
          :placeholder="$t('15_5_5_1_value_option_placeholder')"
          v-model="firstOption.value"
          @input="validationError.title = false"
          ref="contentTitle"
        />
      </FormGroup>

     <div
       class="column"
       v-for="(option, index) in newOptions"
       :key="index"
     >
       <FormGroup
         :label="option.name"
       >
         <input
           type="text"
           :placeholder="$t('15_5_5_1_value_option_placeholder')"
           v-model="option.value"
           @input="validationError.title = false"
           ref="contentTitle"
         />
       </FormGroup>
       <button @click="handleRemoveScale(index)" class="modal-scale-remove">
         <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path d="M3.33398 6.33333H16.6673M8.33398 9.66667V14.6667M11.6673 9.66667V14.6667M4.16732 6.33333L5.00065 16.3333C5.00065 16.7754 5.17625 17.1993 5.48881 17.5118C5.80137 17.8244 6.22529 18 6.66732 18H13.334C13.776 18 14.1999 17.8244 14.5125 17.5118C14.8251 17.1993 15.0007 16.7754 15.0007 16.3333L15.834 6.33333M7.50065 6.33333V3.83333C7.50065 3.61232 7.58845 3.40036 7.74473 3.24408C7.90101 3.0878 8.11297 3 8.33398 3H11.6673C11.8883 3 12.1003 3.0878 12.2566 3.24408C12.4129 3.40036 12.5007 3.61232 12.5007 3.83333V6.33333" stroke="#17252A" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
         </svg>
       </button>
     </div>

      <div
        v-if="newOptions.length < 3"
        class="modal-scale-add"
      >
        <button
          @click="handleAppendOption"
          class="modal-scale-add-btn"
        >
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99935 4.66602V16.3327M4.16602 10.4993H15.8327" stroke="#2CE0C1" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          {{ $t('15_5_5_1_btn_add_option') }}
        </button>
      </div>

      <FormGroup
        :label="lastOption.name"
      >
        <input
          type="text"
          :placeholder="$t('15_5_5_1_value_option_placeholder')"
          v-model="lastOption.value"
          @input="validationError.title = false"
          ref="contentTitle"
        />
      </FormGroup>
    </div>

    <div class="modal-scale-footer">
      <Button @click="handleCancel" type="secondary">{{ $t('15_5_5_1_btn_cancel') }}</Button>


      <Button v-if=" scaleToEdit && scaleToEdit.scale" @click="handleUpdate" type="primary">{{ $t('15_5_5_1_btn_update_custom_scale') }}</Button>
      <Button v-else @click="handleSave" type="primary">{{ $t('15_5_5_1_btn_add_custom_scale') }}</Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/button/Button.vue";
import Dropdown from "@/components/dropdown/Dropdown.vue";
import TextAreaEditor from "@/components/TextAreaEditor.vue";
import textLength from "@/constants/TextLengthConstant";
import WYSIWYGEditor from "@/components/WYSIWYGEditor.vue";
import TinymceEditor from "@/components/TinymceEditor.vue";
import FormGroup from "@/components/FormGroup.vue";
export default {
  components: {
    FormGroup,
    TinymceEditor,
    WYSIWYGEditor,
    TextAreaEditor,
    Button,
    Dropdown
  },
  computed: {
    length() {
      return textLength
    },
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    options() {
      let response = [];
      response.push(this.firstOption);
      response = [...response, ...this.newOptions];
      response.push(this.lastOption);
      return response;
    }
  },
  props: ['scaleToEdit'],
  created() {
    if(this.scaleToEdit && this.scaleToEdit.scale && Array.isArray(this.scaleToEdit.scale)) {

      this.firstOption = {
        name: this.$t('15_5_5_1_label_option') + ' #1',
        value: this.scaleToEdit.scale[0]
      }

      this.lastOption = {
        name: this.$t('15_5_5_1_label_option') + ' #5',
        value: this.scaleToEdit.scale[this.scaleToEdit.scale.length - 1]
      }



      if (this.scaleToEdit.scale.length === 5) {
        this.newOptions.push(
          {
            name: this.$t('15_5_5_1_label_option') + ' #2',
            value: this.scaleToEdit.scale[1]
          }
        )

        this.newOptions.push(
          {
            name: this.$t('15_5_5_1_label_option') + ' #3',
            value: this.scaleToEdit.scale[2]
          }
        )

        this.newOptions.push(
          {
            name: this.$t('15_5_5_1_label_option') + ' #4',
            value: this.scaleToEdit.scale[3]
          }
        )
      }



      if (this.scaleToEdit.scale.length === 4) {
        this.newOptions.push(
          {
            name: this.$t('15_5_5_1_label_option') + ' #2',
            value: this.scaleToEdit.scale[1]
          }
        )

        this.newOptions.push(
          {
            name: this.$t('15_5_5_1_label_option') + ' #3',
            value: this.scaleToEdit.scale[2]
          }
        )
      }



      if (this.scaleToEdit.scale.length === 3) {
        this.newOptions.push(
          {
            name: this.$t('15_5_5_1_label_option') + ' #2',
            value: this.scaleToEdit.scale[1]
          }
        )
      }
    }
  },
  data() {
    return {
      lastOption: {
        name: this.$t('15_5_5_1_label_option') + ' #5',
        value: ''
      },
      firstOption: {
        name: this.$t('15_5_5_1_label_option') + ' #1',
        value: ''
      },
      newOptions: [],
      optionCounter: 1,

      validationError: {
        title: '',
        description: ''
      },

      content: '',
      formValidation: {
        content: true,
      },
      contentIsOpenShowError: false,
      contentIsOpen: true,
    }
  },
  methods: {
    handleUpdate() {
      const scale = this.options.map((item) => item.value);
      this.$emit('updateScale', scale);
    },
    handleCancel() {
      this.$emit('close');
    },
    handleClose() {
      this.$emit('close');
    },
    handleSave() {
      const scale = this.options.map((item) => item.value);
      const preselectedIndex = Math.round(scale.length / 2) - 1;

      const item = {
        example: '',
        characteristic: '',
        scale: scale,
        selected: scale[preselectedIndex],
      }
      this.$emit('addScale', item);
      this.$emit('close');
    },
    saveEditName() {

    },
    cancelEditName() {

    },
    handleAppendOption() {
      this.optionCounter++;

      if (this.optionCounter > 4) {
        return;
      }
      this.newOptions.push(
        {
          name: this.$t('15_5_5_1_label_option') + ' #' + this.optionCounter,
          value: ''
        }
      );
    },
    handleRemoveScale(index) {
      this.optionCounter--;
      this.newOptions.splice(index, 1);
    }
  }
}
</script>

<style scoped>
.modal-scale {
  width: 600px;
}

.modal-scale-header {
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-scale-header button {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.modal-scale-title {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
  text-align: left;
  color: #17252A;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.modal-scale-title span {
  position: relative;
  font-family: Nunito;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  display: inline-block;
  color: #888F91;
}

.modal-scale-title span:after {
  content: '';
  position: absolute;
  left: -10px;
  top: 4px;
  bottom: 4px;
  width: 1px;
  background-color: #888F91;
}

.modal-scale-body {
  margin-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  gap: 30px;
  display: flex;
  flex-direction: column;
}

.modal-scale-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 32px;
  border-top: 1px solid #E7E9E9;
  padding: 32px;
}

.modal-scale-vis {
  border-radius: 6px;
  width: 100%;
  background: rgba(246, 246, 247, 1);
  display: flex;
  gap: 4px;
  padding: 4px;
  align-items: stretch;
  height: 100%;
  justify-content: flex-start;
}

.modal-scale-vis-item {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
  color: rgba(136, 143, 145, 1);
  width: 100%;
  border-radius: 6px;
  border: 1px dashed rgba(136, 143, 145, 1);
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-scale-wrapper {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
}

.modal-scale-add {
  display: flex;
  justify-content: flex-start;
}

.modal-scale-add-btn {
  border: none;
  background: none;
  display: flex;
  cursor: pointer;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: rgba(44, 224, 193, 1);
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
}

.modal-scale-remove {
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 6px;
  background: rgba(231, 233, 233, 1);
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.modal-scale-remove:hover {
  background: #d1d4d4;
  color: #17252a;
}

.modal-scale-description {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 15px;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: rgba(136, 143, 145, 1);
}
</style>
