<template>
  <div class="warning-maximum-limit">
    <div class="warning-maximum-limit__ico">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2C17.523 2 22 6.477 22 12C22.0021 14.6255 20.9715 17.1464 19.1309 19.0186C17.2902 20.8908 14.7872 21.9641 12.162 22.0066C9.53689 22.0491 7.00034 21.0576 5.1 19.246C3.19966 17.4344 2.08799 14.9482 2.005 12.324L2 12L2.004 11.72C2.152 6.327 6.57 2 12 2ZM12.01 15L11.883 15.007C11.64 15.0359 11.4159 15.153 11.2534 15.336C11.0909 15.519 11.0011 15.7552 11.0011 16C11.0011 16.2448 11.0909 16.481 11.2534 16.664C11.4159 16.847 11.64 16.9641 11.883 16.993L12 17L12.127 16.993C12.37 16.9641 12.5941 16.847 12.7566 16.664C12.9191 16.481 13.0089 16.2448 13.0089 16C13.0089 15.7552 12.9191 15.519 12.7566 15.336C12.5941 15.153 12.37 15.0359 12.127 15.007L12.01 15ZM12 7C11.7551 7.00003 11.5187 7.08996 11.3356 7.25272C11.1526 7.41547 11.0357 7.63975 11.007 7.883L11 8V12L11.007 12.117C11.0359 12.36 11.153 12.5841 11.336 12.7466C11.519 12.9091 11.7552 12.9989 12 12.9989C12.2448 12.9989 12.481 12.9091 12.664 12.7466C12.847 12.5841 12.9641 12.36 12.993 12.117L13 12V8L12.993 7.883C12.9643 7.63975 12.8474 7.41547 12.6644 7.25272C12.4813 7.08996 12.2449 7.00003 12 7Z" fill="#CCB700"/>
      </svg>
    </div>
    <div class="warning-maximum-limit__text">
      <div class="warning-maximum-limit__text__title">
        {{ $t('special_list_inputs_warning_title') }}
      </div>
      <div class="warning-maximum-limit__text__description">
        <p>{{ $t('special_list_inputs_warning_description_part_1') }} <a href="#" @click.prevent="handleGoToBilling()">{{ $t('special_list_inputs_warning_description_link') }}</a> {{ $t('special_list_inputs_warning_description_part_2') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WarningMaximumLimit',
  methods: {
    handleGoToBilling() {
      this.$router.push({ path: `/calendar` });
    }
  }
}
</script>

<style scoped>
.warning-maximum-limit {
  padding: 12px;
  display: flex;
  gap: 16px;
  align-items: flex-start;
  background: rgba(250, 245, 204, 1);
  border-radius: 6px;
  margin-top: 24px;
}

.warning-maximum-limit__ico {
  min-width: 23px;
  max-width: 23px;
  margin-top: 4px;
}

.warning-maximum-limit__text p{
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
  color: #101828;
}

.warning-maximum-limit__text p a{
  color: #101828;
}

.warning-maximum-limit__text p a:hover{
  text-decoration: none;
}

.warning-maximum-limit__text__title {
  font-family: Nunito;
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  text-align: left;
  color: rgba(16, 24, 40, 1);
}
</style>
