<template>
  <div class="participants">
    <div
      class="participants__header"
      :class="{ 'show-content-details': showContentDetails }"
      @click="toggleContentDetails()"
    >
      <span>{{ $t('Participants') }} (3)</span>
      <IcoToggle />
    </div>
    <div
      v-if="showContentDetails"
      class="participants__body"
    >
      <div class="participants__items">
        <ParticipantsItem />
        <ParticipantsItem />
        <ParticipantsItem />
      </div>

      <div class="participants__add">
        <button>
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0013 4.66602V16.3327M4.16797 10.4993H15.8346" stroke="#888F91" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          {{ $t('Add Participant') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import IcoToggle from "@/components/svg/IcoToggle.vue";
import ParticipantsItem from "@/components/sources/components/meetingNotes/ParticipantsItem.vue";

export default {
  components: {ParticipantsItem, IcoToggle},
  data() {
    return {
      showContentDetails: true,
    }
  },
  methods: {
    toggleContentDetails() {
      this.showContentDetails = !this.showContentDetails
    }
  }
}
</script>

<style scoped>
.participants {
  margin-top: 30px;
  border: 2px solid #f5f5f5;
  border-radius: 10px;
  max-width: 540px;
}

.participants__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  padding: 15px 20px;
  color: #17252a;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 14px;
  font-family: Nunito;
  letter-spacing: -0.2px;
  text-transform: uppercase;
}

.participants__header svg {
  transform: rotate(180deg);
  transition-duration: 0.2s;
  transition-property: transform;
}

.participants__header.show-content-details svg {
  transform: rotate(0deg);
  transition-duration: 0.2s;
  transition-property: transform;
}

.participants__body {
  padding: 0 20px 20px;
}

.participants__items {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.participants__add {
  padding-top: 16px;
}

.participants__add button {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #888F91;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
</style>
