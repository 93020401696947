<template>
  <div
    @click="editContent"
    class="content-status-card"
    :draggable="role === 'READ_ONLY' ? false : draggable"
    :class="{ 'read-only': role === 'READ_ONLY' || !draggable }"
    @dragstart="dragStart"
    @dragend="dragEnd"
    @dragover.prevent
    v-bind:data-state="idea.state"
    v-bind:data-date="idea.dates"
  >
    <div class="content-status-card__inner">
      <div class="content-status-card__title">{{ idea.name }}</div>
      <div
        class="content-status-card__label"
        :style="{ color: getColor(channelName) }"
      >
        {{ channelName }}
      </div>
      <div v-if="showDate" class="content-status-card__date">
        <span class="content-status-card__date__ico"><IcoCalendar /></span>
        <span>{{ idea.date | moment('Do MMM YYYY') }}</span>
      </div>
      <div class="content-status-card__user">
        <span class="content-status-card__user__ico"><IcoIdeaUser /></span>
        <span>{{ personas }}</span>
      </div>
      <div
        class="content-status-card__list content-status-card__list--capitalize"
      >
        <IcoList /> {{ stdcs }}
      </div>
      <div v-if="role !== 'READ_ONLY'" class="content-status-card__avatar">
        <Avatar :name="idea.owner.name" :user="idea.owner" />
      </div>
      <div class="content-status-card__menu">
        <VDropdown
          :placements="'bottom-end'"
          @hide="closeData"
          :eagerMount="autoHide"
          :auto-hide="autoHide"
          :triggers="[]"
          :shown="showControll"
        >
          <button @click.stop="toggleControll">
            <IcoKebabBig />
          </button>
          <template #popper>
            <div class="content-status-card__control">
              <ul>
                <li>
                  <button @click.stop="shareContent()">
                    <IcoShare /> {{ $t('8_4_content_btn_share') }}
                  </button>
                </li>
                <li v-if="role !== 'READ_ONLY'">
                  <button @click.stop="duplicateContent()">
                    <IcoDuplicate /> {{ $t('8_4_content_btn_duplicate') }}
                  </button>
                </li>
                <li v-if="role !== 'READ_ONLY'">
                  <button @click.stop="deleteContent()">
                    <IcoBin /> {{ $t('8_4_content_btn_delete') }}
                  </button>
                </li>
              </ul>
            </div>
          </template>
        </VDropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { ColorService } from '@/services/ColorService.js'
import moment from 'moment'

import IcoKebabBig from '@/components/svg/IcoKebabBig.vue'
import IcoIdeaUser from '@/components/svg/IcoIdeaUser.vue'
import IcoCalendar from '@/components/svg/IcoCalendar.vue'
import IcoList from '@/components/svg/IcoList.vue'
import IcoShare from '@/components/svg/IcoShare.vue'
import IcoDuplicate from '@/components/svg/IcoDuplicate.vue'
import IcoBin from '@/components/svg/IcoBin.vue'

import CalendarHeader from '@/components/CalendarHeader.vue'
import Avatar from '@/components/Avatar.vue'

import ClickOutside from 'vue-click-outside'
import Button from "@/components/button/Button.vue";

export default {
  components: {
    CalendarHeader,
    Avatar,
    IcoKebabBig,
    IcoIdeaUser,
    IcoCalendar,
    IcoList,
    IcoShare,
    IcoDuplicate,
    IcoBin,
    Button,
  },
  directives: {
    ClickOutside,
  },
  props: {
    idea: {
      type: Object,
      required: true,
    },
    draggable: {
      type: Boolean,
      default: true,
    },
    showDate: {
      type: Boolean,
      default: true,
    },
  },
  filters: {
    date: function (date) {
      return moment(date).format('Do MMM YYYY')
    },
  },
  computed: {
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
    channelName() {
      if (this.idea.channel && this.idea.channel.name) {
        return this.idea.channel.name
      }
      return ''
    },
    personas() {
      if (this.idea && this.idea.personas && this.idea.personas.length > 0) {
        return this.idea.personas.map((e) => e.name).join(', ')
      }

      return ''
    },
    stdcs() {
      if (this.idea && this.idea.stdcs) {
        return this.idea.stdcs.join(', ').toLowerCase()
      }

      return ''
    },
  },
  data() {
    return {
      showControll: false,
      autoHide: true,
    }
  },
  methods: {
    async shareContent() {
      this.showControll = false
      const link =
        '' +
        window.location.protocol +
        window.location.host +
        '/calendar/' +
        this.selectedCompany.id +
        '/' +
        this.idea.id

      await navigator.clipboard.writeText(link)

      this.$notify({
        title: this.$t('share_content_title_copied_to_clipboard'),
        text: this.$t('share_content_description_copied_to_clipboard'),
        type: 'info',
        duration: 5000,
      })
    },
    duplicateContent() {
      this.showControll = false
      this.$store.commit('setDuplicateContentModalId', this.idea.id)
    },
    deleteContent() {
      this.showControll = false
      this.$store.commit('setContentToDelete', this.idea)
    },
    hideControll() {
      this.showControll = false
    },
    toggleControll() {
      this.showControll = !this.showControll
      this.autoHide = false
      setTimeout(() => (this.autoHide = true), 300)
    },
    editContent() {
      this.$store.commit('setEditContentModalStatus', this.idea)
    },
    getColor(name) {
      return ColorService.computedColor(name)
    },
    dragStart(e) {
      this.$store.commit('setGlobalDrag', true)
      const target = e.target
      e.dataTransfer.setData('idea_id', this.idea.id)
      e.dataTransfer.setData('idea_date', this.idea.date)
    },
    dragEnd(e) {
      this.$store.commit('setGlobalDrag', false)
      this.$store.commit('setActiveDropZone', '')
    },
    closeData() {
      this.showControl = false
    },
  },
}
</script>

<style scoped>
.content-status-card {
  display: block;
  cursor: grab;
  margin-top: 15px;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05);
  border-color: transparent;
  border-radius: 8px;
  background: #ffffff;
  padding: 14px 0 14px 25px;
}

.content-status-card.read-only {
  cursor: pointer;
}

.content-status-card__inner {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  width: 100%;
}

.content-status-card__title {
  display: inline-block;
  width: 40%;
  overflow: hidden;
  color: #17252a;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  font-family: Nunito;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content-status-card__label {
  display: inline-block;
  align-items: center;
  width: 10%;
  overflow: hidden;
  color: #26c6ff;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  font-family: Nunito;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content-status-card__date {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  vertical-align: center;
  gap: 8px;
  width: 14%;
  overflow: hidden;
  color: #17252a;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  font-family: Nunito;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content-status-card__date svg {
  position: relative;
  top: -1px;
  flex: 1 0 0;
  width: max-content;
  min-width: 16px;
  max-width: 16px;
  height: 16px;
}

@media screen and (max-width: 1140px) {
  .content-status-card__date svg {
    display: none;
  }
}

.content-status-card__user {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 16%;
  overflow: hidden;
  color: #17252a;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  font-family: Nunito;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content-status-card__user__ico {
  display: inline-block;
  flex-shrink: 0;
}

.content-status-card__list {
  display: flex;
  vertical-align: center;
  gap: 8px;
  width: 10%;
  overflow: hidden;
  color: #17252a;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  font-family: Nunito;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content-status-card__list--capitalize {
  text-transform: capitalize;
}

.content-status-card__avatar {
  display: flex;
  width: 4%;
}

.content-status-card__menu {
  position: relative;
  width: 5%;
}

.content-status-card__menu button {
  position: relative;
  top: 3px;
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 0 25px;
}

.content-status-card__control {
  top: 100%;
  right: 0;
  z-index: 99;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  background: #fff;
}

.content-status-card__control ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.content-status-card__control ul li {
  text-align: left;
}

.content-status-card__control ul li button {
  display: block;
  margin: 0;
  border: none;
  background: none;
  padding: 0;
  padding-left: 16px;
  min-width: 124px;
  height: 36px;
  color: #17252a;
  font-style: normal;

  font-weight: bold;
  font-size: 14px;
  line-height: 36px;
  font-family: 'Nunito';
  text-align: left;
}

.content-status-card__control ul li:hover {
  background: #f6f6f7;
}

.content-status-card__control ul li svg {
  display: inline-block;
  position: relative;
  top: 2px;
  margin-right: 4px;
}
</style>
