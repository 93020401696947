<template>
  <div class="dropdown-multi-selected">
    <div class="dropdown-multi-selected-name">
      <template v-if="type === 'geography'">{{ getFlagEmoji(item.value) }}</template> {{ item.name }}
    </div>
    <button
      @click.prevent="handleRemoveSelected"
      class="dropdown-multi-selected-remove"
    >
      <IcoCross />
    </button>
  </div>
</template>

<script>
import IcoCross from "../svg/small/IcoCross.vue";
import {getFlagEmoji} from "../../utils/utils";

export default {
  name: 'DropdownMultiSelected',
  components: {IcoCross},
  data() {
    return {

    }
  },
  props: ['item', 'type'],
  methods: {
    getFlagEmoji,
    handleRemoveSelected() {
      this.$emit('removeMultiSelected');
    }
  }
}
</script>

<style scoped>
.dropdown-multi-selected {
  display: inline-flex;
  gap: 6px;
  padding: 4px;
  border-radius: 3px;
  background: rgba(231, 233, 233, 1);
  align-items: center;
  line-height: 1;
}

.dropdown-multi-selected-name {
  font-family: Nunito;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  color: rgba(23, 37, 42, 1);
  line-height: 1;
}

.dropdown-multi-selected-remove {
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  line-height: 1;
  cursor: pointer;
  position: relative;
  top: 1px;
}
</style>
