function convertMBToBytes(mb) {
  return mb * 1024 * 1024; // 1 MB = 1024 * 1024 bytes
}

function readableFileSize(fileSize) {
  const bytes = fileSize;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  const size = bytes / Math.pow(1024, i);
  return size.toFixed(2) + ' ' + sizes[i];
}

module.exports = {
  convertMBToBytes,
  readableFileSize
}
