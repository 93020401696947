<template>
  <div class="join-email">
    <RadioButtons v-model="usersRole" :items="[{'name': $t('role_approver'), 'value': 'APPROVER'}, {'name': $t('role_admin'), 'value': 'ADMIN'}]" />

    <div class="join-email__wrapper" :class="{'has-focus': focusJoinEmail}">
      <div class="join-email__placeholder">
        {{ $t("15_2_label_email") }}
        <IcoInfo v-tooltip="$t('15_2_tooltip_email')" />
      </div>

      <div class="join-email__input">
        <div v-if="emails.length > 0" style="padding-bottom: 5px">
          <div
            v-for="(item, index) in emails"
            :key="index"
            :class="{ 'is-invalidEmail': !validateEmail(item) }"
            class="join-email__item"
          >
            {{ item }}
            <span @click.stop="deleteItem(index)" class="join-email__delete"
              ><IcoCross
            /></span>
          </div>
        </div>

        <input
          v-model="joinEmail"
          type="email"
          :placeholder="$t('15_2_placeholder_email')"
          @keydown.enter="addEmail"
          @keydown.188="addEmail"
          @keydown.delete="removeLastEmail"
          @keyup.22="addEmail"
          @keyup.ctrl.76="addEmail"
          @focus="focusJoinEmail = true"
          @blur="focusJoinEmail = false"
        />
      </div>
    </div>

    <div v-if="(totalUsers + emails.length) >= maxUsers" class="join-email__maximum-quota">
      <div class="join-email__maximum-quota__ico">
        <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.44 0C11.9212 0.000330943 12.3951 0.116773 12.8216 0.339439C13.2482 0.562104 13.6147 0.884415 13.89 1.279L13.998 1.443L22.429 15.517C22.6816 15.955 22.819 16.4499 22.8283 16.9555C22.8376 17.461 22.7184 17.9607 22.4821 18.4077C22.2457 18.8547 21.8998 19.2344 21.4767 19.5113C21.0536 19.7882 20.5672 19.9532 20.063 19.991L19.863 20H3.007C2.50219 19.994 2.00713 19.8602 1.56796 19.6112C1.1288 19.3622 0.759831 19.006 0.495465 18.5759C0.231098 18.1458 0.0799379 17.6558 0.0560764 17.1515C0.032215 16.6473 0.136429 16.1451 0.359004 15.692L0.460004 15.503L8.885 1.438C9.15148 0.999015 9.52656 0.636088 9.97408 0.384215C10.4216 0.132341 10.9265 1.66427e-05 11.44 0ZM11.51 14L11.383 14.007C11.14 14.0359 10.9159 14.153 10.7534 14.336C10.5909 14.519 10.5011 14.7552 10.5011 15C10.5011 15.2448 10.5909 15.481 10.7534 15.664C10.9159 15.847 11.14 15.9641 11.383 15.993L11.5 16L11.627 15.993C11.8701 15.9641 12.0941 15.847 12.2566 15.664C12.4191 15.481 12.5089 15.2448 12.5089 15C12.5089 14.7552 12.4191 14.519 12.2566 14.336C12.0941 14.153 11.8701 14.0359 11.627 14.007L11.51 14ZM11.5 6C11.2551 6.00003 11.0187 6.08996 10.8356 6.25272C10.6526 6.41547 10.5357 6.63975 10.507 6.883L10.5 7V11L10.507 11.117C10.5359 11.36 10.653 11.5841 10.836 11.7466C11.019 11.9091 11.2552 11.9989 11.5 11.9989C11.7448 11.9989 11.981 11.9091 12.164 11.7466C12.347 11.5841 12.4641 11.36 12.493 11.117L12.5 11V7L12.493 6.883C12.4643 6.63975 12.3474 6.41547 12.1644 6.25272C11.9813 6.08996 11.7449 6.00003 11.5 6Z" fill="#F12727"/>
        </svg>
      </div>
      <div class="join-email__maximum-quota__text">
        <p>{{$t('15_2_5_1_description_maximum_quota')}} <strong>({{maxUsers}})</strong>!</p>
      </div>
    </div>

    <div v-if="totalUsers >= maxUsers" class="join-email__reached-maximum-quota">
      <div class="join-email__reached-maximum-quota__ico">
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.5 2C18.023 2 22.5 6.477 22.5 12C22.5021 14.6255 21.4715 17.1464 19.6309 19.0186C17.7902 20.8908 15.2872 21.9641 12.662 22.0066C10.0369 22.0491 7.50034 21.0576 5.6 19.246C3.69966 17.4344 2.58799 14.9482 2.505 12.324L2.5 12L2.504 11.72C2.652 6.327 7.07 2 12.5 2ZM12.51 15L12.383 15.007C12.14 15.0359 11.9159 15.153 11.7534 15.336C11.5909 15.519 11.5011 15.7552 11.5011 16C11.5011 16.2448 11.5909 16.481 11.7534 16.664C11.9159 16.847 12.14 16.9641 12.383 16.993L12.5 17L12.627 16.993C12.87 16.9641 13.0941 16.847 13.2566 16.664C13.4191 16.481 13.5089 16.2448 13.5089 16C13.5089 15.7552 13.4191 15.519 13.2566 15.336C13.0941 15.153 12.87 15.0359 12.627 15.007L12.51 15ZM12.5 7C12.2551 7.00003 12.0187 7.08996 11.8356 7.25272C11.6526 7.41547 11.5357 7.63975 11.507 7.883L11.5 8V12L11.507 12.117C11.5359 12.36 11.653 12.5841 11.836 12.7466C12.019 12.9091 12.2552 12.9989 12.5 12.9989C12.7448 12.9989 12.981 12.9091 13.164 12.7466C13.347 12.5841 13.4641 12.36 13.493 12.117L13.5 12V8L13.493 7.883C13.4643 7.63975 13.3474 7.41547 13.1644 7.25272C12.9813 7.08996 12.7449 7.00003 12.5 7Z" fill="#CCB700"/>
        </svg>
      </div>
      <div class="join-email__reached-maximum-quota__text">
        <p><strong>{{$t('15_2_5_2_title_reached_maximum_quota')}}</strong></p>
        <p>{{$t('15_2_5_2_description_reached_maximum_quota')}}</p>
      </div>
    </div>

    <div class="join-email__button">
      <Button
        @click="clickedInvite()"
        :disabled="joinEmail.length < 1 && !(emails.length > 0)"
        style="width: 100%"
        type="primary"
        >{{ $t("15_2_btn_send_email_join_request") }}
      </Button>
    </div>
  </div>
</template>

<script>
import INVITE_CREATE from "@/graphql/InviteCreate.gql";
import IcoInfo from "@/components/svg/small/IcoInfo.vue";
import IcoCross from "@/components/svg/small/IcoCross.vue";


import Button from "@/components/button/Button.vue";
import Checkbox from "@/components/Checkbox.vue";
import RadioButtons from '@/components/RadioButtons.vue';

export default {
  components: {
    Button,
    IcoInfo,
    IcoCross,
    Checkbox,
    RadioButtons
  },
  computed: {
    selectedCompany() {
      return this.$store.getters.getSelectedCompany;
    },
  },
  props: [
    'maxUsers',
    'totalUsers'
  ],
  data() {
    return {
      focusJoinEmail: false,
      isDisabledSendJoinRequest: true,
      joinEmail: "",
      emails: [],
      doneInvites: 0,
      totalInvites: 0,
      usersRole: 'APPROVER'
    }
  },
  methods: {
    deleteItem(index) {
      this.emails.splice(index, 1);

      this.isDisabledSendJoinRequest = this.emails.length <= 0;
    },
    clickedInvite() {

      if (this.joinEmail.trim().length > 0) {
        // this.emails.push(this.joinEmail.trim())

        const strings = this.joinEmail.trim().split(/[, ]+/);
        for (let i = 0; i < strings.length; i++) {
          const element = strings[i];

          if (this.emails.length >= 10) {
            this.$notify({
              title: this.$t("6_3_toaster_error_title_max_users"),
              text: this.$t("6_3_toaster_error_description_max_users"),
              type: "error",
              duration: 5000,
            });

            return;
          }

          if (element.length > 0) {
            this.emails.push(element.trim());
          }
        }
      }

      this.joinEmail = "";

      this.totalInvites = this.emails.length;

      this.emails.forEach((email) => {
        if (this.validateEmail(email)) {
          try {
            this.$apollo
              .mutate({
                mutation: INVITE_CREATE,
                variables: {
                  id: this.selectedCompany.id,
                  email: email,
                  companyRole: this.usersRole
                },
                update: (store, { data: { updateCompany } }) => {
                  this.$notify({
                    title: this.$t("6_3_1_toaster_success_title_user_invited") + " " + email,
                    text: this.$t(
                      "6_3_1_toaster_success_description_user_invited"
                    ),
                    type: "success",
                    duration: 5000,
                  });
                },
              })
              .then(() => {
                this.doneInvites++
                this.emails.splice( this.emails.indexOf(email), 1)

                this.isDisabledSendJoinRequest = this.emails.length <= 0;
              })
              .catch((error) => {
                this.$notify({
                  title: this.$t("6_3_2_toaster_error_title_user_invited") + " " + email,
                  text: this.$t("global_toaster_error_description"),
                  type: "error",
                  duration: 5000,
                });
              });
          } catch (e) {
            this.$notify({
              title: this.$t("6_3_2_toaster_error_title_user_invited") + " " + email,
              text: this.$t("global_toaster_error_description"),
              type: "error",
              duration: 5000,
            });
          }
        }

      });
    },
    validateEmail(email) {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
    },
    addEmail(event) {
      event.preventDefault();

      const val = event.target.value.trim();
      if (val.length > 0) {
        const strings = val.split(/[, ]+/);
        for (let i = 0; i < strings.length; i++) {
          const element = strings[i];

          if (this.emails.length >= 10) {
            this.$notify({
              title: this.$t("6_3_toaster_error_title_max_users"),
              text: this.$t("6_3_toaster_error_description_max_users"),
              type: "error",
              duration: 5000,
            });

            return;
          }

          if (element.length > 0) {
            this.emails.push(element.trim());
          }
        }

        this.joinEmail = "";
      }
    },
    removeLastEmail(event) {
      if (event.target.value.length === 0) {
        this.removeEmail(this.emails.length - 1);
      }
    },
    removeEmail(index) {
      this.emails.splice(index, 1);

      this.isDisabledSendJoinRequest = this.emails.length <= 0;
    },
  },
  watch: {
    joinEmail() {
      this.isDisabledSendJoinRequest = this.emails.length <= 0;
    },
    doneInvites() {
      this.$emit('done')

      /*
      if (this.doneInvites === this.totalInvites) {
        this.$emit('done')
      }
       */
    }
  },
};
</script>

<style scoped>
.join-email {
  margin-top: 20px;
}

.join-email__wrapper {
  position: relative;
  display: block;
  mix-blend-mode: normal;
  border: 1px solid #bfc5c5;
  border-radius: 8px;
  width: 100%;
  margin-top: 20px;
}

.join-email__wrapper.has-focus {
  border: 1px solid hsl(170, 74%, 53%);
}

.join-email__placeholder {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.2px;
  color: #17252a;

  position: absolute;
  top: 0;
  left: 14px;
  transform: translate(0, -50%);
  display: inline-block;
  padding: 2px 6px;
  background-color: #fff;

  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.2px;
  color: #17252a;
}

.join-email__input {
  margin-top: 6px;

  padding: 8px 20px 12px 20px;
}

.join-email__item {
  display: block;
  padding: 6px 0;
}

.join-email__item.has-focus {
  border: 1px solid hsl(170, 74%, 53%);
}

.join-email__item.is-invalidEmail {
  color: red;
}

.join-email__input input {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  letter-spacing: -0.2px;
  color: #17252a;
  padding: 0;
  margin: 0;
  width: 100%;

  border: none;
  background: transparent;
}

.join-email__input input:focus {
  outline: none;
}

.join-email__input input::placeholder {
  color: #bfc5c5;
}

.join-email__button {
  margin-top: 24px;
}

.join-email__delete {
  position: relative;
  top: 1px;
  cursor: pointer;
  display: none;
  padding-left: 5px;
}

.join-email__item:hover .join-email__delete {
  display: inline-block;
}

.join-email__maximum-quota {
  padding: 12px;
  display: flex;
  gap: 16px;
  align-items: flex-start;
  background: #FCD4D4;
  border-radius: 6px;
  margin-top: 24px;
}

.join-email__maximum-quota__ico {
  min-width: 23px;
  max-width: 23px;
  margin-top: 4px;
}

.join-email__maximum-quota__text p{
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
  color: #101828;
}


.join-email__reached-maximum-quota {
  padding: 12px;
  display: flex;
  gap: 16px;
  align-items: flex-start;
  background: #FAF5CC;
  border-radius: 6px;
  margin-top: 24px;
}

.join-email__reached-maximum-quota__ico {
  min-width: 23px;
  max-width: 23px;
  margin-top: 4px;
}

.join-email__reached-maximum-quota__text {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.join-email__reached-maximum-quota__text p{
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
  color: #101828;
}
</style>
