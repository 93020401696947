<template>
  <div class="modal-edit-content">
    <div class="modal-edit-content-header">
      <div class="modal-edit-content-title">
        <strong>{{ $t('15_7_1_2_modal_edit_title') }}</strong>
      </div>
      <button @click="handleCancel" >
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 6.5L6 18.5M6 6.5L18 18.5" stroke="#888F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>

    <div class="modal-edit-content-body">

      <div class="column">
        <FormGroup
          :label="$t('15_7_1_2_label_product_service_title')"
          :required="true"
          :validationError="validationError.name"
        >
          <input
            type="text"
            :placeholder="$t('15_7_1_2_placeholder_product_service_title')"
            v-model="product.name"
            @input="validationError.name = false"
            ref="contentName"
          />
        </FormGroup>
      </div>

      <div class="column">
        <FormGroup
          :label="$t('15_7_1_2_label_key_insight')"
          :required="false"
        >
          <input
            type="text"
            :placeholder="$t('15_7_1_2_placeholder_key_insight')"
            v-model="product.keyInsights"
          />
        </FormGroup>
      </div>

      <div class="column">
        <Dropdown
          items-position="top"
          v-model="product.averagePrice"
          :label="$t('15_7_1_2_label_average_price')"
          :placeholder="$t('15_7_1_2_placeholder_average_price')"
          :items="AveragePrice"
        />
      </div>
    </div>

    <div class="modal-edit-content-footer">
      <Button @click="handleCancel" type="secondary">{{ $t('15_7_1_2_modal_edit_btn_cancel') }}</Button>
      <Button @click="handleCreate" type="primary">{{ $t('15_7_1_2_modal_edit_btn_save') }}</Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/button/Button.vue";
import Dropdown from "@/components/dropdown/Dropdown.vue";
import TextAreaEditor from "@/components/TextAreaEditor.vue";
import textLength from "@/constants/TextLengthConstant";
import WYSIWYGEditor from "@/components/WYSIWYGEditor.vue";
import TinymceEditor from "@/components/TinymceEditor.vue";
import FormGroup from "@/components/FormGroup.vue";
import CompanyLogo from "@/components/CompanyLogo.vue";
import {DateService as DataService} from "../../../../services/DateService";
export default {
  components: {
    CompanyLogo,
    FormGroup,
    TinymceEditor,
    WYSIWYGEditor,
    TextAreaEditor,
    Button,
    Dropdown
  },
  computed: {
    length() {
      return textLength
    },
    role() {
      return this.$store.getters.getMeCompanyRole
    },
  },
  props: {
    content: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      product: {
        name: '',
        keyInsights: '',
        averagePrice: null
      },
      validationError: {
        name: false,
      },
      formValidation: {
        content: true,
      },
      contentIsOpenShowError: false,
      contentIsOpen: true,

      AveragePrice: DataService.getAveragePrices()
    }
  },
  methods: {
    handleCancel() {
      this.$emit('close');
    },
    handleCreate() {
      this.$emit('create', this.product);
    }
  }
}
</script>

<style scoped>
.modal-edit-content {
  width: 600px;
}

.modal-edit-content-header {
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-edit-content-header button {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.modal-edit-content-title {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
  text-align: left;
  color: #17252A;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.modal-edit-content-title span {
  position: relative;
  font-family: Nunito;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  display: inline-block;
  color: #888F91;
}

.modal-edit-content-title span:after {
  content: '';
  position: absolute;
  left: -10px;
  top: 4px;
  bottom: 4px;
  width: 1px;
  background-color: #888F91;
}

.modal-edit-content-body {
  margin-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  gap: 30px;
  display: flex;
  flex-direction: column;
}

.modal-edit-content-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 32px;
  border-top: 1px solid #E7E9E9;
  padding: 32px;
}
</style>
