<template>
  <div class="scale-card">
    <div class="scale-card-value">
      <Scale
        :selected="scale.selected"
        :choices="scale.scale"
        @setScale="setScale"
      />
    </div>
    <div class="scale-card-texts">
      <div class="scale-card-texts-item">
        <FormGroup
          :label="$t('15_5_2_label_characteristics')"
          :focused="focusedCharacteristics"
        >
          <input
            type="text"
            :placeholder="$t('15_5_2_placeholder_characteristics')"
            v-model="scale.characteristic"
            @input="validationError.characteristics = false"
            @focus="focusedCharacteristics = true"
            @blur="focusedCharacteristics = false"
            ref="characteristics"
          />
        </FormGroup>
      </div>
      <div class="scale-card-texts-item">
        <FormGroup
          :label="$t('15_5_2_label_examples')"
          :focused="focusedExamples"
        >
          <input
            type="text"
            :placeholder="$t('15_5_2_placeholder_examples')"
            v-model="scale.example"
            @input="validationError.examples = false"
            @focus="focusedExamples = true"
            @blur="focusedExamples = false"
            ref="examples"
          />
        </FormGroup>
      </div>
      <div class="scale-card-control">

        <button
          v-if="type === 'additional'"
          @click="handleAppendScale"
        >
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99935 4.66602V16.3327M4.16602 10.4993H15.8327" stroke="#17252A" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>

        <VDropdown
          v-if="type === 'custom'"
          :triggers="[]"
          :shown="showData"
          :handle-resize="true"
          :arrow-padding="distanceNo"
          :popperClass="boxSize"
          :auto-hide="autoHide"
          :eagerMount="autoHide"
          @hide="closeData"
        >
          <button @click="openData">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.16602 10.5007C9.16602 10.7217 9.25381 10.9336 9.41009 11.0899C9.56637 11.2462 9.77833 11.334 9.99935 11.334C10.2204 11.334 10.4323 11.2462 10.5886 11.0899C10.7449 10.9336 10.8327 10.7217 10.8327 10.5007C10.8327 10.2796 10.7449 10.0677 10.5886 9.9114C10.4323 9.75512 10.2204 9.66732 9.99935 9.66732C9.77833 9.66732 9.56637 9.75512 9.41009 9.9114C9.25381 10.0677 9.16602 10.2796 9.16602 10.5007Z" stroke="#17252A" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9.16602 16.334C9.16602 16.555 9.25381 16.767 9.41009 16.9232C9.56637 17.0795 9.77833 17.1673 9.99935 17.1673C10.2204 17.1673 10.4323 17.0795 10.5886 16.9232C10.7449 16.767 10.8327 16.555 10.8327 16.334C10.8327 16.113 10.7449 15.901 10.5886 15.7447C10.4323 15.5884 10.2204 15.5007 9.99935 15.5007C9.77833 15.5007 9.56637 15.5884 9.41009 15.7447C9.25381 15.901 9.16602 16.113 9.16602 16.334Z" stroke="#17252A" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9.16602 4.66732C9.16602 4.88833 9.25381 5.10029 9.41009 5.25657C9.56637 5.41285 9.77833 5.50065 9.99935 5.50065C10.2204 5.50065 10.4323 5.41285 10.5886 5.25657C10.7449 5.10029 10.8327 4.88833 10.8327 4.66732C10.8327 4.4463 10.7449 4.23434 10.5886 4.07806C10.4323 3.92178 10.2204 3.83398 9.99935 3.83398C9.77833 3.83398 9.56637 3.92178 9.41009 4.07806C9.25381 4.23434 9.16602 4.4463 9.16602 4.66732Z" stroke="#17252A" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>

          <template #popper>
            <ScaleMenu
              @editScale="editScale"
              @deleteScale="deleteScale"
            />
          </template>

        </VDropdown>
      </div>
    </div>
  </div>
</template>
<script>
import Scale from "@/components/sources/components/toneOfVoice/Scale.vue";
import FormGroup from "@/components/FormGroup.vue";
import ScaleMenu from "./ScaleMenu.vue";

export default {
  components: {ScaleMenu, FormGroup, Scale},
  props: ['scale', 'type'],
  data() {
    return {
      characteristics: '',
      examples:'',

      focusedExamples: false,
      focusedCharacteristics: false,

      validationError: {
        characteristics: false,
        examples: false,
      },
      showData: false,
      distanceNo: 12,
      autoHide: true,
      boxSize: 'popper-scale-submenu',
    }
  },
  methods: {
    closeData() {
      this.showData = false
    },
    openData() {
      if (this.disabled) {
        this.showData = false
        return
      }
      this.autoHide = false
      setTimeout(() => (this.autoHide = true), 300)

      this.showData = true
    },
    deleteScale() {
      this.$emit('deleteScale');
      this.closeData();
    },
    editScale() {
      this.$emit('editScale');
      this.closeData();
    },
    setScale(scale) {
      if(this.type === 'additional') {
        this.$emit('setAdditionalScale', scale);
      }

      if(this.type === 'custom') {
        this.$emit('setCustomScale', scale);
      }
    },
    handleAppendScale() {
      this.$emit('appendScale', this.scale);
      this.closeData();
    }
  }
}
</script>

<style scoped>
.scale-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.scale-card-value {
  width: 100%;
}

.scale-card-texts {
  min-width: 455px;
  max-width: 455px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.scale-card-texts-item {
  width: 100%;
}

.scale-card-control {
  min-width: 50px;
  max-width: 50px;
  height: 50px;
}

.scale-card-control button {
  width: 50px;
  height: 50px;
  background: #E7E9E9;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  line-height: 1;
}
</style>
