<template>
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 1V9M9 5L1 5" :stroke="color" stroke-width="1.5" stroke-linecap="round"/>
    </svg>
</template>

<script>
export default ({
  components: {

  },
  props: {
    color: {
      type: String,
      default: 'white'
    },
  },
  methods: {

  }
})
</script>
