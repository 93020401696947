<template>
  <div class="item">
    <div class="item-avatar">
      <Avatar size="small" />
    </div>
    <div class="item-data">
      <div class="item-data-text">
        <div class="item-data-text-persona">
          <div class="item-data-text-persona-name">Vladka Bačová</div>
          <div class="item-data-text-persona-position">CEO</div>
        </div>
        <div class="item-data-text-role">
          <RoleLabel name="OWNER" />
        </div>
      </div>
      <div class="item-data-control">
        <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M1.03117 2.5782C0.64065 2.18768 0.64065 1.55451 1.03117 1.16399C1.4217 0.773463 2.05486 0.773463 2.44539 1.16399L6.86834 5.58694L11.2913 1.16399C11.6818 0.773463 12.315 0.773463 12.7055 1.16399C13.096 1.55451 13.096 2.18768 12.7055 2.5782L8.28255 7.00115L12.705 11.4236C13.0955 11.8141 13.0955 12.4473 12.705 12.8378C12.3144 13.2283 11.6813 13.2283 11.2908 12.8378L6.86834 8.41537L2.44592 12.8378C2.0554 13.2283 1.42223 13.2283 1.03171 12.8378C0.641185 12.4473 0.641185 11.8141 1.03171 11.4236L5.45413 7.00115L1.03117 2.5782Z" fill="#AEB3B5"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar.vue'
import RoleLabel from "@/components/sources/components/roleLabel/RoleLabel.vue";

export default {
  components: {
    RoleLabel,
    Avatar
  },
  data() {
    return {

    }
  }
}
</script>

<style scoped>
.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.item-avatar {
  min-width: 25px;
  max-width: 25px;
  min-height: 25px;
  max-height: 25px;
}

.item-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.item-data-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.item-data-control {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-data-text-persona-name {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.1px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
  color: #17252A;
}

.item-data-text-persona-position {
  font-family: Nunito;
  font-size: 12px;
  font-weight: 500;
  line-height: 16.37px;
  text-align: left;
  color: #888F91;
}
</style>
