<template>
  <div id="app" :key="$i18n.locale">
    <router-view />

    <notifications>
      <template slot="body" slot-scope="{ item, close }">
        <div class="notification" :class="[item.type]">
          <button class="notification__close" @click="close">
            <IcoClose />
          </button>

          <div class="notification__ico">
            <IcoError v-if="item.type === 'error'" />
            <IcoWarning v-if="item.type === 'warning'" />
            <IcoSuccess v-if="item.type === 'success'" />
            <IcoInfo v-if="item.type === 'info'" />
          </div>

          <div class="notification__data">
            <div class="notification__data__title">{{ item.title }}</div>
            <div class="notification__data__text" v-html="item.text"></div>
          </div>
        </div>
      </template>
    </notifications>

    <ModalContent
      v-if="showCreateContentModal || duplicateContentModalId !== ''"
    />
    <ModalEditContent
      v-if="
        showEditContentModal.id ||
        (showEditContentModal.id && showEditContentModal.companyId)
      "
    />
    <ModalContact v-if="showContactModal" />
    <ModalDeleteContent v-if="showModalContentDelete.id" />
    <ModalConfirmCloseCreateContent v-if="showModalConfirmCloseCreateContent" />
    <ModalConfirmCloseEditContent v-if="showModalConfirmCloseEditContent" />
    <ModalUserRolesInfo v-if="showModalUserRolesInfo" />
    <ModalFeedback v-if="showModalFeedback" />
    <ModalInappropriateDevice v-if="showModalInappropriateDevice" />
  </div>
</template>

<script>
import { states } from '@/constants/StateConstant.js'
import { stdcs } from '@/constants/STDCSConstant.js'

import gql from 'graphql-tag'

import ModalFeedback from '@/components/ModalFeedback.vue'
import ModalContent from '@/components/ModalContent.vue'
import ModalEditContent from '@/components/ModalEditContent.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import ModalContact from '@/components/ModalContact.vue'
import ModalDeleteContent from '@/components/ModalDeleteContent.vue'
import ModalUserRolesInfo from '@/components/ModalUserRolesInfo.vue'
import ModalConfirmCloseCreateContent from '@/components/ModalConfirmCloseCreateContent.vue'
import ModalConfirmCloseEditContent from '@/components/ModalConfirmCloseEditContent.vue'

import IcoError from '@/components/svg/notification/IcoError.vue'
import IcoClose from '@/components/svg/notification/IcoClose.vue'
import IcoSuccess from '@/components/svg/notification/IcoSuccess.vue'
import IcoWarning from '@/components/svg/notification/IcoWarning.vue'
import IcoInfo from '@/components/svg/notification/IcoInfo.vue'
import ModalInappropriateDevice from '@/components/ModalInappropriateDevice.vue'

export default {
  components: {
    ModalInappropriateDevice,
    ModalFeedback,
    ModalContent,
    ModalEditContent,
    ModalContact,
    ModalDeleteContent,
    ModalUserRolesInfo,
    ModalConfirmCloseCreateContent,
    ModalConfirmCloseEditContent,
    ConfirmDialog,
    IcoError,
    IcoClose,
    IcoSuccess,
    IcoWarning,
    IcoInfo,
  },
  apollo: {
    company: {
      query: gql`
        query getCompany($id: String!) {
          companies(filter: [{ name: id, operator: EQ, value: $id }]) {
            name
            tags {
              name
              id
            }
            users {
              name
              id
              position
              role
            }
            channels {
              name
              id
            }
            products {
              name
              id
            }
            personas {
              name
              id
            }
          }
        }
      `,
      update: (data) => data.companies,
      variables() {
        return {
          id: this.selectedCompany.id,
        }
      },
      skip() {
        return !this.selectedCompany
      },
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()

    this.codelist['name'] = this.nameList
    this.codelist['state'] = this.stateCodelist
    this.codelist['stdcs'] = this.stdcsCodelist
    this.codelist['users'] = this.usersCodelist
    this.codelist['channels'] = this.channelsCodelist
    this.codelist['tags'] = this.tagsCodelist
    this.codelist['products'] = this.productsCodelist
    this.codelist['personas'] = this.personasCodelist

    let storageSelectedLanguage = localStorage.getItem('selectedLanguage')
    if (!storageSelectedLanguage) {
      const locale = navigator.language.split('-')[0]

      if (locale !== 'cs' && locale !== 'en') {
        localStorage.setItem('selectedLanguage', 'en')
      } else {
        localStorage.setItem('selectedLanguage', locale)
      }

      storageSelectedLanguage = locale
    }

    this.$i18n.locale = storageSelectedLanguage

    if (storageSelectedLanguage === 'en') {
      this.$moment.locale('en-gb')
    } else {
      this.$moment.locale('cs')
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  computed: {
    refreshContent() {
      return this.$store.getters.getRefreshContent
    },
    user() {
      return this.me
    },
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
    language() {
      return this.$store.getters.getDuplicateContentModalId
    },
    duplicateContentModalId() {
      return this.$store.getters.getDuplicateContentModalId
    },
    showModalFeedback() {
      return this.$store.getters.getFeedbackModal
    },
    showCreateContentModal() {
      return this.$store.getters.getCreateContentModalStatus
    },
    showEditContentModal() {
      return this.$store.getters.getEditContentModalStatus
    },
    showContactModal() {
      return this.$store.getters.getContactModal
    },
    showModalContentDelete() {
      return this.$store.getters.getContentToDelete
    },
    showModalConfirmCloseCreateContent() {
      return this.$store.getters.getModalConfirmCloseCreateContent
    },
    showModalConfirmCloseEditContent() {
      return this.$store.getters.getModalConfirmCloseEditContent
    },
    showModalUserRolesInfo() {
      return this.$store.getters.getModalUserRolesInfo
    },
    showModalInappropriateDevice() {
      const smallResolutionWarning = localStorage.getItem(
        'small_resolution_warning'
      )

      return (
        this.windowWidth !== 0 &&
        this.windowWidth <= 1200 &&
        !smallResolutionWarning
      )
    },
    stateCodelist() {
      let arr = []

      states.forEach((element) => {
        arr.push({
          label: this.$t(element.id),
          value: element.id,
          id: element.id,
        })
      })

      return arr
    },
    stdcsCodelist() {
      let arr = []

      stdcs.forEach((element) => {
        arr.push({
          label:
            element.charAt(0).toUpperCase() + element.slice(1).toLowerCase(),
          value: element,
          id: element,
        })
      })

      return arr
    },
    tagsCodelist() {
      let arr = []
      if (!this.company.length) return []

      const tags = this.company[0].tags

      tags.forEach((element) => {
        arr.push({
          label: element.name,
          value: element.name,
          id: element.id,
        })
      })

      return arr
    },
    productsCodelist() {
      let arr = []
      if (!this.company.length) return []

      const products = this.company[0].products

      products.forEach((element) => {
        arr.push({
          label: element.name,
          value: element.name,
          id: element.id,
        })
      })

      return arr
    },
    personasCodelist() {
      let arr = []
      if (!this.company.length) return []

      const personas = this.company[0].personas

      personas.forEach((element) => {
        arr.push({
          label: element.name,
          value: element.name,
          id: element.id,
        })
      })

      return arr
    },
    usersCodelist() {
      let arr = []
      if (!this.company.length) return []

      const users = this.company[0].users

      users.forEach((element) => {
        arr.push({
          label: element.name,
          value: element.name,
          id: element.id,
          position: element.position,
          role: element.role,
        })
      })

      return arr
    },
    channelsCodelist() {
      let arr = []
      if (!this.company.length) return []

      const channels = this.company[0].channels

      channels.forEach((element) => {
        arr.push({
          label: element.name,
          value: element.name,
          id: element.id,
        })
      })

      return arr
    },
  },
  data() {
    return {
      company: [],
      companies: [],
      codelist: {},
      windowWidth: 0,
      windowHeight: 0,
    }
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
    },
  },
  watch: {
    company() {
      if (!this.company.length) {
        return
      }

      let tags = []
      if (this.company[0].tags && this.company[0].tags.length > 0) {
        this.company[0].tags.forEach((element) => {
          tags.push({
            label: element.name,
            value: element.name,
            id: element.id,
          })
        })
      }

      let products = []
      if (this.company[0].products && this.company[0].products.length > 0) {
        this.company[0].products.forEach((element) => {
          products.push({
            label: element.name,
            value: element.name,
            id: element.id,
          })
        })
      }

      let personas = []
      if (this.company[0].personas && this.company[0].personas.length > 0) {
        this.company[0].personas.forEach((element) => {
          personas.push({
            label: element.name,
            value: element.name,
            id: element.id,
          })
        })
      }

      let users = []
      if (this.company[0].users && this.company[0].users.length > 0) {
        this.company[0].users.forEach((element) => {
          users.push({
            label: element.name,
            value: element.name,
            id: element.id,
            position: element.position,
            role: element.role,
          })
        })
      }

      let channels = []
      if (this.company[0].channels && this.company[0].channels.length > 0) {
        this.company[0].channels.forEach((element) => {
          channels.push({
            label: element.name,
            value: element.name,
            id: element.id,
          })
        })
      }

      this.codelist['products'] = products
      this.codelist['personas'] = personas
      this.codelist['users'] = users
      this.codelist['channels'] = channels
      this.codelist['tags'] = tags

      this.$store.commit('setCodelist', this.codelist)
    },
    refreshContent() {
      this.$apollo.queries.company.refetch()
    },
  },
}
</script>

<style>
.vue-notification-group {
  z-index: 99999 !important;
}

.mt-30 {
  margin-top: 30px;
}

.mt-15 {
  margin-top: 15px;
}

html {
  overflow-y: scroll;
}

@import url('assets/scss/_style.scss');
</style>
