<template>
  <div class="boxed boxed--small">
    <div class="boxed-wrapper">
      <div class="column column--80gap">
        <div class="left">
          <div class="budgets-no-data">
            <h1>{{ $t('empty_cards_channel_title') }}</h1>
            <p>{{ $t('empty_cards_channel_description') }}</p>
            <div class="budgets-no-data-buttons">
              <Button type="primary" @click="handleAdd" size="nav"><IcoPlus /> {{ $t('empty_cards_channel_btn_add') }}</Button>
              <Button @click="handleDelete" size="nav">
                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.33203 4.83333H14.6654M6.33203 8.16667V13.1667M9.66536 8.16667V13.1667M2.16536 4.83333L2.9987 14.8333C2.9987 15.2754 3.17429 15.6993 3.48685 16.0118C3.79941 16.3244 4.22334 16.5 4.66536 16.5H11.332C11.7741 16.5 12.198 16.3244 12.5105 16.0118C12.8231 15.6993 12.9987 15.2754 12.9987 14.8333L13.832 4.83333M5.4987 4.83333V2.33333C5.4987 2.11232 5.5865 1.90036 5.74278 1.74408C5.89906 1.5878 6.11102 1.5 6.33203 1.5H9.66536C9.88638 1.5 10.0983 1.5878 10.2546 1.74408C10.4109 1.90036 10.4987 2.11232 10.4987 2.33333V4.83333" stroke="#888F91" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                {{ $t('empty_cards_channel_btn_delete') }}
              </Button>
            </div>
          </div>
        </div>
        <div class="right">
          <svg width="402" height="241" viewBox="0 0 402 241" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5005_55081)">
              <path d="M156.192 215.535C154.034 216.293 151.717 216.482 149.465 216.086C147.213 215.69 145.1 214.721 143.332 213.272C138.827 209.496 137.415 203.275 136.266 197.515L132.867 180.477L139.983 185.37C145.1 188.89 150.333 192.522 153.876 197.619C157.419 202.717 158.965 209.676 156.119 215.191" fill="#E6E6E6"/>
              <path d="M155.583 236.163C156.303 230.924 157.043 225.617 156.538 220.321C156.09 215.617 154.653 211.023 151.728 207.257C150.176 205.261 148.287 203.551 146.146 202.204C145.588 201.852 145.074 202.736 145.63 203.086C149.334 205.425 152.199 208.877 153.812 212.947C155.593 217.472 155.879 222.405 155.572 227.212C155.386 230.119 154.993 233.008 154.596 235.892C154.565 236.022 154.585 236.16 154.651 236.276C154.717 236.393 154.826 236.48 154.954 236.52C155.084 236.555 155.224 236.538 155.342 236.471C155.459 236.404 155.546 236.294 155.583 236.163Z" fill="#F2F2F2"/>
              <path d="M148.646 226.89C147.721 228.295 146.449 229.438 144.952 230.21C143.456 230.981 141.787 231.354 140.104 231.294C135.78 231.089 132.175 228.075 128.93 225.213L119.332 216.748L125.684 216.445C130.253 216.226 134.939 216.023 139.29 217.43C143.641 218.837 147.654 222.223 148.45 226.722" fill="#E6E6E6"/>
              <path d="M157.619 239.176C154.153 233.05 150.132 226.241 142.948 224.065C140.95 223.462 138.855 223.245 136.777 223.428C136.121 223.485 136.285 224.493 136.939 224.437C140.424 224.148 143.902 225.067 146.787 227.039C149.564 228.927 151.726 231.552 153.556 234.335C154.677 236.04 155.681 237.817 156.685 239.592C157.006 240.159 157.944 239.75 157.619 239.176Z" fill="#F2F2F2"/>
              <path d="M250.527 116.447L174.953 179.009C174.002 179.795 172.778 180.171 171.549 180.056C170.321 179.941 169.188 179.344 168.399 178.396L136.541 140.003C135.755 139.053 135.378 137.831 135.493 136.604C135.608 135.377 136.206 134.245 137.155 133.458L212.729 70.8955C213.68 70.1101 214.904 69.7335 216.133 69.8484C217.361 69.9633 218.494 70.5602 219.282 71.5082L251.14 109.901C251.927 110.851 252.304 112.074 252.189 113.301C252.074 114.528 251.476 115.659 250.527 116.447Z" fill="#CCCCCC"/>
              <path d="M247.498 114.563L173.661 175.687C173.171 176.092 172.541 176.286 171.908 176.227C171.275 176.168 170.691 175.86 170.285 175.372L139.867 138.714C139.462 138.225 139.268 137.595 139.327 136.963C139.386 136.33 139.694 135.748 140.183 135.342L214.02 74.2178C214.51 73.8132 215.14 73.6192 215.773 73.6784C216.406 73.7376 216.99 74.0451 217.396 74.5335L247.814 111.191C248.219 111.681 248.413 112.311 248.354 112.943C248.295 113.575 247.987 114.158 247.498 114.563Z" fill="white"/>
              <path d="M250.905 127.359L235.719 125.939L246.588 117.395L252.443 124.451C252.672 124.72 252.813 125.052 252.85 125.403C252.886 125.754 252.815 126.108 252.647 126.418C252.537 126.629 252.386 126.816 252.203 126.968C252.023 127.116 251.816 127.226 251.593 127.293C251.371 127.36 251.137 127.383 250.905 127.359Z" fill="#CCCCCC"/>
              <path d="M220.405 100.188L183.052 131.11C182.824 131.299 182.561 131.441 182.277 131.528C181.994 131.616 181.697 131.646 181.402 131.619C181.107 131.591 180.82 131.506 180.558 131.367C180.296 131.229 180.064 131.041 179.875 130.813C179.686 130.585 179.543 130.322 179.456 130.039C179.368 129.757 179.338 129.459 179.365 129.165C179.393 128.87 179.478 128.584 179.617 128.322C179.755 128.06 179.944 127.828 180.172 127.639L217.525 96.7177C217.753 96.5289 218.016 96.3867 218.299 96.2995C218.583 96.2122 218.88 96.1814 219.175 96.209C219.47 96.2366 219.757 96.322 220.019 96.4602C220.281 96.5985 220.513 96.7869 220.702 97.0148C220.891 97.2427 221.034 97.5056 221.121 97.7884C221.208 98.0712 221.239 98.3684 221.211 98.6631C221.184 98.9578 221.098 99.2442 220.96 99.5059C220.822 99.7676 220.633 99.9995 220.405 100.188Z" fill="#CCCCCC"/>
              <path d="M202.126 102.882L176.935 123.736C176.474 124.117 175.88 124.3 175.284 124.245C174.689 124.189 174.139 123.899 173.757 123.439C173.376 122.979 173.192 122.386 173.248 121.79C173.304 121.195 173.594 120.647 174.055 120.265L199.246 99.4111C199.707 99.0297 200.301 98.8467 200.897 98.9024C201.492 98.9581 202.042 99.248 202.424 99.7082C202.805 100.168 202.989 100.761 202.933 101.356C202.877 101.952 202.587 102.5 202.126 102.882Z" fill="#CCCCCC"/>
              <path d="M163.091 145.532C166.985 145.532 170.142 142.378 170.142 138.488C170.142 134.599 166.985 131.445 163.091 131.445C159.196 131.445 156.039 134.599 156.039 138.488C156.039 142.378 159.196 145.532 163.091 145.532Z" fill="#2CE0C1"/>
              <path d="M219.182 124.371C220.428 124.371 221.439 123.362 221.439 122.117C221.439 120.872 220.428 119.863 219.182 119.863C217.936 119.863 216.926 120.872 216.926 122.117C216.926 123.362 217.936 124.371 219.182 124.371Z" fill="#E6E6E6"/>
              <path d="M225.264 119.336C226.511 119.336 227.521 118.327 227.521 117.082C227.521 115.837 226.511 114.828 225.264 114.828C224.018 114.828 223.008 115.837 223.008 117.082C223.008 118.327 224.018 119.336 225.264 119.336Z" fill="#E6E6E6"/>
              <path d="M231.346 114.303C232.593 114.303 233.603 113.293 233.603 112.049C233.603 110.804 232.593 109.795 231.346 109.795C230.1 109.795 229.09 110.804 229.09 112.049C229.09 113.293 230.1 114.303 231.346 114.303Z" fill="#E6E6E6"/>
              <path d="M195.38 235.249L200.56 235.249L203.025 215.291L195.379 215.291L195.38 235.249Z" fill="#FFB8B8"/>
              <path d="M194.059 233.561L204.261 233.561H204.261C205.115 233.561 205.96 233.728 206.749 234.055C207.538 234.381 208.255 234.859 208.858 235.462C209.462 236.065 209.941 236.781 210.268 237.569C210.594 238.357 210.763 239.202 210.763 240.054V240.265L194.059 240.266L194.059 233.561Z" fill="#2F2E41"/>
              <path d="M176.786 235.249L181.966 235.249L184.431 215.291L176.785 215.291L176.786 235.249Z" fill="#FFB8B8"/>
              <path d="M175.465 233.561L185.667 233.561H185.667C186.521 233.561 187.366 233.728 188.155 234.055C188.944 234.381 189.661 234.859 190.265 235.462C190.868 236.065 191.347 236.781 191.674 237.569C192.001 238.357 192.169 239.202 192.169 240.054V240.265L175.465 240.266L175.465 233.561Z" fill="#2F2E41"/>
              <path d="M181.985 229.143H181.985L177.579 229.142C177.086 229.143 176.612 228.953 176.258 228.611C175.903 228.269 175.696 227.803 175.68 227.311L175.541 223.461C174.428 192.677 173.276 160.844 180.408 146.536L180.464 146.424L204.076 145.57L208.816 189.004C209.019 190.857 208.945 192.729 208.597 194.56L202.33 227.542C202.245 227.99 202.002 228.391 201.646 228.675C201.289 228.958 200.843 229.104 200.387 229.086L195.439 228.926C195.18 228.916 194.926 228.853 194.692 228.741C194.458 228.629 194.25 228.47 194.079 228.275C193.909 228.079 193.781 227.851 193.702 227.605C193.623 227.358 193.596 227.097 193.621 226.84L197.509 196.334C197.641 195.018 197.521 193.688 197.156 192.416L192.322 175.536C192.282 175.397 192.195 175.275 192.076 175.192C191.957 175.109 191.813 175.069 191.668 175.079C191.523 175.089 191.386 175.149 191.279 175.248C191.173 175.347 191.104 175.479 191.084 175.623L183.869 227.505C183.804 227.958 183.578 228.373 183.232 228.674C182.887 228.975 182.444 229.141 181.985 229.143Z" fill="#2F2E41"/>
              <path d="M184.808 102.148C190.54 102.148 195.186 97.507 195.186 91.782C195.186 86.057 190.54 81.416 184.808 81.416C179.076 81.416 174.43 86.057 174.43 91.782C174.43 97.507 179.076 102.148 184.808 102.148Z" fill="#FFB8B8"/>
              <path d="M202.969 148.777H181.493C181.22 148.777 180.951 148.718 180.702 148.605C180.454 148.492 180.233 148.326 180.055 148.12C179.876 147.914 179.744 147.672 179.668 147.41C179.591 147.148 179.572 146.873 179.612 146.603L180.673 139.346L174.443 122.23C173.799 120.463 173.56 118.575 173.743 116.703C173.926 114.832 174.526 113.026 175.499 111.416C176.471 109.807 177.793 108.435 179.365 107.402C180.938 106.369 182.722 105.701 184.588 105.446C187.245 105.076 189.951 105.566 192.309 106.843C194.668 108.12 196.556 110.117 197.695 112.543L199.064 115.452L204.838 146.532C204.889 146.806 204.879 147.088 204.809 147.357C204.738 147.627 204.609 147.878 204.431 148.092C204.252 148.307 204.029 148.479 203.776 148.597C203.523 148.716 203.248 148.777 202.969 148.777Z" fill="#2CE0C1"/>
              <path d="M157.637 166.983C158.283 166.833 158.889 166.543 159.411 166.134C159.933 165.725 160.359 165.207 160.659 164.616C160.959 164.024 161.126 163.375 161.147 162.712C161.168 162.05 161.044 161.391 160.783 160.781L177.517 123.02L167.944 120.611L154.505 158.487C153.509 158.993 152.734 159.847 152.326 160.886C151.919 161.925 151.907 163.077 152.293 164.125C152.679 165.172 153.436 166.041 154.421 166.568C155.406 167.095 156.55 167.243 157.637 166.983Z" fill="#FFB8B8"/>
              <path d="M175.248 129.773L165.938 125.42C165.673 125.296 165.438 125.117 165.249 124.894C165.06 124.671 164.921 124.41 164.842 124.129C164.762 123.848 164.745 123.553 164.79 123.264C164.835 122.976 164.942 122.7 165.104 122.457L170.762 113.922C171.405 112.572 172.557 111.533 173.965 111.03C175.374 110.528 176.925 110.603 178.278 111.241C179.631 111.878 180.676 113.025 181.183 114.431C181.691 115.836 181.621 117.385 180.987 118.739L178.061 128.515C177.977 128.794 177.834 129.053 177.641 129.273C177.448 129.492 177.21 129.668 176.943 129.787C176.676 129.906 176.387 129.967 176.094 129.965C175.802 129.962 175.513 129.897 175.248 129.773Z" fill="#2CE0C1"/>
              <path d="M227.442 69.8098C227.644 70.4411 227.705 71.1091 227.621 71.7665C227.537 72.424 227.309 73.055 226.954 73.6151C226.599 74.1752 226.126 74.6508 225.567 75.0083C225.008 75.3658 224.378 75.5966 223.72 75.6845L199.817 109.379L192.844 102.399L218.532 71.4738C218.458 70.3603 218.795 69.2582 219.48 68.3763C220.164 67.4945 221.149 66.8941 222.247 66.689C223.346 66.4839 224.481 66.6883 225.439 67.2635C226.396 67.8387 227.109 68.7447 227.442 69.8098Z" fill="#FFB8B8"/>
              <path d="M205.342 105.424C205.376 105.714 205.347 106.008 205.257 106.286C205.167 106.564 205.018 106.819 204.82 107.035L197.911 114.551C197.072 115.789 195.776 116.644 194.307 116.928C192.838 117.213 191.316 116.904 190.074 116.069C188.833 115.235 187.974 113.942 187.685 112.476C187.396 111.009 187.701 109.488 188.533 108.246L192.975 99.0208C193.102 98.7576 193.284 98.5247 193.509 98.3381C193.734 98.1514 193.996 98.0154 194.279 97.9393C194.561 97.8632 194.857 97.8488 195.145 97.8971C195.434 97.9453 195.708 98.0551 195.95 98.2189L204.462 103.977C204.704 104.141 204.908 104.355 205.06 104.605C205.212 104.854 205.308 105.134 205.342 105.424Z" fill="#2CE0C1"/>
              <path d="M198.445 87.4426C198.438 86.5044 198.308 85.5712 198.057 84.6672C196.385 78.4958 190.212 74.7918 184.017 75.7213C182.813 75.3763 181.565 75.2019 180.312 75.2031C177.124 75.2031 174.041 76.3418 171.62 78.4135C169.198 80.4853 167.599 83.3535 167.109 86.5001L162.105 118.674H189.149L193.515 90.5972C193.677 89.5538 193.716 88.4949 193.629 87.4426H198.445Z" fill="#2F2E41"/>
              <path d="M232.057 240.5H142.899C142.787 240.5 142.68 240.456 142.6 240.377C142.521 240.298 142.477 240.19 142.477 240.078C142.477 239.966 142.521 239.859 142.6 239.78C142.68 239.701 142.787 239.656 142.899 239.656H232.057C232.169 239.656 232.277 239.701 232.356 239.78C232.435 239.859 232.48 239.966 232.48 240.078C232.48 240.19 232.435 240.298 232.356 240.377C232.277 240.456 232.169 240.5 232.057 240.5Z" fill="#CCCCCC"/>
              <path d="M321.879 33.2793C322.497 33.2199 323.121 33.2966 323.706 33.504C324.292 33.7114 324.825 34.0446 325.267 34.4799C325.71 34.9153 326.051 35.4423 326.268 36.0238C326.485 36.6053 326.571 37.2271 326.521 37.8456L340.341 43.921L333.749 48.1714L321.636 41.7504C320.601 41.5976 319.658 41.0711 318.987 40.2706C318.315 39.47 317.96 38.4511 317.99 37.407C318.02 36.3628 318.432 35.3659 319.149 34.6051C319.866 33.8444 320.837 33.3726 321.879 33.2793Z" fill="#FFB8B8"/>
              <path d="M377.628 25.7007C383.36 25.7007 388.007 21.0597 388.007 15.3347C388.007 9.60976 383.36 4.96875 377.628 4.96875C371.897 4.96875 367.25 9.60976 367.25 15.3347C367.25 21.0597 371.897 25.7007 377.628 25.7007Z" fill="#FFB8B8"/>
              <path d="M358.844 163.144L353.664 163.143L351.199 143.186L358.845 143.186L358.844 163.144Z" fill="#FFB8B8"/>
              <path d="M360.165 168.159L343.461 168.158V167.947C343.461 167.094 343.629 166.25 343.956 165.462C344.283 164.674 344.761 163.958 345.365 163.355C345.969 162.752 346.686 162.274 347.474 161.947C348.263 161.621 349.109 161.453 349.962 161.453L360.165 161.454L360.165 168.159Z" fill="#2F2E41"/>
              <path d="M380.395 163.144L375.214 163.143L372.75 143.186L380.396 143.186L380.395 163.144Z" fill="#FFB8B8"/>
              <path d="M381.715 168.159L365.012 168.158V167.947C365.012 167.094 365.18 166.25 365.507 165.462C365.833 164.674 366.312 163.958 366.916 163.355C367.52 162.752 368.236 162.274 369.025 161.947C369.814 161.621 370.659 161.453 371.513 161.453L381.716 161.454L381.715 168.159Z" fill="#2F2E41"/>
              <path d="M380.148 158.892C380.07 158.892 379.993 158.887 379.916 158.877L372.333 157.945C371.889 157.891 371.478 157.682 371.173 157.355C370.868 157.028 370.689 156.604 370.666 156.158L368.457 106.455C368.449 106.314 368.395 106.18 368.302 106.073C368.209 105.967 368.083 105.894 367.944 105.868C367.805 105.841 367.662 105.861 367.536 105.925C367.41 105.989 367.309 106.094 367.249 106.222L361.572 125.123L360.403 155.484C360.393 155.746 360.329 156.002 360.215 156.238C360.101 156.474 359.939 156.684 359.739 156.854C359.54 157.024 359.308 157.151 359.057 157.228C358.806 157.304 358.542 157.327 358.281 157.296L351.714 156.524C351.245 156.471 350.812 156.244 350.502 155.888C350.191 155.532 350.025 155.073 350.036 154.601L350.593 126.023L355.042 81.1621L355.241 81.1695L382.293 82.2111L382.05 156.999C382.048 157.501 381.847 157.983 381.49 158.338C381.134 158.693 380.651 158.892 380.148 158.892Z" fill="#2F2E41"/>
              <path d="M381.268 86.3103C381.251 86.3103 381.235 86.3103 381.218 86.3099L353.973 85.5968C353.706 85.5899 353.443 85.5268 353.202 85.4116C352.961 85.2965 352.747 85.132 352.574 84.9287C352.401 84.7254 352.273 84.488 352.198 84.232C352.123 83.9759 352.102 83.7069 352.138 83.4425L357.374 44.9551C357.701 42.3564 358.573 39.8558 359.933 37.6164C361.293 35.3771 363.111 33.4495 365.267 31.9594C367.164 30.655 369.319 29.7713 371.586 29.3675C373.853 28.9637 376.181 29.0492 378.412 29.6183C387.922 32.0253 393.627 42.225 391.13 52.355L383.115 84.8653C383.011 85.2767 382.773 85.6421 382.439 85.9038C382.105 86.1654 381.693 86.3084 381.268 86.3103Z" fill="#3F3D56"/>
              <path d="M345.525 55.7477C343.73 55.7499 341.973 55.2285 340.47 54.2475L324.581 43.8355C324.349 43.6832 324.152 43.4817 324.006 43.2453C323.86 43.0089 323.768 42.7434 323.735 42.4674C323.703 42.1915 323.732 41.9118 323.82 41.6482C323.908 41.3846 324.052 41.1435 324.244 40.9418L326.637 38.4177C326.898 38.141 327.237 37.9493 327.608 37.8675C327.98 37.7856 328.368 37.8175 328.721 37.959L346.469 45.018L362.91 33.2909C363.679 32.7426 364.553 32.3596 365.477 32.1662C366.402 31.9728 367.357 31.9732 368.281 32.1674C369.206 32.3616 370.08 32.7453 370.848 33.2943C371.616 33.8432 372.262 34.5455 372.745 35.3567C373.227 36.1678 373.536 37.0702 373.652 38.0067C373.767 38.9431 373.686 39.8933 373.415 40.7972C373.144 41.701 372.688 42.5387 372.076 43.2574C371.464 43.9761 370.709 44.56 369.859 44.9723L349.539 54.8303C348.288 55.4355 346.916 55.7492 345.525 55.7477Z" fill="#3F3D56"/>
              <path d="M381.222 25.2481C382.993 25.6657 385.129 25.9861 386.394 24.6805C386.619 24.434 386.819 24.1669 386.993 23.8828L388.647 21.36C389.914 19.4275 391.204 17.4445 391.74 15.1973C392.049 13.906 392.097 12.5665 391.882 11.2564C391.668 9.94632 391.195 8.69188 390.491 7.56595C389.787 6.44001 388.866 5.46502 387.781 4.6976C386.697 3.93018 385.471 3.38564 384.174 3.09561C381.704 2.54322 379.287 1.59278 376.761 1.76615C374.236 1.93952 371.275 1.8826 369.155 0.5C369.288 1.11639 370.002 3.83985 370.002 3.83985C370.002 3.83985 366.815 6.75988 366.176 7.48798C367.012 7.11756 367.923 6.94364 368.837 6.97958C369.752 7.01552 370.645 7.26037 371.45 7.69531C372.255 8.13025 372.949 8.7437 373.48 9.48852C374.01 10.2333 374.362 11.0897 374.51 11.9918C374.691 13.1 374.584 14.3291 375.244 15.2387C375.871 16.1038 376.995 16.4069 377.94 16.9064C379.303 17.6817 380.343 18.9177 380.873 20.3915C381.404 21.8653 381.39 23.48 380.833 24.9443L381.222 25.2481Z" fill="#2F2E41"/>
              <path d="M368.762 90.0232H282.735C281.654 90.022 280.617 89.5923 279.852 88.8286C279.088 88.0648 278.657 87.0293 278.656 85.9491V42.2459C278.657 41.1658 279.088 40.1303 279.852 39.3665C280.617 38.6027 281.654 38.1731 282.735 38.1719H368.762C369.843 38.1731 370.88 38.6027 371.645 39.3665C372.409 40.1303 372.839 41.1658 372.841 42.2459V85.9491C372.839 87.0293 372.409 88.0648 371.645 88.8286C370.88 89.5923 369.843 90.0219 368.762 90.0232Z" fill="#CCCCCC"/>
              <path d="M367.771 87.0602H283.722C283.165 87.0596 282.631 86.8382 282.237 86.4448C281.843 86.0513 281.622 85.5179 281.621 84.9614V43.2335C281.622 42.6771 281.843 42.1436 282.237 41.7502C282.631 41.3567 283.165 41.1354 283.722 41.1348H367.771C368.329 41.1354 368.863 41.3567 369.257 41.7502C369.65 42.1436 369.872 42.6771 369.873 43.2335V84.9614C369.872 85.5179 369.65 86.0513 369.257 86.4448C368.863 86.8382 368.329 87.0596 367.771 87.0602Z" fill="white"/>
              <path d="M362.906 97.5956L353.453 88.1543L365.572 88.4603V96.4926C365.575 96.8019 365.485 97.1049 365.313 97.362C365.141 97.6192 364.895 97.8185 364.608 97.9338C364.415 98.0143 364.209 98.056 364 98.0565C363.796 98.0559 363.595 98.0148 363.407 97.9357C363.219 97.8565 363.049 97.741 362.906 97.5956Z" fill="#CCCCCC"/>
              <path d="M357.54 62.226H315.021C314.496 62.226 313.993 62.0179 313.622 61.6474C313.251 61.277 313.043 60.7746 313.043 60.2507C313.043 59.7268 313.251 59.2244 313.622 58.8539C313.993 58.4835 314.496 58.2754 315.021 58.2754H357.54C358.064 58.2754 358.567 58.4835 358.938 58.8539C359.309 59.2244 359.517 59.7268 359.517 60.2507C359.517 60.7746 359.309 61.277 358.938 61.6474C358.567 62.0179 358.064 62.226 357.54 62.226Z" fill="#CCCCCC"/>
              <path d="M343.695 53.8314H315.02C314.76 53.8318 314.502 53.781 314.262 53.6819C314.022 53.5828 313.803 53.4374 313.619 53.2539C313.435 53.0705 313.289 52.8526 313.19 52.6127C313.09 52.3729 313.039 52.1158 313.039 51.8561C313.039 51.5965 313.09 51.3394 313.19 51.0996C313.289 50.8597 313.435 50.6418 313.619 50.4584C313.803 50.2749 314.022 50.1295 314.262 50.0304C314.502 49.9313 314.76 49.8805 315.02 49.8809H343.695C344.22 49.8809 344.723 50.089 345.094 50.4594C345.464 50.8299 345.673 51.3323 345.673 51.8561C345.673 52.38 345.464 52.8824 345.094 53.2529C344.723 53.6233 344.22 53.8314 343.695 53.8314Z" fill="#CCCCCC"/>
              <path d="M297.418 62.2264C300.832 62.2264 303.598 59.4628 303.598 56.0536C303.598 52.6445 300.832 49.8809 297.418 49.8809C294.005 49.8809 291.238 52.6445 291.238 56.0536C291.238 59.4628 294.005 62.2264 297.418 62.2264Z" fill="#2CE0C1"/>
              <path d="M344.439 78.3139C345.531 78.3139 346.416 77.4295 346.416 76.3386C346.416 75.2476 345.531 74.3633 344.439 74.3633C343.346 74.3633 342.461 75.2476 342.461 76.3386C342.461 77.4295 343.346 78.3139 344.439 78.3139Z" fill="#E6E6E6"/>
              <path d="M351.357 78.3139C352.449 78.3139 353.334 77.4295 353.334 76.3386C353.334 75.2476 352.449 74.3633 351.357 74.3633C350.264 74.3633 349.379 75.2476 349.379 76.3386C349.379 77.4295 350.264 78.3139 351.357 78.3139Z" fill="#E6E6E6"/>
              <path d="M358.278 78.3139C359.371 78.3139 360.256 77.4295 360.256 76.3386C360.256 75.2476 359.371 74.3633 358.278 74.3633C357.186 74.3633 356.301 75.2476 356.301 76.3386C356.301 77.4295 357.186 78.3139 358.278 78.3139Z" fill="#E6E6E6"/>
              <path d="M367.431 84.8261C367.5 84.2094 367.703 83.6152 368.026 83.0852C368.349 82.5553 368.784 82.1026 369.301 81.759C369.819 81.4153 370.405 81.1892 371.019 81.0964C371.633 81.0036 372.26 81.0464 372.856 81.2219L381.638 68.9531L384.455 76.2683L375.683 86.7974C375.322 87.7776 374.613 88.5914 373.691 89.0844C372.769 89.5775 371.698 89.7156 370.681 89.4726C369.664 89.2297 368.771 88.6224 368.172 87.766C367.573 86.9097 367.31 85.8636 367.431 84.8261Z" fill="#FFB8B8"/>
              <path d="M377.49 84.1632C377.352 84.1748 377.213 84.1713 377.075 84.1528C376.8 84.116 376.535 84.019 376.301 83.8688C376.067 83.7185 375.869 83.5187 375.721 83.2834L373.865 80.3424C373.661 80.0214 373.559 79.6463 373.571 79.2662C373.584 78.8861 373.711 78.5186 373.936 78.2118L385.177 62.7844L377.871 43.9712C377.218 42.3159 377.243 40.4707 377.939 38.8334C378.636 37.1961 379.949 35.8977 381.595 35.2181C382.525 34.8285 383.53 34.6495 384.538 34.6942C385.545 34.7389 386.53 35.006 387.422 35.4764C388.314 35.9468 389.09 36.6088 389.696 37.4147C390.301 38.2206 390.72 39.1505 390.922 40.1374L395.455 62.2416C395.77 63.8004 395.677 65.4142 395.185 66.9266C394.693 68.439 393.818 69.7988 392.645 70.8744L378.612 83.6732C378.302 83.956 377.908 84.1283 377.49 84.1632Z" fill="#3F3D56"/>
              <path d="M401.077 169.174H311.919C311.807 169.174 311.699 169.13 311.62 169.051C311.541 168.971 311.496 168.864 311.496 168.752C311.496 168.64 311.541 168.533 311.62 168.454C311.699 168.375 311.807 168.33 311.919 168.33H401.077C401.189 168.33 401.296 168.375 401.376 168.454C401.455 168.533 401.499 168.64 401.499 168.752C401.499 168.864 401.455 168.971 401.376 169.051C401.296 169.13 401.189 169.174 401.077 169.174Z" fill="#CCCCCC"/>
              <path d="M108.934 64.1345H22.907C21.8256 64.1333 20.7888 63.7037 20.0241 62.9399C19.2595 62.1761 18.8293 61.1406 18.8281 60.0604V16.3573C18.8293 15.2771 19.2595 14.2416 20.0241 13.4778C20.7888 12.714 21.8256 12.2844 22.907 12.2832H108.934C110.015 12.2844 111.052 12.714 111.817 13.4778C112.581 14.2416 113.011 15.2771 113.013 16.3573V60.0605C113.011 61.1406 112.581 62.1761 111.817 62.9399C111.052 63.7037 110.015 64.1333 108.934 64.1345Z" fill="#CCCCCC"/>
              <path d="M107.943 61.1715H23.8942C23.3371 61.1709 22.803 60.9496 22.4091 60.5561C22.0152 60.1627 21.7936 59.6292 21.793 59.0728V17.3448C21.7936 16.7884 22.0152 16.2549 22.4091 15.8615C22.803 15.468 23.3371 15.2467 23.8942 15.2461H107.943C108.5 15.2467 109.034 15.468 109.428 15.8615C109.822 16.2549 110.044 16.7884 110.045 17.3448V59.0728C110.044 59.6292 109.822 60.1627 109.428 60.5561C109.034 60.9496 108.5 61.1709 107.943 61.1715Z" fill="white"/>
              <path d="M103.078 71.707L93.625 62.2656L105.744 62.5716V70.6039C105.747 70.9132 105.657 71.2162 105.485 71.4734C105.313 71.7305 105.067 71.9298 104.78 72.0451C104.587 72.1256 104.381 72.1673 104.172 72.1679C103.968 72.1672 103.766 72.1261 103.579 72.047C103.391 71.9679 103.22 71.8523 103.078 71.707Z" fill="#CCCCCC"/>
              <path d="M97.7114 36.3373H55.1925C54.668 36.3373 54.165 36.1292 53.7941 35.7587C53.4232 35.3883 53.2148 34.8859 53.2148 34.362C53.2148 33.8381 53.4232 33.3357 53.7941 32.9653C54.165 32.5948 54.668 32.3867 55.1925 32.3867H97.7114C98.2359 32.3867 98.739 32.5948 99.1098 32.9653C99.4807 33.3357 99.6891 33.8381 99.6891 34.362C99.6891 34.8859 99.4807 35.3883 99.1098 35.7587C98.739 36.1292 98.2359 36.3373 97.7114 36.3373Z" fill="#CCCCCC"/>
              <path d="M83.868 27.9428H55.1925C54.668 27.9428 54.165 27.7347 53.7941 27.3642C53.4232 26.9938 53.2148 26.4914 53.2148 25.9675C53.2148 25.4436 53.4232 24.9412 53.7941 24.5707C54.165 24.2003 54.668 23.9922 55.1925 23.9922H83.868C84.3925 23.9922 84.8956 24.2003 85.2664 24.5707C85.6373 24.9412 85.8457 25.4436 85.8457 25.9675C85.8457 26.4914 85.6373 26.9938 85.2664 27.3642C84.8956 27.7347 84.3925 27.9428 83.868 27.9428Z" fill="#CCCCCC"/>
              <path d="M37.5902 36.3377C41.0034 36.3377 43.7703 33.5741 43.7703 30.165C43.7703 26.7558 41.0034 23.9922 37.5902 23.9922C34.1771 23.9922 31.4102 26.7558 31.4102 30.165C31.4102 33.5741 34.1771 36.3377 37.5902 36.3377Z" fill="#2CE0C1"/>
              <path d="M84.6065 52.4271C85.6987 52.4271 86.5842 51.5428 86.5842 50.4518C86.5842 49.3609 85.6987 48.4766 84.6065 48.4766C83.5143 48.4766 82.6289 49.3609 82.6289 50.4518C82.6289 51.5428 83.5143 52.4271 84.6065 52.4271Z" fill="#E6E6E6"/>
              <path d="M91.5284 52.4271C92.6206 52.4271 93.506 51.5428 93.506 50.4518C93.506 49.3609 92.6206 48.4766 91.5284 48.4766C90.4362 48.4766 89.5508 49.3609 89.5508 50.4518C89.5508 51.5428 90.4362 52.4271 91.5284 52.4271Z" fill="#E6E6E6"/>
              <path d="M98.4503 52.4271C99.5425 52.4271 100.428 51.5428 100.428 50.4518C100.428 49.3609 99.5425 48.4766 98.4503 48.4766C97.3581 48.4766 96.4727 49.3609 96.4727 50.4518C96.4727 51.5428 97.3581 52.4271 98.4503 52.4271Z" fill="#E6E6E6"/>
              <path d="M105.367 8.08392C104.782 8.29408 104.251 8.62968 103.81 9.06708C103.37 9.50448 103.031 10.0331 102.817 10.6156C102.603 11.1981 102.519 11.8203 102.572 12.4385C102.625 13.0567 102.813 13.6558 103.123 14.1936L93.2266 25.5848L101.005 26.6141L109.21 15.639C110.08 15.0593 110.707 14.181 110.972 13.1705C111.237 12.16 111.122 11.0875 110.648 10.1562C110.174 9.22497 109.375 8.49966 108.402 8.11772C107.428 7.73578 106.348 7.72376 105.367 8.08392Z" fill="#FFB8B8"/>
              <path d="M39.2906 12.6458C39.5016 13.2294 39.5821 13.852 39.5262 14.4699C39.4703 15.0879 39.2794 15.6861 38.967 16.2223C38.6546 16.7586 38.2283 17.22 37.718 17.5739C37.2078 17.9278 36.6261 18.1657 36.0139 18.2707L33.5513 33.1507L27.793 27.8267L31.0144 14.5127C30.9058 13.4737 31.1826 12.4311 31.7922 11.5823C32.4019 10.7336 33.3021 10.1377 34.3223 9.90757C35.3424 9.67745 36.4116 9.82909 37.3273 10.3338C38.243 10.8384 38.9416 11.6611 39.2906 12.6458Z" fill="#FFB8B8"/>
              <path d="M54.7548 163.144L59.9352 163.143L62.3995 143.186L54.7539 143.186L54.7548 163.144Z" fill="#FFB8B8"/>
              <path d="M53.4336 161.454L63.6356 161.453H63.636C64.4898 161.453 65.3352 161.621 66.124 161.947C66.9128 162.274 67.6296 162.752 68.2333 163.355C68.837 163.958 69.3159 164.674 69.6426 165.462C69.9694 166.25 70.1375 167.094 70.1375 167.947V168.158L53.4339 168.159L53.4336 161.454Z" fill="#2F2E41"/>
              <path d="M28.1337 163.144L33.3141 163.143L35.7784 143.186L28.1328 143.186L28.1337 163.144Z" fill="#FFB8B8"/>
              <path d="M26.8125 161.454L37.0145 161.453H37.0149C37.8687 161.453 38.7141 161.621 39.5029 161.947C40.2917 162.274 41.0085 162.752 41.6122 163.355C42.2159 163.958 42.6948 164.674 43.0216 165.462C43.3483 166.25 43.5165 167.094 43.5164 167.947V168.158L26.8128 168.159L26.8125 161.454Z" fill="#2F2E41"/>
              <path d="M53.6751 26.8824C59.4069 26.8824 64.0534 22.2414 64.0534 16.5164C64.0534 10.7914 59.4069 6.15039 53.6751 6.15039C47.9434 6.15039 43.2969 10.7914 43.2969 16.5164C43.2969 22.2414 47.9434 26.8824 53.6751 26.8824Z" fill="#FFB8B8"/>
              <path d="M52.4291 156.513L51.571 101.897C51.5688 101.743 51.5102 101.594 51.4062 101.48C51.3022 101.366 51.16 101.294 51.0063 101.277C50.8527 101.26 50.6981 101.3 50.5718 101.389C50.4455 101.478 50.3562 101.61 50.3206 101.76L36.2535 155.182C36.1476 155.609 35.898 155.987 35.5465 156.253C35.1949 156.519 34.7626 156.656 34.322 156.642L26.5936 156.311C26.3308 156.3 26.0733 156.235 25.8372 156.119C25.6011 156.003 25.3917 155.84 25.222 155.639C25.0524 155.438 24.9263 155.205 24.8516 154.953C24.777 154.701 24.7555 154.436 24.7884 154.176L36.7249 85.5257L36.7494 85.332L69.9965 86.9032L70.2134 86.9131L63.0839 156.627C63.0465 157.104 62.8307 157.55 62.4795 157.876C62.1282 158.202 61.6671 158.384 61.1878 158.385H54.3304C53.8313 158.383 53.3529 158.186 52.9975 157.836C52.6422 157.486 52.4381 157.011 52.4291 156.513Z" fill="#2F2E41"/>
              <path d="M78.687 91.5288L30.4412 87.5742C30.15 87.5503 29.8682 87.4598 29.6177 87.3095C29.3673 87.1593 29.1548 86.9534 28.9969 86.7079C28.839 86.4623 28.7399 86.1837 28.7072 85.8937C28.6746 85.6037 28.7093 85.3101 28.8087 85.0357L36.4064 64.0547L36.8436 39.1525C36.8595 38.2486 37.0549 37.3567 37.4185 36.5288C37.7821 35.7008 38.3067 34.9532 38.9619 34.3293C39.617 33.7055 40.3897 33.2177 41.235 32.8944C42.0803 32.5711 42.9815 32.4186 43.8863 32.4459L47.0947 29.541H58.8499L59.8734 30.8814L68.0528 32.1368C69.5097 32.3665 70.854 33.058 71.8873 34.1093C72.9206 35.1606 73.588 36.5159 73.7911 37.975L80.727 89.3827C80.7631 89.6509 80.7414 89.9237 80.6634 90.1828C80.5854 90.442 80.4529 90.6815 80.2747 90.8853C80.0965 91.0891 79.8767 91.2526 79.6302 91.3647C79.3836 91.4768 79.1159 91.535 78.845 91.5354C78.7926 91.5354 78.7398 91.5333 78.687 91.5288Z" fill="#2F2E41"/>
              <path d="M69.2891 34.5505L89.8603 28.5305L99.1702 16.2877C99.4265 15.9505 99.7883 15.7085 100.198 15.6003C100.608 15.492 101.042 15.5236 101.432 15.6901L107.245 18.1717C107.492 18.2775 107.715 18.4347 107.897 18.6329C108.08 18.8311 108.218 19.0657 108.302 19.3213C108.387 19.5768 108.416 19.8475 108.388 20.1152C108.36 20.3829 108.276 20.6417 108.14 20.8742L101.183 32.7996C100.05 34.7504 98.3251 36.2909 96.2582 37.1986L72.0625 47.8817L69.2891 34.5505Z" fill="#2F2E41"/>
              <path d="M29.8232 43.5568C28.5938 42.6722 27.6134 41.4861 26.9766 40.1129C26.3399 38.7397 26.0681 37.2257 26.1876 35.7171L27.0904 23.7699C27.1276 23.2927 27.3434 22.847 27.6948 22.5216C28.0462 22.1961 28.5074 22.0148 28.9866 22.0137H29.0034L35.8199 22.0743C36.0992 22.0768 36.3744 22.1407 36.6262 22.2615C36.8779 22.3823 37.0999 22.557 37.2764 22.7731C37.4529 22.9893 37.5796 23.2416 37.6474 23.5122C37.7153 23.7828 37.7227 24.0649 37.669 24.3387L36.4043 30.784L45.43 34.5932L37.8295 49.362L29.8232 43.5568Z" fill="#2F2E41"/>
              <path d="M59.7116 4.0859C59.7116 4.0859 48.8584 -1.75114 42.9427 5.13782C37.027 12.0268 45.1965 22.7996 45.1965 22.7996L56.837 24.266C56.837 24.266 57.2595 16.3625 60.031 16.5159C62.8025 16.6693 71.4099 6.69627 59.7116 4.0859Z" fill="#2F2E41"/>
              <path d="M90.0806 168.752H0.92255C0.810483 168.752 0.703005 168.708 0.623762 168.629C0.544518 168.55 0.5 168.442 0.5 168.33C0.5 168.218 0.544518 168.111 0.623762 168.032C0.703005 167.953 0.810483 167.908 0.92255 167.908H90.0806C90.1927 167.908 90.3001 167.953 90.3794 168.032C90.4586 168.111 90.5032 168.218 90.5032 168.33C90.5032 168.442 90.4586 168.55 90.3794 168.629C90.3001 168.708 90.1927 168.752 90.0806 168.752Z" fill="#CCCCCC"/>
            </g>
            <defs>
              <clipPath id="clip0_5005_55081">
                <rect width="401" height="240" fill="white" transform="translate(0.5 0.5)"/>
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/button/Button.vue";
import IcoPlus from '@/components/svg/IcoPlus.vue';

export default {
  components: {
    Button,
    IcoPlus
  },
  computed: {

  },
  data() {
    return {}
  },
  methods: {
    handleAdd() {
      this.$emit('add');
    },
    handleDelete() {
      this.$emit('delete');
    }
  }
}
</script>

<style scoped>
.boxed-wrapper {
  padding: 30px;
}

.right {
  min-width: 402px;
  max-width: 402px;
}

.right svg {
  width: 100% !important;
  height: auto !important;
}

.left {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.budgets-no-data {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
}

.budgets-no-data h1 {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 800;
  line-height: 28.8px;
  text-align: left;
  color: #17252A;
}

.budgets-no-data p {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: #888F91;
}

.budgets-no-data-buttons {
  display: flex;
  align-items: center;
  gap: 16px;
}
</style>
