<template>
  <ContentWrapper disable-top-offset="true">
    <SlickMenu :disable-back="true" :title="'Meeting Notes'" >
      <Button size="nav" type="primary" @click="handleAddNote">
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.0013 4.66602V16.3327M4.16797 10.4993H15.8346" stroke="white" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ $t('Add Meeting Note') }}</Button>
    </SlickMenu>

    <div class="items" style="margin-top: 80px;">
      <MeetingNotesStatusCard
        type="note"
      />

      <MeetingNotesStatusCard type="note" />
    </div>

    <Modal
      type="source"
      v-if="showModalNote"
      @closeModal="showModalNote = false"
    >
      <MeetingNoteModal
        @close="showModalNote = false"
      />
    </Modal>

    <Modal
      type="source"
      v-if="showModalNoteAdd"
      @closeModal="showModalNoteAdd = false"
    >
      <MeetingNoteModalAdd
        @close="showModalNoteAdd = false"
      />
    </Modal>
  </ContentWrapper>
</template>
<script>
import Button from "@/components/button/Button.vue";
import ContentWrapper from "@/components/sources/ContentWrapper.vue";
import SlickMenu from "@/components/sources/components/SlickMenu.vue";
import MeetingNotesStatusCard from "@/components/sources/components/meetingNotes/MeetingNotesStatusCard.vue";
import MeetingStatusCard from "@/components/sources/components/meetingNotes/MeetingStatusCard.vue";
import IcoAdd from "@/components/svg/IcoAdd.vue";
import Modal from "@/components/Modal.vue";
import MeetingNoteModal from "@/components/sources/components/meetingNotes/MeetingNoteModal.vue";
import MeetingNoteModalAdd from "@/components/sources/components/meetingNotes/MeetingNoteModalAdd.vue";

export default {
  components: {
    MeetingNoteModalAdd,
    MeetingNoteModal,
    Modal, IcoAdd, MeetingStatusCard, MeetingNotesStatusCard, SlickMenu, ContentWrapper, Button},
  data() {
    return {
      showModalNote: false,
      showModalNoteAdd: false,

      idea: {
        date: '20241001',
        name: 'test',
        state: 'note',
        dates: [],
        channel: [],
        personas: [],
        stdcs: []
      }
    }
  },
  methods: {
    handleAddNote() {
      this.showModalNoteAdd = true;
    }
  }
}
</script>
