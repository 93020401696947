<template>
  <div class="sources-profile-progress">
    <div class="sources-profile-progress-header">
      <div class="sources-profile-progress-header-title">
        {{ $t('15_0_4_2_title') }}
      </div>
      <div @click="handleClose" class="sources-profile-progress-header-control">
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 5.5L5 15.5M5 5.5L15 15.5" stroke="#888F91" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>

    <div class="sources-profile-progress-wrapper">
      <div class="sources-profile-progress-progress">
        <div class="sources-profile-progress-progress-state"></div>
      </div>
    </div>

    <div class="sources-profile-progress-state">
      <div class="sources-profile-progress-state-actual">
        3 / 10 {{ $t('15_0_4_2_completed') }}&nbsp;-&nbsp;
      </div>
      <div class="sources-profile-progress-state-slogan">
        {{ $t('15_0_4_2_slogan') }}
      </div>
    </div>

    <vuescroll :ops="ops">
      <div class="sources-profile-progress-items">
        <SourcesProfileProgressItem
          v-for="(item, index) in items"
          :key="index"
          :data="item"
        />
      </div>
    </vuescroll>

  </div>
</template>

<script>
import vuescroll from 'vuescroll';
import SourcesProfileProgressItem
  from "@/components/sources/components/sourcesProfileProgress/SourcesProfileProgressItem.vue";

export default {
  components: {
    vuescroll,
    SourcesProfileProgressItem

  },
  computed: {

  },
  data() {
    return {
      isCollapsed: false,
      items: [
        {
          id: 1,
          title: this.$t('15_0_4_2_item_title_fill_up_company_info'),
          completed: true,
        },
        {
          id: 2,
          title: this.$t('15_0_4_2_item_title_add_at_least_one_team_member'),
          completed: true,
        },
        {
          id: 3,
          title: this.$t('15_0_4_2_item_title_fill_up_brand_and_identity_info'),
          completed: true,
        },
        {
          id: 4,
          title: this.$t('15_0_4_2_item_title_add_at_least_one_lean_canvas'),
          completed: false,
        },
        {
          id: 5,
          title: this.$t('15_0_4_2_item_title_set_up_one_scale_and_do_dont_rule_in_the_tone_of_voice'),
          completed: false,
        },
        {
          id: 6,
          title: this.$t('15_0_4_2_item_title_add_at_least_one_persona'),
          completed: false,
        },
        {
          id: 7,
          title: this.$t('15_0_4_2_item_title_add_at_least_one_product'),
          completed: false,
        },
        {
          id: 8,
          title: this.$t('15_0_4_2_item_title_set_up_least_one_stdc_framework'),
          completed: false,
        },
        {
          id: 9,
          title: this.$t('15_0_4_2_item_title_add_at_least_one_channel'),
          completed: false,
        },
        {
          id: 10,
          title: this.$t('15_0_4_2_item_title_add_at_least_one_competitor'),
          completed: false,
        },
      ],
      ops: {
        vuescroll: {},
        scrollPanel: {
          scrollingX: false,
          scrollingY: true,
          initialScrollY: false,
          initialScrollX: false,
        },
        rail: {},
        bar: {
          onlyShowBarOnScroll: true,
          keepShow: false,
          minSize: 0,
        }
      },
    }
  },
  methods: {
    handleClose() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
.sources-profile-progress {
  position: fixed;
  bottom: 0;
  right: 20px;
  z-index: 9992;
  box-shadow: 1px 1px 60px 0px #0000001A;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 314px;
  background: #fff;
  padding-bottom: 16px;
}

.sources-profile-progress-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.sources-profile-progress-header-title {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 800;
  line-height: 19.2px;
  text-align: left;
  color: #17252A;
}

.sources-profile-progress-header-control {
  cursor: pointer;
}

.sources-profile-progress-wrapper {
  padding-left: 16px;
  padding-right: 16px;
}

.sources-profile-progress-progress {
  position: relative;
  background: #E7E9E9;
  border-radius: 8px;
  width: 100%;
  height: 8px;
}

.sources-profile-progress-progress-state {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 30%;
  border-radius: 8px;
  background-color: #9795FF;
}

.sources-profile-progress-state {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  display: flex;
  justify-content: flex-start;
}

.sources-profile-progress-state-actual {
  font-family: Nunito;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.4px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #888F91;
}

.sources-profile-progress-state-slogan {
  font-family: Nunito;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.4px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #7977CC;
}

.sources-profile-progress-state {
  padding-bottom: 16px;
  border-bottom: 1px solid #E7E9E9;
}

.sources-profile-progress-items {
  display: flex;
  flex-direction: column;
  max-height: 314px;
  padding-left: 16px;
  padding-right: 16px;
}
</style>
