<template>
  <div class="modal-edit-content">
    <div class="modal-edit-content-header">
      <div class="modal-edit-content-title">
        <strong>{{ $t('15_8_1_3_modal_edit_title') }}</strong>
      </div>
      <button @click="handleCancel" >
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 6.5L6 18.5M6 6.5L18 18.5" stroke="#888F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>

    <div class="modal-edit-content-body">
      <div class="column">
        <FormGroup
          :label="$t('15_8_1_3_label_title')"
          :required="true"
          :focused="focusName"
          :validationError="validationError.name"
        >
          <input
            type="text"
            :placeholder="$t('15_8_1_3_placeholder_title')"
            v-model="stdc.name"
            @input="validationError.name = false"
            @focus="focusName = true"
            @blur="focusName = false"
            ref="contentName"
          />
        </FormGroup>
      </div>

      <div class="column">
        <FormGroup
          :label="$t('15_8_1_3_label_description')"
          :required="false"
        >
          <textarea
            class="form-group__input large"
            :placeholder="$t('15_8_1_3_placeholder_description')"
            v-model="stdc.description"
          ></textarea>
        </FormGroup>
      </div>

      <div class="column">
        <Dropdown
          v-model="persona"
          :label="$t('15_8_1_3_label_competitors')"
          :placeholder="$t('15_8_1_3_placeholder_competitors')"
          :items="personasValues"
          :multiple="true"
        />
      </div>

      <div class="column">
        <Dropdown
          v-model="product"
          :label="$t('15_8_1_3_label_product_and_services')"
          :placeholder="$t('15_8_1_3_placeholder_product_and_services')"
          :items="productValues"
          :multiple="true"
          items-position="top"
        />
      </div>
    </div>

    <div class="modal-edit-content-footer">
      <Button @click="handleCancel" type="secondary">{{ $t('15_8_1_3_modal_edit_btn_cancel') }}</Button>
      <Button v-if="type === 'create'" @click="handleCreate" type="primary">{{ $t('15_8_1_3_modal_edit_btn_save') }}</Button>
      <Button v-if="type === 'edit'" @click="handleUpdate" type="primary">{{ $t('15_8_1_3_modal_edit_btn_edit') }}</Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/button/Button.vue";
import Dropdown from "@/components/dropdown/Dropdown.vue";
import TextAreaEditor from "@/components/TextAreaEditor.vue";
import textLength from "@/constants/TextLengthConstant";
import WYSIWYGEditor from "@/components/WYSIWYGEditor.vue";
import TinymceEditor from "@/components/TinymceEditor.vue";
import FormGroup from "@/components/FormGroup.vue";
import CompanyLogo from "@/components/CompanyLogo.vue";
import ButtonAdd from "@/components/buttonCopy/ButtonAdd.vue";
import ButtonRemove from "@/components/buttonRemove/ButtonRemove.vue";
import {obj_clone} from "../../../../services/helpers/clone";
import STDC_ALLOWED_RESOURCES from '@/graphql/StdcAllowedResources.gql'

export default {
  components: {
    ButtonAdd,
    ButtonRemove,
    CompanyLogo,
    FormGroup,
    TinymceEditor,
    WYSIWYGEditor,
    TextAreaEditor,
    Button,
    Dropdown
  },
  computed: {
    type() {
      if(this.stdcToEdit !== null) {
        return 'edit';
      }
      return 'create';
    },
    length() {
      return textLength
    },
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    personasValues() {
      const selectedPersonaIds = this.personaList.map(persona => persona.value.value);

      return this.personas
        .filter(persona => !selectedPersonaIds.includes(persona.id))
        .map(persona => ({
          name: persona.name,
          value: persona.id
        }));
    },
    personasToSave() {
      return this.persona.map((item) => item.value);
    },
    productValues() {
      const selectedProductsIds = this.productList.map(product => product.value.value);

      return this.products
        .filter(product => !selectedProductsIds.includes(product.id))
        .map(product => ({
          name: product.name,
          value: product.id
        }));
    },
    productsToSave() {
      return this.product.map((item) => item.value);
    },
    personaIds() {
      return this.persona.map((item) => item.value);
    },
    productIds() {
      return this.product.map((item) => item.value);
    }
  },
  props: [
    'stdcToEdit',
    'selectedPersonas',
    'selectedProducts'
  ],
  created() {
    if (this.stdcToEdit) {
      this.stdc.name = this.stdcToEdit.name;
      this.stdc.description = this.stdcToEdit.description;

      this.stdc.product = [];
      if(this.stdcToEdit.products && this.stdcToEdit.products.length) {
        this.product = this.stdcToEdit.products.map((item) => {
          return {
            name: item.name,
            value: item.value
          }
        });
      }

      this.stdc.persona = [];
      if (this.stdcToEdit.personas && this.stdcToEdit.personas.length) {
        this.persona = this.stdcToEdit.personas.map((item) => {
          return {
            name: item.name,
            value: item.value
          }
        });
      }
    }

   this.fetchAvailableResources();
  },
  data() {
    return {
      persona: [],
      product: [],

      personas: [],
      products: [],

      emptyRecord: {
        name: null,
        value: ''
      },
      personaList: [{value: obj_clone(this.emptyRecord)}],
      productList: [{value: obj_clone(this.emptyRecord)}],
      description: '',
      focusName: false,
      validationError: {
        name: false,
      },
      content: '',
      contentIsOpenShowError: false,
      contentIsOpen: true,
      stdc: {
        name: '',
        companyId: '',
        phases: [
          {
            phaseType: 'SEE',
            phaseData: {
              channelIds: [],
              ratio: 25,
              state: '',
              action: '',
              reaction: '',
              trigger: ''
            }
          },
          {
            phaseType: 'THINK',
            phaseData: {
              action: '',
              channelIds: [],
              ratio: 25,
              reaction: '',
              state: '',
              trigger: ''
            }
          },
          {
            phaseType: 'DO',
            phaseData: {
              action: '',
              channelIds: [],
              ratio: 25,
              reaction: '',
              state: '',
              trigger: ''
            }
          },
          {
            phaseType: 'CARE',
            phaseData: {
              action: '',
              channelIds: [],
              ratio: 25,
              reaction: '',
              state: '',
              trigger: ''
            }
          }
        ],
      },
    }
  },
  methods: {
    async fetchAvailableResources() {
      try {
        const stdc = {
          companyId: this.$route.params.id,
          personaIds: this.personaIds ?? [],
          productIds: this.productIds ?? [],
          name: ''
        };

        const response = await this.$apollo.query({
          query: STDC_ALLOWED_RESOURCES,
          variables: {
            id: this.$route.params.id,
            stdc: stdc,
          }
        });

        this.personas = response.data.companies[0].stdcAllowedResources.allowedPersonas;
        this.products = response.data.companies[0].stdcAllowedResources.allowedProducts;

      } catch(e) {}
    },
    handleCancel() {
      this.$emit('close');
    },
    handleCreate() {
      if (this.stdc.name === '') {
        this.validationError.name = true;
        return;
      }

      this.stdc.productIds = this.product.map((item) => item.value);
      this.stdc.personaIds = this.persona.map((item) => item.value);
      this.$emit('create', this.stdc);
    },
    handleUpdate() {
      if (this.stdc.name === '') {
        this.validationError.name = true;
        return;
      }

      this.stdc.productIds = this.product.map((item) => item.value);
      this.stdc.personaIds = this.persona.map((item) => item.value);
      this.$emit('update', this.stdc);
    }
  },
  watch: {
    product() {
      this.fetchAvailableResources();
    },
    persona() {
      this.fetchAvailableResources();
    }
  }
}
</script>

<style scoped>
.form-group__input.large {
  height: 100px;
}

.modal-edit-content {
  width: 600px;
}

.modal-edit-content-header {
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-edit-content-header button {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.modal-edit-content-title {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
  text-align: left;
  color: #17252A;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.modal-edit-content-title span {
  position: relative;
  font-family: Nunito;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  display: inline-block;
  color: #888F91;
}

.modal-edit-content-title span:after {
  content: '';
  position: absolute;
  left: -10px;
  top: 4px;
  bottom: 4px;
  width: 1px;
  background-color: #888F91;
}

.modal-edit-content-body {
  margin-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  gap: 30px;
  display: flex;
  flex-direction: column;
}

.modal-edit-content-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 32px;
  border-top: 1px solid #E7E9E9;
  padding: 32px;
}
</style>
