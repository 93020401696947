<template>
  <div class="rules-card">
    <div class="rules-card-data">
      <div class="rules-card-title">
        {{ rule.name }}
        <div class="rules-card-title-control">
          <button @click="handleEdit">
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.66602 5.16675H3.99935C3.64573 5.16675 3.30659 5.30722 3.05654 5.55727C2.80649 5.80732 2.66602 6.14646 2.66602 6.50008V12.5001C2.66602 12.8537 2.80649 13.1928 3.05654 13.4429C3.30659 13.6929 3.64573 13.8334 3.99935 13.8334H9.99935C10.353 13.8334 10.6921 13.6929 10.9422 13.4429C11.1922 13.1928 11.3327 12.8537 11.3327 12.5001V11.8334M10.666 3.83341L12.666 5.83341M13.5894 4.89007C13.8519 4.62751 13.9994 4.27139 13.9994 3.90007C13.9994 3.52875 13.8519 3.17264 13.5894 2.91007C13.3268 2.64751 12.9707 2.5 12.5993 2.5C12.228 2.5 11.8719 2.64751 11.6094 2.91007L5.99935 8.50007V10.5001H7.99935L13.5894 4.89007Z" stroke="#888F91" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
          <button v-if="!confirmDelete" @click="handleDelete">
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.66602 5.16667H13.3327M6.66602 7.83333V11.8333M9.33268 7.83333V11.8333M3.33268 5.16667L3.99935 13.1667C3.99935 13.5203 4.13982 13.8594 4.38987 14.1095C4.63992 14.3595 4.97906 14.5 5.33268 14.5H10.666C11.0196 14.5 11.3588 14.3595 11.6088 14.1095C11.8589 13.8594 11.9993 13.5203 11.9993 13.1667L12.666 5.16667M5.99935 5.16667V3.16667C5.99935 2.98986 6.06959 2.82029 6.19461 2.69526C6.31964 2.57024 6.4892 2.5 6.66602 2.5H9.33268C9.50949 2.5 9.67906 2.57024 9.80409 2.69526C9.92911 2.82029 9.99935 2.98986 9.99935 3.16667V5.16667" stroke="#888F91" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
          <button
            v-if="confirmDelete"
            class="confirm-delete"
            @click="handleConfirmDelete"
          >
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.66602 5.16667H13.3327M6.66602 7.83333V11.8333M9.33268 7.83333V11.8333M3.33268 5.16667L3.99935 13.1667C3.99935 13.5203 4.13982 13.8594 4.38987 14.1095C4.63992 14.3595 4.97906 14.5 5.33268 14.5H10.666C11.0196 14.5 11.3588 14.3595 11.6088 14.1095C11.8589 13.8594 11.9993 13.5203 11.9993 13.1667L12.666 5.16667M5.99935 5.16667V3.16667C5.99935 2.98986 6.06959 2.82029 6.19461 2.69526C6.31964 2.57024 6.4892 2.5 6.66602 2.5H9.33268C9.50949 2.5 9.67906 2.57024 9.80409 2.69526C9.92911 2.82029 9.99935 2.98986 9.99935 3.16667V5.16667" stroke="#F45050" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
      </div>
      <div class="rules-card-description">{{ rule.description }}</div>
    </div>
    <div class="rules-card-do">
      <FormGroup
        :label="$t('15_5_2_label_do')"
        label-color="#23B39A"
        :focused="focusedDo"
      >
        <textarea
          class="form-group__input large"
          :placeholder="$t('15_5_2_placeholder_do')"
          v-model="rule.Do"
          @input="validationError.content = false"
          @focus="focusedDo = true"
          @blur="focusedDo = false"
          ref="do"
        ></textarea>
      </FormGroup>
    </div>
    <div class="rules-card-dont">
      <FormGroup
        :label="$t('15_5_2_label_dont')"
        label-color="#DB0E0E"
        :focused="focusedDont"
      >
        <textarea
          class="form-group__input large"
          :placeholder="$t('15_5_2_placeholder_dont')"
          v-model="rule.Dont"
          @input="validationError.content = false"
          @focus="focusedDont = true"
          @blur="focusedDont = false"
          ref="dont"
        ></textarea>
      </FormGroup>
    </div>
  </div>
</template>

<script>
import FormGroup from "@/components/FormGroup.vue";
import Button from "../../../button/Button.vue";

export default {
  components: {
    Button,
    FormGroup

  },
  computed: {

  },
  props: {
    rule: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      confirmDelete: false,
      focusedDo: false,
      focusedDont: false,
      content: '',
      validationError: {
        content: ''
      }
    }
  },
  methods: {
    handleEdit() {
      this.$emit('edit');
    },
    handleDelete() {
      this.confirmDelete = true;
      setTimeout(() => {
        this.confirmDelete = false;
      }, 2000);
    },
    handleConfirmDelete() {
      this.confirmDelete = false;
      this.$emit('delete');
    }
  }
}
</script>

<style scoped>
.rules-card {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
}

.rules-card-data {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: left;
}

.rules-card-title {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  color: #17252A;
  display: flex;
  gap: 8px;
}

.rules-card-title-control {
  display: flex;
  gap: 4px;
  opacity: 0;
}

.rules-card-title:hover .rules-card-title-control {
  opacity: 1;
}

.rules-card-title-control button {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.rules-card-title-control button.confirm-delete {
  color: rgb(219, 14, 14);
}

.rules-card-title-control button.confirm-delete svg path{
  stroke: rgb(219, 14, 14);
}

.rules-card-description {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: #888F91;
}

.rules-card-do {
  width: 100%;
}

.rules-card-dont {
  width: 100%;
}
</style>
