<template>
  <ContentWrapper>
    <SlickMenu :disable-back="true" :title="$t('15_0_4_2_title_main')">
      <!--
      <Button size="nav" type="secondary">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.1667 14.1667H15.8333C16.2754 14.1667 16.6993 13.9911 17.0118 13.6785C17.3244 13.366 17.5 12.942 17.5 12.5V9.16667C17.5 8.72464 17.3244 8.30072 17.0118 7.98816C16.6993 7.67559 16.2754 7.5 15.8333 7.5H4.16667C3.72464 7.5 3.30072 7.67559 2.98816 7.98816C2.67559 8.30072 2.5 8.72464 2.5 9.16667V12.5C2.5 12.942 2.67559 13.366 2.98816 13.6785C3.30072 13.9911 3.72464 14.1667 4.16667 14.1667H5.83333M14.1667 7.5V4.16667C14.1667 3.72464 13.9911 3.30072 13.6785 2.98816C13.366 2.67559 12.942 2.5 12.5 2.5H7.5C7.05797 2.5 6.63405 2.67559 6.32149 2.98816C6.00893 3.30072 5.83333 3.72464 5.83333 4.16667V7.5M5.83333 12.5C5.83333 12.058 6.00893 11.634 6.32149 11.3215C6.63405 11.0089 7.05797 10.8333 7.5 10.8333H12.5C12.942 10.8333 13.366 11.0089 13.6785 11.3215C13.9911 11.634 14.1667 12.058 14.1667 12.5V15.8333C14.1667 16.2754 13.9911 16.6993 13.6785 17.0118C13.366 17.3244 12.942 17.5 12.5 17.5H7.5C7.05797 17.5 6.63405 17.3244 6.32149 17.0118C6.00893 16.6993 5.83333 16.2754 5.83333 15.8333V12.5Z" stroke="#17252A" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ $t('15_5_1_btn_print') }}
      </Button>
      -->

      <Button size="nav" type="primary" @click="handleSave()">
        {{ $t('15_5_1_btn_save') }}
      </Button>
    </SlickMenu>

    <div class="tone-of-voice-cards">
      <div class="tone-of-voice-scales">
        <div class="boxed boxed--small boxed--first">
          <div class="box">
            <div class="tone-of-voice-scales-header">
              <div class="tone-of-voice-scales-header-title">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 20H17M6 6L12 5L18 6M6 6L9 12C9 12.7956 8.68393 13.5587 8.12132 14.1213C7.55871 14.6839 6.79565 15 6 15C5.20435 15 4.44129 14.6839 3.87868 14.1213C3.31607 13.5587 3 12.7956 3 12L6 6ZM18 6L21 12C21 12.7956 20.6839 13.5587 20.1213 14.1213C19.5587 14.6839 18.7956 15 18 15C17.2044 15 16.4413 14.6839 15.8787 14.1213C15.3161 13.5587 15 12.7956 15 12L18 6ZM12 3V20" stroke="#9795FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <h2>{{ $t('15_5_1_title_scales') }}</h2>
              </div>
              <div class="tone-of-voice-scales-header-control">
                <Button @click="handleAddCustomScale" size="small"><IcoPlus color="#17252A" />{{ $t('15_5_1_add_custom_scale') }}</Button>
              </div>
            </div>

            <ScaleCard
              v-for="(item, index) in scales"
              :key="index"
              :scale="item"
              type="custom"
              @deleteScale="deleteScale(index)"
              @editScale="editScale(index)"
              @setCustomScale="setCustomScale($event, index)"
            />

            <Accordion
              v-if="computedAdditionalScales.length > 0"
              :title="$t('15_5_1_accordion_title')"
              :description="$t('15_5_1_accordion_description')"
            >
              <ScaleCard
                v-for="(item, index) in computedAdditionalScales"
                type="additional"
                :key="index"
                :scale="item"
                @appendScale="appendScale"
                @setAdditionalScale="setAdditionalScale($event, index)"
              />
            </Accordion>
          </div>
        </div>
      </div>

      <div class="tone-of-voice-rules">
        <div class="boxed boxed--small">
          <div class="box">
            <div class="tone-of-voice-rules-header">
              <div class="tone-of-voice-rules-header-title">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 7H10M7 4V10M20 18H14M5 19L19 5" stroke="#9795FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <h2>{{ $t('15_5_1_title_do_dont_rules') }}</h2>
              </div>
              <div class="tone-of-voice-rules-header-control">
                <Button @click="handleAddCustomRule" size="small"><IcoPlus color="#17252A" />{{ $t('15_5_1_btn_add_custom_rule') }}</Button>
              </div>
            </div>

            <div class="tone-of-voice-rules-cards">
              <RulesCard
                v-for="(rule, index) in rules"
                :key="`pending-rules-${index}`"
                :rule="rule"
                @edit="editRule(index)"
                @delete="deleteRule(index)"
              />
            </div>

            <Accordion
              v-if="computedAdditionalRules.length > 0"
              :title="$t('15_5_1_accordion_dodont_title')"
              :description="$t('15_5_1_accordion_dodont_placeholder')"
            >
              <div class="tone-of-voice-rules-cards">
                <RulesCard
                  v-for="(rule, index) in computedAdditionalRules"
                  :key="`default-rules-${index}`"
                  :rule="rule"
                />
              </div>
            </Accordion>
          </div>
        </div>
      </div>

    </div>

    <Modal
      type="source"
      v-if="showModalRule"
      @closeModal="showModalRule = false"
    >
      <ModalRule
        :ruleToEdit="ruleToEdit"
        @close="showModalRule = false"
        @addRule="addRule"
        @updateRule="updateRule"
      />
    </Modal>

    <Modal
      type="source"
      v-if="showModalScale"
      @closeModal="showModalScale = false"
    >
      <ModalScale
        :scaleToEdit="scaleToEdit"
        @close="showModalScale = false"
        @addScale="addScale"
        @updateScale="updateScale"
      />
    </Modal>

    <ModalConfirmClose
      v-if="showLeaveModal"
      @close="handleModalLeaveClose"
      @confirm="handleModalLeaveConfirm"
    />
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/sources/ContentWrapper.vue";
import IcoPlus from "@/components/svg/IcoPlus.vue";
import Button from "@/components/button/Button.vue";
import Accordion from "@/components/accordion/Accordion.vue";
import FormGroup from "@/components/FormGroup.vue";
import RulesCard from "@/components/sources/components/toneOfVoice/RulesCard.vue";
import ScaleCard from "@/components/sources/components/toneOfVoice/ScaleCard.vue";
import SlickMenu from "@/components/sources/components/SlickMenu.vue";
import Modal from "@/components/Modal.vue";
import ModalScale from "@/components/sources/components/toneOfVoice/ModalScale.vue";
import ModalRule from "@/components/sources/components/toneOfVoice/ModalRule.vue";
import COMPANY_TONE_OF_VOICE_UPDATE from '@/graphql/CompanyToneOfVoiceUpdate.gql'
import COMPANY_TONE_OF_VOICE from '@/graphql/CompanyToneOfVoice.gql'
import utils from "../../../utils/utils";
import ModalConfirmClose from "../../ModalConfirmClose.vue";
import {arrayClone, obj_clone} from "../../../services/helpers/clone";

export default {
  components: {
    ModalConfirmClose,
    ModalRule,
    ModalScale,
    Modal,
    SlickMenu,
    ScaleCard,
    RulesCard,
    FormGroup,
    Accordion,
    ContentWrapper,
    IcoPlus,
    Button
  },
  apollo: {
    companies: {
      query: COMPANY_TONE_OF_VOICE,
      variables() {
        return {
          id: this.companyID,
        }
      },
      update: function(data) {
        const companies = data.companies.filter((item) => item.id === this.companyID);
        const company = companies[0];

        try {
          if(company && company.toneOfVoice) {
            const rules = company.toneOfVoice.rules ?? [];
            const scales = company.toneOfVoice.scales ?? [];

            this.rules = arrayClone(rules);
            this.originalRules = arrayClone(rules);

            this.scales = arrayClone(scales);
            this.originalScales = arrayClone(scales);
          }

          this.checkAndSetDefaultRulesAndScales();

        } catch(e) {
          console.log("error: ", e);
          this.rules = arrayClone([]);
          this.originalRules = arrayClone([]);

          this.scales = arrayClone([]);
          this.originalScales = arrayClone([]);

          this.checkAndSetDefaultRulesAndScales();
        }
      }
    },
  },
  computed: {
    companyID() {
      return this.$route.params.id;
    },
    computedAdditionalScales() {
      return this.additionalScales.filter(additionalScale => {
        return !this.scales.some(scale => JSON.stringify(scale.scale) === JSON.stringify(additionalScale.scale));
      });
    },
    computedAdditionalRules() {
      return this.additionalRules.filter(additionalRule => {
        return !this.rules.some(rule => rule.name === additionalRule.name);
      });
    },
    hasRulesChanged() {
      return JSON.stringify(this.rules) !== JSON.stringify(this.originalRules) || JSON.stringify(this.scales) !== JSON.stringify(this.originalScales);
    }
  },
  data() {
    const formalChoices = [
      this.$t('15_5_3_scale_formal_formal'),
      this.$t('15_5_3_scale_formal_text'),
      this.$t('15_5_3_scale_formal_neutral'),
      this.$t('15_5_3_scale_formal_text'),
      this.$t('15_5_3_scale_formal_casual'),
    ],
      seriousChoices = [
        this.$t('15_5_3_scale_serious_serious'),
        this.$t('15_5_3_scale_serious_text'),
        this.$t('15_5_3_scale_serious_neutral'),
        this.$t('15_5_3_scale_serious_text'),
        this.$t('15_5_3_scale_serious_playful'),
    ],
      respectfulChoices = [
        this.$t('15_5_3_scale_respectful_respectful'),
        this.$t('15_5_3_scale_respectful_text'),
        this.$t('15_5_3_scale_respectful_neutral'),
        this.$t('15_5_3_scale_respectful_text'),
        this.$t('15_5_3_scale_respectful_irreverent'),
    ],
      matterOfFactChoices = [
        this.$t('15_5_3_scale_matter_of_fact_matter_of_fact'),
        this.$t('15_5_3_scale_matter_of_fact_text'),
        this.$t('15_5_3_scale_matter_of_fact_neutral'),
        this.$t('15_5_3_scale_matter_of_fact_text'),
        this.$t('15_5_3_scale_matter_of_fact_irreverent'),
    ];
    return {
      scales: [],
      originalScales: [],
      scaleType: '',
      scaleToEdit: {},
      scaleToEditIndex: null,
      showModalScale: false,
      additionalScales: [
        {
          example: '',
          characteristic: '',
          selected: this.$t('15_5_3_scale_formal_neutral'),
          scale: formalChoices,
        },
        {
          example: '',
          characteristic: '',
          selected: this.$t('15_5_3_scale_serious_neutral'),
          scale: seriousChoices,
        },
        {
          example: '',
          characteristic: '',
          selected: this.$t('15_5_3_scale_respectful_neutral'),
          scale: respectfulChoices,
        },
        {
          example: '',
          characteristic: '',
          selected: this.$t('15_5_3_scale_matter_of_fact_neutral'),
          scale: matterOfFactChoices,
        }
      ],
      rule: {
        description: '',
        Do: '',
        Dont: '',
        name: ''
      },
      rules: [],
      originalRules: [],
      ruleType: '',
      ruleToEdit: null,
      ruleToEditIndex: null,
      showModalRule: false,
      content: '',
      validationError: {
        content: '',
      },
      additionalRules: [
        {
          name: this.$t('15_5_3_rule_general_title'),
          description: this.$t('15_5_3_rule_general_description'),
          Do: '',
          Dont: ''
        },
        {
          name: this.$t('15_5_3_rule_brand_title_title'),
          description: this.$t('15_5_3_rule_brand_title_description'),
          Do: '',
          Dont: ''
        },
        {
          name: this.$t('15_5_3_rule_communication_with_customer_title'),
          description: this.$t('15_5_3_rule_communication_with_customer_description'),
          Do: '',
          Dont: ''
        },
        {
          name: this.$t('15_5_3_rule_reaching_out_to_customers_title'),
          description: this.$t('15_5_3_rule_reaching_out_to_customers_description'),
          Do: '',
          Dont: ''
        },
        {
          name: this.$t('15_5_3_rule_brand_glossary_title'),
          description: this.$t('15_5_3_rule_brand_glossary_description'),
          Do: '',
          Dont: ''
        },
        {
          name: this.$t('15_5_3_rule_frequently_used_phrases_and_expressions_title'),
          description: this.$t('15_5_3_rule_frequently_used_phrases_and_expressions_description'),
          Do: '',
          Dont: ''
        },
        {
          name: this.$t('15_5_3_rule_brand_personality_title'),
          description: this.$t('15_5_3_rule_brand_personality_description'),
          Do: '',
          Dont: ''
        },
        {
          name: this.$t('15_5_3_rule_themes_areas_title'),
          description: this.$t('15_5_3_rule_themes_areas_description'),
          Do: '',
          Dont: ''
        }
      ],
      showLeaveModal: false,
      nextRoute: null,
    }
  },
  methods: {
    deleteScale(index) {
      this.scales.splice(index, 1);
      this.checkAndSetDefaultRulesAndScales();
    },
    editScale(index) {
      const scale = this.scales[index];
      this.scaleToEdit = scale;
      this.scaleToEditIndex = index;
      this.showModalScale = true;
    },
    setCustomScale(selectedScale, index) {
      this.scales[index].selected = selectedScale;
    },
    setAdditionalScale(selectedScale, index) {
      this.computedAdditionalScales[index].selected = selectedScale;
    },
    appendScale(scale) {
      this.scales.push(scale);
    },
    updateScale(scale) {
      this.scales[this.scaleToEditIndex].scale = scale;
      this.scaleToEdit = {};
      this.scaleToEditIndex = null;
      this.showModalScale = false;
    },
    handleModalLeaveClose() {
      this.showLeaveModal = false;
    },
    handleModalLeaveConfirm() {
      this.rules = arrayClone(this.originalRules);
      this.scales = arrayClone(this.originalScales);

      this.showLeaveModal = false;
      if (this.nextRoute) {
        this.$router.push(this.nextRoute);
      }
    },
    initPage() {
      this.$apollo.queries.companies.refetch()
    },
    handleSave() {
      const additionalRules = this.computedAdditionalRules.filter((item) => item.Do || item.Dont);
      const rules = [...utils.removeTypename(this.rules), ...utils.removeTypename(additionalRules)];

      const additionalScales = this.computedAdditionalScales.filter((item) => item.example || item.characteristic);
      const scales = [...utils.removeTypename(this.scales), ...utils.removeTypename(additionalScales)];

      try {
        this.$apollo.mutate({
          mutation: COMPANY_TONE_OF_VOICE_UPDATE,
          variables: {
            id: this.companyID,
            toneOfVoice: {
              rules: rules,
              scales: scales
            }
          },
          update: (store, data) => {
            this.rules = arrayClone(data.data.updateCompany.toneOfVoice.rules);
            this.originalRules = arrayClone(data.data.updateCompany.toneOfVoice.rules);

            this.scales = arrayClone(data.data.updateCompany.toneOfVoice.scales);
            this.originalScales = arrayClone(data.data.updateCompany.toneOfVoice.scales);
          },
        }).then((data) => {
          this.$notify({
            title: this.$t('15_5_3_toaster_success_title_saved'),
            text: this.$t('15_5_3_toaster_success_description_saved'),
            type: 'success',
            duration: 5000,
          })
        }).catch((error) => {
          console.log("error: ", error);
        })

      } catch(e) {
        this.$notify({
          title: this.$t('15_5_3_toaster_error_title_saved'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    handleAddCustomScale() {
      this.scaleToEdit = null;
      this.scaleToEditIndex = null;
      this.showModalScale = true;
    },
    handleAddCustomRule() {
      this.ruleToEdit = null;
      this.ruleToEditIndex = null;
      this.showModalRule = true;
    },
    addRule(rule) {
      this.rules.push(rule);
    },
    updateRule(rule) {
      this.rules[this.ruleToEditIndex] = rule;
      this.ruleToEditIndex = null;
      this.ruleToEdit = null;
      this.showModalRule = false;
    },
    addScale(scale) {
      this.scales.push(scale);
    },
    editRule(index) {
      this.ruleToEdit = this.rules[index];
      this.ruleToEditIndex = index;
      this.showModalRule = true;
    },
    deleteRule(index) {
      this.rules[index].Do = '';
      this.rules[index].Dont = '';
      const deletedRule = this.additionalRules.filter((item) => item.name ===  this.rules[index].name);

      if (deletedRule && Array.isArray(deletedRule) && deletedRule.length > 0) {
        deletedRule[0].Do = '';
        deletedRule[0].Dont = '';
      }

      this.rules.splice(index, 1);
      this.checkAndSetDefaultRulesAndScales();
    },
    confirmLeave(to, from, next) {
      if (this.hasRulesChanged) {
        // Show the modal if there are unsaved changes
        this.showLeaveModal = true;
        this.nextRoute = to; // Store the route the user wants to go to
        next(false); // Prevent immediate navigation
      } else {
        // No changes, allow navigation
        next();
      }
    },
    checkAndSetDefaultRulesAndScales() {
      if (this.rules.length === 0) {
        this.rules.push( obj_clone(this.additionalRules[0]) );
        this.originalRules.push( obj_clone(this.additionalRules[0]) );
      }
      if (this.scales.length === 0) {
        this.scales.push( obj_clone(this.additionalScales[0]) );
        this.originalScales.push( obj_clone(this.additionalScales[0]) );
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.confirmLeave(to, from, next);
  },
  watch: {
    '$route.params': 'initPage',
  }
}
</script>

<style scoped>
.tone-of-voice-scales-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tone-of-voice-scales-header-title {
  display: flex;
  align-items: center;
  gap: 12px;
}

.tone-of-voice-scales-header-title h2{
  font-family: Nunito;
  font-size: 21px;
  font-weight: 800;
  line-height: 25.2px;
  text-align: left;
  color: #17252A;
}

.tone-of-voice-rules-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tone-of-voice-rules-header-title {
  display: flex;
  align-items: center;
  gap: 12px;
}

.tone-of-voice-rules-header-title h2{
  font-family: Nunito;
  font-size: 21px;
  font-weight: 800;
  line-height: 25.2px;
  text-align: left;
  color: #17252A;
}
</style>

<style>
.tone-of-voice-rules-cards .rules-card {
  border-bottom: 1px solid #E7E9E9;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.tone-of-voice-rules-cards .rules-card:last-child {
  border-bottom: none;
  padding: 0;
  margin: 0;
}
</style>
