<template>
  <div class="hint"><slot /></div>
</template>

<script>
export default {
  components: {

  },
  computed: {

  },
  data() {
    return {}
  },
  methods: {

  }
}
</script>

<style scoped>
  .hint {
    padding: 2px 0;
    font-family: Nunito;
    font-size: 10px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #888F91;
  }
</style>
