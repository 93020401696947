<template>
  <div @click="handleClick" class="smc">
    <div class="smc-header">
      <div class="smc-header-title">
        {{ content.name }}
      </div>
      <div class="smc-header-status">
        <Status :state="content.state" />
      </div>
    </div>
    <div class="smc-data">
      {{ sizes.readableFileSize(content.storageUsed) }}
    </div>
  </div>
</template>

<script>
import Status from "../status/Status.vue";
import sizes from "../../../../utils/sizes";

export default {
  name: 'StorageManagementCard',
  computed: {
    sizes() {
      return sizes
    }
  },
  components: {Status},
  props: ['content', 'type'],
  methods: {
    handleClick() {
      this.$store.commit('setEditContentModalStatus', this.content);
    }
  }
}
</script>

<style scoped>
  div.smc {
    display: flex;
    justify-content: space-between;
    align-items: center;

    box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.05);
    background: rgba(255, 255, 255, 1);
    padding: 16px;
    border-radius: 8px;

    cursor: pointer;
  }

  .smc-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
  }

  .smc-header-title {
    font-family: Nunito;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    text-align: left;
    color: rgba(23, 37, 42, 1);
  }

  .smc-data {
    font-family: Nunito;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.82px;
    text-align: left;
    color: rgba(136, 143, 145, 1);
  }
</style>
