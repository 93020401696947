<template>
  <div class="menu-wrapper">
    <div class="menu clearfix">
      <div class="menu__controll">
        <div class="menu__group">
          <Logo />
          <CompanySelect />
        </div>

        <div class="menu__items">
          <ul>
            <li
              :class="{
                'is-selected':
                  ($route.name === 'Calendar' ||
                    $route.name === 'CalendarDay' ||
                    $route.name === 'CalendarWeek' ||
                    $route.name === 'List') &&
                  selectedCompany &&
                  !selectedCompany.billingOverdue,
                'is-disabled':
                  selectedCompany && selectedCompany.billingOverdue,
              }"
            >
              <router-link to="/calendar">
                {{ $t('5_1_menu_calendar') }}
              </router-link>
            </li>
            <li
              :class="{
                'is-disabled':
                  selectedCompany && selectedCompany.billingOverdue,
                'is-selected': $route.name === 'Dashboard',
              }"
            >
              <router-link :to="{ name: 'Dashboard' }">
                {{ $t('5_1_menu_dashboard') }}
              </router-link>
            </li>
            <li
              :class="{
                'is-disabled':
                  selectedCompany && selectedCompany.billingOverdue,
                'is-selected': isRouteActiveCompany,
              }"
            >
              <a @click.prevent="handleOpenCompany">
                {{ $t('5_1_menu_company') }}
              </a>
            </li>
            <li
              :class="{
                'is-disabled':
                  selectedCompany && selectedCompany.billingOverdue,
                'is-selected': $route.name === 'Ideas',
              }"
            >
              <router-link to="/ideas">{{ $t('5_1_menu_ideas') }}</router-link>
            </li>
          </ul>
        </div>

        <div v-if="role !== 'READ_ONLY'" class="menu__create">
          <button
            v-if="selectedCompany && !selectedCompany.billingOverdue"
            @click="addContent"
            class="menu__create__btn"
          >
            <IcoPlus /> {{ $t('5_1_menu_add_content') }}
          </button>
        </div>
      </div>

      <div class="menu__icons">
        <MenuNotification />
        <MenuUser />
        <MenuFeedback />
      </div>
    </div>

    <MenuRenewsSubscription
      v-if="
        selectedCompany &&
        selectedCompany.expirationDays &&
        selectedCompany.showExpirationWarning &&
        !selectedCompany.billingOverdue
      "
      :days="selectedCompany.expirationDays"
    />
  </div>
</template>

<script>
import IcoPlus from '@/components/svg/IcoPlus.vue'
import CompanySelect from '@/components/CompanySelect.vue'
import MenuUser from '@/components/MenuUser.vue'
import Logo from '@/components/Logo.vue'
import MenuNotification from '@/components/MenuNotification.vue'
import MenuFeedback from '@/components/MenuFeedback.vue'
import MenuRenewsSubscription from '@/components/MenuRenewsSubscription.vue'

export default {
  components: {
    MenuRenewsSubscription,
    IcoPlus,
    MenuUser,
    MenuNotification,
    MenuFeedback,
    CompanySelect,
    Logo,
  },
  computed: {
    me() {
      return this.$store.getters.getMe
    },
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
    isRouteActiveCompany() {
      const parentRoute = this.$route.matched[0];
      if (
        this.selectedCompany &&
        parentRoute.name === 'Company' &&
        this.$route.params.id &&
        this.$route.params.id === this.selectedCompany.id
      ) {
        return true
      }

      return this.$route.name === 'Company' && !this.$route.params.id
    },
  },

  methods: {
    addContent() {
      this.$store.commit('setCreateContentDefaultValue', {})
      this.$store.commit('setCreateContentModalStatus', true)
    },
    handleOpenCompany() {
      this.$router.push({ path: `/company/${this.selectedCompany.id}/company-info` });
    }
  },
}
</script>

<style>
li.is-disabled a {
  opacity: 0.5;
  pointer-events: none;
  color: #aeb3b4;
}
</style>
